import { StepNames } from "@/containers/Integrations/ConnectionSteps";
import Tile from "../../Tile/Tile";
import GDrive from "@/images/gdrive.svg";
import { useCallback, useContext, useEffect } from "react";
import { IntegrationApp } from "@/containers/Integrations/types";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { authenticateGDrive } from "@/lib/utils";
import { useParams, useSearchParams } from "react-router-dom";

type GDriveConnectorProps = {
  onSuccess?: (stepName: StepNames) => void;
  activeIntegration?: string;
};

export default function GDriveConnector({
  onSuccess,
  activeIntegration
}: GDriveConnectorProps) {
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(IntegrationConnectionsContext);

  useEffect(() => {
    if (isAppActive(IntegrationApp.GDRIVE)) {
      onSuccess?.("gdrive-picker");
      setSearchParams({ });
    }
  }, [isAppActive, onSuccess]);

  const authenticateClient = useCallback(async () => {
    if (isAppConnected(IntegrationApp.GDRIVE)) {
      return onSuccess?.("gdrive-picker");
    }
    authenticateGDrive(artifactId);
  }, [artifactId, isAppConnected, onSuccess]);

  return (
    <Tile
      onPress={authenticateClient}
      label="Google"
      src={GDrive}
      connected={isAppConnected(IntegrationApp.GDRIVE)}
      selected={activeIntegration === "gdrive-picker"}
      isLoading={isLoading}
    />
  );
}
