import { useState, useCallback } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  RowData
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";

import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { AccessLevel, UserSettings } from "./table-columns";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectIcon
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

declare module "@tanstack/react-table" {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
    openEditDialog: (user: BrevUser) => void;
  }
}

interface DataTableProps<TData extends BrevUser, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  onUpdateData: (rowIndex: number, columnId: string, value: unknown) => void;
  openEditDialog: (user: BrevUser) => void;
}

export function DataTable<TData extends BrevUser, TValue>({
  columns,
  data,
  onUpdateData,
  openEditDialog
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [massEditDialogOpen, setMassEditDialogOpen] = useState(false);
  const [massEditData, setMassEditData] = useState<Partial<BrevUser>>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    meta: {
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        onUpdateData(rowIndex, columnId, value);
      },
      openEditDialog: (user: BrevUser) => {
        openEditDialog(user);
      }
    }
  });

  const selectedRows = table.getSelectedRowModel().rows;

  const handleMassEdit = () => {
    setMassEditData({});
    setMassEditDialogOpen(true);
  };

  const handleSaveMassEdit = useCallback(() => {
    selectedRows.forEach((row) => {
      Object.entries(massEditData).forEach(([columnId, value]) => {
        const rowIndex = data.findIndex(
          (item) => item.userId === row.original.userId
        );
        if (rowIndex !== -1) {
          onUpdateData(rowIndex, columnId, value);
        }
      });
    });
    setMassEditDialogOpen(false);
  }, [selectedRows, massEditData, data, onUpdateData]);

  const handleMassEditChange = useCallback(
    (columnId: keyof BrevUser | keyof UserSettings, value: unknown) => {
      setMassEditData((prev) => ({ ...prev, [columnId]: value }));
    },
    []
  );

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} />
      <div className="flex justify-between">
        <Button onClick={handleMassEdit} disabled={selectedRows.length === 0}>
          Edit Selected Users ({selectedRows.length})
        </Button>
      </div>
      <div className="rounded-[8px] border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />

      <Dialog open={massEditDialogOpen} onOpenChange={setMassEditDialogOpen}>
        <DialogContent className="sm:max-w-[625px] h-[600px]">
          <DialogHeader>
            <DialogTitle>Edit {selectedRows.length} Selected Users</DialogTitle>
          </DialogHeader>
          <div className="grid gap-4 py-4 overflow-y-scroll overflow-x-hidden scrollbar-webkit-standard">
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="aiService" className="text-right">
                Service
              </Label>
              <Select
                value={massEditData.aiService}
                onValueChange={(value) =>
                  handleMassEditChange("aiService", value)
                }
              >
                <SelectTrigger className="w-[180px] bg-brev text-brev-light">
                  <SelectValue placeholder="Select Service" />
                  <SelectIcon />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={"ChatGPT"}>ChatGPT</SelectItem>
                  <SelectItem value={"Claude"}>Claude</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="accessLevel" className="text-right">
                Access Level
              </Label>
              <Select
                value={massEditData.accessLevel}
                onValueChange={(value) =>
                  handleMassEditChange("accessLevel", value)
                }
              >
                <SelectTrigger className="w-[180px] bg-brev text-brev-light">
                  <SelectValue placeholder="Select Access Level" />
                  <SelectIcon />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value={AccessLevel.BASIC}>Basic</SelectItem>
                  <SelectItem value={AccessLevel.PRO}>Pro</SelectItem>
                  <SelectItem value={AccessLevel.ADMIN}>Admin</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="nextBestActionsEnabled" className="text-right">
                Enable Next Best Actions
              </Label>
              <Switch
                checked={massEditData.userSettings?.nextBestActionsEnabled}
                onCheckedChange={(checked) =>
                  handleMassEditChange("nextBestActionsEnabled", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="useCompanyMemory" className="text-right">
                Use Company Memory
              </Label>
              <Switch
                checked={massEditData.userSettings?.useCompanyMemory}
                onCheckedChange={(checked) =>
                  handleMassEditChange("useCompanyMemory", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="useV3" className="text-right">
                Attribute #1
              </Label>
              <Switch
                checked={massEditData.userSettings?.useV3}
                onCheckedChange={(checked) =>
                  handleMassEditChange("useV3", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="attribute2" className="text-right">
                Attribute #2
              </Label>
              <Switch
                checked={massEditData.userSettings?.attribute2}
                onCheckedChange={(checked) =>
                  handleMassEditChange("attribute2", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="attribute3" className="text-right">
                Attribute #3
              </Label>
              <Switch
                checked={massEditData.userSettings?.attribute3}
                onCheckedChange={(checked) =>
                  handleMassEditChange("attribute3", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="attribute4" className="text-right">
                Attribute #4
              </Label>
              <Switch
                checked={massEditData.userSettings?.attribute4}
                onCheckedChange={(checked) =>
                  handleMassEditChange("attribute4", checked)
                }
              />
            </div>
            <div className="flex justify-between items-center mx-4">
              <Label htmlFor="attribute5" className="text-right">
                Attribute #5
              </Label>
              <Switch
                checked={massEditData.userSettings?.attribute5}
                onCheckedChange={(checked) =>
                  handleMassEditChange("attribute5", checked)
                }
              />
            </div>
          </div>
          <DialogFooter>
            <Button onClick={handleSaveMassEdit}>Save Changes</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
