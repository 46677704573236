import { VisualType, VisualWidget } from "../review-types";
import { BarWidget } from "./BarWidget";
import { LineWidget } from "./LineWidget";
// import ChartWidget from "./ChartWidget";
import TableWidget from "./TableWidget";
// import { PieWidget } from "./PieWidget";
// import KPIWidget from "./KPIWidget";
// import GoalsWidget from "./GoalsWidget";
import { MdError } from "react-icons/md";
import { AreaWidget } from "./AreaWidget";

type GenericDataWidgetProps = {
  visualWidget: VisualWidget;
  isPreview?: boolean;
  isEditable?: boolean;
};

export default function GenericDataWidget({
  visualWidget,
  isEditable
}: GenericDataWidgetProps) {
  const { visualType: rawVisualType, dataset } = visualWidget;
  const visualType =
    (rawVisualType?.toUpperCase() as VisualType) || VisualType.TABLE;
  if (!dataset || !dataset.rows) {
    return (
      <div className="h-[30px] w-1/2 bg-brev-light dark:bg-dark-alt rounded-[8px] my-3 flex flex-row justify-center gap-2 items-center">
        <div>No data to display</div>
        <MdError />
      </div>
    );
  }

  const transformedData = dataset.row_headers.map((rowHeader, rowIndex) => {
    const dataPoint: { [key: string]: string | number } = {
      name: rowHeader
    };
    if (Array.isArray(dataset.rows[rowIndex])) {
      dataset.col_headers.slice(1).forEach((colHeader, colIndex) => {
        if (dataset.rows[rowIndex][colIndex + 1] !== undefined) {
          const value = dataset.rows[rowIndex][colIndex + 1];
          if (value) {
            const cleanedValue =
              typeof value === "string" ? value.replace(/[$,%]/g, "") : value;
            const parsedValue = parseFloat(cleanedValue.toString());
            dataPoint[colHeader] = isNaN(parsedValue)
              ? cleanedValue
              : parsedValue;
          }
        }
      });
    }
    return dataPoint;
  });

  // return <code>{JSON.stringify(transformedData)} {JSON.stringify(dataset)}</code>;
  switch (visualType) {
    case VisualType.TABLE:
      return <TableWidget content={dataset!} />;
    case VisualType.LINE:
      // return <ChartWidget dataset={dataset!} isPreview={isPreview} />;
      return <LineWidget content={transformedData} isEditable={isEditable} />;
    case VisualType.BAR:
      return <BarWidget content={transformedData} isEditable={isEditable} />;
    // case VisualType.PIE:
    //   return <PieWidget dataset={dataset!} isPreview={isPreview} />;
    case VisualType.AREA:
      return <AreaWidget content={transformedData} isEditable={isEditable} />;
    // case VisualType.DONUT:
    //   return <PieWidget2 dataset={dataset!} isPreview={isPreview} />;
    // case VisualType.KPI:
    //   return <KPIWidget isPreview={isPreview} />;
    // case VisualType.GOAL:
    //   return <GoalsWidget isPreview={isPreview} />;
    default:
      return <TableWidget content={dataset!} />;
  }
}
