import { useCallback, useContext, useState } from "react";
import { SourceType } from "@/components/Connector/types";
import { FaRegFileLines } from "react-icons/fa6";
import SlackSvg from "@/components/Icons/SlackSvg";
import ExcelSvg from "@/components/Icons/ExcelSvg";
import SalesforceSvg from "@/components/Icons/SalesforceSvg";
import GDocsSvg from "@/components/Icons/GDocsSvg";
import GSheetsSvg from "@/components/Icons/GSheetsSvg";
import NotionSvg from "@/components/Icons/NotionSvg";
import GithubSvg from "@/components/Icons/GithubSvg";
import HubspotSvg from "@/components/Icons/HubspotSvg";
import Hint from "@/components/Tooltips/Hint";
import { LuMoreHorizontal } from "react-icons/lu";
import { IntegrationApp, IntegrationConnection } from "@/containers/Integrations/types";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";

type TileSource = {
  app_name: IntegrationApp;
  integration_entity_id: string;
  integration_entity_name: string;
  integration_entity_type: SourceType;
};

export default function TileSources({ sources }: { sources?: TileSource[] }) {
  const [showAll, setShowAll] = useState(false);

  if (!sources || sources.length === 0) { 
    return null;
  }

  const visibleSources = showAll ? sources : sources.slice(0, 3);
  const hasMore = sources.length > 3;

  return (
    <div
      className="flex"
      onMouseEnter={() => setShowAll(true)}
      onMouseLeave={() => setShowAll(false)}
    >
      {visibleSources.map((source, index) => (
        <div key={index} className="flex gap-2 items-center">
          <div className="bg-slate-100 dark:bg-dark-alt rounded-full"></div>
          <SourceIcon source={source} />
        </div>
      ))}
      {!showAll && hasMore && (
        <Hint message="More sources" side="bottom">
          <div className="flex items-center justify-center pb-1 ml-2">
            <LuMoreHorizontal size={18} />
          </div>
        </Hint>
      )}
    </div>
  );
}

const SourceIcon = ({ source }: { source: TileSource }) => {
  const { connections } = useContext(IntegrationConnectionsContext);

  const buildDeepLink = useCallback((tileSource: TileSource) => {
    switch (tileSource.integration_entity_type) {
      case SourceType.GOOGLE_DOC:
        return `https://docs.google.com/document/d/${tileSource.integration_entity_id}`;
      case SourceType.GOOGLE_SHEET:
        return `https://docs.google.com/spreadsheets/d/${tileSource.integration_entity_id}`;
      case SourceType.NOTION_PAGE:
        return `https://www.notion.so/${tileSource.integration_entity_id}`;
      case SourceType.SLACK_CHANNEL:
        return `https://slack.com/${tileSource.integration_entity_id}`;
      case SourceType.SALESFORCE: {
        const connection = connections.find((connection: IntegrationConnection) => connection.integrationApp === IntegrationApp.SALESFORCE);
        if (!connection) {
          return undefined;
        }
        return `${connection.instanceUrl}/lightning/r/${tileSource.integration_entity_id}/view`;
      }
      default:
        return undefined;
    }
  }, [connections]);

  return (
    <div className="cursor-pointer z-[99]" onMouseDown={() => window.open(buildDeepLink(source), "_blank")}>
    <Hint message={source.integration_entity_name} offset={12} side="top">
      <div className="flex items-center justify-center pb-1">
        {source.integration_entity_type === SourceType.CSV && (
          <FaRegFileLines size={18} />
        )}
        {source.integration_entity_type === SourceType.TEXT && (
          <FaRegFileLines size={18} />
        )}
        {source.integration_entity_type === SourceType.SLACK_CHANNEL && (
          <SlackSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.EXCEL && (
          <ExcelSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.SALESFORCE && (
          <SalesforceSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.GOOGLE_DOC && (
          <GDocsSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.GOOGLE_SHEET && (
          <GSheetsSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.NOTION_PAGE && (
          <NotionSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.NOTION_DB && (
          <NotionSvg size={18} />
        )}
        {source.integration_entity_type === SourceType.GITHUB_REPO && (
          <GithubSvg size={18} />
        )}
        {source.integration_entity_type?.startsWith("HUBSPOT_") && (
          <HubspotSvg size={18} />
        )}
      </div>
    </Hint>
    </div>
  );
};
