import Pressable from "@/components/ui/pressable";
import { ConnectorDataEntity } from "../types";
import { useState } from "react";
import BrevLoader from "@/components/Loaders/BrevLoader";
import CheckMarkSvg from "@/components/Icons/CheckMarkSvg";
import EntityIcon from "@/components/Connector/components/EntityIcon";

type EntityPickerOptionProps = {
  dataEntity: ConnectorDataEntity;
  chosenEntityId?: string;
  onEntityPressed?: (entity: ConnectorDataEntity) => Promise<void>;
};

const EntityPickerOption = ({
  dataEntity,
  chosenEntityId,
  onEntityPressed
}: EntityPickerOptionProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(dataEntity.connected);
  const isEntityChosen =
    (chosenEntityId && chosenEntityId === dataEntity.id) ||
    (!chosenEntityId && isConnected);

  return (
    <Pressable
      key={dataEntity.id}
      disabled={isLoading}
      className={`w-full h-[36px] border pl-2 py-2 rounded-[8px] dark:text-brev-light hover:bg-[#3FCFF115] dark:hover:bg-[#3FCFF115] ${isEntityChosen ? "bg-[#3FCFF1]/15" : "bg-brev-light dark:bg-dark-alt"} transition-colors duration-200 ease-in-out`}
      onPress={() => {
        setIsLoading(true);
        setIsConnected(!isConnected);
        if (!onEntityPressed) {
          setIsLoading(false);
        } else {
          onEntityPressed({
            ...dataEntity,
            connected: isConnected
          }).then(() => {
            setIsConnected(!isConnected);
            setIsLoading(false);
          });
        }
      }}
    >
      <div className="relative flex-row flex justify-between">
        <div className="flex gap-2 justify-start text-left flex-shrink-0">
          <div className="absolute right-2 top-[2px]">
            {isLoading && <BrevLoader type="ring" size={16} />}
            {!isLoading && isEntityChosen && <CheckMarkSvg size={14} />}
          </div>
          <div className="flex items-center justify-center pb-1">
            <EntityIcon sourceType={dataEntity.sourceType!} />
          </div>
          <span
            className={`pr-6 text-xs truncate w-[14rem] pt-0.5`}
          >{`${dataEntity.name}`}</span>
          {dataEntity.isNew && (
            <span className="absolute -top-1 -left-1 flex h-1 w-1">
              <span className="animate-ping absolute inset-0 inline-flex h-full w-full rounded-full bg-brev-accent opacity-75"></span>
              <span className="absolute inset-0 inline-flex rounded-full h-1 w-1 bg-brev-accent"></span>
            </span>
          )}
        </div>
      </div>
    </Pressable>
  );
};

export default EntityPickerOption;
