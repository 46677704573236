import Tile from "../../Tile/Tile";
import Zoom from "@/images/zoom.svg";
export default function ZoomConnector() {
  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "SNOWFLAKE"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  const authenticateClient = () => {};

  return (
    <Tile
      disabled={true}
      onPress={authenticateClient}
      label="Zoom"
      src={Zoom}
    />
  );
}
