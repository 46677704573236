import { Button } from "@/components/ui/button";
import { useDropzone } from "react-dropzone";
import { useContext, useState } from "react";
import UploadedFileCard from "./UploadedFileCard";
import { useToast } from "@/components/ui/use-toast";
import { MdOutlineClose } from "react-icons/md";
import { SourceType } from "../types";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import {
  IntegrationApp,
  IntegrationEntityModel
} from "@/containers/Integrations/types";
import { useParams } from "react-router-dom";
import { computeSHA256 } from "@/lib/utils";
import { ArtifactSettingsContext } from "@/containers/ArtifactBuilder/ArtifactSettings/ArtifactSettingsProvider";
import * as XLSX from "xlsx";

const UploadFilePicker = () => {
  const { getAppConnection } = useContext(IntegrationConnectionsContext);
  const { sendApiRequest } = useContext(AuthContext);
  const { integrationEntities, setIntegrationEntities } = useContext(
    ArtifactSettingsContext
  );
  const { artifactId } = useParams<{ artifactId: string }>();
  const { toast } = useToast();

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isLoading, setisLoading] = useState(false);

  const onDrop = (acceptedFiles: File[]) => {
    const uniqueFiles = filterDuplicates(acceptedFiles, selectedFiles);

    if (acceptedFiles.length !== uniqueFiles.length) {
      toast({
        title: "Duplicate File",
        description: "File has already been added."
      });
    }
    setSelectedFiles((prevFiles) => [...prevFiles, ...uniqueFiles]);
  };

  const filterDuplicates = (newFiles: File[], existingFiles: File[]) => {
    const uniqueFiles = [];

    for (const file of newFiles) {
      const isDuplicate = existingFiles.some(
        (existingFile) =>
          existingFile.name === file.name && existingFile.size === file.size
      );
      const isExistingResource = false; //uploadFileSectionResources?.some(
      //   (resource) => resource.resourceName === file.name
      // );
      if (!isDuplicate && !isExistingResource) {
        uniqueFiles.push(file);
      }
    }
    return uniqueFiles;
  };

  const removeFile = (index: number) => {
    // Remove the file from the selectedFiles state
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSave = () => {
    console.log("handleSave");
    // For each of the multiple files, selected, it uploads them to s3 and updates DB ca
    selectedFiles.forEach(async (file) => {
      try {
        setisLoading(true);
        let sourceType;
        if (file.type === "text/csv" && file.name.endsWith(".csv")) {
          sourceType = SourceType.CSV;
        } else if (file.type === "text/plain" && file.name.endsWith(".txt")) {
          sourceType = SourceType.TEXT;
        } else if (
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
          file.name.endsWith(".xlsx")
        ) {
          sourceType = SourceType.EXCEL;
        } else {
          throw new Error("Invalid file type");
        }

        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async (e) => {
          console.log("reader.onload");
          // Do whatever you want with the file contents
          // const binaryStr = e.target?.result;
          // console.log("binaryStr", binaryStr);
          let binaryStr;

          if (sourceType === SourceType.EXCEL) {
            const data = new Uint8Array(e.target?.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: "array" });

            const allSheets = workbook.SheetNames.map((sheetName) => {
              const worksheet = workbook.Sheets[sheetName];
              return {
                sheetName,
                content: XLSX.utils.sheet_to_json(worksheet, { header: 1 })
              };
            });

            binaryStr = JSON.stringify(allSheets);
          } else {
            binaryStr = e.target?.result as string;
          }

          // const contentBase64 = btoa(
          //   String.fromCharCode(...new Uint8Array(binaryStr as ArrayBuffer))
          // );
          const contentBase64 = btoa(
            decodeURIComponent(encodeURIComponent(binaryStr))
          );
          const contentSha = await computeSHA256(contentBase64);

          const response = (await sendApiRequest(
            `/integrations/${getAppConnection(IntegrationApp.UPLOADFILE)?.integrationId}/connect-object`,
            "POST",
            {
              object: {
                objectId: contentSha.substring(0, 16),
                objectName: file.name,
                objectType: sourceType,
                integrationApp: IntegrationApp.UPLOADFILE,
                rawFileContent: binaryStr
              },
              artifactId
            }
          )) as {
            data: {
              integrationEntity: IntegrationEntityModel;
              isContextTruncated: boolean;
            };
          };
          setIntegrationEntities([
            {
              ...response.data.integrationEntity,
              isConnected: true
            } as IntegrationEntityModel,
            ...integrationEntities.filter(
              (ie) => ie.entityId !== response.data.integrationEntity.entityId
            )
          ]);
          if (!response.data.isContextTruncated) {
            toast({
              title: "Success",
              description: "Object selected successfully."
            });
          } else {
            toast({
              title: "Warning",
              description:
                "Context truncated to fit the 40KB size limit. Consider resizing your content to get more accurate results."
            });
          }
        };
        if (sourceType === SourceType.EXCEL) {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsText(file);
        }

        setisLoading(false);
        toast({
          title: "Upload Complete",
          description: "File added succesfully."
        });
        // onSubmit?.();
      } catch (e) {
        toast({
          title: "Error",
          description: `Upload Failed: ${(e as Error).message}`
        });
        setisLoading(false);
      }
    });
  };

  // const _DeleteButton = ({ resourceName }: { resourceName: string }) => {
  //   return (
  //     <div
  //       className="cursor-pointer hover:scale-[110%] hover:text-red-500 transition-all ease-in-out"
  //       onClick={async () => {
  //         try {
  //           await deleteSectionResources(
  //             getReviewId()!,
  //             "section.sectionId",
  //             "UPLOADFILE",
  //             resourceName
  //           );

  //           toast({
  //             title: "Success",
  //             description: `Deletion Successfull`
  //           });
  //         } catch (e) {
  //           toast({
  //             title: "Error",
  //             description: `Delete Failed: ${(e as Error).message}`
  //           });
  //         }
  //       }}
  //     >
  //       <MdOutlineClose className="text-red-600 hover:text-white" size={20} />
  //     </div>
  //   );
  // };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: true,
    accept: {
      "text/plain": [".txt"],
      "text/csv": [".csv"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx"
      ]
    }
  });

  return (
    <div className="mx-2 mt-2 flex flex-col">
      <div className="text-xs mt-3 text-gray-500">
        Attach any file to provide context for your Artifact. Brev works best
        with CSVs and text files.
      </div>
      <div>
        <Button
          variant="outline"
          className="rounded-[8px] mt-3 mb-6 w-full "
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <span className="mb-0">Choose a file</span>
        </Button>
        {/*
        {uploadFileSectionResources &&
        uploadFileSectionResources?.length > 0 ? (
          <TextThroughLine content="Added Data" />
        ) : (
          selectedFiles.length > 0 && <TextThroughLine content="Added Data" />
        )} */}

        <div className="flex flex-col gap-2 max-h-[250px] overflow-y-scroll no-scrollbar my-2">
          {selectedFiles.length > 0 && (
            <div className="flex flex-col gap-2">
              {selectedFiles.map((file, index) => (
                <UploadedFileCard
                  key={index}
                  index={index}
                  fileName={file.name}
                  actionElement={
                    <Button
                      className="w-6 h-6 bg-transparent p-0"
                      onClick={() => removeFile(index)}
                    >
                      <MdOutlineClose
                        className="text-red-600 hover:text-white"
                        size={10}
                      />
                    </Button>
                  }
                />
              ))}
            </div>
          )}

          {/* {resourcesMap && resourcesMap?.size > 0 && (
            <div className="flex flex-col gap-2">
              {Object.values(resourcesMap).map((resource, index) => (
                <UploadedFileCard
                  key={index}
                  index={index}
                  fileName={resource.resourceName}
                  uploaded
                  // actionElement={
                  //   <DeleteButton
                  //     handleDelete={() => {}} //deleteSectionResources
                  //     resourceName={resource.resourceName}
                  //   />
                  // }
                />
              ))}
            </div>
          )} */}
        </div>
        <div className="flex justify-center items-center w-full py-2">
          <Button
            variant="gradient"
            type="submit"
            isLoading={isLoading}
            disabled={selectedFiles.length <= 0}
            onClick={handleSave}
            className="h-[25px] w-[135px] rounded-[8px] text-xs font-medium"
          >
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadFilePicker;
