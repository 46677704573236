import TextLabel from "@/components/Text/TextLabel";
import { cn } from "@/lib/utils";
import CheckMarkSvg from "../Icons/CheckMarkSvg";
import BrevLoader from "../Loaders/BrevLoader";

type TileProps = {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  label: string;
  onPress(): void;
  src?: string;
  connected?: boolean;
  numResources?: number;
  svg?: React.ReactElement;
  selected?: boolean;
  isLoading?: boolean;
};

export default function Tile({
  label,
  src,
  onPress,
  disabled,
  className,
  connected,
  numResources,
  svg,
  selected,
  isLoading
}: TileProps) {
  return (
    <button
      disabled={disabled || isLoading}
      onClick={onPress}
      className={`rounded-xl disabled:opacity-60 p-2 ${selected ? "bg-brev text-brev-light" : "bg-brev-light dark:bg-dark-alt text-dark-text dark:text-brev-light"} border hover:shadow-lg dark:shadow-dark-alt border-slate-900 hover:bg-white/80 hover:text-black ${cn(
        className
      )}`}
      data-cy={`${label}-tile`}
      data-loading={isLoading}
    >
      <div
        className={`rounded-xl flex flex-col gap-2 items-center justify-center py-1`}
      >
        <div className="relative">
          {isLoading && (
            <BrevLoader
              type="ring"
              className="absolute -top-[2px] left-[18px]"
            />
          )}
          {!isLoading && connected && (
            <>
              {!numResources ? (
                <CheckMarkSvg
                  size={18}
                  className="absolute -top-[2px] left-[18px]"
                />
              ) : (
                <div className="absolute left-[18px] -top-[2px] size-[20px] rounded-full bg-gradient-to-r from-[#41C7FF] to-[#38F4B3] text-dark-text font-bold text-xs flex items-center justify-center border-2 border-[#14333E]">
                  {numResources}
                </div>
              )}
            </>
          )}
          {src && <img src={src} className="w-7 h-7 overflow-clip" />}
          {!src && (
            <div className="flex items-center justify-center h-[28px] w-[28px] rounded-full">
              {svg}
            </div>
          )}
        </div>
        <TextLabel className="">{label}</TextLabel>
      </div>
    </button>
  );
}
