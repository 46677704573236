import BrevLoader from "@/components/Loaders/BrevLoader";
import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { ArtifactContext } from "../ArtifactProvider";
// import SlideChart from "./TemplateComponents/SlideChart";
// import SlideKPI from "./TemplateComponents/SlideKPI";
// import SlideGoal from "./TemplateComponents/SlideGoal";
import { Button } from "@/components/ui/button";
import { sampleSlides } from "./SampleData";

type SlideContent = {
  type: "subtitle" | "bulletList" | "paragraph" | "chart" | "kpi" | "goal";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  className?: string;
  gridArea?: string;
};


type SlideData = {
  title: string;
  content: SlideContent[];
};

const isValidSlideData = (slide: unknown): slide is SlideData => {
  if (typeof slide !== "object" || slide === null) return false;
  
  const s = slide as Partial<SlideData>;
  return (
    typeof s.title === "string" &&
    Array.isArray(s.content) &&
    s.content.every(
      (item): item is SlideContent =>
        typeof item === "object" &&
        item !== null &&
        typeof (item as Partial<SlideContent>).type === "string" &&
        (item as Partial<SlideContent>).data !== undefined
    )
  );
};

export default function PresentationContainer() {
  const { artifactContent, isArtifactGenerating } = useContext(ArtifactContext);
  const [slides, setSlides] = useState<SlideData[]>(sampleSlides);
  // const [ slides, setSlides ] = useState<SlideData[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [parseError, setParseError] = useState<string | null>(null);
  const [isPreviewMode, setIsPreviewMode] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const togglePreviewMode = () => setIsPreviewMode(!isPreviewMode);

  const enterFullScreen = useCallback(() => {
    if (containerRef.current) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      }
    }
  }, []);

  const exitFullScreen = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (isFullScreen) {
      exitFullScreen();
    } else {
      enterFullScreen();
    }
  }, [isFullScreen, enterFullScreen, exitFullScreen]);

  useEffect(() => {
    const fullScreenChangeHandler = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", fullScreenChangeHandler);

    return () => {
      document.removeEventListener("fullscreenchange", fullScreenChangeHandler);
    };
  }, []);

  useEffect(() => {
    if (!isArtifactGenerating && artifactContent) {
      try {
        const arrayMatch = artifactContent.match(/\[[\s\S]*\]/);
        if (!arrayMatch) {
          throw new Error("NO valid slides");
        }

        const arrayString = arrayMatch[0];
        const parsedContent = JSON.parse(arrayString);

        if (!Array.isArray(parsedContent)) {
          throw new Error("Not an array");
        }

        const validSlides = parsedContent.filter(isValidSlideData);

        if (validSlides.length !== parsedContent.length) {
          console.warn(
            `${parsedContent.length - validSlides.length} slides were invalid and filtered out.`
          );
        }

        if (validSlides.length === 0) {
          throw new Error("No valid slides found in the parsed content");
        }

        console.log("Parsed and validated slides:", validSlides);
        setSlides(validSlides);
        setParseError(null);
      } catch (error) {
        console.error("Error parsing artifactContent:", error);
        setParseError(
          "Failed to parse presentation content. Please try again."
        );
      }
    }
  }, [artifactContent, isArtifactGenerating]);

  const nextSlide = useCallback(
    () => setCurrentSlide((prev) => Math.min(prev + 1, slides.length - 1)),
    [slides.length]
  );

  const prevSlide = useCallback(
    () => setCurrentSlide((prev) => Math.max(prev - 1, 0)),
    []
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "ArrowRight" || event.key === "ArrowDown") {
        nextSlide();
      } else if (event.key === "ArrowLeft" || event.key === "ArrowUp") {
        prevSlide();
      }
    },
    [nextSlide, prevSlide]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  if (isArtifactGenerating) {
    return (
      <div className="flex justify-center items-center h-[50rem]">
        <BrevLoader type="propagate" size={20} loadingId="artifact-loading" />
      </div>
    );
  }

  if (parseError) {
    return <div className="text-red-500">{parseError}</div>;
  }

  if (slides.length === 0) {
    return <div>No slides available</div>;
  }

  const isFirstSlide = currentSlide === 0;
  const isLastSlide = currentSlide === slides.length - 1;

  return (
    <div
      ref={containerRef}
      className={`relative ${
        isFullScreen ? "w-screen h-screen bg-white p-16" : "w-full h-[50rem]"
      }`}
    >
      {isPreviewMode ? (
        <div className="grid grid-cols-3 gap-4 p-4 overflow-y-auto h-full">
          {slides.map((slide, index) => (
            <SlidePreview
              key={index}
              slide={slide}
              onClick={() => {
                setCurrentSlide(index);
                setIsPreviewMode(false);
              }}
            />
          ))}
        </div>
      ) : (
        <div
          className={`flex justify-center items-center h-full ${isFullScreen ? "fixed inset-0 bg-white z-50" : ""}`}
        >
          <Slide
            content={slides[currentSlide].content}
            title={slides[currentSlide].title}
            isFullScreen={isFullScreen}
          />
        </div>
      )}

      <div
        className={`absolute bottom-4 left-4 right-4 flex justify-between ${
          isFullScreen ? "opacity-0 hover:opacity-100 transition-opacity" : ""
        }`}
        style={{ zIndex: 10 }}
      >
        <Button
          variant="outline"
          onClick={prevSlide}
          disabled={isFirstSlide || isPreviewMode}
          className={`${isFirstSlide || isPreviewMode ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          Previous
        </Button>
        <Button variant="outline" onClick={togglePreviewMode}>
          {isPreviewMode ? "Exit Preview" : "Preview All"}
        </Button>
        <Button variant="outline" onClick={toggleFullScreen}>
          {isFullScreen ? "Exit Presentation Mode" : "Presentation Mode"}
        </Button>
        <Button
          variant="outline"
          onClick={nextSlide}
          disabled={isLastSlide || isPreviewMode}
          className={`${isLastSlide || isPreviewMode ? "opacity-50 cursor-not-allowed" : ""}`}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

const Slide = ({
  content,
  title,
  isFullScreen
}: {
  content: SlideContent[];
  title: string;
  isFullScreen: boolean;
}) => {
  const slideRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (isFullScreen && slideRef.current) {
      const slideAspectRatio = 16 / 9;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
      const viewportAspectRatio = viewportWidth / viewportHeight;

      let newScale;
      if (viewportAspectRatio > slideAspectRatio) {
        newScale = viewportHeight / 720;
      } else {
        newScale = viewportWidth / 1280;
      }
      setScale(newScale);
    } else {
      setScale(1);
    }
  }, [isFullScreen]);

  const slideStyle: React.CSSProperties = isFullScreen
    ? {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: `translate(-50%, -50%) scale(${scale})`,
        transformOrigin: "center center"
      }
    : {};

  return (
    <div
      ref={slideRef}
      className={`flex flex-col ${
        isFullScreen ? "fixed inset-0" : "w-[1280px] h-[720px]"
      }`}
      style={slideStyle}
    >
      <h1 className="text-4xl font-bold mb-4 px-12 pt-8">{title}</h1>
      <div className="flex-grow px-12 pb-8 overflow-hidden">
        <div
          className="grid grid-cols-6 grid-rows-auto h-full border border-light-border rounded-lg p-8 dark:border-dark-border overflow-hidden"
          // style={{ gridTemplateRows: "repeat(5, 1fr)" }}
        >
          {content.map((item, index) => (
            <SlideItem
              key={index}
              type={item.type}
              data={item.data}
              className={`${item.className || ""} overflow-auto`}
              gridArea={item.gridArea}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const SlideItem = ({ type, data, className, gridArea }: SlideContent) => {
  const style: React.CSSProperties = gridArea
    ? {
        gridArea,
        minHeight: 0,
        maxHeight: "100%",
        overflow: "auto"
      }
    : {};

  const renderContent = () => {
    switch (type) {
      case "subtitle":
        return <Subtitle data={data} />;
      case "paragraph":
        return <Paragraph data={data} />;
      case "bulletList":
        return <BulletList data={data} />;
      // case "chart":
      //   return (
      //     <div className="w-full h-full flex items-center justify-center">
      //       <SlideChart data={data} />
      //     </div>
      //   );
      // case "kpi":
      //   return <SlideKPI kpi={data} />;
      // case "goal":
      //   return <SlideGoal data={data} />;
      default:
        return null;
    }
  };

  return (
    <div className={`${className} p-2`} style={style}>
      {renderContent()}
    </div>
  );
};

const Subtitle = ({
  data,
  className
}: {
  data: string;
  className?: string;
}) => <h3 className={`text-lg font-medium ${className}`}>{data}</h3>;

const Paragraph = ({
  data,
  className
}: {
  data: string;
  className?: string;
}) => (
  <div className="h-full flex justify-center p-4 rounded-lg border border-light-alt dark:border-dark-border overflow-auto">
    <p className={`text-base ${className}`}>{data}</p>
  </div>
);

const BulletList = ({
  data,
  className
}: {
  data: string[];
  className?: string;
}) => (
  <div className="h-full flex flex-col items-center p-4 rounded-lg border border-light-alt dark:border-dark-border">
    <ul className={`list-disc list-inside ${className} space-y-2`}>
      {data.map((item, index) => (
        <li key={index} className="text-sm">
          {item}
        </li>
      ))}
    </ul>
  </div>
);

const SlidePreview = ({
  slide,
  onClick
}: {
  slide: SlideData;
  onClick: () => void;
}) => (
  <div
    className="border rounded-lg p-2 cursor-pointer hover:bg-gray-100 overflow-hidden"
    onClick={onClick}
    style={{ height: "200px" }}
  >
    <h3 className="text-sm font-bold mb-2">{slide.title}</h3>
    <div className="text-xs overflow-y-auto" style={{ maxHeight: "160px" }}>
      {slide.content.map((item, i) => (
        <div key={i} className="mb-1">
          {renderPreviewContent(item)}
        </div>
      ))}
    </div>
  </div>
);

const renderPreviewContent = (item: SlideContent) => {
  switch (item.type) {
    case "subtitle":
      return <p className="font-semibold">{item.data}</p>;
    case "paragraph":
      return <p>{item.data.substring(0, 50)}...</p>;
    case "bulletList":
      return (
        <ul className="list-disc list-inside">
          {item.data.slice(0, 3).map((bullet: string, index: number) => (
            <li key={index}>{bullet.substring(0, 30)}...</li>
          ))}
          {item.data.length > 3 && <li>...</li>}
        </ul>
      );
    case "chart":
      return <p>[Chart: {item.data.title}]</p>;
    case "kpi":
      return <p>[KPI: {item.data.title}]</p>;
    case "goal":
      return <p>[Goal: {item.data.title}]</p>;
    default:
      return null;
  }
};
