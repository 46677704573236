import * as React from "react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight
} from "react-icons/md";
import { DateFormatter, DayPicker } from "react-day-picker";
import { format } from "date-fns";

import { cn } from "@/lib/utils";
import { useState } from "react";

const formatWeekdayName: DateFormatter = (weekday, options) => {
  return format(weekday, "EEEEE", { locale: options?.locale });
};
export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  // const [hoveredDate, setHoveredDate] = useState<Date | null>(null);
  const [hoveredDay, setHoveredDay] = useState<Date | null>(null);

  const handleMouseEnter = (day: Date) => {
    setHoveredDay(day);
  };

  // const hoveredRangeMatcher = (day: Date) => {
  //   return (
  //     props.selected?.from &&
  //     hoveredDay &&
  //     day >= props.selected.from &&
  //     day <= hoveredDay
  //   );
  // };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3 bg-brev", className)}
      classNames={{
        months:
          "flex flex-col text-white sm:flex-row space-y-2 sm:space-x-2 sm:space-y-0",
        month: "space-y-3",
        caption: "flex justify-center relative items-center",
        caption_label: "text-xs font-medium font-semibold",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          { variant: "default" },
          "bg-transparent p-0 opacity-100 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex ml-2",
        head_cell:
          "text-white font-semibold rounded-[8px] w-8 font-normal text-xs",
        row: "flex w-full mt-2",
        cell: "h-8 w-8 text-center text-xs p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-full [&:has([aria-selected].day-range-start)]:rounded-l-full [&:has([aria-selected])]:bg-[#282535] first:[&:has([aria-selected])]:rounded-l-full last:[&:has([aria-selected])]:rounded-r-full focus-within:relative focus-within:z-20",
        day: cn(
          { variant: "default" },
          "h-8 w-8 p-0 font-semibold aria-selected:opacity-100"
        ),
        day_range_start: "day-range-start rounded-full",
        day_range_end: "day-range-end rounded-full",
        day_selected:
          "bg-brev-accent text-[#000] hover:text-[#404040] rounded-full",
        day_today: "bg-[#DDD] text-accent-foreground rounded-full",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-[#282535] aria-selected:text-brev-accent hover:rounded-none",
        day_hidden: "invisible",
        ...classNames
      }}
      onDayMouseEnter={handleMouseEnter}
      modifiers={{
        // hoveredRange: hoveredRangeMatcher,
        // hoveredDay: !!props.selected?.from && hoveredDay ? hoveredDay : false
        hoveredDay: (day) => day.getTime() === hoveredDay?.getTime()
      }}
      modifiersStyles={{
        hoveredRange: {
          backgroundColor: "rgba(40, 37, 53, 1)"
        },
        hoveredDay: {
          backgroundColor: "rgba(100, 100, 100, 10)",
          borderRadius: "50%",
          color: "black"
        }
      }}
      components={{
        IconLeft: () => <MdOutlineKeyboardArrowLeft size="30" />,
        IconRight: () => <MdOutlineKeyboardArrowRight size="30" />
      }}
      formatters={{ formatWeekdayName }}
      weekStartsOn={1}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
