// import AdminDashboard from "./AdminDashboard";
import { useState, useCallback, useEffect } from "react";
import { DataTable } from "./data-table";
import { columns } from "./table-columns";
import { UserEditDialog } from "./user-edit";
// import { fakeUsers } from "./table-data";
import LayoutContainer from "../Layout/LayoutContainer";
import { getUsers } from "@/lib/utils";
import { AccessLevel } from "./table-columns";
import BrevLoader from "@/components/Loaders/BrevLoader";

export default function AdminContainer() {
  const [data, setData] = useState<BrevUser[] | null>(null);
  const [editingUser, setEditingUser] = useState<BrevUser | null>(null);

  const onLoad = useCallback(async () => {
    try {
      const users = await getUsers();
      setData(users);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    console.log("data", data);
  }, [data]);

  const openEditDialog = (user: BrevUser) => {
    setEditingUser(user);
  };

  const closeEditDialog = () => {
    setEditingUser(null);
  };

  const handleUpdateUser = (updatedUser: BrevUser) => {
    if (updatedUser && data) {
      setData(
        data.map((user) =>
          user?.userId === updatedUser.userId ? updatedUser : user
        )
      );
      saveUser(updatedUser);
    }
  };

  const handleUpdateData = (
    rowIndex: number,
    columnId: string,
    value: unknown
  ) => {
    setData((prevData) => {
      if (!prevData) return null;
      return prevData.map((row, index) => {
        if (index === rowIndex) {
          const updatedRow: BrevUser = {
            ...row,
            email: row.email || row.cognitoUser?.attributes?.email || "",
            firstName: row.firstName || "",
            lastName: row.lastName || "",
            companyName: row.companyName || "",
            companyGeography: row.companyGeography || "",
            companySegment: row.companySegment || "",
            companyIndustry: row.companyIndustry || "",
            companySize: row.companySize || "",
            jobTitle: row.jobTitle || "",
            aiService: row.aiService || "",
            accessLevel: row.accessLevel || AccessLevel.BASIC,
            userSettings: {
              nextBestActionsEnabled:
                row.userSettings?.nextBestActionsEnabled ?? false,
              useCompanyMemory: row.userSettings?.useCompanyMemory ?? false,
              useV3: row.userSettings?.useV3 ?? false,
              attribute2: row.userSettings?.attribute2 ?? false,
              attribute3: row.userSettings?.attribute3 ?? false,
              attribute4: row.userSettings?.attribute4 ?? false,
              attribute5: row.userSettings?.attribute5 ?? false
            }
          };

          if (columnId in updatedRow.userSettings) {
            updatedRow.userSettings = {
              ...updatedRow.userSettings,
              [columnId]: value
            };
          } else {
            updatedRow[columnId as keyof BrevUser] = value as never;
          }

          return updatedRow;
        }
        return row;
      });
    });
  };
  // const saveUser = useCallback(
  //   async (data: BrevUser) => {
  //     if (!data) return;
  //     try {
  //       const updatedUser = await updateUser(data);
  //       dispatch({
  //         type: "users/UPDATE",
  //         payload: {
  //           user: updatedUser
  //         }
  //       });
  //     } catch (error) {
  //       console.error("Form cannot be submitted: ", error);
  //     }
  //   },
  //   [data, dispatch]
  // );

  // temporary fix for saving user with missing attributes (users from old database)
  const saveUser = useCallback(async (data: BrevUser) => {
    if (!data) return;
    try {
      // const userToUpdate: BrevUser = {
      //   userId: data.userId,
      //   email: data.email || data.cognitoUser?.attributes?.email || "",
      //   firstName: data.firstName || "",
      //   lastName: data.lastName || "",
      //   companyName: data.companyName || "",
      //   companyGeography: data.companyGeography || "",
      //   companySegment: data.companySegment || "",
      //   companyIndustry: data.companyIndustry || "",
      //   companySize: data.companySize || "",
      //   jobTitle: data.jobTitle || "",
      //   aiService: data.aiService || "",
      //   accessLevel: data.accessLevel || AccessLevel.BASIC,
      //   userSettings: {
      //     nextBestActionsEnabled:
      //       data.userSettings?.nextBestActionsEnabled ?? false,
      //     useCompanyMemory: data.userSettings?.useCompanyMemory ?? false,
      //     useV3: data.userSettings?.useV3 ?? false,
      //     attribute2: data.userSettings?.attribute2 ?? false,
      //     attribute3: data.userSettings?.attribute3 ?? false,
      //     attribute4: data.userSettings?.attribute4 ?? false,
      //     attribute5: data.userSettings?.attribute5 ?? false
      //   }
      // };
      // const updatedUser = await updateUser(userToUpdate);
    } catch (error) {
      console.error("Form cannot be submitted: ", error);
    }
  }, []);

  // const handleUpdateData = (
  //   rowIndex: number,
  //   columnId: string,
  //   value: unknown
  // ) => {
  //   setData((prevData) => {
  //     if (!prevData) return null;
  //     return prevData.map((row, index) => {
  //       if (index === rowIndex) {
  //         const updatedRow = {
  //           ...row,
  //           ...(columnId in (row.userSettings || {})
  //             ? {
  //                 userSettings: {
  //                   ...row.userSettings,
  //                   [columnId]: value
  //                 }
  //               }
  //             : { [columnId]: value })
  //         };
  //         return updatedRow as BrevUser;
  //       }
  //       return row;
  //     });
  //   });
  // };

  return (
    <div className="flex justify-end">
      <LayoutContainer>
        {data ? (
          <>
            <DataTable
              data={data}
              columns={columns}
              onUpdateData={handleUpdateData}
              openEditDialog={openEditDialog}
            />
            <UserEditDialog
              user={editingUser}
              isOpen={!!editingUser}
              onClose={closeEditDialog}
              onSave={handleUpdateUser}
            />
          </>
        ) : (
          <div className="grid place-content-center my-8 h-screen">
            <BrevLoader type="propagate" size={32} />
          </div>
        )}
      </LayoutContainer>
    </div>
  );
}
