import { useCallback, useContext } from "react";
import { cn } from "@/lib/utils";
import { GlobalContext } from "@/GlobalProvider";
import EditableText from "./ArtifactCanvas/EditableText";
import { ArtifactContext } from "./ArtifactProvider";

type ArtifactTitleProps = {
  published?: boolean;
  className?: string;
};

const ArtifactTitle = (props: ArtifactTitleProps) => {
  const { artifactTitle, setArtifactTitle } = useContext(GlobalContext);
  const { isArtifactGenerating, saveArtifact } = useContext(ArtifactContext);

  const handleInputChange = useCallback(
    (value: string) => {
      if (setArtifactTitle) {
        setArtifactTitle(value);
        saveArtifact({ artifactTitle: value }, false);
      }
    },
    [setArtifactTitle, saveArtifact]
  );

  const { published = false, className } = props;

  return (
    <div className="flex items-baseline justify-start w-full text-[1.5rem] z-50">
      {!published ? (
        <EditableText
          value={artifactTitle}
          onSave={handleInputChange}
          className={cn(
            "w-full font-bold placeholder:text-[#BCBEC6] bg-transparent text-dark-text dark:text-brev-light dark:focus-within:bg-dark-main transition-colors duration-500 ease-in-out line-clamp-1",
            className
          )}
          placeholder="Untitled Artifact"
          scaleFontSize={true}
          maxLength={100}
          minLength={35}
          minFontSize={16}
          maxFontSize={36}
          editable={!isArtifactGenerating}
          onSubmit={false}
        />
      ) : (
        <div className="text-[1.5rem] font-bold mt-5">{artifactTitle}</div>
      )}
    </div>
  );
};

export default ArtifactTitle;
