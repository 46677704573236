import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { DayPicker } from "@/components/ui/daypicker";

const FormSchema = z.object({
  fiscal_calendar: z.date(),
  plan_rhythms: z.boolean()
});

export default function TimePeriods() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="py-4">
        <div className="space-y-4 px-4 pt-4 w-full min-h-[647px] rounded-2xl bg-brev-light text-xs font-semibold">
          <span>Time period settings</span>
          <FormField
            control={form.control}
            name="fiscal_calendar"
            render={({ field }) => (
              <FormItem className="flex justify-between">
                <div className="px-4">
                  <FormLabel className="text-xs font-medium">
                    Fiscal calendar
                  </FormLabel>
                  <FormDescription className="py-1 w-[800px] text-[11px] font-normal text-black/75">
                    Our fiscal calendar starts on and ends
                  </FormDescription>
                </div>
                <FormControl>
                  <DayPicker
                    onDateChange={field.onChange}
                    className="rounded-full hover:text-white hover:bg-brev bg-[#FFF]"
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="plan_rhythms"
            render={({ field }) => (
              <FormItem className="flex justify-between pr-1">
                <div className="px-4">
                  <FormLabel className="text-xs font-medium">
                    Planning rhythms
                  </FormLabel>
                  <FormDescription className="py-1 w-[800px] text-[11px] font-normal text-black/75">
                    Turns on Email notifications for users in your organization.
                    Users will receive reminders, mentions, and various activity
                    based notifications in Email. Users can manage individual
                    level preferences in their account.
                  </FormDescription>
                </div>
                <div className="pt-2">
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="data-[state=checked]:bg-white data-[state=unchecked]:bg-white w-[56px] h-[18px]"
                    />
                  </FormControl>
                </div>
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  );
}
