import {
  ColumnDef,
  getCoreRowModel,
  flexRender,
  useReactTable,
  getFilteredRowModel
} from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
// import { Input } from "@/components/ui/input"
import { useState } from "react";

type OrgTeam = {
  id: string;
  name: string;
  owner: string;
  description: string;
  tag: string;
  members: string[];
};

export default function OrganizationTeams() {
  const [data] = useState<OrgTeam[]>([]);

  const columns: ColumnDef<OrgTeam>[] = [
    {
      id: "team",
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => <div>{row.getValue("name")}</div>
    },
    {
      id: "owner",
      accessorKey: "owner",
      header: "Owner",
      cell: ({ row }) => <div>{row.getValue("owner")}</div>
    },
    {
      id: "admins",
      accessorKey: "members",
      header: "Team admins",
      cell: ({ row }) => <div>{row.getValue("members")}</div>
    },
    {
      id: "tag",
      accessorKey: "tag",
      header: "Tag",
      cell: ({ row }) => <div>{row.getValue("tag")}</div>
    }
  ];

  const table = useReactTable<OrgTeam>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel()
  });

  return (
    <div className="w-full">
      <div className="rounded-2xl bg-brev-light max-w-[1068px] max-h-[818px] mt-4">
        <Table className="border-transparent">
          <TableHeader className="border-b-2 border-brev-border">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-dark-text">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="py-1">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
