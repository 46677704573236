import { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import { TileType } from "@/containers/ReviewBuilder/review-types";
import {
  LuText,
  LuBarChart4,
  LuTarget,
  LuLayoutGrid,
  // LuPresentation,
  // LuRows,
  LuHeading,
  // LuMinus,
  // LuPlus,
  LuCode2
} from "react-icons/lu";
import { motion, AnimatePresence } from "framer-motion";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { GridContext } from "./GridProvider";
// import { ImPageBreak } from "react-icons/im";

export default function TileToolbar() {
  const {
    // isPresentation,
    // setIsPresentation,
    // isSlideView,
    // setIsSlideView,
    onDragStart
  } = useContext(GridContext);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  // const handlePresentationChange = () => {
  //   setIsPresentation(!isPresentation);
  //   setIsSlideView(false);
  // };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleTooltipDisplay = () => {
    setIsTooltipVisible(true);

    setTimeout(() => {
      setIsTooltipVisible(false);
    }, 1500);
  };

  // const handleSlideViewChange = () => {
  //   setIsSlideView(!isSlideView);
  //   setIsPresentation(false);
  // };

  const tileOptions = [
    {
      type: TileType.KPI,
      label: "KPI",
      icon: <LuLayoutGrid size={16} />,
      tooltip: "KPI"
    },
    {
      type: TileType.CHART,
      label: "Chart",
      icon: <LuBarChart4 size={16} />,
      tooltip: "Chart"
    },
    {
      type: TileType.TEXT,
      label: "Text",
      icon: <LuText size={16} />,
      tooltip: "Text"
    },
    {
      type: TileType.GOAL,
      label: "Goal",
      icon: <LuTarget size={16} />,
      tooltip: "Goal"
    },
    {
      type: TileType.HEADING,
      label: "Heading",
      icon: <LuHeading size={16} />,
      tooltip: "Heading"
    },
    {
      type: TileType.EMBED,
      label: "URL",
      icon: <LuCode2 size={16} />,
      tooltip: "Embed URL"
    }
  ];

  const handleDragStart = (e: React.DragEvent, type: TileType) => {
    e.dataTransfer.setData("text/plain", type);
    onDragStart(type);
  };

  return (
    <TooltipProvider>
      <motion.div
        className="flex flex-col items-center"
        initial={false}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <Button
          size="icon"
          variant="ghost"
          onClick={toggleExpand}
          className={`w-9 h-9 rounded-lg transition-colors duration-500 ease-in-out hover:bg-transparent`}
        >
          <motion.div className="relative place-items-center h-3 w-3 bg-gradient-to-br from-brev-accent from-30% to-[#00FFB2] shadow rounded-full">
            <motion.span className="absolute left-[50%] -translate-x-1/2 top-[50%] -translate-y-1/2" />
          </motion.div>
        </Button>

        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="w-3/4 mb-2 mx-auto bg-dark-alt/50 dark:bg-light-alt/50 rounded-lg min-h-[1px]"
          />
        )}

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="w-full flex flex-col items-center"
            >
              <ToolbarTooltip
                message="Drag to add new tile"
                isVisible={isTooltipVisible}
              />
              {tileOptions.map((option) => (
                <motion.div
                  key={option.type}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="mb-2"
                >
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        variant="ghost"
                        draggable
                        onDragStart={(e) => handleDragStart(e, option.type)}
                        className={`h-9 flex-col transition-all duration-400 ease-in cursor-grab active:cursor-grabbing hover:shadow-lg shadow-black hover:border hover:opacity-100 hover:bg-light-main dark-hover:bg-dark-main hover:scale-110`}
                        onMouseDown={handleTooltipDisplay}
                      >
                        {option.icon}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent side="right">
                      <span>{option.tooltip}</span>
                    </TooltipContent>
                  </Tooltip>
                </motion.div>
              ))}
              {/* <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    onClick={handlePresentationChange}
                    variant="ghost"
                    className={`min-h-9 ${isPresentation ? "dark:bg-light-alt bg-dark-alt dark:text-dark-text text-light-text" : ""} transition-colors duration-500 ease-in-out`}
                  >
                    <ImPageBreak size={16} />
                  </Button>
                </TooltipTrigger>
                <TooltipContent side="right">
                  Toggle slide dividers
                </TooltipContent>
              </Tooltip> */}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </TooltipProvider>
  );
}

const ToolbarTooltip = ({
  isVisible = true,
  message = "Testing message"
}: {
  isVisible?: boolean;
  message?: string;
}) => {
  return (
    <div
      className={`${isVisible ? "opacity-100" : "opacity-[0%]"} absolute top-full w-24 left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 text-[12px] shadow bg-dark-alt dark:bg-light-alt text-light-text dark:text-dark-text rounded-lg text-center transition-opacity duration-300 ease-in-out`}
    >
      <div className="absolute w-0 h-0 left-1/2 bottom-full -translate-x-1/2 border-8 border-transparent border-b-dark-alt dark:border-b-light-alt"></div>
      {message}
    </div>
  );
};
