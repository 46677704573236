import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover";
import { DateRange } from "react-day-picker";
import ToggleOptions from "./ArtifactSettings/ToggleOptions";
import { getYearToDate, getWeekRange, getMonthRange, getQuarterRange } from "@/lib/utils";

type DateRangeOption = {
  title: string;
  label: string;
  range?: DateRange;
};

enum DateRangeOptionType {
  WEEK = "this week",
  LAST_WEEK = "last week",
  MONTH = "this month",
  LAST_MONTH = "last month",
  QUARTER = "this quarter",
  LAST_QUARTER = "last quarter",
  YEAR = "year to date",
  CUSTOM = "custom"
}

const currentDate = new Date();

const DateRangeOptions: DateRangeOption[] = [
  {
    title: DateRangeOptionType.LAST_WEEK,
    label: "Last Week",
    range: getWeekRange(currentDate, -7)
  },
  {
    title: DateRangeOptionType.LAST_MONTH,
    label: "Last Month",
    range: getMonthRange(currentDate, -1)
  },
  {
    title: DateRangeOptionType.LAST_QUARTER,
    label: "Last Quarter",
    range: getQuarterRange(currentDate, -1)
  },
  {
    title: DateRangeOptionType.WEEK,
    label: "This Week",
    range: getWeekRange(currentDate)
  },
  {
    title: DateRangeOptionType.MONTH,
    label: "This Month",
    range: getMonthRange(currentDate)
  },
  {
    title: DateRangeOptionType.QUARTER,
    label: "Last Quarter",
    range: getQuarterRange(currentDate)
  },
  {
    title: DateRangeOptionType.YEAR,
    label: "Year to date",
    range: getYearToDate(currentDate)
  },
  {
    title: DateRangeOptionType.CUSTOM,
    label: "Custom",
    range: {
      from: new Date(),
      to: new Date()
    }
  }
];

export default function DatePicker({
  date,
  setDate
}: {
  date: DateRange | undefined;
  setDate: (dateRange: DateRange | undefined) => void;
}) {
  const [rangeOption, setRangeOption] = useState<DateRangeOption | undefined>(
    // DateRangeOptions[1]
    undefined
  );

  useEffect(() => {
    if (date && date.from && date.to) {
      const matchedOption = DateRangeOptions.find(
        (option) =>
          option.range &&
          date.from!.getTime() === option.range.from!.getTime() &&
          date.to!.getTime() === option.range.to!.getTime()
      );

      if (matchedOption) {
        setRangeOption(matchedOption);
      } else {
        setRangeOption(
          DateRangeOptions.find(
            (option) => option.title === DateRangeOptionType.CUSTOM
          )
        );
      }
    }
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <ToggleOptions
        values={DateRangeOptions.filter(
          (dro) => dro.title !== DateRangeOptionType.CUSTOM
        ).map((dro) => dro.title)}
        option={rangeOption?.title || ""}
        setOption={(value: string) => {
          const newRangeOption = DateRangeOptions.find(dro => dro.title === value);
          setRangeOption(newRangeOption);
          setDate(newRangeOption?.range);
        }}
      />
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="dropdown"
            id="date"
            className={cn(
              "max-w-[14rem]",
              !date && "bg-brev-light",
              rangeOption?.title === DateRangeOptionType.CUSTOM &&
                "hover:bg-dark-alt hover:text-light-text bg-dark-alt text-brev-light dark:text-dark-text dark:bg-light-main border-dark-main dark:border-dark-hover"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Custom date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex w-auto flex-col space-y-2 p-0 mt-2 border-0 border-none overflow-hidden rounded-[8px]">
          <div
            className="rounded-[8px]"
            onMouseDown={() =>
              setRangeOption(DateRangeOptions[DateRangeOptions.length - 1])
            }
          >
            <Calendar
              initialFocus
              mode="range"
              // defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
