import { Node, mergeAttributes } from "@tiptap/core";

export const BulletListExtension = Node.create({
  name: 'ul',
  group: 'block',

  addOptions() {
    return {
      HTMLAttributes: { 'class': 'list-style-disc' },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'ul'
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addAttributes() {
    return {
      class: {
        parseHTML: element => element.getAttribute('class'),
      },
    }
  },
});