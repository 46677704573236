import { createContext, useState } from "react";

interface GlobalContextProps {
  artifactTitle: string;
  setArtifactTitle: React.Dispatch<React.SetStateAction<string>>;
}

const GlobalContext = createContext<GlobalContextProps>({
  artifactTitle: "",
  setArtifactTitle: () => {
    throw new Error('not implemented');
  }
});

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [artifactTitle, setArtifactTitle] = useState<string>("");

  return (
    <GlobalContext.Provider value={
      {
      artifactTitle,
      setArtifactTitle 
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export { GlobalProvider, GlobalContext };
