import * as React from "react";
import { inputVariants } from "./variants";
import { VariantProps } from "class-variance-authority";
import { cn, debounce } from "@/lib/utils";
import BrevLoader from "@/components/Loaders/BrevLoader";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  start?: React.ReactNode;
  end?: React.ReactNode;
  label?: React.ReactNode;
  onChangeText?: (text: string) => void;
  focus?: boolean;
  value?: string;
  allowEditing?: boolean;
  outerClassName?: string;
  inputClassName?: string;
  onSave?: () => Promise<void>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      outerClassName,
      inputClassName,
      type,
      start,
      end,
      onChangeText,
      label,
      variant,
      fontSize,
      value,
      focus = true,
      allowEditing = true,
      onSave,
      ...props
    },
    ref
  ) => {
    const [isSaving, setIsSaving] = React.useState(false);
    const onSaveRef = React.useRef(onSave);

    React.useEffect(() => {
      onSaveRef.current = onSave;
    }, [onSave]);

    const debouncedOnSave = React.useCallback(
      debounce(async () => {
        if (value && value.trim() !== "") {
          setIsSaving(true);
          if (onSaveRef.current) {
            await onSaveRef.current!();
          }
          setIsSaving(false);
        }
      }, 500),
      [value, onSave]
    );

    const onChange = React.useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        console.log("onChange");
        onChangeText?.(newValue);
        props.onChange?.(event);

        if (newValue.trim() !== "") {
          debouncedOnSave();
        }
      },
      [debouncedOnSave, onChangeText, props]
    );

    return (
      <div className={cn("", outerClassName)}>
        {label && (
          <label
            className={`flex flex-col pl-2 my-2 text-dark-text dark:text-light-text text-xs`}
          >
            {label}
          </label>
        )}
        <div
          className={`${cn(inputVariants({ variant, fontSize }))} ${className}`}
        >
          {start && <div className="pl-3 m-auto">{start}</div>}
          <input
            tabIndex={0}
            autoFocus={focus}
            type={type}
            inputMode={type === "number" ? "numeric" : undefined}
            className={`${cn(
              "py-0 px-2 flex w-full rounded-full outline-none bg-transparent transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium disabled:cursor-not-allowed disabled:opacity-50",
              inputClassName
            )}`}
            ref={ref}
            onChange={onChange}
            value={value}
            readOnly={!allowEditing}
            {...props}
          />
          {end}
          {isSaving && <BrevLoader type="ring" size={20} />}
        </div>
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
