import { ResourceModel } from "@/components/Connector/types"

export enum ContextType {
  TEXT_SUMMARY = "TEXT_SUMMARY",
  DATASET = "DATASET",
  PRODUCTS = "PRODUCTS",
  PEOPLE = "PEOPLE",
  CUSTOMERS = "CUSTOMERS",
  MUTEX = "MUTEX",
}

export enum ContextBaseType {
  ARTIFACT = "ARTIFACT",
}

export enum ContextStatus {
  GENERATING = "GENERATING",
  COMPLETE = "COMPLETE",
  DISABLED = "DISABLED",
}

export type ContextBaseModel = {
  contextBaseId: string,
  contextBaseType: ContextBaseType,
}

export type ContextModel = {
  contextId: string,
  userId: string,
  contextName: string,
  contextType: ContextType,
  contextContent: string,
  contextBaseId: string,
  contextBaseModel: ContextBaseModel,
  resourceId?: string,
  resourceModel?: ResourceModel,
  status: ContextStatus,
  createdAt: number,
  latencyInMs: number,
}

export type ContextsByResourceModel = {
  resourceId: string,
  resourceModel: ResourceModel,
  contexts: ContextModel[],
}