import { useContext } from "react";
import { Button } from "@/components/ui/button";
import { LuPaintBucket, LuDatabase } from "react-icons/lu";
import { KPITile } from "@/containers/ReviewBuilder/review-types";
import { ArtifactContext } from "@/containers/ArtifactBuilder/ArtifactProvider";

import Hint from "@/components/Tooltips/Hint";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger
  // SelectValue
} from "@/components/ui/select";

export default function KpiToolbar({ tile }: { tile: KPITile }) {
  const { kpiDatasets, updateTileContent } = useContext(ArtifactContext);

  // const handleContentUpdate = (updates: Partial<KPIData>) => {
  //   updateTileContent(tile.id, { ...tile.content, ...updates });
  // };

  const handleContentUpdate = (selectedTitle: string) => {
    const selectedDataset = kpiDatasets.find((d) => d.title === selectedTitle);
    if (selectedDataset) {
      updateTileContent(tile.id, { ...tile.content, ...selectedDataset });
    }
  };

  return (
    <div className="flex flex-nowrap gap-1 overflow-x-scroll no-scrollbar w-full px-3 py-1.5">
      <Select onValueChange={handleContentUpdate}>
        <SelectTrigger asChild>
          <Button variant="ghost" className="hover:opacity-100">
            <Hint message="Select dataset" side="bottom" offset={20}>
              <LuDatabase size={16} />
            </Hint>
          </Button>
        </SelectTrigger>
        <SelectContent className="data-[state=open]:rounded-t-lg my-3 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text ">
          <SelectGroup>
            <SelectLabel className="text-dark-text dark:text-light-text">
              KPI Data
            </SelectLabel>
            {kpiDatasets
              .filter((d) => d?.title)
              .map((d, index) => (
                <SelectItem
                  key={`dataset-${index}`}
                  value={d.title}
                  className="text-[12px] text-dark-text dark:text-light-text"
                >
                  {d?.title}
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select value={""} disabled>
        <SelectTrigger asChild>
          <Button variant="ghost" className="hover:opacity-100" disabled>
            <Hint message="Color scheme" side="bottom" offset={20}>
              <LuPaintBucket size={16} />
            </Hint>
          </Button>
        </SelectTrigger>
        <SelectContent className="data-[state=open]:rounded-t-lg my-3 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text ">
          <SelectGroup>
            <SelectLabel className="text-dark-text dark:text-light-text">
              Colors
            </SelectLabel>
            {["red", "blue", "green", "yellow", "orange", "purple"].map(
              (color) => (
                <SelectItem
                  key={color}
                  value={color}
                  className="text-[12px] text-dark-text dark:text-light-text"
                >
                  {color}
                </SelectItem>
              )
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
