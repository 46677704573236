import { Link, useRouteError, isRouteErrorResponse } from "react-router-dom";
import BrevLogo from "../components/ui/brevlogo";
import { useState, useEffect } from "react";
import { Button } from "../components/ui/button";
import { getDefaultMessageForStatus, getErrorTitle } from "@/lib/utils";

function useTypingEffect(
  text: string,
  initialDelay: number = 1000,
  speed: number = 50
) {
  const [displayedText, setDisplayedText] = useState("");
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    let i = 0;

    const initialDelayTimeout = setTimeout(() => {
      const typingInterval = setInterval(() => {
        if (i < text.length) {
          setDisplayedText(() => text.substring(0, i + 1));
          i++;
        } else {
          clearInterval(typingInterval);
        }
      }, speed);

      return () => clearInterval(typingInterval);
    }, initialDelay);

    const cursorInterval = setInterval(() => {
      setShowCursor((prev) => !prev);
    }, 500);

    return () => {
      clearTimeout(initialDelayTimeout);
      clearInterval(cursorInterval);
    };
  }, [text, speed, initialDelay]);

  return { displayedText, showCursor };
}

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  let errorMessage: string;
  let errorStatus: number | string;

  if (isRouteErrorResponse(error)) {
    errorStatus = error.status;
    errorMessage =
      getDefaultMessageForStatus(error.status) ||
      error.statusText ||
      error.data;
  } else if (error instanceof Error) {
    errorStatus = "Uh oh! Something went wrong.";
    errorMessage = error.message;
  } else {
    errorStatus = "Unknown Error";
    errorMessage = "An unexpected error occurred";
  }

  const { displayedText, showCursor } = useTypingEffect(errorMessage, 1000, 25);
  const errorTitle = getErrorTitle(errorStatus);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-light-main">
      <BrevLogo />
      <div className="text-[2rem] font-bold py-10 text-dark-text">{errorTitle}</div>
      <div className="h-10 mt-4 font-normal text-dark-text relative">
        <span>{displayedText}</span>
        {showCursor && <span className="animate-blink absolute -right-1.5">|</span>}
      </div>
      <div className="flex h-[10rem] w-full items-center justify-center">
        <Link to="/home">
          <Button variant="outline" className="">Back to home</Button>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
