export default function GSheetsSvg({ size }: { size?: number }) {
  return (
    <svg
      width={size || 24}
      height={size || 32}
      viewBox="0 0 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_877_2375)">
        <mask
          id="mask0_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_877_2375)">
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L17.6075 5.5462L14.2048 0.782349Z"
            fill="#0F9D58"
          />
        </g>
        <mask
          id="mask1_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_877_2375)">
          <path
            d="M6.03906 15.4141V25.2821H16.9279V15.4141H6.03906ZM10.8029 23.921H7.40019V22.2196H10.8029V23.921ZM10.8029 21.1987H7.40019V19.4974H10.8029V21.1987ZM10.8029 18.4765H7.40019V16.7752H10.8029V18.4765ZM15.5668 23.921H12.164V22.2196H15.5668V23.921ZM15.5668 21.1987H12.164V19.4974H15.5668V21.1987ZM15.5668 18.4765H12.164V16.7752H15.5668V18.4765Z"
            fill="#F1F1F1"
          />
        </g>
        <mask
          id="mask2_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_877_2375)">
          <path
            d="M14.8027 8.35168L22.3722 15.9194V8.94888L14.8027 8.35168Z"
            fill="url(#paint0_linear_877_2375)"
          />
        </g>
        <mask
          id="mask3_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_877_2375)">
          <path
            d="M14.2051 0.782471V6.90745C14.2051 8.03544 15.1187 8.94909 16.2467 8.94909H22.3717L14.2051 0.782471Z"
            fill="#87CEAC"
          />
        </g>
        <mask
          id="mask4_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_877_2375)">
          <path
            d="M2.63541 0.782349C1.51249 0.782349 0.59375 1.70109 0.59375 2.82401V2.99414C0.59375 1.87123 1.51249 0.952487 2.63541 0.952487H14.2048V0.782349H2.63541Z"
            fill="white"
            fillOpacity="0.2"
          />
        </g>
        <mask
          id="mask5_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_877_2375)">
          <path
            d="M20.3298 30.5567H2.63541C1.5125 30.5567 0.59375 29.6379 0.59375 28.515V28.6851C0.59375 29.8081 1.5125 30.7268 2.63541 30.7268H20.3298C21.4527 30.7268 22.3714 29.8081 22.3714 28.6851V28.515C22.3714 29.6379 21.4527 30.5567 20.3298 30.5567Z"
            fill="#263238"
            fillOpacity="0.2"
          />
        </g>
        <mask
          id="mask6_877_2375"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="23"
          height="31"
        >
          <path
            d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_877_2375)">
          <path
            d="M16.2467 8.94899C15.1187 8.94899 14.2051 8.03534 14.2051 6.90735V7.07748C14.2051 8.2055 15.1187 9.11912 16.2467 9.11912H22.3717V8.94899H16.2467Z"
            fill="#263238"
            fillOpacity="0.1"
          />
        </g>
        <path
          d="M14.2048 0.782349H2.63541C1.5125 0.782349 0.59375 1.70109 0.59375 2.82401V28.685C0.59375 29.8079 1.5125 30.7267 2.63541 30.7267H20.3298C21.4527 30.7267 22.3714 29.8079 22.3714 28.685V8.94897L14.2048 0.782349Z"
          fill="url(#paint1_radial_877_2375)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_877_2375"
          x1="18.5879"
          y1="9.00147"
          x2="18.5879"
          y2="15.9205"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#263238" stopOpacity="0.2" />
          <stop offset="1" stopColor="#263238" stopOpacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_877_2375"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(1.28368 1.37755) scale(35.1162 35.1162)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_877_2375">
          <rect
            width="22.8113"
            height="30.8261"
            fill="white"
            transform="translate(0.208984 0.341553)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
