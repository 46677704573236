export default function LightBlueGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="67.0977"
        y="6.23438"
        width="42.25"
        height="94.7949"
        rx="3"
        fill="url(#paint0_linear_9047_19)"
        fillOpacity="0.35"
      />
      <path
        d="M18.3218 17.2192H45.7953"
        stroke="url(#paint1_linear_9047_19)"
        strokeOpacity="0.35"
        strokeWidth="5.24026"
      />
      <path
        d="M17.5762 64.3467H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 68.8389H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 55.3633H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 59.8555H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 46.3799H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 50.8721H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 37.3965H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 41.8887H58.375"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 74.4531H58.3753"
        stroke="#E7E9F2"
        strokeWidth="0.748608"
      />
      <rect
        x="8.8387"
        y="1.08964"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect
        x="0.357422"
        y="46.3799"
        width="120.526"
        height="86.8393"
        fill="url(#paint2_linear_9047_19)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9047_19"
          x1="88.2227"
          y1="6.23437"
          x2="88.2227"
          y2="101.029"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#41C6FF" />
          <stop offset="1" stopColor="#35FF9E" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9047_19"
          x1="32.0586"
          y1="17.2192"
          x2="32.0586"
          y2="18.2192"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.29" stopColor="#41C6FF" />
          <stop offset="1" stopColor="#35FF9E" />
        </linearGradient>
      </defs>
    </svg>
  );
}
