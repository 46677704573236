import Connections from "@/components/Connector/components/connections";
import UploadFilePicker from "@/components/Connector/UploadFile/UploadFilePicker";
import TextLabel from "@/components/Text/TextLabel";
import { useStepper } from "@/components/Stepper/useStepper";
import { ReactNode, useContext } from "react";
import { toast } from "@/components/ui/use-toast";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import InternalPicker from "@/components/Connector/Internal/InternalPicker";
import { cn } from "@/lib/utils";
import { AuthContext } from "../Auth/AuthProvider";
import IntegrationObjectPicker from "@/components/Connector/IntegrationObjectPicker";
import { IntegrationApp } from "./types";
import { NotionObjectType } from "@/components/Connector/Notion/NotionConnector";
import { IntegrationConnectionsContext } from "./IntegrationConnectionsProvider";

type StepProps = {
  onPress?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
  onSubmit?: () => void;
  onRemove?: (integrationId: string) => void;
};

type BaseProps = {
  setStep?: (name: StepNames) => void;
  onSuccess?: () => void;
  onSubmit?: () => void;
  onRemove?: (integrationId: string) => void;
  className?: string;
};

type ConnectionsProps = {
  isOpen?: boolean;
  setSelectedIntegration?: (integration: APPS) => void;
} & BaseProps;

type AccordionItemProps = {
  id: string;
  title: ReactNode;
  children?: ReactNode;
  noTitle?: boolean;
};

export function ConnectorItem({
  id,
  title,
  children,
  noTitle
}: AccordionItemProps) {
  return (
    <AccordionItem
      value={id}
      className="w-full bg-brev-light border border-gray-200 rounded-3"
    >
      {!noTitle && (
        <AccordionTrigger className="place-items-center data-[state=closed]:bg-brev data-[state=closed]:text-brev-light text-[1rem] font-bold pl-[20px] pr-4 rounded-3 h-[48px]">
          {title}
        </AccordionTrigger>
      )}
      <AccordionContent className="pl-[1rem] pr-2 pb-2 rounded-3">
        {children}
      </AccordionContent>
    </AccordionItem>
  );
}

const steps = [
  {
    key: "connections",
    Component: ({ onSuccess, setStep, className }: ConnectionsProps) => (
      <Connections
        onSuccess={onSuccess}
        setStep={setStep}
        className={className}
      />
    )
  },
  {
    key: "slack-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: StepProps) => (
      <IntegrationObjectPicker
        title="Slack"
        ctaName="Apply data"
        placeholder="#channel-name"
        integrationApp={IntegrationApp.SLACK}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  },
  {
    key: "salesforce-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: BaseProps) => (
      <IntegrationObjectPicker
        title="Salesforce"
        ctaName="Apply data"
        placeholder="Find a report"
        integrationApp={IntegrationApp.SALESFORCE}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  },
  {
    key: "gdrive-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: BaseProps) => (
      <IntegrationObjectPicker
        title="Google Drive"
        ctaName="Select a file"
        placeholder="File name"
        integrationApp={IntegrationApp.GDRIVE}
        fetchOnSearch
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  },
  {
    key: "github-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: BaseProps) => (
      <IntegrationObjectPicker
        title="GitHub"
        ctaName="Select a repository"
        placeholder="Repository"
        integrationApp={IntegrationApp.GITHUB}
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  },
  {
    key: "notion-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: BaseProps) => (
      <IntegrationObjectPicker
        title="Notion"
        ctaName="Select a file"
        placeholder="File name"
        integrationApp={IntegrationApp.NOTION}
        // queryTypes={[NotionObjectType.PAGE, NotionObjectType.DATABASE]}
        queryTypes={[NotionObjectType.PAGE]}
        defaultQueryType={NotionObjectType.PAGE}
        fetchOnSearch
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  },
  {
    key: "uploadFile-picker",
    Component: () => {
      return <UploadFilePicker />;
    }
  },
  {
    key: "internal-picker",
    Component: () => <InternalPicker />
  },
  {
    key: "hubspot-picker",
    Component: ({ onSuccess, onSubmit, onRemove }: BaseProps) => (
      <IntegrationObjectPicker
        title="Hubspot"
        ctaName="Select a file"
        placeholder="File name"
        integrationApp={IntegrationApp.HUBSPOT}
        fetchOnSearch
        onSuccess={onSuccess}
        onSubmit={onSubmit}
        onRemove={onRemove}
      />
    )
  }
] as const;

type Steps = typeof steps;
type Union = Steps[number];
export type StepNames = Steps[number]["key"];

export function DialogConnectionSteps({ className }: { className?: string }) {
  const { sendApiRequest } = useContext(AuthContext);
  const { fetchConnections } = useContext(IntegrationConnectionsContext);
  const [Step, { is, next, set, getCurrentIndex, first }] = useStepper<
    Union,
    Steps
  >("connections", steps);

  // Add this console.log
  const setWithLog = (stepName: StepNames) => {
    console.log("Setting step to:", stepName);
    set(stepName);
  };

  const handleConnectionRemove = async (integrationId: string) => {
    await sendApiRequest(`/integrations/${integrationId}`, "DELETE");
    set("connections");
    await fetchConnections();
    toast({
      title: "Success",
      description: `Deletion Successful`
    });
  };

  return (
    <div
      className={cn(
        "flex flex-col px-3 pb-2.5 pt-3 rounded-[8px] bg-light-alt dark:bg-dark-main max-h-[24rem] overflow-y-scroll no-scrollbar border border-brev-border dark:border-brev transition-colors duration-500 ease-in-out",
        className
      )}
    >
      {is(Step, "connections") ? (
        <Step.Component
          onSuccess={() => {
            next();
          }}
          // setStep={(name: StepNames) => set(name)}
          setStep={(name: StepNames) => setWithLog(name)}
        />
      ) : (
        <Step.Component
          setStep={set}
          onSuccess={() => {
            set("slack-picker");
          }}
          onSubmit={first}
          onFailure={() => set("connections")}
          onRemove={handleConnectionRemove}
        />
      )}
      <div className="flex justify-center py-1">
        {getCurrentIndex() > 0 && (
          <Button
            variant="ghost"
            onClick={() => {
              set("connections");
            }}
          >
            <TextLabel className="">Back</TextLabel>
          </Button>
        )}
      </div>
    </div>
  );
}
