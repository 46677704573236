import { StepNames } from "@/containers/Integrations/ConnectionSteps";
import Tile from "../../Tile/Tile";
import HubSpot from "@/images/hubspot.svg";
import { useCallback, useContext, useEffect } from "react";
import { IntegrationApp } from "@/containers/Integrations/types";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { authenticateHubSpot } from "@/lib/utils";
import { useParams, useSearchParams } from "react-router-dom";

type HubSpotConnectorProps = {
  onSuccess?: (stepName: StepNames) => void;
  activeIntegration?: string;
};

export default function HubSpotConnector({
  onSuccess,
  activeIntegration
}: HubSpotConnectorProps) {
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(
    IntegrationConnectionsContext
  );

  useEffect(() => {
    if (isAppActive(IntegrationApp.HUBSPOT)) {
      onSuccess?.("hubspot-picker");
      setSearchParams({});
    }
  }, [isAppActive, onSuccess]);

  const authenticateClient = useCallback(async () => {
    if (isAppConnected(IntegrationApp.HUBSPOT)) {
      onSuccess?.("hubspot-picker");
      return;
    }
    authenticateHubSpot(artifactId);
  }, [artifactId, isAppConnected, onSuccess]);

  return (
    <Tile
      onPress={authenticateClient}
      label="HubSpot"
      src={HubSpot}
      connected={isAppConnected(IntegrationApp.HUBSPOT)}
      selected={activeIntegration === "hubspot-picker"}
      isLoading={isLoading}
    />
  );
}
