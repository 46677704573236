import React, { createContext, useEffect, useState, useMemo, useCallback, useContext } from 'react';
import WebSocketManager from './WebSocketManager';
import config from '@/config';
import { AuthContext } from '../Auth/AuthProvider';
import { GoDotFill } from 'react-icons/go';

interface WebSocketProviderProps {
  webSocketManager: WebSocketManager | null;
  isConnectionOpen: boolean;
}

const WebSocketContext = createContext<WebSocketProviderProps>({
  webSocketManager: null,
  isConnectionOpen: false,
});

const WebSocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { authToken } = useContext(AuthContext);
  const [webSocketManager] = useState(() => new WebSocketManager(config.apiGateway.COPILOT_WEBSOCKET, authToken!));
  const [isConnectionOpen, setIsConnectionOpen] = useState(false);

  const checkConnection = useCallback(() => {
    setIsConnectionOpen(webSocketManager.isReady());
  }, [webSocketManager]);

  useEffect(() => {
    if (webSocketManager) {
      // Set up an interval to periodically check the socket status
      const intervalId = setInterval(checkConnection, 500);

      // Clear the interval when the component unmounts or when socket changes
      return () => clearInterval(intervalId);
    }
  }, [webSocketManager, checkConnection]);

  useEffect(() => {
    webSocketManager.connect();
    return () => {
      webSocketManager.disconnect();
    }
  }, [webSocketManager]);

  const contextValue = useMemo(() => {
    return {
      webSocketManager,
      isConnectionOpen,
    };
  }, [
    webSocketManager,
    isConnectionOpen,
  ]);

  return (
    <WebSocketContext.Provider value= { contextValue } >
      <div className="fixed bottom-2 right-2 flex justify-end items-center gap-2 z-50">
        {isConnectionOpen && <GoDotFill size={16} color="#3FCFF1" />}
        {!isConnectionOpen && <GoDotFill size={16} color="red" />}
      </div>
      { children }
    </WebSocketContext.Provider>
  );
};

export { WebSocketProvider, WebSocketContext };