import { useState, useCallback, useRef, useEffect } from 'react';

interface ChunkData<T> {
  chunk: T;
  index: number;
}

interface UseChunkBufferOptions<T> {
  processChunk: (chunk: T) => void;
  onComplete?: () => void;
  bufferTimeoutMs?: number;
}

export default function useChunkBuffer<T>({
  processChunk,
  onComplete,
  bufferTimeoutMs = 5000
}: UseChunkBufferOptions<T>) {
  const [buffer, setBuffer] = useState<Map<number, T>>(new Map());
  const nextIndexRef = useRef(0);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const processBuffer = useCallback(() => {
    let processed = false;
    while (buffer.has(nextIndexRef.current)) {
      const chunk = buffer.get(nextIndexRef.current);
      if (chunk !== undefined) {
        processChunk(chunk);
        buffer.delete(nextIndexRef.current);
        nextIndexRef.current++;
        processed = true;
      }
    }
    if (processed) {
      setBuffer(new Map(buffer));
    }
    return buffer.size === 0;
  }, [buffer, processChunk]);

  const addChunk = useCallback(({ chunk, index }: ChunkData<T>) => {
    setBuffer(prev => {
      if (index < nextIndexRef.current) {
        // console.warn(`Ignoring duplicate or out-of-order chunk at index ${index}: ${nextIndexRef.current}`);
        return prev;
      }
      const newBuffer = new Map(prev);
      newBuffer.set(index, chunk);
      return newBuffer;
    });

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      if (processBuffer() && onComplete) {
        onComplete();
      }
    }, bufferTimeoutMs);
  }, [processBuffer, bufferTimeoutMs, onComplete]);

  useEffect(() => {
    const isComplete = processBuffer();
    if (isComplete && onComplete) {
      onComplete();
    }
  }, [buffer, processBuffer, onComplete]);

  const cleanup = useCallback(() => {
    setBuffer(new Map());
    nextIndexRef.current = 0;
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, []);

  return { addChunk, cleanup };
}