import { ColumnDef } from "@tanstack/react-table";
import { Badge } from "@/components/ui/badge";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTableColumnHeader } from "./column-header";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { RxPencil2 } from "react-icons/rx";
// import type { CognitoUser } from "amazon-cognito-identity-js";

// TODO: change this to a map of string keys to boolean values
export type UserSettings = {
  nextBestActionsEnabled?: boolean;
  useCompanyMemory?: boolean;
  useV3?: boolean;
  attribute2?: boolean;
  attribute3?: boolean;
  attribute4?: boolean;
  attribute5?: boolean;
};

export enum AccessLevel {
  BASIC = "basic",
  PRO = "pro",
  ADMIN = "admin"
}

export const columns: ColumnDef<BrevUser>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={
          table.getIsAllPageRowsSelected() ||
          (table.getIsSomePageRowsSelected() && "indeterminate")
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
        className="translate-y-[2px]"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        className="translate-y-[2px]"
      />
    ),
    enableSorting: false,
    enableHiding: false
  },
  {
    id: "userName",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="User Name" />
    ),
    cell: ({ row }) => {
      const firstName = row.original?.firstName;
      const lastName = row.original?.lastName;
      const accessLevel = row.original?.accessLevel ?? AccessLevel.BASIC;
      return (
        <div className="flex items-center space-x-2">
          <Badge variant="outline">{accessLevel}</Badge>
          <span className="font-medium">{`${firstName} ${lastName}`}</span>
        </div>
      );
    },
    enableSorting: true
  },
  {
    id: "email",
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    cell: ({ row }) => {
      const email =
        row.original?.email ??
        row.original?.cognitoUser?.attributes?.email ??
        "";
      return <div className="max-w-[500px] truncate">{email}</div>;
    }
  },
  {
    id: "aiService",
    accessorKey: "aiService",
    header: ({ column }) => {
      return <DataTableColumnHeader column={column} title="Service" />;
    },
    cell: ({ row, table }) => {
      const aiService = row.original?.aiService ?? "";
      return (
        <Select
          value={aiService}
          onValueChange={(value: string) => {
            table.options.meta?.updateData(row.index, "aiService", value);
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select AI Service" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={"ChatGPT"}>ChatGPT</SelectItem>
            <SelectItem value={"Claude"}>Claude</SelectItem>
          </SelectContent>
        </Select>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    }
  },
  {
    id: "nextBestActionsEnabled",
    accessorKey: "userSettings.nextBestActionsEnabled",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Next Best Actions" />
    ),
    cell: ({ row, table }) => {
      const enabled =
        row.original?.userSettings?.nextBestActionsEnabled ?? false;
      return (
        <div className="flex gap-2">
          <div>{enabled ? "Enabled" : "Disabled"}</div>
          <Checkbox
            checked={enabled}
            onCheckedChange={(value) => {
              table.options.meta?.updateData(
                row.index,
                "nextBestActionsEnabled",
                value
              );
            }}
          />
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(String(row.getValue(id)));
    }
  },
  {
    id: "useCompanyMemory",
    accessorKey: "userSettings.useCompanyMemory",
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Use Company Memory" />
    ),
    cell: ({ row, table }) => {
      const enabled = row.original?.userSettings?.useCompanyMemory ?? false;
      return (
        <div className="flex gap-2">
          <div>{enabled ? "Enabled" : "Disabled"}</div>
          <Checkbox
            checked={enabled}
            onCheckedChange={(value) => {
              table.options.meta?.updateData(
                row.index,
                "useCompanyMemory",
                value
              );
            }}
          />
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(String(row.getValue(id)));
    }
  },
  {
    id: "actions",
    cell: ({ row, table }) => (
      <Button
        variant="ghost"
        onClick={() => table.options.meta?.openEditDialog(row.original)}
      >
        <RxPencil2 className="h-4 w-4" />
      </Button>
    )
  }
];
