import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import config from "@/config";
import axios from "axios";
import LoadingPage from "../Loading/LoadingPage";
import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";

type UserModel = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  companySize?: string;
  companyGeography?: string;
  companyIndustry?: string;
  companySegment?: string;
  jobTitle?: string;
  accessLevel?: string;
  profilePictureUrl?: string;
};

interface AuthProviderProps {
  user: UserModel | null;
  userId: string;
  authToken: string | null;
  sendApiRequest: (
    path: string,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    data?: object,
    failureMessage?: string
  ) => Promise<object | undefined>;
  fetchUser: () => Promise<void>;
  isAuthenticated: boolean;
  logout: () => void;
}

const AuthContext = createContext<AuthProviderProps>({
  user: null,
  userId: "",
  authToken: null,
  sendApiRequest: () => {
    throw new Error("sendApiRequest function not defined");
  },
  fetchUser: async () => {
    throw new Error("fetchUser function not defined");
  },
  isAuthenticated: false,
  logout: () => {
    throw new Error("logout function not defined");
  }
});

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [userId, setUserId] = useState<string>("");
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem("authToken")
  );
  const { toast } = useToast();
  const navigate = useNavigate();
  const logout = useCallback(() => {
    localStorage.clear();
    navigate(`/login`);
  }, [navigate]);

  const sendApiRequest = useCallback(
    async (
      path: string,
      method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
      data?: object,
      failureMessage?: string
    ) => {
      if (!authToken) {
        throw new Error("No auth token found");
      }
      try {
        return await axios({
          method,
          url: `${config.apiGateway.V3_API_URL}${path}`,
          data,
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json"
          }
        });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error && error.response && error.response.status === 401) {
          toast({
            title: "Error",
            description: `Authentication expired. Logging out in 3 seconds...`
          });
          const intervalId = setInterval(() => {
            clearInterval(intervalId);
            logout();
          }, 3000);
        } else {
          console.error("Error sending API request", error);
          toast({
            title: "Error making request",
            description: failureMessage || `${error.response.data.message}`
          });
        }
      }
    },
    [authToken, logout, toast]
  );

  const fetchUser = useCallback(async () => {
    const response = (await sendApiRequest("/users/me", "GET")) as {
      data: { user: UserModel };
    };
    const userData = response.data.user;
    setUser(userData);
    setUserId(userData.userId);
  }, [sendApiRequest]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token) {
      window.location.href = `${config.env.REDIRECT_URL_ROOT}/login`;
    } else {
      setAuthToken(token);
      if (!user) {
        fetchUser();
      }
    }
  }, []);

  const isAuthenticated = !!user && !!authToken;

  const contextValue = useMemo(() => {
    return {
      user,
      userId,
      authToken,
      sendApiRequest,
      fetchUser,
      isAuthenticated,
      logout
    };
  }, [
    user,
    userId,
    authToken,
    sendApiRequest,
    fetchUser,
    isAuthenticated,
    logout
  ]);
  return (
    <AuthContext.Provider value={contextValue}>
      {userId && children}
      {!userId && <LoadingPage />}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
