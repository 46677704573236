import { useContext, useState } from "react";
import * as z from "zod";
import { Input } from "../../components/ui/input";
import { motion } from "framer-motion";
import { LuSendHorizonal } from "react-icons/lu";
import { Button } from "../../components/ui/button";
// import SelectRole from "./SelectRole";
import { UserSettingsContext } from "../UserSettings/UserSettingsProvider";
import config from "@/config";

const nameSchema = z
  .string()
  .min(2)
  .max(50)
  .regex(/^[a-zA-Z\s]+$/, {
    message: "Sorry! This name is invalid. Please only use letters."
  });

const companySchema = z
  .string()
  .min(2)
  .max(50, "Company name must be fewer than 50 characters");

const jobTitleSchema = z
  .string()
  .min(2)
  .max(50, "Job title must be fewer than 50 characters");

export default function OnboardingInput() {
  const [error, setError] = useState<string | null>(null);
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    jobTitle,
    setJobTitle,
    companyName,
    setCompanyName,
    saveUserSettings
  } = useContext(UserSettingsContext);
  const [step, setStep] = useState(0);
  const [name, setName] = useState(() => {
    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    }
    return "";
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setName(input);

    if (input.length > 2) {
      try {
        nameSchema.parse(input);
        setError(null);
      } catch (error) {
        if (error instanceof z.ZodError) {
          setError(error.errors[0].message);
        } else {
          setError("An error occurred");
        }
      }
    }

    if (!error) {
      const nameParts = input.trim().split(" ");
      const firstName = nameParts[0];
      const lastName =
        nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
      setFirstName(firstName.trim());
      setLastName(lastName.trim());
    }
  };

  const handleCompanyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setCompanyName(input);

    if (input.length > 2) {
      try {
        companySchema.parse(input);
        setError(null);
      } catch (error) {
        if (error instanceof z.ZodError) {
          setError(error.errors[0].message);
        } else {
          setError("An error occurred");
        }
      }

      if (!error) {
        setCompanyName(input);
      }
    }
  };

  const handleJobTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setJobTitle(input);

    if (input.length > 2) {
      try {
        jobTitleSchema.parse(input);
        setError(null);
      } catch (error) {
        if (error instanceof z.ZodError) {
          setError(error.errors[0].message);
        } else {
          setError("An error occurred");
        }
      }

      if (!error) {
        setJobTitle(input);
      }
    }
  };

  // const handleRoleChange = useCallback(
  //   (role: string) => {
  //     setJobTitle(role);
  //     setStep(3);
  //   },
  //   [setJobTitle]
  // );

  return (
    <>
      <div className="text-[1.5rem] text-light-alt font-semibold">
        Let's start with your name
      </div>
      <Input
        variant="ghost"
        value={name}
        onChange={handleNameChange}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter" && !error) {
            setStep(1);
          }
        }}
        placeholder="Enter your full name"
        // start={
        //   <Initials
        //     visible={!!firstName}
        //     initials={`${firstName[0]}${lastName[0] || ""}`}
        //   />
        // }
        data-cy="onboarding-name"
        className={`text-light-alt placeholder:text-brev-gray`}
        inputClassName={`${step > 0 && "flex text-center items-center justify-center"}`}
        disabled={step > 0}
        end={
          firstName &&
          step === 0 && <EnterButton handleEnter={() => setStep(1)} />
        }
      />
      {step > 0 && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-3 w-full flex flex-col gap-3 items-center justify-center"
        >
          <div className="text-2xl text-light-alt font-semibold">
            Next we'll need your company name
          </div>
          <Input
            variant="ghost"
            value={companyName}
            onChange={handleCompanyChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && !error) {
                setStep(2);
              }
            }}
            placeholder="Enter your company name"
            className={`text-light-alt placeholder:text-brev-light/2`}
            inputClassName={`${step > 1 && "flex text-center items-center justify-center"}`}
            disabled={step > 1}
            data-cy="onboarding-company"
            end={
              companyName &&
              step === 1 && <EnterButton handleEnter={() => setStep(2)} />
            }
          />
        </motion.div>
      )}
      {step > 1 && (
        // <SelectRole selected={jobTitle} setRole={handleRoleChange} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="mt-3 w-full flex flex-col gap-3 items-center justify-center"
        >
          <div className="text-2xl text-light-alt font-semibold">
            What is your job title?
          </div>
          <Input
            variant="ghost"
            value={jobTitle}
            onChange={handleJobTitleChange}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "Enter" && !error) {
                setStep(3);
              }
            }}
            placeholder="Enter your job title"
            className={`text-light-alt placeholder:text-brev-light/2`}
            inputClassName={`${step > 2 && "flex text-center items-center justify-center"}`}
            disabled={step > 2}
            data-cy="onboarding-jobtitle"
            end={
              jobTitle &&
              step === 2 && <EnterButton handleEnter={() => setStep(3)} />
            }
          />
        </motion.div>
      )}
      {step >= 2 && jobTitle && (
        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="mt-4"
        >
          <Button
            variant="gradient"
            onClick={async () => {
              setIsLoading(true);
              await saveUserSettings();
              try {
                window.location.href = `${config.env.REDIRECT_URL_ROOT}/home`;
              } catch (error) {
                console.error("Error creating new artifact:", error);
              } finally {
                setIsLoading(false);
              }
            }}
            disabled={!!error || isLoading}
            isLoading={isLoading}
          >
            <span>Let's go!</span>
          </Button>
        </motion.div>
      )}
      <span className="text-red-500">{error}</span>
    </>
  );
}

// const Initials = ({
//   visible,
//   initials
// }: {
//   visible: boolean;
//   initials: string;
// }) => {
//   return (
//     visible && (
//       <motion.div
//         initial={{ opacity: 0, x: -50 }}
//         animate={{ opacity: 1, x: 0 }}
//         transition={{ duration: 0.5 }}
//         className="flex h-10 w-10 rounded-full bg-slate-800 text-brev-light/60 items-center justify-center text-xl border border-brev-border/50 pt-0.5"
//       >
//         {initials}
//       </motion.div>
//     )
//   );
// };

const EnterButton = ({ handleEnter }: { handleEnter: () => void }) => {
  return (
    <motion.button
      initial={{ opacity: 0, x: 50 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.5 }}
      onClick={handleEnter}
    >
      <LuSendHorizonal
        size={15}
        className="hover:scale-[1.02] active:scale-[0.98] transition-all duration-300"
      />
    </motion.button>
  );
};
