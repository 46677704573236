import { cn } from "@/lib/utils";

export default function PencilIcon({
  className,
  size
}: {
  className?: string;
  size?: number;
}) {
  return (
    <svg
      width={size || "13"}
      height={size || "13"}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89658 7.53429L12.3152 1.11566C12.4351 0.991503 12.5015 0.82521 12.5 0.6526C12.4985 0.479991 12.4292 0.314876 12.3072 0.192818C12.1851 0.0707597 12.02 0.00152482 11.8474 2.48863e-05C11.6748 -0.00147504 11.5085 0.0648799 11.3843 0.184798L4.96571 6.60342L4.77282 7.72718L5.89658 7.53429ZM2.30412 0.650231C1.69303 0.650231 1.10697 0.892986 0.674862 1.32509C0.242755 1.7572 0 2.34326 0 2.95435V10.1959C0 10.807 0.242755 11.393 0.674862 11.8251C1.10697 12.2572 1.69303 12.5 2.30412 12.5H9.54565C10.1567 12.5 10.7428 12.2572 11.1749 11.8251C11.607 11.393 11.8498 10.807 11.8498 10.1959V5.25847C11.8498 5.08388 11.7804 4.91643 11.657 4.79297C11.5335 4.66951 11.366 4.60015 11.1914 4.60015C11.0169 4.60015 10.8494 4.66951 10.7259 4.79297C10.6025 4.91643 10.5331 5.08388 10.5331 5.25847V10.1959C10.5331 10.4578 10.4291 10.7089 10.2439 10.8941C10.0587 11.0793 9.80754 11.1834 9.54565 11.1834H2.30412C2.04223 11.1834 1.79106 11.0793 1.60587 10.8941C1.42068 10.7089 1.31664 10.4578 1.31664 10.1959V2.95435C1.31664 2.69246 1.42068 2.44129 1.60587 2.2561C1.79106 2.07091 2.04223 1.96687 2.30412 1.96687H7.24153C7.41612 1.96687 7.58357 1.89751 7.70703 1.77405C7.83049 1.6506 7.89985 1.48315 7.89985 1.30855C7.89985 1.13395 7.83049 0.966508 7.70703 0.843049C7.58357 0.71959 7.41612 0.650231 7.24153 0.650231H2.30412Z"
        className={cn("fill-[#FAF9F9]", className)}
      />
    </svg>
  );
}
