import { Input } from "@/components/ui/input";
import { IoSearch } from "react-icons/io5";

type SearchInputProps = {
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export default function SearchInput({
  placeholder,
  value,
  onChange,
  disabled = false
}: SearchInputProps) {
  return (
    <div className="flex items-center w-[20.875rem] text-dark-text dark:text-brev-light border border-brev-border dark:border-dark-border dark:bg-dark-alt rounded-[8px] transition-colors duration-500 ease-in-out">
      <Input
        variant="ghost"
        fontSize="sm"
        placeholder={placeholder}
        type="text"
        value={value}
        start={<IoSearch size={15} />}
        onChangeText={onChange}
        disabled={disabled}
      />
    </div>
  );
}
