import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
  DialogClose
} from "@/components/ui/dialog";
import type { CalendarEvent } from "./calendar-types";
import { Button } from "@/components/ui/button";
import CheckMarkSvg from "@/components/Icons/CheckMarkSvg";
import GCalSvg from "@/components/Icons/GCalSvg";
import useRhythmContext from "./useRhythmContext";
import { IoSparklesSharp } from "react-icons/io5";

type RhythmDialogProps = {
  activeRhythms: string[];
  handleRhythmSelect: (event: CalendarEvent) => void;
};

export default function RhythmDialog({
  activeRhythms,
  handleRhythmSelect
}: RhythmDialogProps) {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {activeRhythms && activeRhythms.length > 0 ? (
          <div className="relative w-[100px]">
            <Button
              variant="ghost"
              className="w-full"
              onClick={() => setIsOpen(true)}
            >
              + Add
            </Button>
          </div>
        ) : (
          <div className="relative w-[230px]">
            <div className="rounded-full bg-brev-accent w-3 h-3 absolute top-0 -right-2 animate-ping"></div>
            <Button
              variant="outline"
              className="w-full"
              onClick={() => setIsOpen(true)}
            >
              Choose Rhythms
            </Button>
          </div>
        )}
      </DialogTrigger>
      <DialogContent className="flex flex-col items-center justify-center min-w-[600px] max-w-[600px] w-[600px] h-[600px]">
        <DialogHeader>
          <DialogTitle>
            <span className="text-black/40 py-4 text-[24px] font-normal flex items-center">
              <GCalSvg /> <span className="pl-2">Calendar</span>
            </span>
          </DialogTitle>
          <DialogDescription>
            <span className="flex w-[427px] text-start text-[20px] font-bold text-dark-text">
              Apply your Rhythms
            </span>
          </DialogDescription>
        </DialogHeader>
        <RhythmList
          activeRhythms={activeRhythms}
          handleRhythmSelect={handleRhythmSelect}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              variant="gradient"
              className="w-[272.45px] h-[42px] text-xs font-medium rounded-[8px]"
              onClick={() => setIsOpen(false)}
            >
              <IoSparklesSharp /> <span className="pl-4">Apply Rhythm Data</span>
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

const RhythmList = ({
  activeRhythms,
  handleRhythmSelect
}: {
  activeRhythms: string[];
  handleRhythmSelect: (event: CalendarEvent) => void;
}) => {
  const { eventArray } = useRhythmContext();
  const addedEventSummary = new Set();
  // Don't show repeated events with the same summary. For demo purposes only.
  const revEvents = [...eventArray]
    .reduce((acc: CalendarEvent[], event: CalendarEvent) => {
      if (event.recurrence && !addedEventSummary.has(event.summary)) {
        acc.push(event);
        addedEventSummary.add(event.summary);
      }
      return acc;
    }, [])
    .reverse();

  return (
    <div className="flex flex-col w-full items-center gap-1.5 max-h-[500px] overflow-y-scroll scrollbar-webkit-standard">
      {revEvents.map((event) => {
        return eventArray.length > 0 ? (
          event.recurrence && (
            <div
              key={event.id}
              className={`${activeRhythms.includes(event.id) ? "bg-brev-accent/20" : "bg-white"} flex items-center justify-between p-3 h-[36px] w-[427px] text-xs font-medium border border-brev-border rounded-[8px] cursor-pointer`}
              onMouseDown={() => handleRhythmSelect(event)}
            >
              <span>{event.summary}</span>
              {activeRhythms.includes(event.id) && <CheckMarkSvg />}
            </div>
          )
        ) : (
          <></>
        );
      })}
    </div>
  );
};
