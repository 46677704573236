/**
 * Util file for all app deployment related helpers 
 */

export const getAppStage = () => {
    const SST_STAGE = localStorage.getItem('SST_STAGE')
    return SST_STAGE !== "{{ SST_STAGE }}" ? SST_STAGE : process.env.SST_STAGE
}

export const isDevEnvironment = (): boolean => {
    return getAppStage() === "dev"
}

export const isStagingEnvironment = (): boolean => {
    return getAppStage() === "staging"
}

export const isProdEnvironment = (): boolean => {
    return getAppStage() === "prod"
}

export const isBeta = (): boolean => {
    return localStorage.getItem('IS_BETA') === '{{ IS_BETA }}' || localStorage.getItem('IS_BETA') === 'true';
}