import { useCallback, useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import { TabProps } from "./types";
import ToggleOptions from "./ToggleOptions";
import { ArtifactContext } from "../ArtifactProvider";
import { useParams } from "react-router-dom";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { WebSocketContext } from "@/containers/WebSocket/WebSocketProvider";
import DatePicker from "../DatePicker";
import { DateRange } from "react-day-picker";
import { Textarea } from "@/components/ui/textarea";

export default function ContextTab({ handleTabSelection }: TabProps) {
  const { userId } = useContext(AuthContext);
  const { webSocketManager } = useContext(WebSocketContext);
  const { artifactMetadata, setArtifactMetadata, saveArtifact, llmModelValue } =
    useContext(ArtifactContext);
  const { startAt, endAt, format, audience, purpose } = artifactMetadata;
  const { artifactId } = useParams<{ artifactId: string }>();

  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState<DateRange | undefined>({
    from: startAt,
    to: endAt
  });

  const updateMetadata = useCallback(
    (newMetadata: Partial<typeof artifactMetadata>) => {
      setArtifactMetadata((prevMetadata) => ({
        ...prevMetadata,
        ...newMetadata
      }));
    },
    [setArtifactMetadata]
  );

  const handleDateChange = useCallback(
    (newDateRange: DateRange | undefined) => {
      console.log("handleDateChange", newDateRange);
      setDateRange(newDateRange);
      if (newDateRange?.from && newDateRange?.to) {
        updateMetadata({ startAt: newDateRange.from, endAt: newDateRange.to });
        saveArtifact(
          {
            artifactMetadata: {
              ...artifactMetadata,
              startAt: newDateRange.from,
              endAt: newDateRange.to
            }
          },
          true
        );
      }
    },
    [updateMetadata, saveArtifact, artifactMetadata]
  );

  const handleFormatChange = useCallback(
    (value: string) => {
      console.log("handleFormatChange", value);
      updateMetadata({ format: value });
      saveArtifact(
        {
          artifactMetadata: { ...artifactMetadata, format: value }
        },
        true
      );
    },
    [updateMetadata, saveArtifact, artifactMetadata]
  );

  const handleAudienceChange = useCallback(
    (value: string) => {
      console.log("handleAudienceChange", value);
      updateMetadata({ audience: value });
      saveArtifact(
        {
          artifactMetadata: { ...artifactMetadata, audience: value }
        },
        true
      );
    },
    [updateMetadata, saveArtifact, artifactMetadata]
  );

  const audienceOptions = [
    { label: "manager", value: "My manager" },
    { label: "team", value: "My team" },
    { label: "department", value: "My department" },
    { label: "cross", value: "Cross-functional" },
    { label: "executive", value: "Executive team" }
  ];

  const typeOptions = [
    // { label: "document", value: "Document" },
    { label: "documentv4", value: "Document" },
    { label: "deck", value: "Deck" },
    { label: "dashboard", value: "Dashboard" }
  ];

  const handleNextClick = useCallback(async () => {
    setIsLoading(true);
    webSocketManager?.sendMessage({
      action: "generateArtifactTemplateCandidates",
      data: {
        userId,
        artifactId,
        llmModel: llmModelValue
      }
    });
    await saveArtifact({ artifactMetadata: { ...artifactMetadata } }, true);
    setIsLoading(false);
    handleTabSelection(1);
  }, [
    handleTabSelection,
    webSocketManager,
    userId,
    artifactId,
    llmModelValue,
    saveArtifact,
    artifactMetadata
  ]);

  const handlePurposeBlur = useCallback(() => {
    console.log("onBlur", artifactMetadata);
    setIsLoading(true);
    saveArtifact({ artifactMetadata: { ...artifactMetadata } }, true);
    setIsLoading(false);
  }, [saveArtifact, artifactMetadata]);

  return (
    <div className="flex flex-col gap-3 p-4 h-full bg-brev-light dark:bg-dark-alt">
      <div className="text-lg font-bold text-dark-text dark:text-brev-light py-2">
        We need some context to build your Brev
      </div>
      <div className="rounded-2xl text-xs font-semibold space-y-6">
        <div className="flex flex-col justify-between">
          <label className="text-sm font-semibold py-2 text-dark-text dark:text-brev-light">
            What type of artifact are you building?
          </label>
          <ToggleOptions
            values={typeOptions.map((o) => o.value)}
            option={format || ""}
            setOption={handleFormatChange}
            disabledOptions={["Deck", "Dashboard"]}
            defaultOption="Document"
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="text-sm font-semibold py-2 text-dark-text dark:text-brev-light">
            What's the purpose of this Artifact?
          </label>
          <Textarea
            className="resize-none p-2 font-normal"
            value={purpose}
            onChange={(e) => {
              updateMetadata({ purpose: e.target.value });
            }}
            onBlur={handlePurposeBlur}
            placeholder="ex. Monthly business report for my department"
          />
        </div>
        <div className="flex flex-col justify-between">
          <label className="text-sm font-semibold py-2 text-dark-text dark:text-brev-light">
            What's the date range you're covering?
          </label>
          <DatePicker date={dateRange} setDate={handleDateChange} />
        </div>
        <div className="flex flex-col justify-between">
          <label className="text-sm font-semibold py-2 text-dark-text dark:text-brev-light">
            Who's it for?
          </label>
          <ToggleOptions
            values={audienceOptions.map((o) => o.value)}
            option={audience || ""}
            setOption={handleAudienceChange}
          />
        </div>
      </div>
      <div className="flex gap-2 absolute bottom-5 right-5 ">
        <Button
          variant="gradient"
          onClick={handleNextClick}
          disabled={
            isLoading ||
            !(audience && purpose && dateRange?.from && dateRange?.to)
          }
          isLoading={isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
