import { AuthContext } from "@/containers/Auth/AuthProvider";
import { IntegrationEntityModel, IntegrationEntityStatus } from "@/containers/Integrations/types";
import React, { createContext, useCallback, useContext, useEffect, useState } from "react";
import { ArtifactTemplateModel } from "../types";
import { ArtifactContext } from "../ArtifactProvider";

interface ArtifactSettingsContextProps {
  isContextBaseLoading: boolean;
  integrationEntities: IntegrationEntityModel[];
  setIntegrationEntities: React.Dispatch<React.SetStateAction<IntegrationEntityModel[]>>;
  artifactTemplates: ArtifactTemplateModel[];
  setArtifactTemplates: React.Dispatch<React.SetStateAction<ArtifactTemplateModel[]>>;
  isTemplatesLoading: boolean;
}

const ArtifactSettingsContext = createContext<ArtifactSettingsContextProps>({
  isContextBaseLoading: false,
  integrationEntities: [],
  setIntegrationEntities: () => {
    throw new Error('not implemented');
  },
  artifactTemplates: [],
  setArtifactTemplates: () => {
    throw new Error('not implemented');
  },
  isTemplatesLoading: false,
});

const ArtifactSettingsProvider = ({ artifactId, children }: { artifactId: string, children: React.ReactNode }) => {
  const { sendApiRequest } = useContext(AuthContext);
  const { integrationEntities, setIntegrationEntities } = useContext(ArtifactContext);
  const [isContextBaseLoading, setIsContextBaseLoading] = useState<boolean>(false);
  const [isTemplatesLoading, setIsTemplatesLoading] = useState<boolean>(false);
  
  const [artifactTemplates, setArtifactTemplates] = useState<
    ArtifactTemplateModel[]
  >([]);

  const fetchTemplates = useCallback(async () => {
    setIsTemplatesLoading(true);
    const response = (await sendApiRequest(
      `/artifact-templates?artifactId=${artifactId}`,
      "GET"
    )) as { data: { artifactTemplates: ArtifactTemplateModel[] } };
    const { artifactTemplates } = response.data;
    setIsTemplatesLoading(false);
    if (artifactTemplates.length > 0) {
      setArtifactTemplates([...(artifactTemplates as ArtifactTemplateModel[])]);
    }
  }, [sendApiRequest, artifactId]);

  useEffect(() => {
    setIsContextBaseLoading(true);
    const fetchIntegrationEntities = async () => {
      const response = await sendApiRequest(`/integration-entities?artifactId=${artifactId}`, 'GET') as { data: { integrationEntities: IntegrationEntityModel[] } };
      setIntegrationEntities(response.data.integrationEntities.map((entity: IntegrationEntityModel) => {
        return {
          ...entity,
          entityStatus: IntegrationEntityStatus.INACTIVE,
        };
      }));
      setIsContextBaseLoading(false);
    };
    fetchIntegrationEntities();
    fetchTemplates();
  }, [artifactId, fetchTemplates, sendApiRequest]);

  const contextValue = {
    integrationEntities,
    setIntegrationEntities,
    isContextBaseLoading,
    artifactTemplates,
    setArtifactTemplates,
    isTemplatesLoading,
  };
  
  return (
    <ArtifactSettingsContext.Provider value={contextValue}>
      {children}
    </ArtifactSettingsContext.Provider>
  );
}

export { ArtifactSettingsProvider, ArtifactSettingsContext };