import { Button } from "@/components/ui/button";
import { ImPageBreak } from "react-icons/im";
import { GridContext } from "./GridProvider";
import { useContext } from "react";
import Hint from "@/components/Tooltips/Hint";

export default function DivideButton() {
  const { isPresentation, setIsPresentation } = useContext(GridContext);

  const handlePresentViewChange = () => {
    if (isPresentation) {
      setIsPresentation(false);
    } else {
      setIsPresentation(true);
    }
  };

  return (
    <Button
      variant="outline"
      className={`h-[1.75rem] ${isPresentation ? "bg-dark-main dark:bg-light-main text-light-text dark:text-dark-text hover:bg-opacity-80 hover:bg-dark-main hover:dark:bg-light-main" : ""}`}
      onClick={handlePresentViewChange}
    >
      <Hint message="Show slide view" side="bottom" offset={20}>
        <ImPageBreak size={16} />
      </Hint>
    </Button>
  );
}
