import { useContext, useState } from "react";
import { ContextModel, ContextType } from "./types";
import Loading from "@/components/ui/loading";
import ResourceIndicator from "../ReviewBuilder/ResourceIndicator";
import TableWidget from "../ReviewBuilder/DataVisualWidgets/TableWidget";
import { TabularData } from "../ReviewBuilder/review-types";
import { DialogConnectionSteps } from "../Integrations/ConnectionSteps";
import { ArtifactSettingsContext } from "../ArtifactBuilder/ArtifactSettings/ArtifactSettingsProvider";
import Markdown from "react-markdown";
import EmptySectionAccordionSvg from "../../components/Graphics/EmptySectionAccordionSvg";
import { IntegrationConnectionsProvider } from "../Integrations/IntegrationConnectionsProvider";
import IntegrationEntityListItem from "./IntegrationEntityListItem";

export default function ContextBaseContainer() {
  const { isContextBaseLoading, integrationEntities } = useContext(
    ArtifactSettingsContext
  );
  const [selectedContext, _setSelectedContext] = useState<ContextModel | null>(
    null
  );
  return (
    <div className="h-full flex flex-col gap-2 w-full p-4">
      <div className="h-full flex gap-2 w-full">
        {!selectedContext && (
          <div className="flex flex-col w-full gap-3">
            <div className="relative flex justify-between items-center">
              <div className="text-lg font-bold text-dark-text dark:text-brev-light py-2">
                Connect your relevant data
              </div>
              <span className="text-[0.75rem] mr-[7rem] mt-2 dark:text-brev-light">
                Available data for your artifact
              </span>
            </div>
            <div className="flex gap-3">
              <IntegrationConnectionsProvider>
                <DialogConnectionSteps className="flex-1" />
              </IntegrationConnectionsProvider>
              <div className="h-[24rem] min-w-[300px] w-[300px] bg-light-alt dark:bg-dark-main border border-brev-border dark:border-brev rounded-[8px] p-3 space-y-2 transition-colors duration-500 ease-in-out overflow-y-scroll no-scrollbar">
                {isContextBaseLoading && (
                  <Loading className="w-full h-full flex justify-center items-center" />
                )}
                {integrationEntities.length > 0 ? (
                  integrationEntities.map((integrationEntity) => (
                    <IntegrationEntityListItem
                      key={`${integrationEntity.id}-${integrationEntity.isConnected}`}
                      integrationEntity={integrationEntity}
                    />
                  ))
                ) : (
                  <div className="flex flex-col flex-grow h-full items-center justify-center p-10">
                    <span className="text-[0.875rem] font-semibold text-brev/25 dark:text-brev-light/25">
                      First, connect your data
                    </span>
                    <EmptySectionAccordionSvg />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {selectedContext && (
          <div className="flex flex-col gap-2 py-2 w-[370px] h-[400px]">
            {selectedContext.resourceModel && (
              <div className="w-full flex justify-end items-center text-slate-500">
                <div className="text-nowrap text-[0.75rem]">
                  {selectedContext.resourceModel.resourceName}
                </div>
                <ResourceIndicator
                  resources={[selectedContext.resourceModel]}
                />
              </div>
            )}
            <div
              className="w-full text-[1.2rem] border-b-2 py-2 mb-2 text-nowrap truncate"
              title={selectedContext.contextName}
            >
              {selectedContext.contextName}
            </div>
            {selectedContext?.contextType === ContextType.DATASET && (
              <div className="overflow-y-auto overflow-x-hidden scrollbar-webkit-standard">
                <TableWidget
                  content={
                    JSON.parse(selectedContext?.contextContent) as TabularData
                  }
                />
              </div>
            )}
            {selectedContext?.contextType !== ContextType.DATASET && (
              <div className="w-full h-full overflow-y-auto overflow-x-hidden scrollbar-webkit-standard text-balance text-[0.75rem] my-3 p-3 rounded-[8px] bg-slate-100 font-light">
                <Markdown>
                  {JSON.parse(selectedContext?.contextContent)}
                </Markdown>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
