import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import {
  LuText,
  LuBarChart4,
  LuTarget,
  LuLayoutGrid,
  LuHeading,
  LuCode2,
  LuPlus
} from "react-icons/lu";
import { TileType } from "@/containers/ReviewBuilder/review-types";
import { useContext, useState } from "react";
import { GridContext } from "./GridProvider";
import ArtifactSettingsDialog from "../ArtifactSettings/ArtifactSettingsDialog";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from "@/components/ui/tooltip";

export default function DrawerToolbar() {
  const { onDragStart } = useContext(GridContext);
  const [isOpen, setIsOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openTab, _setOpenTab] = useState(0);

  const tileOptions = [
    {
      type: TileType.KPI,
      label: "KPI",
      icon: <LuLayoutGrid size={16} />,
      tooltip: "KPI"
    },
    {
      type: TileType.CHART,
      label: "Chart",
      icon: <LuBarChart4 size={16} />,
      tooltip: "Chart"
    },
    {
      type: TileType.TEXT,
      label: "Text",
      icon: <LuText size={16} />,
      tooltip: "Text"
    },
    {
      type: TileType.GOAL,
      label: "Goal",
      icon: <LuTarget size={16} />,
      tooltip: "Goal"
    },
    {
      type: TileType.HEADING,
      label: "Heading",
      icon: <LuHeading size={16} />,
      tooltip: "Heading"
    },
    {
      type: TileType.EMBED,
      label: "URL",
      icon: <LuCode2 size={16} />,
      tooltip: "Embed URL"
    }
  ];

  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
  };

  const handleDragStart = (e: React.DragEvent, type: TileType) => {
    e.dataTransfer.setData("text/plain", type);
    onDragStart(type);
    // Close the dropdown when starting to drag
    setIsOpen(false);
  };

  return (
    <div className="flex flex-col gap-0.5 p-1 rounded-lg bg-light-main dark:bg-dark-main border-brev-border dark:border-dark-border shadow transition-colors duration-500 ease-in-out">
      <DropdownMenu open={isOpen} onOpenChange={handleOpenChange}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger asChild>
                <Button
                  size="icon"
                  variant="ghost"
                  className="w-8 h-8 rounded-full transition-transform duration-300"
                >
                  <motion.div
                    className="text-dark-text text-2xl font-bold"
                    animate={{ rotate: isOpen ? 45 : 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    <LuPlus
                      size={24}
                      className="text-dark-text dark:text-light-text"
                    />
                  </motion.div>
                </Button>
              </DropdownMenuTrigger>
            </TooltipTrigger>
            <TooltipContent side="left" sideOffset={10}>
              <span>Add new tile</span>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <DropdownMenuContent
          align="end"
          side="top"
          sideOffset={10}
          className="w-48 p-2 bg-light-alt/95 dark:bg-dark-alt/95 backdrop-blur-sm"
          style={{ pointerEvents: "auto" }}
        >
          {tileOptions.map((option) => (
            <DropdownMenuItem
              key={option.type}
              draggable
              onDragStart={(e) => handleDragStart(e, option.type)}
              className="flex items-center gap-2 px-4 py-2 cursor-grab active:cursor-grabbing hover:bg-light-main dark:hover:bg-dark-main"
            >
              {option.icon}
              <span className="text-sm">{option.label}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <ArtifactSettingsDialog
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        openTab={openTab}
      />
    </div>
  );
}

// const ToolbarTooltip = ({
//   isVisible = true,
//   message = "Testing message"
// }: {
//   isVisible?: boolean;
//   message?: string;
// }) => {
//   return (
//     <div
//       className={`${isVisible ? "opacity-100" : "opacity-[0%]"} absolute top-full w-24 left-1/2 transform -translate-x-1/2 mt-2 px-3 py-1 text-[12px] shadow bg-dark-alt dark:bg-light-alt text-light-text dark:text-dark-text rounded-lg text-center transition-opacity duration-300 ease-in-out`}
//     >
//       <div className="absolute w-0 h-0 left-1/2 bottom-full -translate-x-1/2 border-8 border-transparent border-b-dark-alt dark:border-b-light-alt"></div>
//       {message}
//     </div>
//   );
// };
