import { cn } from "@/lib/utils";

type PageContainerProps = {
  title?: string | React.ReactNode;
  subtitle?: string;
  className?: string;
  children?: React.ReactNode;
  containerRef?: React.RefObject<HTMLDivElement>;
};

const PageContainer = ({
  // title,
  // subtitle,
  className,
  children
}: PageContainerProps) => {
  return (
    <div className={cn("flex flex-col", className)}>
      {/* <div className="sticky top-0 z-50 p-2 bg-white backdrop-blur-[0.5rem]">
        <div className="font-bold text-lg">{title}</div>
        {subtitle && <div className="text-sm mt-2">{subtitle}</div>}
      </div> */}
      <div className="pt-3">{children}</div>
    </div>
  );
};

export default PageContainer;
