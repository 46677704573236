import { useState, useEffect, useRef } from "react";
import { cn } from "@/lib/utils";

type EditableTextProps = {
  value: string;
  onSave: (value: string) => void;
  className?: string;
  placeholder?: string;
  isTextarea?: boolean;
  rows?: number;
  editable?: boolean;
  scaleFontSize?: boolean;
  maxLength?: number;
  minLength?: number;
  minFontSize?: number;
  maxFontSize?: number;
  onSubmit?: boolean;
};

const adjustTextSize = (
  text: string,
  maxLength: number,
  minLength: number,
  minFontSize: number,
  maxFontSize: number
): string => {
  if (text.length <= minLength) return `${maxFontSize}px`;

  if (text.length >= maxLength) return `${minFontSize}px`;

  const range = maxLength - minLength;
  const excessLength = text.length - minLength;
  const scale = 1 - excessLength / range;
  const fontSize = minFontSize + (maxFontSize - minFontSize) * scale;

  return `${Math.round(fontSize)}px`;
};

export default function EditableText({
  value,
  onSave,
  className,
  placeholder,
  isTextarea = false,
  rows = 3,
  editable = true,
  scaleFontSize,
  maxLength = 100,
  minLength = 5,
  minFontSize = 12,
  maxFontSize = 36,
  onSubmit = true
}: EditableTextProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleIsEditingChange = () => {
    if (editable) {
      setIsEditing(true);
      setEditedValue(value);
    }
  };

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter" && !isTextarea) {
      onSave(editedValue);
      setIsEditing(false);
    } else if (e.key === "Escape") {
      setEditedValue(value);
      setIsEditing(false);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    console.log("handleChange (artifact title)", e.target.value);
    setEditedValue(e.target.value);
    if (!onSubmit) {
      onSave(e.target.value);
    }
  };

  const handleBlur = () => {
    onSave(editedValue);
    setIsEditing(false);
  };

  const fontSize = scaleFontSize
    ? adjustTextSize(value, maxLength, minLength, minFontSize, maxFontSize)
    : undefined;

  if (isEditing) {
    const commonProps = {
      value: editedValue,
      onKeyDown: handleKeyDown,
      onBlur: handleBlur,
      className: cn(
        "bg-transparent outline-none border-b border-dark-border",
        className
      ),
      placeholder: placeholder || "",
      style: { fontSize }
    };

    return isTextarea ? (
      <textarea
        {...commonProps}
        ref={textareaRef}
        onChange={(e) => handleChange(e)}
        rows={rows}
        autoFocus
      />
    ) : (
      <input
        type="text"
        ref={inputRef}
        onChange={(e) => handleChange(e)}
        {...commonProps}
      />
    );
  }

  return (
    <span
      onClick={handleIsEditingChange}
      className={`cursor-text ${className}`}
      style={{ fontSize }}
    >
      {value || placeholder}
    </span>
  );
}
