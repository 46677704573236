import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  SelectIcon
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { AccessLevel, UserSettings } from "./table-columns";

interface UserEditDialogProps {
  user: BrevUser | null;
  isOpen: boolean;
  onClose: () => void;
  onSave: (updatedUser: BrevUser) => void;
}

export function UserEditDialog({
  user,
  isOpen,
  onClose,
  onSave
}: UserEditDialogProps) {
  const [editedUser, setEditedUser] = React.useState<BrevUser | null>(null);

  React.useEffect(() => {
    setEditedUser(user);
  }, [user]);

  if (!editedUser) return null;

  const handleInputChange = (
    field: keyof BrevUser | keyof UserSettings,
    value: unknown
  ) => {
    if (field in (editedUser!.userSettings || {})) {
      setEditedUser({
        ...editedUser!,
        userSettings: {
          ...editedUser!.userSettings,
          [field]: value
        }
      });
    } else {
      setEditedUser({
        ...editedUser!,
        userSettings: editedUser!.userSettings || {},
        [field]: value
      });
    }
  };

  const handleSave = () => {
    onSave(editedUser);
    onClose();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[625px] h-[600px]">
        <DialogHeader>
          <DialogTitle>Edit User</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4 overflow-y-scroll overflow-x-hidden scrollbar-webkit-standard">
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="firstName" className="text-right">
              First Name
            </Label>
            <Input
              id="firstName"
              value={editedUser.firstName}
              onChange={(e) => handleInputChange("firstName", e.target.value)}
              className="bg-brev/10"
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="lastName" className="text-right">
              Last Name
            </Label>
            <Input
              id="lastName"
              value={editedUser.lastName}
              onChange={(e) => handleInputChange("lastName", e.target.value)}
              className="bg-brev/10"
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="email" className="text-right">
              Email
            </Label>
            <span>{editedUser.email}</span>
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="accessLevel" className="text-right">
              Access Level
            </Label>
            <Select
              value={editedUser.accessLevel}
              onValueChange={(value) => handleInputChange("accessLevel", value)}
            >
              <SelectTrigger className="w-[180px] bg-brev text-brev-light">
                <SelectValue placeholder="Select Access Level" />
                <SelectIcon />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={AccessLevel.BASIC}>Basic</SelectItem>
                <SelectItem value={AccessLevel.PRO}>Pro</SelectItem>
                <SelectItem value={AccessLevel.ADMIN}>Admin</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="aiService" className="text-right">
              AI Service
            </Label>
            <Select
              value={editedUser.aiService}
              onValueChange={(value) => handleInputChange("aiService", value)}
            >
              <SelectTrigger className="w-[180px] bg-brev text-brev-light">
                <SelectValue placeholder="Select AI Service" />
                <SelectIcon />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={"ChatGPT"}>ChatGPT</SelectItem>
                <SelectItem value={"Claude"}>Claude</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="nextBestActionsEnabled" className="text-right">
              Next Best Actions
            </Label>
            <Switch
              checked={editedUser.userSettings?.nextBestActionsEnabled}
              onCheckedChange={(checked) =>
                handleInputChange("nextBestActionsEnabled", checked)
              }
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="useCompanyMemory" className="text-right">
              Use Company Memory
            </Label>
            <Switch
              checked={editedUser.userSettings?.useCompanyMemory}
              onCheckedChange={(checked) =>
                handleInputChange("useCompanyMemory", checked)
              }
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="useV3" className="text-right">
              Attribute #1
            </Label>
            <Switch
              checked={editedUser.userSettings?.useV3}
              onCheckedChange={(checked) => handleInputChange("useV3", checked)}
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="attribute2" className="text-right">
              Attribute #2
            </Label>
            <Switch
              checked={editedUser.userSettings?.attribute2}
              onCheckedChange={(checked) =>
                handleInputChange("attribute2", checked)
              }
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="attribute3" className="text-right">
              Attribute #3
            </Label>
            <Switch
              checked={editedUser.userSettings?.attribute3}
              onCheckedChange={(checked) =>
                handleInputChange("attribute3", checked)
              }
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="attribute4" className="text-right">
              Attribute #4
            </Label>
            <Switch
              checked={editedUser.userSettings?.attribute4}
              onCheckedChange={(checked) =>
                handleInputChange("attribute4", checked)
              }
            />
          </div>
          <div className="flex justify-between items-center mx-4">
            <Label htmlFor="attribute5" className="text-right">
              Attribute #5
            </Label>
            <Switch
              checked={editedUser.userSettings?.attribute5}
              onCheckedChange={(checked) =>
                handleInputChange("attribute5", checked)
              }
            />
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSave}>Save changes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
