import { Button } from "@/components/ui/button";
import { LuPanelLeftClose } from "react-icons/lu";

type PaneOpenCloseButtonProps = {
  onClick: () => void;
  open: boolean;
  reversed?: boolean;
};

export default function PaneOpenCloseButton({
  onClick,
  open = false,
  reversed = false
}: PaneOpenCloseButtonProps) {
  const isOpen = reversed ? !open : open;
  return (
    <Button
      variant="ghost"
      onClick={onClick}
      className="p-2 hover:bg-light-main dark:hover:bg-brev transition-colors duration-500"
      title={
        reversed
          ? open
            ? "close quick view"
            : "open quick view"
          : open
            ? "close sub menu"
            : "open sub menu"
      }
      data-reversed={reversed}
    >
      <LuPanelLeftClose
        size={24}
        className={`text-dark-text dark:text-[#E0E0E0] transition-all duration-500 ease-in-out ${isOpen ? "" : "-rotate-180"}`}
      />
    </Button>
  );
}
