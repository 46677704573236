import type { NavSubMenuContent } from "./types";
import PaneOpenCloseButton from "../Buttons/PaneOpenCloseButton";
import { AnimatePresence, motion } from "framer-motion";

export default function NavSubMenu({
  sub,
  isOpen,
  setIsOpen
}: {
  sub: NavSubMenuContent;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div
      className={`${isOpen ? "w-[12.75rem] xl:w-[16.75rem] 2xl:w-[16.75rem] 3xl:w-[18.75rem]" : "w-[2.5rem]"} relative flex flex-col h-full transition-all duration-500 flex-shrink-0 overflow-x-hidden bg-light-alt dark:bg-dark-alt font-[Poppins]`}
    >
      <div
        className={`min-h-20 border border-transparent border-b-brev-border dark:border-b-brev flex items-center justify-between ${isOpen ? "pl-4 pr-2" : "px-0"} pr-2 capitalize text-dark-text dark:text-brev-light transition-all duration-500 ease-in-out overflow-hidden`}
      >
        <AnimatePresence>
          {isOpen && (
            <motion.span
              initial={{ x: -100, opacity: 0, width: 0 }}
              animate={{ x: 0, opacity: 1, width: 1 }}
              transition={{ duration: 0.5 }}
              exit={{ x: -100, opacity: 0, width: 0 }}
              className="text-[1rem] font-semibold"
            >
              {sub.name}
            </motion.span>
          )}
        </AnimatePresence>
        <PaneOpenCloseButton onClick={() => setIsOpen(!isOpen)} open={isOpen} />
      </div>
      {/* <AnimatePresence>
        {sub.open && ( */}
      <motion.div
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // transition={{ duration: 0.3 }}
        className={`${isOpen ? "opacity-[100%]" : "opacity-[0%]"} flex flex-col flex-grow overflow-x-hidden overflow-y-scroll no-scrollbar rounded-[8px] transition-opacity duration-300 ease-in-out`}
      >
        {sub.component}
      </motion.div>
      {/* )}
      </AnimatePresence> */}
    </div>
  );
}
