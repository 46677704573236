import Tile from "../Tile/Tile";
import Asana from "@/images/asana.svg";

export default function AsanaConnector() {
  // const { setIsRedirected } = useOAuthClient({
  //   server: "https://app.asana.com/",
  //   clientId: import.meta.env.VITE_ASANA_CLIENT_ID,
  //   clientSecret: import.meta.env.VITE_ASANA_SECRET_ID,
  //   tokenEndpoint: "-/oauth_token",
  //   authorizationEndpoint: "-/oauth_authorize",
  //   discoveryEndpoint: "/.well-known/oauth2-authorization-server",
  //   authenticationMethod: "client_secret_post",
  //   redirectUri: "https://localhost:5173/reviews"
  // });

  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "ASANA"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  // const authenticateClient = () => {};

  return (
    <Tile disabled={true} onPress={() => null} label="Asana" src={Asana} />
  );
}
