import { cn } from "@/lib/utils";

type PressableProps = {
  children: React.ReactNode;
  onPress?(event?: React.MouseEvent<HTMLButtonElement>): void;
  className?: string;
  disabled?: boolean;
  type?: "button" | "submit" | "reset";
};

export default function Pressable({
  children,
  onPress,
  className,
  type,
  disabled
}: PressableProps) {
  return (
    <button
      disabled={disabled}
      onClick={onPress}
      type={type}
      className={cn(
        disabled ? "cursor-not-allowed" : "cursor-pointer",
        className
      )}
    >
      {children}
    </button>
  );
}
