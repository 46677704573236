import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogTitle
} from "@/components/ui/dialog";
import { motion } from "framer-motion";
import ContextSvg from "../../../components/Icons/ContextSvg";
import ContextTab from "./ContextTab";
import ConnectTab from "./ConnectTab";
import TemplateTab from "./TemplateTab";
// import BadgeButton from "@/components/Buttons/BadgeButton";
import { Button } from "@/components/ui/button";
import { ArtifactContext } from "../ArtifactProvider";
import { ArtifactSettingsContext } from "../ArtifactSettings/ArtifactSettingsProvider";
import { LuLayoutList, LuUploadCloud } from "react-icons/lu";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent
} from "@/components/ui/tooltip";
// import LoadingMessage from "../ArtifactCanvas/LoadingMessage";
import { HiSparkles } from "react-icons/hi2";

export type SettingsTabProps = {
  name: string;
  component: React.ReactElement;
  icon?: React.ReactElement;
};

export default function ArtifactSettingsDialog({
  dialogOpen,
  setDialogOpen,
  openTab
}: {
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  openTab?: number;
}) {
  const [selectedTab, setSelectedTab] = useState(openTab || 0);
  const [openTabs, setOpenTabs] = useState<number[]>([0]);
  const { integrationEntities } = useContext(ArtifactSettingsContext);
  const { artifactMetadata, isArtifactGenerating } =
    useContext(ArtifactContext);
  const { format, audience, endAt, startAt } = artifactMetadata;

  const connectedEntitiesCount = useMemo(() => {
    return integrationEntities.filter((entity) => entity.isConnected).length;
  }, [integrationEntities]);

  const metaDataComplete = useMemo(() => {
    return !!format && !!audience && !!endAt && !!startAt;
  }, [format, audience, endAt, startAt]);

  useEffect(() => {
    setOpenTabs([0]);
    if (metaDataComplete) {
      setOpenTabs([0, 1]);
    }
    if (connectedEntitiesCount > 0 && metaDataComplete) {
      setOpenTabs([0, 1, 2]);
    }
  }, [connectedEntitiesCount, metaDataComplete]);

  useEffect(() => {
    setSelectedTab(openTab || 0);
  }, [openTab]);

  const tabs: SettingsTabProps[] = [
    {
      name: "Context",
      component: <ContextTab handleTabSelection={setSelectedTab} />,
      icon: <ContextSvg size={18} />
    },
    {
      name: "Integrate",
      component: <ConnectTab handleTabSelection={setSelectedTab} />,
      icon: <LuUploadCloud size={18} strokeWidth={3} />
    },
    {
      name: "Template",
      component: (
        <TemplateTab
          handleTabSelection={setSelectedTab}
          setDialogOpen={setDialogOpen}
        />
      ),
      icon: <LuLayoutList size={18} strokeWidth={2.4} />
    }
  ];

  return (
    <>
      <div className="w-3/4 mx-auto bg-dark-alt/50 dark:bg-light-alt/50 rounded-lg min-h-[1px]" />
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DialogTrigger asChild>
                <Button
                  // title="Artifact settings"
                  variant="ghost"
                  className="w-8 h-8 rounded-lg"
                >
                  {isArtifactGenerating ? (
                    <HiSparkles size={24} color="#3ED8E2 animate-pulse" />
                  ) : (
                    <HiSparkles size={24} color="#3ED8E2" />
                  )}
                </Button>
              </DialogTrigger>
            </TooltipTrigger>
            <TooltipContent side="left" sideOffset={10}>
              <span>Generate Artifact</span>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <DialogContent className="flex gap-0 min-w-[888px] min-h-[517px] p-0 rounded-2xl sm:rounded-3xl overflow-hidden border border-dark-alt focus:outline-black focus:border-dark-alt">
          <DialogTitle className="sr-only">Artifact Settings</DialogTitle>
          <div className="flex flex-col h-[517px] w-[169px] bg-brev items-center gap-4 relative">
            <div className="text-center text-white w-full py-[1.625rem] text-[1rem] font-semibold">
              Artifact Builder
            </div>
            <div className="absolute right-1 top-[92px] w-[2px] h-[140px]">
              <div className="absolute w-full h-full bg-dark-alt/20 rounded-full" />
              <div
                className="absolute w-full rounded-full bg-gradient-to-b from-[#3FCFF1] from-50% to-[#00FFB2] transition-all duration-300 overflow-hidden"
                style={{
                  clipPath: `inset(0 0 ${
                    openTabs.length === 1
                      ? "74%"
                      : openTabs.length === 2
                        ? "37%"
                        : openTabs.length === 3
                          ? "0%"
                          : "100%"
                  } 0)`
                }}
              >
                <div className="w-full h-[140px]"></div>
              </div>
            </div>
            {tabs.map((tab, index) => (
              <div
                key={index}
                onClick={() =>
                  openTabs.includes(index) && setSelectedTab(index)
                }
                className={`${openTabs.includes(index) ? "cursor-pointer" : "opacity-30"} text-light-main w-[141px] text-sm font-semibold relative flex items-center justify-start px-3 py-2 rounded-[8px] select-none transition-all duration-300 hover:bg-dark`}
              >
                {tab.name === tabs[selectedTab].name && (
                  <motion.span
                    layoutId="connector-tab"
                    className="absolute inset-0 rounded-[8px] bg-dark-alt"
                  ></motion.span>
                )}
                <span className="flex items-center gap-3 z-10">
                  {tab.icon &&
                    React.cloneElement(tab.icon, {
                      color:
                        tab.name === tabs[selectedTab].name
                          ? "#3FCFF1"
                          : "#5D5C65"
                    })}
                  {tab.name}
                </span>
              </div>
            ))}
          </div>
          <div className="flex flex-col flex-grow-1 h-[517px] bg-white w-[750px]">
            {tabs[selectedTab].component}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
