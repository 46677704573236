import { useState, useEffect, useCallback, useLayoutEffect } from "react";
import {
  googleLogout,
  useGoogleLogin,
  TokenResponse
} from "@react-oauth/google";
import axios from "axios";
import { gapi } from "gapi-script";
import rhythmgraphic from "../../images/rhythmgraphic.svg";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import type { CalendarEvent } from "./calendar-types";
import RhythmViews from "./RhythmViews";
import CreateQuickView from "./CreateQuickView";
import EventQuickView from "./EventQuickView";
import GCalSvg from "@/components/Icons/GCalSvg";
import LayoutContainer from "../Layout/LayoutContainer";
import { QuickView } from "../Layout/QuickView";
import RhythmDialog from "./RhythmDialog";
import useRhythmContext from "./useRhythmContext";
import { IoSparklesSharp } from "react-icons/io5";

const rhythmColours = [
  "#427AA2",
  "#A6D39F",
  "#979572",
  "#4673D9",
  "#EBE2B8",
  "#B5EF89",
  "#643E62",
  "#F78764",
  "#BDAE9C",
  "#0EBABB",
  "#FAD4C0"
];

type Profile = {
  name?: string;
  email?: string;
  picture?: string;
};

export default function Rhythms() {
  const { eventArray, setEventArray, setSelectedEvents, setCurrentEventId } =
    useRhythmContext();
  const [user, setUser] = useState<TokenResponse | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [currentTab, setCurrentTab] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeRhythms, setActiveRhythms] = useState<string[]>([]);
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const [quickViewContent, setQuickViewContent] =
    useState<React.ReactNode>(null);

  const tabs: string[] = [
    "Week",
    "Month",
    "Quarter",
    "Bi-Annual",
    "Annual",
    "Custom"
  ];

  useLayoutEffect(() => {
    function start() {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "https://www.googleapis.com/auth/calendar"
      });
    }
    gapi.load("client:auth2", start);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem("googleAccessToken");
    if (storedToken) {
      // console.log("stored token", storedToken);
    }
  }, []);

  useEffect(() => {
    if (user) {
      listEvents();
    }
  }, [user]);

  const handleEventClick = useCallback((event: CalendarEvent) => {
    setCurrentEventId(event.id);
    openQuickView(<EventQuickView event={event} />);
  }, []);

  const openQuickView = useCallback((content: React.ReactNode) => {
    setQuickViewContent(content);
    setCreateOpen(true);
  }, []);

  const closeQuickView = useCallback(() => {
    setCreateOpen(false);
  }, []);

  const RhythmHeader = () => {
    return (
      <div
        className={`${user ? "h-[88px] max-w-[1078px]" : "h-[740px] max-w-[1030px] flex-col items-center justify-center mt-10"} mb-4 flex gap-4 rounded-[12px] bg-brev-light`}
      >
        <img src={rhythmgraphic} alt="rhythm graphic" width="420px" />
        <div
          className={`${user ? "max-w-[700px]" : "w-full"} flex flex-col items-center justify-center ml-[20px] my-auto`}
        >
          <span className={`${user ? "text-lg" : "text-[2rem]"} font-bold`}>
            {!user ? "Connect to import your Rhythms" : "Rhythms"}
          </span>
          <span className="text-xs font-medium text-[#00000052]">
            {!user &&
              "Connect your account and we can build your Rhythms for you."}
          </span>
        </div>
        <div className="flex items-center w-full justify-evenly">
          {!profile ? (
            <>
              <Button
                variant="ghost"
                className="rounded-[8px] shadow-[0_0_15.5px_0_rgba(54,71,114,0.05)] w-[220px] h-[46px]"
                onClick={handleLogin}
              >
                <GCalSvg />
                <span className="pl-2">Calendar</span>
              </Button>
            </>
          ) : (
            <Button
              onClick={logOut}
              variant="negative"
              className="max-w-[200px] rounded-[8px]"
            >
              <GCalSvg />
              <span className="pl-2">Log out</span>
            </Button>
          )}
        </div>
        {!user && (
          <div className="flex flex-col gap-2 bg-brev h-[193px] w-full rounded-b-2xl text-white items-center justify-center">
            <div className="text-lg font-semibold">
              Create a Rhythm from scratch?
            </div>
            <div className="text-xs font-medium mb-2">
              Try creating a Rhythm without your data
            </div>
            <Button
              variant="gradient"
              className="rounded-[8px] shadow-xl w-[272px] h-[42px] text-xs font-medium"
              onClick={() => openQuickView(<CreateQuickView />)}
            >
              Launch my first Rhythm
            </Button>
          </div>
        )}
      </div>
    );
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setUser(codeResponse);
      localStorage.setItem("googleAccessToken", codeResponse.access_token);
    },
    onError: (error) => console.log("Login Failed:", error),
    scope: "https://www.googleapis.com/auth/calendar"
  });

  const handleLogin = useCallback(() => {
    login();
  }, [login]);

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`
        )
        .then((response) => setProfile(response.data))
        .catch((error) => console.log("Error fetching user profile:", error));
    }
  }, [user]);

  const listEvents = () => {
    gapi.client.load("calendar", "v3", () => {
      gapi.client.calendar.events
        .list({
          timeMin: "2024-04-24T12:00:00+02:00",
          calendarId: "primary",
          showDeleted: false,
          singleEvents: false,
          maxResults: 1000,
          orderBy: "updated"
        })
        .then((response: { result: { items: CalendarEvent[] } }) => {
          const events = response.result.items;
          setEventArray(events);
          setIsLoading(false);
        })
        .catch((error: Error) => {
          console.log("Error fetching events:", error);
          setIsLoading(false);
        });
    });
  };

  const logOut = () => {
    googleLogout();
    setProfile(null);
    setEventArray;
    setCreateOpen(false);
    localStorage.removeItem("googleAccessToken");
    setUser(null);
  };

  const handleTabSelection = useCallback(
    (index: number) => {
      if (index !== currentTab) setCurrentTab(index);
    },
    [currentTab, setCurrentTab]
  );

  const handleRhythmSelect = useCallback(
    (event: CalendarEvent) => {
      const eventId = event.id;
      setActiveRhythms((prevActiveRhythms) => {
        if (prevActiveRhythms.includes(eventId)) {
          return prevActiveRhythms.filter((rhythm) => rhythm !== eventId);
        }
        return [...prevActiveRhythms, eventId];
      });
    },
    [setActiveRhythms]
  );

  useEffect(() => {
    const newSelectedEvents = eventArray.filter((event) => {
      return activeRhythms.includes(event.id);
    });
    const colouredEvents = newSelectedEvents.map((event, index) => ({
      ...event,
      colour: rhythmColours[index % rhythmColours.length]
    }));

    setSelectedEvents(colouredEvents);
  }, [activeRhythms, setSelectedEvents, eventArray]);

  return (
    <div className="flex justify-end overflow-hidden max-h-screen">
      <LayoutContainer>
        {!user && <RhythmHeader />}
        {!!profile && (
          <>
            <div className="rounded-[12px] flex flex-col justify-center max-w-[1078px] ">
              <div className="flex w-full bg-white/20 justify-end">
                <Button
                  variant="gradient"
                  className="flex rounded-[8px] w-[144px] h-[28px] text-xs font-medium px-0"
                  onClick={() => openQuickView(<CreateQuickView />)}
                >
                  <IoSparklesSharp size={14} />
                  <span className="pl-2 pt-0.5">Build a Rhythm</span>
                </Button>
              </div>
              <h4 className="text-sm font-semibold pb-2">Rhythms Dashboard</h4>
              <div className="flex pb-4 gap-3 text-xs font-normal">
                {tabs.map((tab, index) => {
                  const enabled = tab === "Month" || tab === "Quarter";
                  return (
                    <div
                      key={index}
                      onClick={() => enabled && handleTabSelection(index)}
                      className={`${tab === tabs[currentTab] ? "text-white" : "text-black"} font-semibold relative flex items-center justify-center px-3 py-2 rounded-[8px] cursor-pointer select-none transition-all duration-300 ${!enabled ? "pointer-events-none opacity-50" : ""}`}
                    >
                      <span className="z-1">{tab}</span>
                      {tab === tabs[currentTab] && (
                        <motion.span
                          layoutId="rhythmsTabIndicator"
                          className="absolute inset-0 rounded-[8px] bg-brev dark:bg-gray-600/70"
                        ></motion.span>
                      )}
                    </div>
                  );
                })}
              </div>

              {activeRhythms.length > 0 && (
                <RhythmViews
                  isLoading={isLoading}
                  range={currentTab}
                  setCurrentEvent={handleEventClick}
                />
              )}

              <RhythmDialog
                activeRhythms={activeRhythms}
                handleRhythmSelect={handleRhythmSelect}
              />
            </div>
          </>
        )}
      </LayoutContainer>
      <QuickView collapsed={!createOpen} collapse={closeQuickView}>
        {quickViewContent}
      </QuickView>
    </div>
  );
}
