import { useContext } from "react";
import { UserSettingsContext } from "./UserSettingsProvider";
import { Input } from "@/components/ui/input";
import { useState, useEffect } from "react";

export default function UserSettingsInput() {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    jobTitle,
    setJobTitle,
    companyName,
    setCompanyName,
    saveUserSettings
  } = useContext(UserSettingsContext);

  const [displayFirstName, setDisplayFirstName] = useState(firstName);

  useEffect(() => {
    setDisplayFirstName(firstName);
  }, [firstName]);

    const handleSave = async () => {
    if (displayFirstName) setFirstName(displayFirstName);
    await saveUserSettings();
  };

  return (
    <div className="flex flex-col gap-4 px-10">
      <div className="flex w-full justify-between items-center">
        <span className="text-dark-text dark:text-brev-light transition-colors duration-500 ease-in-out">
          First name
        </span>
        <Input
          className="w-[14rem]"
          variant="profile"
          fontSize="xs"
          value={displayFirstName}
          onChangeText={setDisplayFirstName}
          onSave={handleSave}
          autoFocus={true}
          type="first-name"
        />
      </div>

      <div className="flex w-full justify-between items-center">
        <span className="text-dark-text dark:text-brev-light transition-colors duration-500 ease-in-out">
          Last name
        </span>
        <Input
          className="w-[14rem]"
          variant="profile"
          fontSize="xs"
          value={lastName}
          onChangeText={(value) => {
            setLastName(value);
          }}
          onSave={saveUserSettings}
          autoFocus={false}
          type="last-name"
        />
      </div>
      <div className="flex w-full justify-between items-center">
        <span className="text-dark-text dark:text-brev-light transition-colors duration-500 ease-in-out">
          Job title
        </span>
        <Input
          className="w-[14rem]"
          variant="profile"
          fontSize="xs"
          value={jobTitle}
          onChangeText={(value) => {
            setJobTitle(value);
          }}
          onSave={saveUserSettings}
          autoFocus={false}
          type="job-title"
        />
      </div>
      <div className="flex w-full justify-between items-center">
        <span className="text-dark-text dark:text-brev-light transition-colors duration-500 ease-in-out">
          Company
        </span>
        <Input
          className="w-[14rem]"
          variant="profile"
          fontSize="xs"
          value={companyName}
          onChangeText={(value) => {
              setCompanyName(value);
          }}
          onSave={saveUserSettings}
          autoFocus={false}
          type="company-name"
        />
      </div>
    </div>
  );
}
