import { cn } from "@/lib/utils";

export default function CheckMarkSvg({
  size,
  className
}: {
  size?: number | string;
  className?: string;
}) {
  return (
    <svg
      width={size ?? 12}
      height={size ?? 12}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("", className)}
    >
      <path
        d="M10.86 21C16.3202 21 20.72 16.508 20.72 11C20.72 5.49199 16.3202 1 10.86 1C5.39975 1 1 5.49199 1 11C1 16.508 5.39975 21 10.86 21Z"
        fill="url(#paint0_linear_9122_368)"
        stroke="#14333E"
        strokeWidth="2"
      />
      <path
        d="M7.78418 10.9453L9.99918 13.0703L13.937 7.32031"
        stroke="#14333E"
        strokeWidth="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9122_368"
          x1="2"
          y1="11"
          x2="25.5765"
          y2="13.0475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#41C7FF" />
          <stop offset="1" stopColor="#38F4B3" />
        </linearGradient>
      </defs>
    </svg>
  );
}
