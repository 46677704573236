import { SourceType } from "../types";
import { FaRegFileLines } from "react-icons/fa6";
import GDocsSvg from "@/components/Icons/GDocsSvg";
import GSheetsSvg from "@/components/Icons/GSheetsSvg";
import NotionSvg from "@/components/Icons/NotionSvg";
import SlackSvg from "@/components/Icons/SlackSvg";
import GithubSvg from "@/components/Icons/GithubSvg";
import SalesforceSvg from "@/components/Icons/SalesforceSvg";
import HubspotSvg from "@/components/Icons/HubspotSvg";
import ExcelSvg from "@/components/Icons/ExcelSvg";

type EntityIconProps = {
  sourceType: SourceType;
  size?: number;
};

const EntityIcon = ({ sourceType, size = 18 }: EntityIconProps) => {
  switch (sourceType) {
    case SourceType.CSV:
    case SourceType.TEXT:
      return <FaRegFileLines size={size} />;
    case SourceType.SLACK_CHANNEL:
      return <SlackSvg size={size} />;
    case SourceType.EXCEL:
      return <ExcelSvg size={size} />;
    case SourceType.SALESFORCE:
      return <SalesforceSvg size={size} />;
    case SourceType.GOOGLE_DOC:
      return <GDocsSvg size={size} />;
    case SourceType.GOOGLE_SHEET:
      return <GSheetsSvg size={size} />;
    case SourceType.NOTION_PAGE:
    case SourceType.NOTION_DB:
      return <NotionSvg size={size} />;
    case SourceType.GITHUB_REPO:
      return <GithubSvg size={size} />;
    default:
      if (sourceType?.startsWith("HUBSPOT_")) {
        return <HubspotSvg size={size} />;
      }
      return null;
  }
};

export default EntityIcon;