import config from "@/config";
import { useEffect } from "react";
import LoadingPage from "../Loading/LoadingPage";

export default function AuthCallback() {
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    console.log("Search params", searchParams);
    const authToken = searchParams.get("token");
    if (authToken) {
      console.log("Auth token found", authToken);
      localStorage.setItem("authToken", authToken);
      window.location.href = window.location.origin;
    } else {
      window.location.href = `${config.env.REDIRECT_URL_ROOT}/login?${searchParams.toString()}`;
    }
  }, []);
  return <LoadingPage />;
}
