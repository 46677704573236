import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          "flex rounded-[8px] bg-light-alt focus-within:bg-light-main dark:focus-within:bg-dark-alt dark:bg-dark-main border border-light-alt dark:border-dark-border py-2 text-sm ring-offset-background placeholder:text-brev-gray/80 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 font-[Poppins] resize-none text-dark-text dark:text-light-text no-scrollbar",
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
