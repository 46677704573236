// import useOAuthClient from "@/hooks/useOAuthClient";
import { useContext, useEffect } from "react";
import Tile from "../../Tile/Tile";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { useParams, useSearchParams } from "react-router-dom";
import { IntegrationApp } from "@/containers/Integrations/types";
import { authenticateSlack } from "@/lib/utils";
import SlackSvg from "@/components/Icons/SlackSvg";

type SlackConnectorProps = {
  onSuccess: (...arg: unknown[]) => void;
  activeIntegration?: string;
};

export default function SlackConnector({
  onSuccess,
  activeIntegration
}: SlackConnectorProps) {
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(IntegrationConnectionsContext);

  useEffect(() => {
    if (isAppActive(IntegrationApp.SLACK)) {
      onSuccess?.("slack-picker");
      setSearchParams({ });
    }
  }, [isAppActive, onSuccess]);

  const authenticateClient = async () => {
    if (isAppConnected(IntegrationApp.SLACK)) return onSuccess?.("slack-picker");
    authenticateSlack(artifactId);
  };

  return (
    <Tile
      onPress={authenticateClient}
      label="Slack"
      svg={<SlackSvg size={28} />}
      connected={isAppConnected(IntegrationApp.SLACK)}
      selected={activeIntegration === "SLACK"}
      isLoading={isLoading}
    />
  );
}
