import { useEffect, useState } from "react";
import type { CalendarEvent } from "./calendar-types";
import useRhythmContext from "./useRhythmContext";
import { Auth } from "aws-amplify";
import { mockData } from "./mock-data";

const useGenerateBrief = (event: CalendarEvent) => {
    const { openEventId } = useRhythmContext();
    const [tempBrief, setTempBrief] = useState("");

    useEffect(() => {
        const fetchSummary = async () => {
            if (openEventId === event.id) {
                try {
                    const userId = await Auth.currentUserInfo().then((u) => u.id);

                    // const generatedContent = await generateSummary(
                    // );

                    console.log("userid", userId);
                    console.log("eventTitle", event.summary);

                    // const contentReader = generatedContent?.body
                    // ?.pipeThrough(new TextDecoderStream())
                    // .getReader();
                    // let contentGenerationComplete = false;
                    // while (!contentGenerationComplete) {
                    // const { value, done } = await contentReader!.read();

                    // if (value) {
                    //     setTempContent((prevResponse) => (prevResponse += value));
                    // }
                    // contentGenerationComplete = done;
                    // }
                    setTempBrief(mockData["Monthly Sales Review"].description);
                } catch (e) {
                    console.error(e);
                }
            }
        }
        fetchSummary();
    }, [openEventId, event]);

    return tempBrief;
}

export default useGenerateBrief;