import { Layout } from "react-grid-layout";
import { IntegrationApp } from "@/containers/Integrations/types";
import { SourceType } from "@/components/Connector/types";

export enum BrevSectionType {
  Summary = "Summary",
  Highlights = "Highlights",
  Lowlights = "Lowlights",
  Wins = "Wins",
  Losses = "Losses",
  Risks = "Risks",
  Insights = "Insights",
  Learnings = "Learnings"
}

export type VisualWidget = {
  title?: string;
  visualType: VisualType;
  dataset?: TabularData;
  embeddedScript?: string;
};

export type DataSet = {
  title: string;
  description?: string;
  xLabel: string;
  yLabel: string;
  yLabel2?: string;
  data: DataPointXY[];
  visualType?: VisualType;
};

export enum VisualType {
  TABLE = "TABLE",
  LINE = "LINE",
  BAR = "BAR",
  PIE = "PIE",
  DONUT = "DONUT",
  KPI = "KPI",
  GOAL = "GOAL",
  EMBED = "EMBED",
  AREA = "AREA"
}

export type TabularData = {
  title: string;
  data_description?: string;
  source_context_id?: string;
  visual_type?: VisualType;
  col_headers: string[];
  row_headers: string[];
  rows: (string | number)[][];
};

export type DataPointXY = {
  x: string;
  y: number;
  y2?: number;
};

export enum KPIType {
  PERCENTAGE = "percentage",
  CURRENCY = "currency",
  COUNT = "count"
}

export type KPIData = {
  title: string;
  data_description?: string;
  source_context_id?: string;
  value?: string;
  previous_value?: number;
  period?: string;
  kpi_type?: string;
};

export enum Status {
  AtRisk = "At risk",
  Behind = "Behind",
  OnTrack = "On track",
  NoStatus = "No status"
}

export type GoalData = {
  title: string;
  description?: string;
  source_context_id?: string;
  goal_value: number;
  current_value: number;
  unit: string;
  status?: Status;
};

export type TextData = {
  title: string;
  markdown_content: string;
  source_context_id?: string;
};

export type RawData = {
  [key: string]: number | string;
};

export type EmbedData = {
  title: string;
  embed_url?: string;
  embed_code?: string;
};

export enum TileType {
  KPI = "kpi",
  TEXT = "text",
  GOAL = "goal",
  CHART = "chart",
  HEADING = "heading",
  EMBED = "embed"
}

export type TileContent =
  | KPIData
  | TextData
  | GoalData
  | TabularData
  | EmbedData;

export type Tile =
  | KPITile
  | TextTile
  | GoalTile
  | ChartTile
  | HeadingTile
  | EmbedTile;

type TileSource = {
  app_name: IntegrationApp;
  integration_entity_id: string;
  integration_entity_name: string;
  integration_entity_type: SourceType;
};

export type BaseTile = {
  id: string;
  width: number;
  height: number;
  title?: string;
  description?: string;
  visual_type?: VisualType;
  source_integration_entities?: TileSource[];
};

export type KPITile = BaseTile & {
  tile_type: TileType.KPI;
  content: KPIData;
};

export type TextTile = BaseTile & {
  tile_type: TileType.TEXT;
  content: TextData;
};

export type GoalTile = BaseTile & {
  tile_type: TileType.GOAL;
  content: GoalData;
};

export type ChartTile = BaseTile & {
  tile_type: TileType.CHART;
  content: TabularData;
};

export type HeadingTile = BaseTile & {
  tile_type: TileType.HEADING;
  content: TextData;
};

export type EmbedTile = BaseTile & {
  tile_type: TileType.EMBED;
  content: EmbedData;
};

export type SectionOutline = {
  header: string;
  description: string;
};

export type GridItem = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

export type TileGrid = {
  items: GridItem[];
  cols: number;
  rowHeight: number;
};

export type UpdateTileContent = <
  T extends
    | Partial<KPIData>
    | Partial<TabularData>
    | Partial<GoalData>
    | Partial<TextData>
>(
  tileId: string,
  newContent: T
) => void;

export type Slide = {
  id: string;
  items: Layout[];
};

export type JsonContent = {
  event: string;
  data: {
    styled_tiles: Tile[];
    section_tiles?: Tile[];
    section_outlines?: { header?: string; description?: string }[];
    kpi_tiles?: KPITile[];
    chart_tiles?: ChartTile[];
  };
};
