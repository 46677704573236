import { useContext } from "react";
import { ArtifactCopilotContext, SnippetModel } from "../ArtifactCopilotProvider";
import ArtifactSnippetListItem from "./ArtifactSnippetListItem";

export default function ArtifactSnippets({
  snippetList
}: {
  snippetList: SnippetModel[];
}) {
  const { setChosenSnippet } = useContext(ArtifactCopilotContext);
  const uniqueSnippets = new Map(
    snippetList.map((snippet) => [snippet.snippetId, snippet])
  );

  return (
    <div className="flex flex-col gap-2 w-full h-50">
      {Array.from(uniqueSnippets.values()).map((snippet) => (
        <div key={snippet.snippetId} className="flex gap-2">
          <ArtifactSnippetListItem
            snippet={snippet}
            onClick={() => {
              setChosenSnippet(snippet);
            }}
          />
        </div>
      ))}
    </div>
  );
}
