import { QuickView } from "@/containers/Layout/QuickView";
import { createContext, useEffect, useMemo, useState } from "react";
import { motion } from "framer-motion";
import ArtifactSnippets from "./Snippets/ArtifactSnippets";
import ArtifactSnippetInsight from "./Snippets/ArtifactSnippetInsight";
import { Button } from "@/components/ui/button";
import { ChevronLeft } from "lucide-react";

export type SnippetModel = {
  snippetId: string;
  snippetContent: string;
  contextId: string;
};
interface ArtifactCopilotProviderProps {
  selectionContent: string | undefined;
  setSelectionContent: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCitationContextId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSlideContextId: React.Dispatch<React.SetStateAction<string | undefined>>;
  citationContent: string | undefined;
  setCitationContent: React.Dispatch<React.SetStateAction<string | undefined>>;
  snippetList: SnippetModel[];
  setSnippetList: React.Dispatch<React.SetStateAction<SnippetModel[]>>;
  setChosenSnippet: React.Dispatch<
    React.SetStateAction<SnippetModel | undefined>
  >;
}

const ArtifactCopilotContext = createContext<ArtifactCopilotProviderProps>({
  selectionContent: undefined,
  setSelectionContent: () => {
    throw new Error("setSelectionContent() not implemented");
  },
  setCitationContextId: () => {
    throw new Error("setCitationContextId() not implemented");
  },
  setSlideContextId: () => {
    throw new Error("setSlideContextId() not implemented");
  },
  citationContent: undefined,
  setCitationContent: () => {
    throw new Error("setCitationContent() not implemented");
  },
  snippetList: [],
  setSnippetList: () => {
    throw new Error("setSnippetList() not implemented");
  },
  setChosenSnippet: () => {
    throw new Error("setChosenSnippet() not implemented");
  }
});

const ArtifactCopilotProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [selectionContent, setSelectionContent] = useState<string | undefined>(
    undefined
  );
  const [citationContextId, setCitationContextId] = useState<
    string | undefined
  >(undefined);
  const [slideContextId, setSlideContextId] = useState<string | undefined>(
    undefined
  );
  const [quickViewOpen, setQuickViewOpen] = useState<boolean>(false);
  const [citationContent, setCitationContent] = useState<string | undefined>(
    undefined
  );

  const [snippetList, setSnippetList] = useState<SnippetModel[]>([]);
  const [chosenSnippet, setChosenSnippet] = useState<
    SnippetModel | undefined
  >();

  useEffect(() => {
    if (citationContextId) {
      setQuickViewOpen(true);
    }
  }, [citationContextId]);

  useEffect(() => {
    if (slideContextId) {
      setQuickViewOpen(true);
    }
  }, [slideContextId]);

  useEffect(() => {
    if (selectionContent) {
      setQuickViewOpen(true);
    }
  }, [selectionContent]);

  useEffect(() => {
    if (!quickViewOpen) {
      setChosenSnippet(undefined);
      setCitationContextId(undefined);
    }
  }, [quickViewOpen]);

  const contextValue = useMemo(() => {
    return {
      selectionContent,
      setSelectionContent,
      setCitationContextId,
      setSlideContextId,
      citationContent,
      setCitationContent,
      snippetList,
      setSnippetList,
      setChosenSnippet
    };
  }, [
    selectionContent,
    setSelectionContent,
    setCitationContextId,
    setSlideContextId,
    citationContent,
    setCitationContent,
    snippetList,
    setSnippetList,
    setChosenSnippet
  ]);
  return (
    <ArtifactCopilotContext.Provider value={contextValue}>
      {children}
      {snippetList.length > 0 && (
        <QuickView
          collapse={() => setQuickViewOpen(!quickViewOpen)}
          collapsed={!quickViewOpen}
          title="Copilot"
        >
          <div className="transition-all w-full h-full duration-300 ease-in-out">
            <div className="flex flex-col overflow-x-hidden rounded-[8px] overflow-y-auto scrollbar-webkit-standard p-4">
              {chosenSnippet && (
                <Button
                  variant="outline"
                  onClick={() => setChosenSnippet(undefined)}
                  className="gap-4 mb-4"
                >
                  <ChevronLeft
                    size={16}
                    className="text-dark-text dark:text-light-text"
                  />
                  <span>Back to citations</span>
                </Button>
              )}
              {selectionContent && (
                <div className="text-dark-text dark:text-light-text text-[1rem] whitespace-pre-wrap">
                  Dive deeper into: <b>"{selectionContent}"</b>
                </div>
              )}
              {chosenSnippet ? (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                >
                  <ArtifactSnippetInsight snippet={chosenSnippet} />
                </motion.div>
              ) : (
                <ArtifactSnippets snippetList={snippetList} />
              )}
            </div>
          </div>
        </QuickView>
      )}
    </ArtifactCopilotContext.Provider>
  );
};

export { ArtifactCopilotProvider, ArtifactCopilotContext };
