export default function OrangeGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.86649"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <path
        d="M18.5 57.5002L33 45.5576L47 44L61 50.5L76 45.5576L85 36L100.5 31"
        stroke="#FFD84F"
        strokeWidth="2.24582"
      />
      <path
        d="M16.3203 16.5029H43.7939"
        stroke="#FCA326"
        strokeWidth="5.24026"
      />
      <path
        d="M46.3203 16.5029H73.7939"
        stroke="#FCA326"
        strokeWidth="5.24026"
      />
      <path
        d="M76.3203 16.5029H103.794"
        stroke="#FCA326"
        strokeWidth="5.24026"
      />
      <path
        d="M15.5781 63.6309H104.662"
        stroke="#FFD84F"
        strokeOpacity="0.85"
        strokeWidth="0.748608"
      />
      <path
        d="M15.5781 68.123H104.662"
        stroke="#FFED36"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M15.5781 73.7373H56.3773"
        stroke="#FFED36"
        strokeOpacity="0.45"
        strokeWidth="0.748608"
      />
      <rect y="79" width="121" height="84" fill="url(#paint0_linear_9047_14)" />
    </svg>
  );
}
