export type ChartGradient = {
  id: string;
  stops: number[];
  colors: string[];
};

export const gradientOptions: ChartGradient[] = [
  {
    id: "blueTeal",
    stops: [0.5, 79.5],
    colors: ["#3C3FD3", "#4EA8DE"]
  },
  {
    id: "darkTeal",
    stops: [0, 80],
    colors: ["#137D9D", "#4BA0A3"]
  },
  {
    id: "brevBlue",
    stops: [0, 79.5],
    colors: ["#146D95", "#3FCFF1"]
  },

  {
    id: "darkBlue",
    stops: [0.5, 90],
    colors: ["#0859AC", "#BAD7F5"]
  },
  {
    id: "greenYellow",
    stops: [17.5, 79.5],
    colors: ["#1B9C77", "#B4E48D"]
  },
  {
    id: "purple",
    stops: [0, 98],
    colors: ["#5D0A8E", "#8384CC"]
  },
  {
    id: "midnight",
    stops: [0, 56.5],
    colors: ["#062351", "#1F447E"]
  },
  {
    id: "yellow",
    stops: [0, 100],
    colors: ["#FFC700", "#F2DB8B"]
  }
];
