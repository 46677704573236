import { MemberRole } from "@/lib/types"

export type Organization = {
  orgId: string,
  name: string,
  role?: MemberRole,
}

export type OrgUser = {
  userId?: string,
  memberId: string,
  userName?: string,
  email: string,
  role: MemberRole,
  team?: string,
  memberStatus: InvitationStatus,
}

export enum InvitationStatus {
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
}