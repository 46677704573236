// import BadgeButton from "@/components/Buttons/BadgeButton";
import ArtifactSettingsDialog from "./ArtifactSettings/ArtifactSettingsDialog";
// import { useArtifactSettings } from "./ArtifactSettings/useArtifactSettings";
// import { useContext, useMemo } from "react";
// import { ArtifactContext } from "./ArtifactProvider";
// import { LuCalendarDays } from "react-icons/lu";
import { useState, useEffect, useRef, useContext } from "react";
import { GlobalContext } from "@/GlobalProvider";
import { useSearchParams } from "react-router-dom";
import { ArtifactContext } from "./ArtifactProvider";

export default function ArtifactBadges() {
  // const { artifactMetadata } = useContext(ArtifactContext);
  // const { format, audience, endAt, startAt } = artifactMetadata;
  // const { integrationEntities } = useArtifactSettings();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openTab, setOpenTab] = useState(0);
  const artifactTitleRef = useRef<string | undefined>();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { artifactTitle } = useContext(GlobalContext);
  const { artifactTiles } = useContext(ArtifactContext);

  // const onBadgeClick = (tab: number) => {
  //   setDialogOpen(true);
  //   setCurrentTab(tab);
  // };

  // const connectedEntitiesCount = useMemo(() => {
  //   return integrationEntities.filter((entity) => entity.isConnected).length;
  // }, [integrationEntities]);

  useEffect(() => {
    const hasIid = searchParams.get("iid") !== null;
    const hasNoTitle =
      artifactTitle !== undefined && artifactTitle.length === 0;

    if (hasIid) {
      setOpenTab(1);
    }

    if (
      hasIid ||
      (artifactTitleRef.current === undefined && hasNoTitle && artifactTiles.length === 0)
    ) {
      setDialogOpen(true);
    }

    if (artifactTitleRef.current === undefined) {
      artifactTitleRef.current = artifactTitle;
    }
  }, [searchParams, artifactTitle]);

  return (
    // <div className="flex justify-start gap-3 items-center py-1 px-2">
    //   <ArtifactSettingsDialog
    //     dialogOpen={dialogOpen}
    //     setDialogOpen={setDialogOpen}
    //     openTab={openTab}
    //   />

    //   <BadgeButton
    //     title="Artifact format"
    //     data={format}
    //     onClick={() => onBadgeClick(0)}
    //   />
    //   <BadgeButton
    //     title="Target audience"
    //     data={audience}
    //     onClick={() => onBadgeClick(0)}
    //   />
    //   <BadgeButton
    //     title="Artifact date range"
    //     icon={<LuCalendarDays className="mt-0.5" size={18} />}
    //     data={
    //       startAt && endAt
    //         ? `${startAt.toLocaleDateString(undefined, { month: "short", day: "numeric" })} - ${endAt.toLocaleDateString(undefined, { month: "short", day: "numeric" })}`
    //         : ""
    //     }
    //     onClick={() => onBadgeClick(0)}
    //   />
    //   {connectedEntitiesCount > 0 && (
    //     <BadgeButton
    //       title="Source data"
    //       data="Source Data"
    //       onClick={() => onBadgeClick(1)}
    //     />
    //   )}
    // </div>
    <ArtifactSettingsDialog
      dialogOpen={dialogOpen}
      setDialogOpen={setDialogOpen}
      openTab={openTab}
    />
  );
}
