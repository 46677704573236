import CompanyDetails from "./CompanyDetails";
import CompanyMemory from "./CompanyMemory";

export default function CompanySettings() {
    return (
        <div className="flex flex-col">
            <CompanyDetails />
            <CompanyMemory />
        </div>
    )
}