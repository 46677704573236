import { ArtifactTemplateModel, ArtifactTemplateStatus } from "../types";
import CheckMarkSvg from "@/components/Icons/CheckMarkSvg";
import BlueGraphic from "../../../components/Graphics/BlueGraphic";
import PurpleGraphic from "../../../components/Graphics/PurpleGraphic";
import GreenGraphic from "../../../components/Graphics/GreenGraphic";
import YellowGraphic from "../../../components/Graphics/YellowGraphic";
import OrangeGraphic from "../../../components/Graphics/OrangeGraphic";
import DarkBlueGraphic from "../../../components/Graphics/DarkBlueGraphic";
import DarkGreenGraphic from "../../../components/Graphics/DarkGreenGraphic";
import LightBlueGraphic from "../../../components/Graphics/LightBlueGraphic";
import LightGreenGraphic from "../../../components/Graphics/LightGreenGraphic";
import TealGraphic from "../../../components/Graphics/TealGraphic";
import RedGraphic from "../../../components/Graphics/RedGraphic";
import YellowGreenGraphic from "../../../components/Graphics/YellowGreenGraphic";
import BrevLoader from "@/components/Loaders/BrevLoader";

type TemplateCardProps = {
  templateModel?: ArtifactTemplateModel;
  isSelected: boolean;
  onClickHandler: () => void;
  color?: string;
};

export default function TemplateCard({
  templateModel,
  isSelected,
  onClickHandler,
  color
}: TemplateCardProps) {
  const placeholderData: PlaceholderData = {
    title: "Monthly Sales Performance Report",
    description:
      "This document projects future sales based on current pipeline data and historical trends from June 2024. It includes analysis of potential deals, expected close dates, and an overview of lead conversion rates"
  };

  const templateIcon = [
    {
      color: "purple",
      icon: <PurpleGraphic />
    },
    {
      color: "blue",
      icon: <BlueGraphic />
    },
    {
      color: "green",
      icon: <GreenGraphic />
    },
    {
      color: "yellow",
      icon: <YellowGraphic />
    },
    {
      color: "orange",
      icon: <OrangeGraphic />
    },
    {
      color: "red",
      icon: <RedGraphic />
    },
    {
      color: "teal",
      icon: <TealGraphic />
    },
    {
      color: "darkgreen",
      icon: <DarkGreenGraphic />
    },
    {
      color: "darkblue",
      icon: <DarkBlueGraphic />
    },
    {
      color: "lightgreen",
      icon: <LightGreenGraphic />
    },
    {
      color: "lightblue",
      icon: <LightBlueGraphic />
    },
    {
      color: "yellowgreen",
      icon: <YellowGreenGraphic />
    }
  ];

  return (
    <div
      key={templateModel?.templateTitle || placeholderData.title}
      className={`relative flex flex-col text-[8px] justify-start w-[304px] h-[132px] items-start gap-2 p-2 rounded-[8px] border border-dark-border dark:border-light-border dark:hover:border-brev-active hover:border-brev-active overflow-hidden cursor-pointer ${isSelected ? "bg-[#1B2224] dark:bg-light-alt dark:text-dark-text text-brev-light" : ""} transition-colors duration-500 ease-in-out hover:shadow-[0_0_15.5px_0_rgba(54,71,114,0.05)]`}
      onClick={onClickHandler}
      data-cy={"templateCard"}
      data-selected={isSelected}
    >
      <div className="absolute bottom-0 right-0 z-1">
        {color && templateIcon.find((icon) => icon.color === color)?.icon}
      </div>

      <div className="flex flex-col w-full">
        <div
          className="text-[0.75rem] font-semibold w-full text-nowrap truncate overflow-hidden"
          title={templateModel?.templateTitle || placeholderData.title}
        >
          {templateModel?.templateTitle || placeholderData.title}
        </div>
        <div
          className={`${isSelected ? "text-slate-100 dark:text-dark-text" : "text-slate-500 dark:text-brev-light"} px-2 pt-3 pb-2 text-[0.5rem] h-[120px] w-[11rem] text-pretty overflow-y-auto scrollbar-webkit-standard dark:scrollbar-webkit-neutral`}
        >
          {templateModel?.templateDescription || placeholderData.description}
        </div>
      </div>
      <div className="flex justify-end w-full">
        {templateModel?.status === ArtifactTemplateStatus.GENERATING && (
          <div className="">
            <BrevLoader type="ring" size={20} color="black"/>
          </div>
        )}
        {templateModel?.status === ArtifactTemplateStatus.COMPLETE && (
          <div className="">
            <CheckMarkSvg />
          </div>
        )}
      </div>
    </div>
  );
}

type PlaceholderData = {
  title: string;
  description: string;
};
