import type { Tile, Slide } from "@/containers/ReviewBuilder/review-types";
import { Layout } from "react-grid-layout";
import { DataSet, TabularData } from "@/containers/ReviewBuilder/review-types";
import { TileType } from "@/containers/ReviewBuilder/review-types";

export function generateInitialLayout(tiles: Tile[]): Layout[] {
  return tiles.map((tile, index) => ({
    i: tile.id,
    x: (index * tile.width) % GRID_COLS,
    y: Math.floor((index * tile.width) / GRID_COLS) * tile.height,
    w: tile.width,
    h: tile.height
  }));
}

export const SLIDE_HEIGHT = 24;
export const GRID_COLS = 12;
export const ROW_HEIGHT = 25;
export const SLIDE_DIVIDER_ID = "slide-divider-";
export const FINAL_DIVIDER_ID = "final-divider-";

export interface SlideInfo {
  slideNumber: number;
  isLastSlide: boolean;
}

export function parseSlideId(dividerItemId: string): SlideInfo {
  const regex = new RegExp(`(?:${SLIDE_DIVIDER_ID}|${FINAL_DIVIDER_ID})(\\d+)`);
  const match = dividerItemId.match(regex);
  return {
    slideNumber: match ? parseInt(match[1]) + 1 : 1,
    isLastSlide: dividerItemId.startsWith(FINAL_DIVIDER_ID)
  };
}

export const getTileConstraints = (tileType: TileType) => {
  switch (tileType) {
    case TileType.CHART:
      return { minW: 6, minH: 15, maxW: GRID_COLS, maxH: 21 };
    case TileType.TEXT:
      return { minW: 2, minH: 3, maxW: GRID_COLS, maxH: 21 };
    case TileType.GOAL:
      return { minW: 4, minH: 9, maxW: GRID_COLS, maxH: 21 };
    case TileType.KPI:
      return { minW: 3, minH: 5, maxW: 12, maxH: 9 };
    case TileType.HEADING:
      return { minW: GRID_COLS, minH: 2, maxW: GRID_COLS, maxH: 2 };
    case TileType.EMBED:
      return { minW: 6, minH: 6, maxW: GRID_COLS, maxH: 21 };
    default:
      return { minW: 1, minH: 3, maxW: GRID_COLS, maxH: 21 };
  }
};

export const extractJSONArray = (str: string): Tile[] | null => {
  try {
    const match = str.match(/\[[\s\S]*\]/);
    if (match) {
      return JSON.parse(match[0]);
    }
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
  return null;
};

export const tabularDataToDataSet = (tabularData: TabularData): DataSet => {
  return {
    title: tabularData.title,
    description: tabularData.data_description,
    xLabel: tabularData.col_headers[0],
    yLabel: tabularData.col_headers[1],
    data: tabularData.rows.map((row, index) => ({
      x: tabularData.row_headers[index],
      y: parseNumericalValue(row[0])
    })),
    visualType: tabularData.visual_type
  };
};

export const parseNumericalValue = (value: string | number): number => {
  if (typeof value === "number") {
    return value;
  }
  let output = value;
  if (output.toString().startsWith("$")) {
    output = output.slice(1);
  }
  if (output.includes("%")) {
    output = output.replace(/%/g, "");
  }
  if (output.includes(",")) {
    output = output.replace(/,/g, "");
  }
  return parseFloat(output);
};

export const dataSetToTabularData = (dataSet: DataSet): TabularData => {
  return {
    title: dataSet.title,
    data_description: dataSet.description,
    visual_type: dataSet.visualType,
    row_headers: dataSet.data.map((row) => row.x),
    col_headers: [dataSet.yLabel],
    rows: dataSet.data.map((row) => [row.y.toString()])
  };
};

const estimateTextHeight = (content: string, baseHeight: number): number => {
  const lineEstimate = content.split("\n").length;
  const characterEstimate = content.length / 100;
  const estimatedLines = Math.max(lineEstimate, characterEstimate);
  return Math.ceil(baseHeight * (1 + estimatedLines / 10));
};

export const generateLayout = (tiles: Tile[]) => {
  let currentX = 0;
  let currentY = 0;
  let slideIndex = 0;
  const layout: Layout[] = [];

  tiles.forEach((tile) => {
    if (!isTileComplete(tile)) return;

    const constraints = getTileConstraints(tile.tile_type);
    const width = Math.min(
      Math.max(tile.width, constraints.minW),
      constraints.maxW
    );
    let height = Math.min(
      Math.max(tile.height, constraints.minH),
      constraints.maxH
    );

    if (
      "content" in tile &&
      "markdown_content" in tile.content &&
      typeof tile.content.markdown_content === "string"
    ) {
      const estimatedHeight = estimateTextHeight(
        tile.content.markdown_content,
        height
      );
      height = Math.min(
        Math.max(estimatedHeight, constraints.minH),
        constraints.maxH
      );
    }

    if (currentX + width > GRID_COLS) {
      currentX = 0;
      currentY = layout.reduce(
        (max, item) => Math.max(max, item.y + item.h),
        currentY
      );
    }

    if (currentY + height > (slideIndex + 1) * SLIDE_HEIGHT) {
      currentX = 0;
      currentY = (slideIndex + 1) * SLIDE_HEIGHT;
      slideIndex++;
    }

    const newTile = {
      i: tile.id,
      x: currentX,
      y: currentY,
      w: width,
      h: height,
      slideIndex,
      ...constraints
    };

    layout.push(newTile);
    currentX += width;

    if (currentX >= GRID_COLS) {
      currentX = 0;
      currentY = layout.reduce(
        (max, item) => Math.max(max, item.y + item.h),
        currentY
      );
    }
  });

  return layout;
};

export const isTileComplete = (tile: Tile) => {
  return tile.id && tile.tile_type && tile.width && tile.height && tile.content;
};

export const divideIntoSlides = (layout: Layout[]): Slide[] => {
  const slides: Slide[] = [];
  let currentSlide: Layout[] = [];
  let slideIndex = 0;

  layout.forEach((item) => {
    if (item.i.startsWith("slide-divider-")) return;

    const itemSlideIndex = Math.floor(item.y / SLIDE_HEIGHT);

    if (itemSlideIndex > slideIndex) {
      slides.push({ id: `slide-${slideIndex}`, items: currentSlide });
      currentSlide = [];
      slideIndex = itemSlideIndex;
    }

    currentSlide.push({
      ...item,
      y: item.y % SLIDE_HEIGHT
    });
  });

  if (currentSlide.length > 0) {
    slides.push({ id: `slide-${slideIndex}`, items: currentSlide });
  }

  return slides;
};

export const getDefaultTileDimensions = (
  type: TileType
): { width: number; height: number } => {
  switch (type) {
    case TileType.KPI:
      return { width: 3, height: 6 };
    case TileType.TEXT:
      return { width: 12, height: 6 };
    case TileType.GOAL:
      return { width: 4, height: 9 };
    case TileType.CHART:
      return { width: 12, height: 18 };
    case TileType.HEADING:
      return { width: GRID_COLS, height: 2 };
    case TileType.EMBED:
      return { width: 12, height: 18 };
    default:
      return { width: 2, height: 2 };
  }
};
