import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";

type ModuleData = {
  title: string;
  content: {
    id:
      | "slack_notifications"
      | "email_notifications"
      | "next_best_actions"
      | "co_pilot"
      | "transparent_reviews"
      | "download_reviews";
    heading: string;
    description: string;
  }[];
};

const FormSchema = z.object({
  slack_notifications: z.boolean(),
  email_notifications: z.boolean(),
  next_best_actions: z.boolean(),
  co_pilot: z.boolean(),
  transparent_reviews: z.boolean(),
  download_reviews: z.boolean()
});

export default function Modules() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col py-4 gap-4"
      >
        {MODULES_DATA.map((data, index) => (
          <div
            key={index}
            className="flex flex-col gap-3 justify-evenly px-4 pt-2 w-full rounded-2xl bg-brev-light h-[236px] text-xs font-semibold"
          >
            {data.title}
            {data.content.map((c, index) => (
              <FormField
                key={index}
                control={form.control}
                name={c.id}
                render={({ field }) => (
                  <FormItem className="flex justify-between">
                    <div className="px-4">
                      <FormLabel className="text-xs font-medium">
                        {c.heading}
                      </FormLabel>
                      <FormDescription className="py-1 w-[800px] text-[11px] font-normal text-black/75">
                        {c.description}
                      </FormDescription>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        className="data-[state=checked]:bg-white data-[state=unchecked]:bg-white w-[56px] h-[18px]"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            ))}
          </div>
        ))}
      </form>
    </Form>
  );
}

const MODULES_DATA: ModuleData[] = [
  {
    title: "Notification settings",
    content: [
      {
        id: "slack_notifications",
        heading: "Enable Slack Notifications",
        description:
          "Turns on Slack notifications for users in your organization. Users can receive reminders, mentions, and various activity based notifications in Slack.  Users can manage individual level preferences in their account."
      },
      {
        id: "email_notifications",
        heading: "Enable Email Notifications",
        description:
          "Turns on Email notifications for users in your organization. Users will receive reminders, mentions, and various activity based notifications in Email. Users can manage individual level preferences in their account."
      }
    ]
  },
  {
    title: "Co-pilot settings",
    content: [
      {
        id: "next_best_actions",
        heading: "Enable next-best-actions",
        description:
          "Give users the ability to get AI-driven recommendations in various areas of the product."
      },
      {
        id: "co_pilot",
        heading: "Enable Co-pilot",
        description: "Give users the ability to query Brev’s co-pilot chatbot."
      }
    ]
  },
  {
    title: "Review settings",
    content: [
      {
        id: "transparent_reviews",
        heading: "Transparent reviews",
        description:
          "Reviews are internally transparent by default. Members of the organization can search and find Reviews. Users can manage visibility at the individual review level of the Reviews they own."
      },
      {
        id: "download_reviews",
        heading: "Download reviews to PDF",
        description:
          "Gives users the ability to download Reviews to PDF format. "
      }
    ]
  }
];
