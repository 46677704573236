import config from "@/config";
import { API } from "aws-amplify";
import { AxiosError } from "axios";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { MemberRole } from "./types";
import { OrgUser } from "@/containers/Settings/types";
import DOMPurify from "dompurify";
import { IntegrationApp } from "@/containers/Integrations/types";
import type { DateRange } from "react-day-picker";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function stripCodeFence(input: string): string {
  return input.replace(/```html/g, "").replace(/```/g, "");
}

export function escapeHTML(data: string) {
  return data
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#39;")
    .replace(/`/g, "&#96;");
}

export function unescapeHTML(html: string) {
  return html
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&#96;/g, "`");
}

export function sleep(ms: number) {
  console.log("sleeping for", ms, "ms");
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export async function getUserDetails(): Promise<BrevUser | null> {
  try {
    const user = await API.get("reviews", "/users", {});
    if (!user) {
      return null;
    }
    return user;
  } catch (error) {
    return null;
  }
}

export async function getUsers(): Promise<BrevUser[] | null> {
  try {
    const response = await API.get("reviews", "/users/all", {});
    if (!response || !response.data) return null;
    return response.data as BrevUser[];
  } catch (e) {
    console.error("Error fetching users:", e);
    return null;
  }
}

export async function updateUser(userData: unknown) {
  return await API.put("reviews", "/users", {
    body: userData,
    timeout: 300000
  });
}

export async function createUser(userData: unknown) {
  return await API.post("reviews", "/users", {
    body: userData,
    timeout: 300000
  });
}

export async function createReview(reviewTitle: string, content: string) {
  try {
    return await API.post("reviews", "/reviews", {
      body: {
        title: reviewTitle,
        content
      },
      timeout: 300000
    });
  } catch (e) {
    console.error(e);
  }
}

export async function modifyReview(
  reviewId: string,
  reviewTitle: string,
  content: string,
  state: "DRAFT" | "PUBLISHED",
  reviewMetaData: string
) {
  return await API.put("reviews", `/reviews/${reviewId}`, {
    body: {
      reviewId: reviewId,
      title: reviewTitle,
      content,
      state,
      reviewMetaData
    },
    timeout: 300000
  });
}

export async function publishReviewAPI(
  reviewId: string,
  publishedContent: string,
  creator: string
) {
  return await API.put("reviews", `/publishReview/${reviewId}`, {
    body: {
      reviewId,
      publishedContent,
      creator
    },
    timeout: 300000
  });
}

export async function deleteReview(reviewId: string): Promise<boolean> {
  try {
    const res = await API.del("reviews", `/reviews/${reviewId}`, {});
    return res;
  } catch (error) {
    console.error(error);
    throw new Error("Failed to delete review. Please try again later.");
  }
}

export async function fetchReview(reviewId: string) {
  return await API.get("reviews", `/reviews/${reviewId}`, {});
}

export async function getMyOrganizations() {
  return await API.get("reviews", `/my/organizations`, {});
}

export async function createOrganization(name: string) {
  try {
    return await API.post("reviews", "/organizations", {
      body: {
        name
      },
      timeout: 300000
    });
  } catch (e) {
    console.error(e);
  }
}

export async function inviteOrgMember(
  orgId: string,
  email: string,
  role: string
) {
  try {
    return await API.post("reviews", `/organizations/${orgId}/members`, {
      body: {
        email,
        role
      },
      timeout: 300000
    });
  } catch (e) {
    console.error(e);
    if (
      e instanceof AxiosError &&
      e.response &&
      e.response.data &&
      e.response.data.error
    ) {
      throw new Error(e.response.data.error);
    } else {
      // If `e` is not an instance of Error or doesn't have the expected structure
      throw new Error("An unexpected error occurred");
    }
  }
}

export async function getOrgMembers(orgId: string) {
  return await API.get("reviews", `/organizations/${orgId}/members`, {});
}

export async function updateOrgMember(
  orgId: string,
  memberId: string,
  body: Partial<OrgUser>
) {
  try {
    return await API.patch(
      "reviews",
      `/organizations/${orgId}/members/${memberId}`,
      {
        body,
        timeout: 300000
      }
    );
  } catch (e) {
    console.error(e);
  }
}

export enum CopilotRequestType {
  RESOURCE_DATASET = "RESOURCE_DATASET"
}

type AnyFunction = (...args: unknown[]) => void;
export function debounce<T extends AnyFunction>(
  func: T,
  delay: number
): (...args: Parameters<T>) => void {
  let timeoutId: ReturnType<typeof setTimeout>;

  return function (...args: Parameters<T>) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

/**
 * @description IMPORTANT:
 * There are times where reviewId may becoming from
 * an OAuth redirect. In this case, the reviewId is in the state variable based back from
 * the redirect
 *
 * We get the id from the state variable
 * we then set the path to the current path + the id
 * we then call getReviewId again to recursively grab that review id
 * from the searchParams as expected to maintain consistency
 *
 *
 * otherwise, it just gets the id from searchParams
 *
 * @returns reviewId if it exists, null otherwise
 */

export const getReviewId = (): string | null => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const reviewId = urlSearchParams.get("id");
  const id = urlSearchParams.get("state")?.split("/")[1];

  if (id) {
    return id;
  }

  return reviewId;
};

export const getHashParamsString = (): string => {
  const hash = window.location.hash;
  const hashSplits = hash.split("#");
  if (hashSplits.length > 1) {
    return `&${hashSplits[1]}`;
  } else {
    return "";
  }
};

export const convertDateToUnix = (date: Date): string => {
  return Math.floor(date.getTime() / 1000).toString();
};

export function authenticateSalesforce(artifactId?: string) {
  const authURL = new URL(config.env.SALESFORCE_INSTANCE_URL);
  authURL.pathname = "services/oauth2/authorize";

  const app = "SALESFORCE";

  const redirectUri = `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`;

  const authParams = new URLSearchParams({
    client_id: config.env.SF_CONSUMER_KEY,
    response_type: "code",
    redirect_uri: redirectUri,
    state: !artifactId ? app : `${app}/${artifactId}`
  });

  authURL.search = authParams.toString();
  window.location.href = `${authURL.toString()}&scope=api+id+web`;
}

export function authenticateGDrive(artifactId?: string) {
  const authURL = new URL("https://accounts.google.com/o/oauth2/v2/auth");

  const app = IntegrationApp.GDRIVE;

  const redirectUri = `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`;

  const authParams = new URLSearchParams({
    access_type: "offline",
    prompt: "consent",
    client_id: config.env.GDRIVE_CLIENT_ID,
    response_type: "code",
    redirect_uri: redirectUri,
    state: !artifactId ? app : `${app}/${artifactId}`,
    scope: "https://www.googleapis.com/auth/drive.readonly"
  });

  authURL.search = authParams.toString();
  window.location.href = authURL.toString();
}

export function authenticateGitHub(artifactId?: string) {
  const authURL = new URL("https://github.com/login/oauth/authorize");

  const app = IntegrationApp.GITHUB;

  const redirectUri = `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`;

  const authParams = new URLSearchParams({
    // access_type: "offline",
    // prompt: "consent",
    client_id: config.env.GITHUB_CLIENT_ID,
    response_type: "code",
    redirect_uri: redirectUri,
    state: !artifactId ? app : `${app}/${artifactId}`,
    scope: "repo read:org read:project"
  });

  authURL.search = authParams.toString();
  window.location.href = authURL.toString();
}

export function authenticateSlack(artifactId?: string) {
  const authURL = new URL("https://slack.com/oauth/v2/authorize");

  const app = IntegrationApp.SLACK;

  const redirectUri = `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`;

  const authParams = new URLSearchParams({
    client_id: config.env.SLACK_CLIENT_ID,
    redirect_uri: redirectUri,
    state: !artifactId ? app : `${app}/${artifactId}`,
    user_scope: "channels:read,groups:read,channels:history,users:read",
    scope: "channels:history,users:read"
  });

  authURL.search = authParams.toString();
  window.location.href = authURL.toString();
}

export function authenticateHubSpot(artifactId?: string) {
  const authURL = new URL("https://app.hubspot.com/oauth/authorize");

  const app = IntegrationApp.HUBSPOT;

  const redirectUri = `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`;

  const scopes = ["oauth"].join(" ");

  const optionalScopes = [
    "crm.lists.read",
    "crm.objects.companies.read",
    "crm.objects.custom.read",
    "crm.objects.deals.read",
    "crm.objects.goals.read",
    "crm.objects.invoices.read",
    "crm.objects.leads.read",
    "crm.objects.line_items.read",
    "crm.objects.listings.read",
    "crm.objects.marketing_events.read",
    "crm.objects.orders.read",
    "crm.objects.services.read",
    "crm.pipelines.orders.read"
  ];

  const authParams = new URLSearchParams({
    client_id: config.env.HUBSPOT_CLIENT_ID,
    redirect_uri: redirectUri,
    state: !artifactId ? app : `${app}/${artifactId}`,
    scope: scopes,
    optional_scope: optionalScopes.join(" ")
  });

  authURL.search = authParams.toString();
  window.location.href = authURL.toString();
}

export const getOrgId = () => {
  return localStorage.getItem("currentOrgId");
};

export const getOrgName = () => {
  return localStorage.getItem("currentOrgName");
};

export const getOrgRole = () => {
  return localStorage.getItem("currentOrgRole") as MemberRole;
};

export const domPurify = (html: string) => {
  const config = {
    ALLOWED_TAGS: [
      "iframe",
      "div",
      "object",
      "param",
      "noscript",
      "a",
      "img"
      // "script"
    ],
    ALLOWED_ATTR: [
      "allow",
      "allowfullscreen",
      "frameborder",
      "height",
      "scrolling",
      "src",
      "width",
      "style",
      "class",
      "id",
      "name",
      "value",
      "type",
      "href",
      "alt",
      "sandbox",
      "webkitallowfullscreen",
      "mozallowfullscreen"
    ],
    // ADD_TAGS: ["script"],
    ADD_ATTR: ["type"],
    ADD_STYLES: true,
    WHOLE_DOCUMENT: false,
    FORCE_BODY: false
  };
  return DOMPurify.sanitize(html, config);
};

export async function createPaymentIntent(): Promise<{
  clientSecret: string;
} | null> {
  try {
    const response = await API.post("reviews", "/create-payment-intent", {});
    return response;
  } catch (error) {
    console.error("Error creating payment intent:", error);
    return null;
  }
}

export function getOS() {
  const userAgent = window.navigator.userAgent.toLowerCase();
  if (userAgent.indexOf("mac") !== -1) return "mac";
  if (userAgent.indexOf("win") !== -1) return "windows";
  return "other";
}

export function formatDollarValue(value: number) {
  if (value >= 1e9) {
    const formatted = (value / 1e9).toFixed(1);
    return `$${parseFloat(formatted)}B`;
  } else if (value >= 1e6) {
    const formatted = (value / 1e6).toFixed(1);
    return `$${parseFloat(formatted)}M`;
  } else if (value >= 1e3) {
    const formatted = (value / 1e3).toFixed(1);
    return `$${parseFloat(formatted)}K`;
  } else {
    return `$${value}`;
  }
}

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }).format(value);
};

export function formatNumber(value: number, decimalPlaces?: number) {
  if (value >= 1e9) {
    const formatted = (value / 1e9).toFixed(decimalPlaces || 1);
    return `${parseFloat(formatted)}B`;
  } else if (value >= 1e6) {
    const formatted = (value / 1e6).toFixed(decimalPlaces || 1);
    return `${parseFloat(formatted)}M`;
  } else if (value >= 1e3) {
    const formatted = (value / 1e3).toFixed(decimalPlaces || 1);
    return `${parseFloat(formatted)}K`;
  } else {
    return `${value}`;
  }
}

export const getYearToDate = (date: Date): DateRange => {
  const from = new Date(date.getFullYear(), 0, 1);
  const to = date;
  return { from, to };
};

export const getWeekRange = (date: Date, adjustDays: number = 0): DateRange => {
  const adjustedDate = new Date(date);
  adjustedDate.setDate(date.getDate() + adjustDays);

  const dayOfWeek = adjustedDate.getDay();
  const startOfWeek = new Date(adjustedDate);
  startOfWeek.setDate(adjustedDate.getDate() - dayOfWeek + 1);

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return {
    from: startOfWeek,
    to: endOfWeek
  };
};

export const getMonthRange = (
  date: Date,
  adjustMonths: number = 0
): DateRange => {
  const adjustedDate = new Date(date);
  adjustedDate.setMonth(date.getMonth() + adjustMonths);

  const startOfMonth = new Date(
    adjustedDate.getFullYear(),
    adjustedDate.getMonth(),
    1
  );
  const endOfMonth = new Date(
    adjustedDate.getFullYear(),
    adjustedDate.getMonth() + 1,
    0
  );

  return {
    from: startOfMonth,
    to: endOfMonth
  };
};

export const getQuarterRange = (
  date: Date,
  adjustQuarters: number = 0
): DateRange => {
  const adjustedDate = new Date(date);
  const currentMonth = adjustedDate.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3) + adjustQuarters;

  const startOfQuarter = new Date(
    adjustedDate.getFullYear(),
    currentQuarter * 3,
    1
  );
  const endOfQuarter = new Date(
    adjustedDate.getFullYear(),
    (currentQuarter + 1) * 3,
    0
  );

  return {
    from: startOfQuarter,
    to: endOfQuarter
  };
};

export function getErrorTitle(status: number | string): string {
  switch (status) {
    case 400:
      return "400 - Bad Request";
    case 401:
      return "401 - Unauthorized";
    case 403:
      return "403 - Forbidden";
    case 404:
      return "Page not found";
    case 500:
      return "500 - Internal Server Error";
    case 502:
      return "502 - Bad Gateway";
    case 503:
      return "503 - Service Unavailable";
    case 504:
      return "504 - Gateway Timeout";
    default:
      return `${status}`;
  }
}

export function getDefaultMessageForStatus(status: number): string {
  switch (status) {
    case 400:
      return "The request was invalid or cannot be served.";
    case 401:
      return "Authentication is required and has failed or has not been provided.";
    case 403:
      return "You don't have permission to access this resource.";
    case 404:
      return "Brev can help with a lot of things, but finding this page isn't one of them.";
    case 500:
      return "The server encountered an unexpected condition that prevented it from fulfilling the request.";
    case 502:
      return "The server received an invalid response from an upstream server.";
    case 503:
      return "The server is currently unavailable. Please try again later.";
    case 504:
      return "The server timed out waiting for a response from an upstream server.";
    default:
      return "An unexpected error occurred.";
  }
}

export async function computeSHA256(base64String: string): Promise<string> {
  // Decode the base64 string to a Uint8Array
  const binaryString = atob(base64String);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Compute the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest("SHA-256", bytes);

  // Convert the hash to hex format
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
