import { toast } from "@/components/ui/use-toast";
import config from "@/config";
import { useContext, useEffect, useRef } from "react";
import LoadingPage from "./Loading/LoadingPage";
import { AuthContext } from "./Auth/AuthProvider";

export default function OAuth2Callback() {
  const { sendApiRequest } = useContext(AuthContext);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get("code");
  const state = urlSearchParams.get("state");
  const exchangeTokenSent = useRef(false);

  if (!code || !state) {
    toast({
      title: "No Code Available",
      description: "An exchange code is required for the callback to continue."
    });
  }

  const [app, artifactId] = state!.split("/");

  useEffect(() => {
    const exchangeToken = async () => {
      const response = await sendApiRequest("/oauth2/callback", "POST",
        {
          code,
          state,
          appName: app,
          redirectUri: `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`
        }
      ) as { data: { integrationId: string } };
      console.log("Exchange Token Response", response);
      console.log("state", state);
      const { integrationId } = response.data;
      window.location.href = `${config.env.REDIRECT_URL_ROOT}/artifacts/${artifactId}?iid=${integrationId}`;
    };

    if (exchangeTokenSent.current) return;
    exchangeTokenSent.current = true;
    exchangeToken()
  }, []);
  return (
    <div className="NotFound text-center">
      <LoadingPage />
    </div>
  );
}
