type SpacingScale = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type SpacingClassnames = {
  spacingStart?: SpacingScale;
  spacingEnd?: SpacingScale;
  spacingTop?: SpacingScale;
  spacingBottom?: SpacingScale;
  spacing?: SpacingScale;
};

export default function useSpacingClassnames({
  spacingStart,
  spacingEnd,
  spacingTop,
  spacingBottom,
  spacing
}: SpacingClassnames) {
  return `ps-${spacingStart} pr-${spacingEnd} pt-${spacingTop} pb-${spacingBottom} p-${spacing} m-0`;
}
