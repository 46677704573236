import { useState, useCallback } from "react";
import { motion } from "framer-motion";

export type Tab = {
  name: string;
  component: React.FC;
};

export default function Tabs({
  tabs,
  layoutId
}: {
  tabs: Tab[];
  layoutId?: string;
}) {
  const [selectedTab, setSelectedTab] = useState(1);

  const handleTabSelection = useCallback((index: number) => {
    setSelectedTab(index);
  }, []);

  const SelectedTab = tabs[selectedTab].component;

  return (
    <>
      <div className="flex space-x-3">
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              onClick={() => handleTabSelection(index)}
              className={`${tab.name === tabs[selectedTab].name ? "text-white" : "text-black"} text-xs font-semibold relative flex items-center justify-center px-3 py-2 rounded-[8px] cursor-pointer select-none transition-all duration-300`}
            >
              <span className="z-1">{tab.name}</span>
              {tab.name === tabs[selectedTab].name && (
                <motion.span
                  layoutId={layoutId ?? "settingsTabIndicator"}
                  className="absolute inset-0 rounded-[8px] bg-brev dark:bg-gray-600/70"
                ></motion.span>
              )}
            </div>
          );
        })}
      </div>
      <SelectedTab />
    </>
  );
}
