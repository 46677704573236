import { Button } from "@/components/ui/button";
import { AuthContext } from "../Auth/AuthProvider";
import { useContext } from "react";

const LogoutButton = () => {
  const { logout } = useContext(AuthContext);
  return (
    <Button
      variant="negative"
      onClick={logout}
    >
      Logout
    </Button>
  );
};

export default LogoutButton;
