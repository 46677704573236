import { motion } from "framer-motion";
import { useEffect, useState, useContext } from "react";
import { ArtifactContext } from "../ArtifactProvider";
import { KPIData, TabularData, Tile } from "../../ReviewBuilder/review-types";
import EntityIcon from "@/components/Connector/components/EntityIcon";
import { IntegrationEntityModel } from "@/containers/Integrations/types";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { HiSparkles } from "react-icons/hi2";
// import Sparkle from "@/components/Icons/Sparkle";
export default function StreamingCards() {
  const { artifactTiles, kpiDatasets, chartDatasets } =
    useContext(ArtifactContext);

  const getLoadingMessage = () => {
    if (kpiDatasets.length && !chartDatasets.length && !artifactTiles.length) {
      return (
        <RotatingTexts
          prefixComponent={<>Calculating</>}
          texts={kpiDatasets.map((kpiDataset: KPIData) => kpiDataset.title)}
        />
      );
    } else if (chartDatasets.length && !artifactTiles.length) {
      return (
        <RotatingTexts
          prefixComponent={<>Generating</>}
          texts={chartDatasets.map(
            (chartDataset: TabularData) => chartDataset.title
          )}
        />
      );
    } else if (artifactTiles.length) {
      return (
        <RotatingTexts
          prefixComponent={<>Drafting</>}
          texts={artifactTiles.map((tile: Tile) => tile.title || "")}
        />
      );
    } else {
      return <PlaceholderCard prefix="Connecting to" />;
    }
  };

  return (
    <div className="flex justify-start items-center pl-3 pt-3 bg-light-main dark:bg-dark-main transition-colors duration-500 ease-in-out z-10 2xl:max-w-[1112px] h-200 rounded-md display-block max-h-[200px] gap-3">
      <motion.div className="relative h-6 w-6 bg-gradient-to-br from-brev-accent from-30% to-[#00FFB2] rounded-full">
        <BrevLoader
          type="puff"
          size={50}
          className="absolute top-[50%] -translate-x-1/4 -translate-y-1/2"
        />
        <motion.span className="absolute left-[50%] -translate-x-1/2 top-[50%] -translate-y-1/2">
          {/* <Sparkle size={20} isAnimated={true} /> */}
          <HiSparkles size={14} className="text-dark-text" />
        </motion.span>
      </motion.div>
      <GradientText>{getLoadingMessage()}</GradientText>
    </div>
  );
}

const PlaceholderCard = ({ prefix }: { prefix: string }) => {
  const { integrationEntities } = useContext(ArtifactContext);
  const [currentIndex, setCurrentIndex] = useState(0);

  const connectedEntities = integrationEntities.filter(
    (entity: IntegrationEntityModel) => entity.isConnected
  );

  useEffect(() => {
    if (connectedEntities.length === 0) return;

    const interval = setInterval(
      () => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % connectedEntities.length
        );
      },
      Math.random() * 3000 + 3000
    ); // Random interval between 3-5 seconds

    return () => clearInterval(interval);
  }, [connectedEntities]);

  if (connectedEntities.length === 0) return "Connecting to data sources...";

  return (
    <>
      <span className="flex gap-2 items-center">
        {prefix}{" "}
        <EntityIcon
          sourceType={connectedEntities[currentIndex].entityType}
          size={18}
        />
        <TypeInText text={connectedEntities[currentIndex].entityName + "..."} />
      </span>
    </>
  );
};

const RotatingTexts = ({
  prefixComponent,
  texts
}: {
  prefixComponent: React.ReactNode;
  texts: string[];
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (texts.length === 0) return;

    const interval = setInterval(
      () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      },
      Math.random() * 4000 + 1000
    ); // Random interval between 3-5 seconds

    return () => clearInterval(interval);
  }, [texts]);

  if (texts.length === 0) return "";

  return (
    <span className="flex gap-2 items-center w-full">
      {prefixComponent} <TypeInText text={texts[currentIndex]} />
    </span>
  );
};

const TypeInText = ({ text }: { text: string }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let charIndex = 0;
    const typingInterval = setInterval(() => {
      if (text && charIndex < text.length) {
        setDisplayedText(text.slice(0, charIndex + 1));
        charIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 10);

    return () => clearInterval(typingInterval);
  }, [text]);

  return <span className="font-bold">{displayedText}</span>;
};

const GradientText = ({ children }: { children: React.ReactNode }) => (
  <motion.span
    initial={{ backgroundPosition: "200% 50%" }}
    animate={{ backgroundPosition: "-100% 50%" }}
    transition={{
      repeat: Infinity,
      duration: 5,
      ease: "linear"
    }}
    style={{
      display: "inline-block",
      backgroundSize: "300% 100%",
      color: "transparent",
      WebkitBackgroundClip: "text",
      backgroundClip: "text"
    }}
    className={`bg-gradient-to-r from-dark-text/40 dark:from-light-text/40 from-40% dark:from-40% via-brev-accent dark:via-brev-accent to-60% dark:to-60% to-dark-text/40 dark:to-light-text/40`}
  >
    {children}
  </motion.span>
);
