import { Button } from "@/components/ui/button";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { useCallback, useContext, useEffect, useState } from "react";
import { CgCheck, CgLink, CgSpinner, CgTrash } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { ArtifactContext } from "../ArtifactProvider";
import { ArtifactPublishModel } from "../types";

export default function PublishButton() {
  const { artifactId } = useParams<{ artifactId: string }>();
  const { sendApiRequest } = useContext(AuthContext);
  const { artifactPublishes, setArtifactPublishes, isArtifactGenerating } =
    useContext(ArtifactContext);
  const [artifactPublishId, setArtifactPublishId] = useState<string | null>(null);
  const [linkCopied, setLinkCopied] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isUnpublishing, setIsUnpublishing] = useState(false);

  useEffect(() => {
    if (artifactPublishes.length > 0) {
      setArtifactPublishId(artifactPublishes[0].publishId);
    }
  }, [artifactPublishes]);

  const publishArtifactAndCopyUrl = useCallback(async () => {
    setIsPublishing(true);
    let url;
    if (artifactPublishes.length === 0) {
      const response = (await sendApiRequest("/artifact-publish", "POST", {
        artifactId
      })) as { data: { artifactPublishes: ArtifactPublishModel[] } };
      setArtifactPublishes([...response.data.artifactPublishes]);
      url = `${window.location.origin}/artifacts/publish/${response.data.artifactPublishes[0].publishId}`;
    } else {
      url = `${window.location.origin}/artifacts/publish/${artifactPublishes[0].publishId}`;
    }
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
    setIsPublishing(false);
    setLinkCopied(true);
  }, [artifactId, artifactPublishes, sendApiRequest, setArtifactPublishes]);

  const isPublishButtonDisabled =  isArtifactGenerating || !artifactId;

  return (
    <div>
      <Button
        variant="ghost"
        className={`justify-start ${linkCopied ? "text-brev-accent dark:text-brev-accent hover:text-brev-accent" : "text-dark-text"} transition-colors`}
        onClick={publishArtifactAndCopyUrl}
        disabled={isPublishButtonDisabled || isPublishing}
        data-cy="publishButton"
      >
        {linkCopied ? (
          <CgCheck className="mr-2 bg-green-600 rounded-full p-1.5 text-white h-6 w-6" />
        ) : isPublishing ? (
          <CgSpinner className="mr-2 bg-brev-accent rounded-full p-1.5 text-white h-6 w-6" />
        ) : (
          <CgLink className="mr-2 bg-[#0676C7] rounded-full p-1.5 text-white h-6 w-6" />
        )}
        {artifactPublishId ? "Copy link" : "Create link"}
      </Button>
      {artifactPublishes.length > 0 && (
        <Button
          variant="ghost"
          className="text-dark-text justify-start"
          onClick={async () => {
            setIsUnpublishing(true);
            await sendApiRequest(
              `/artifact-publish/${artifactPublishes[0].publishId}`,
              "DELETE"
            );
            setArtifactPublishes((prev) =>
              prev.filter(
                (publish) =>
                  publish.publishId !== artifactPublishes[0].publishId
              )
            );
            setArtifactPublishId(null);
            setLinkCopied(false);
            setIsUnpublishing(false);
          }}
          data-cy="unpublishButton"
          disabled={isUnpublishing}
        >
          {isUnpublishing ? (
            <CgSpinner className="mr-2 bg-brev-accent rounded-full p-1.5 text-white h-6 w-6" />
          ) : (
            <CgTrash className="mr-2 bg-red-600 rounded-full p-1.5 text-white h-6 w-6" />
          )}
          Unpublish link
        </Button>
      )}
    </div>
  );
}
