import { cn } from "@/lib/utils";

type InfoSvgProps = {
  size?: number;
  className?: string;
};

export default function InfoSvg({ size, className }: InfoSvgProps) {
  return (
    <svg
      width={size || 9}
      height={size || 9}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("", className)}
    >
      <circle
        cx="4.43175"
        cy="4.43175"
        r="4.06243"
        fill="url(#paint0_linear_9211_158)"
        stroke="black"
        strokeWidth="0.738624"
      />
      <path
        d="M4.55556 3.11111C4.40278 3.11111 4.27204 3.05676 4.16333 2.94806C4.05463 2.83935 4.00019 2.70852 4 2.55556C3.99982 2.40259 4.05426 2.27185 4.16333 2.16334C4.27241 2.05482 4.40315 2.00037 4.55556 2C4.70796 1.99963 4.8388 2.05408 4.94806 2.16334C5.05731 2.27259 5.11167 2.40334 5.11111 2.55556C5.11056 2.70778 5.0562 2.83861 4.94806 2.94806C4.83991 3.0575 4.70907 3.11185 4.55556 3.11111ZM4.38889 7C4.25082 7 4.13889 6.88807 4.13889 6.75V3.91667C4.13889 3.7786 4.25082 3.66667 4.38889 3.66667H4.72222C4.86029 3.66667 4.97222 3.7786 4.97222 3.91667V6.75C4.97222 6.88807 4.86029 7 4.72222 7H4.38889Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9211_158"
          x1="4.43175"
          y1="0"
          x2="4.43175"
          y2="8.86349"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC700" />
          <stop offset="1" stopColor="#F2DB8B" />
        </linearGradient>
      </defs>
    </svg>
  );
}
