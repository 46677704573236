import { useEffect, useContext, useCallback } from "react";
import { ArtifactContext } from "../ArtifactProvider";
import ArtifactGrid from "./ArtifactGrid";
import {
  Tile,
  TileType,
  ChartTile,
  KPITile,
  KPIData,
  TabularData
} from "@/containers/ReviewBuilder/review-types";
import { Layout } from "react-grid-layout";
import { getTileConstraints, getDefaultTileDimensions } from "./gridUtils";
import { VisualType } from "@/containers/ReviewBuilder/review-types";
import StreamingCards from "./StreamingCards";
import { IntegrationConnectionsProvider } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { v4 as uuidv4 } from "uuid";
import { LoadingSequence } from "./ArtifactSkeleton";

const sortDatasets = <T extends { title: string }>(
  tiles: Array<{ content: T }>
) => {
  const uniqueMap = new Map<string, T>();
  tiles.forEach((tile) => {
    uniqueMap.set(tile.content.title.toLowerCase(), tile.content);
  });
  return Array.from(uniqueMap.values()).sort((a, b) =>
    a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
  );
};

export default function ArtifactContainerV4() {
  const {
    artifactContent,
    saveArtifact,
    artifactLayout,
    artifactTiles,
    setArtifactLayout,
    setArtifactTiles,
    kpiDatasets,
    setKpiDatasets,
    chartDatasets,
    setChartDatasets,
    isArtifactGenerating,
    setIsArtifactGenerating,
    sectionOutlines,
    setSectionOutlines
  } = useContext(ArtifactContext);

  useEffect(() => {
    if (artifactContent && isArtifactGenerating) {
      try {
        const parsedContent = JSON.parse(artifactContent);
        if (parsedContent && parsedContent.data) {
          if (parsedContent.data.chart_tiles) {
            const chartTilesData = parsedContent.data.chart_tiles;
            setChartDatasets(
              chartTilesData.map((tile: ChartTile) => tile.content)
            );
          }

          if (parsedContent.data.section_outlines) {
            setSectionOutlines(parsedContent.data.section_outlines);
          }

          if (parsedContent.data.kpi_tiles) {
            const kpiTilesData = parsedContent.data.kpi_tiles;
            setKpiDatasets(kpiTilesData.map((tile: KPITile) => tile.content));
          }

          if (parsedContent.data.styled_tiles) {
            const uniqueTilesMap = new Map();

            parsedContent.data.styled_tiles.forEach((tile: Tile) => {
              uniqueTilesMap.set(tile.id, tile);
            });

            const uniqueStyledTiles = Array.from(uniqueTilesMap.values());

            setArtifactTiles(uniqueStyledTiles);
            setIsArtifactGenerating(false);

            if (parsedContent.data.chart_tiles) {
              const sortedChartDatasets = sortDatasets<TabularData>(
                parsedContent.data.chart_tiles.map((tile: ChartTile) => ({
                  content: tile.content
                }))
              );
              setChartDatasets(sortedChartDatasets);
            }

            if (parsedContent.data.kpi_tiles) {
              const sortedKpiDatasets = sortDatasets<KPIData>(
                parsedContent.data.kpi_tiles.map((tile: KPITile) => ({
                  content: tile.content
                }))
              );
              setKpiDatasets(sortedKpiDatasets);
            }
          }
        }
      } catch (error) {
        console.error("Error parsing artifact content:", error);
      }
    }
  }, [
    artifactContent,
    setArtifactTiles,
    setChartDatasets,
    setIsArtifactGenerating,
    setKpiDatasets,
    isArtifactGenerating,
    setSectionOutlines
  ]);

  const addNewTile = useCallback(
    (tileType: TileType, layout: Layout) => {
      const newTileId = uuidv4();

      if (artifactTiles.some((tile) => tile.id === newTileId)) {
        console.error("Tile id already exists.");
        return;
      }

      const constraints = getTileConstraints(tileType);
      const defaultDimensions = getDefaultTileDimensions(tileType);
      let newTile: Tile;

      switch (tileType) {
        case TileType.KPI:
          newTile = {
            id: newTileId,
            tile_type: TileType.KPI,
            content: {
              title: "New KPI Title",
              value: "$10000",
              // previous_value: 5000,
              period: "since last week"
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        case TileType.TEXT:
          newTile = {
            id: newTileId,
            tile_type: TileType.TEXT,
            content: {
              title: "New Text Tile",
              markdown_content: "New content"
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        case TileType.GOAL:
          newTile = {
            id: newTileId,
            tile_type: TileType.GOAL,
            content: {
              title: "New Goal Title",
              goal_value: 10,
              current_value: 0,
              unit: "points"
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        case TileType.CHART:
          newTile = {
            id: newTileId,
            tile_type: TileType.CHART,
            content: {
              title: "New Chart Title",
              data_description: "New Chart Description",
              visual_type: VisualType.BAR,
              col_headers: ["Column 1", "Column 2", "Column 3"],
              row_headers: ["Row 1", "Row 2", "Row 3"],
              rows: [
                ["A", "10"],
                ["B", "15"],
                ["C", "20"]
              ]
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        case TileType.HEADING:
          newTile = {
            id: newTileId,
            tile_type: TileType.HEADING,
            content: {
              title: "New Heading Title",
              markdown_content: ""
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        case TileType.EMBED:
          newTile = {
            id: newTileId,
            tile_type: TileType.EMBED,
            content: {
              title: "Embedded Content Title",
              embed_url: ""
            },
            width: defaultDimensions.width,
            height: defaultDimensions.height
          };
          break;
        default:
          throw new Error("Invalid tile type");
      }

      const newLayout: Layout = {
        ...layout,
        i: newTileId,
        w: defaultDimensions.width,
        h: defaultDimensions.height,
        ...constraints
      };

      const updatedTiles = [...artifactTiles, newTile];
      const updatedLayout = [...artifactLayout, newLayout];

      setArtifactTiles(updatedTiles);
      setArtifactLayout(updatedLayout);

      saveArtifact(
        {
          artifactLayout: updatedLayout,
          artifactTiles: JSON.stringify(updatedTiles)
        },
        true
      );
    },
    [
      artifactTiles,
      artifactLayout,
      setArtifactTiles,
      setArtifactLayout,
      saveArtifact
    ]
  );

  return (
    <>
      {isArtifactGenerating && sectionOutlines.length > 0 ? (
        <div className="flex my-5 gap-6 mx-auto max-w-[1080px] 2xl:max-w-[1200px] -translate-x-[34px]">
          <div className="w-12 flex-shrink-0 relative overflow-visible z-[100]" />
          <div className="flex flex-col gap-4 justify-start items-start w-full">
            <div className="sticky top-[68px] z-40 w-full bg-light-main dark:bg-dark-main pb-4">
              <StreamingCards />
            </div>
            <div className="w-full">
              <LoadingSequence
                sectionOutlines={sectionOutlines}
                kpiDatasets={kpiDatasets}
                chartDatasets={chartDatasets}
              />
            </div>
          </div>
        </div>
      ) : isArtifactGenerating ? (
        <div className="flex my-5 gap-6 mx-auto max-w-[1080px] 2xl:max-w-[1200px] -translate-x-[34px]">
          <div className="w-12 flex-shrink-0 relative overflow-visible z-[100]" />
          <div className="sticky top-[72px] z-40 w-full bg-light-main dark:bg-dark-main">
            <StreamingCards />
          </div>
        </div>
      ) : (
        <IntegrationConnectionsProvider>
          <ArtifactGrid onAddNewTile={addNewTile} />
        </IntegrationConnectionsProvider>
      )}
    </>
  );
}
