import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { API } from "aws-amplify";
import { useState } from "react";
import { ConnectorDataEntity, ResourceModel } from "../types";
import EntityPicker from "../components/EntityPicker";
import { useToast } from "@/components/ui/use-toast";

export default function InternalPicker() {
  const [mockUserId, setMockUserId] = useState<string>("");
  const [_resources, setResources] = useState<ResourceModel[]>([]);
  const [dataEntities, setDataEntities] = useState<ConnectorDataEntity[]>([]);
  const { toast } = useToast();
  return (
    <div>
      <div className="flex items-center justify-around">
        <Input
          placeholder="Enter User ID"
          value={mockUserId}
          onChange={async (e) => {
            setMockUserId(e.target.value);
          }}
        />
        <Button
          onClick={async () => {
            const resourcesData = await API.get(
              "reviews",
              `/resources?mockUserId=${mockUserId}`,
              {
                timeout: 90000
              }
            );
            setResources(resourcesData.resources);
            const dataEntities = resourcesData.resources.map(
              (r: ResourceModel) => {
                return {
                  id: r.resourceId,
                  name: r.resourceName,
                  sourceType: r.resourceSource.sourceType,
                  // connected: resourcesMap.has(r.resourceId),
                  resourceModel: r
                };
              }
            );
            setDataEntities(dataEntities);
          }}
        >
          Send
        </Button>
      </div>
      <EntityPicker
        connectedEntities={dataEntities}
        onEntityPressed={async (_entity: ConnectorDataEntity) => {
          // const resource = entity.resourceModel!;
          // await addResource(resource);
          toast({
            title: "Success",
            description: "Files selected successfully."
          });
        }}
      />
    </div>
  );
}
