export enum EmbedType {
  NONE = "none",
  IMAGE = "image",
  EMBED = "embed",
  IFRAME = "iframe",
  TABLEAU = "tableau"
}

export interface RenderProps {
  url: string;
  onLoad: () => void;
  onError: () => void;
}

export const MAX_WIDTH = 1050;
export const DEFAULT_ASPECT_RATIO = 56.25; // 16:9 aspect ratio

export const TABLEAU_PLACEHOLDER = "tableauPlaceholder";
