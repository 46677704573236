import NewArtifactButton from "@/components/SidePane/NewArtifactButton";
import LayoutContainer from "../Layout/LayoutContainer";
import GenerateArrowSvg from "../../components/Icons/GenerateArrowSvg";
import { motion } from "framer-motion";
import { Input } from "@/components/ui/input";
// import { FaPlus } from "react-icons/fa";
import { HiSparkles } from "react-icons/hi2";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { useContext, useEffect } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import config from "@/config";

export default function ArtifactHome() {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user && (!user.firstName || !user.companyName || !user.jobTitle)) {
      window.location.href = `${config.env.REDIRECT_URL_ROOT}/onboarding`;
    }
  }, [user]);

  return (
    <LayoutContainer>
      <div className="relative w-full md:max-w-[940px] mx-auto px-10 bg-light-main dark:bg-dark-main rounded-[8px] transition-colors duration-500 ease-in-out">
        <Input
          outerClassName="w-full"
          variant="title"
          fontSize="title"
          inputClassName="rounded-none"
          className="mt-10 resize-none w-full outline-none font-bold placeholder:text-[#BCBEC6] no-scrollbar bg-transparent transition-colors duration-500 ease-in-out border-none"
          placeholder="Untitled Artifact"
          autoFocus={true}
        />
        <div className="absolute top-[12rem] left-[3rem]">
          <NewArtifactButton asChild>
            <motion.div className="relative h-6 w-6 bg-gradient-to-br from-brev-accent from-30% to-[#00FFB2] rounded-full flex items-center justify-center">
              <BrevLoader
                type="puff"
                size={50}
                className="absolute -translate-x-1/2"
              />
              <motion.span className="absolute">
                <HiSparkles size={14} className="text-dark-text" />
              </motion.span>
            </motion.div>
          </NewArtifactButton>
          <GenerateArrowSvg className="ml-3 mt-4" />
        </div>
      </div>
    </LayoutContainer>
  );
}
