import React from "react";
import BrevLogo from "@/components/ui/brevlogo";
import { Link } from "react-router-dom";
import SharedNavMenu from "./SharedNavMenu";
import { Button } from "@/components/ui/button";

interface SharedArtifactContainerProps {
  children: React.ReactNode;
}

const SharedArtifactContainer = ({
  children
}: SharedArtifactContainerProps) => {
  return (
    <div className="flex flex-col h-screen">
      <div className="relative w-screen h-12 sm:h-20 bg-brev">
        <Link to="/home" className="text-white no-underline">
          <div className={`transition-all duration-300 ease-in-out relative`}>
            <div
              className={`absolute top-3 left-3 sm:top-8 sm:left-8 transition-all duration-300 ease-in-out`}
            >
              <BrevLogo dark />
            </div>
            <span className="absolute top-3 right-3 sm:top-8 sm:right-8 transition-all">
              <Link to="/login">
                <Button
                  variant="gradient"
                  className="rounded-[8px] text-sm relative z-10 hover:scale-[103%] active:scale-100 transition-all duration-100 ease-in-out"
                >
                  Try Brev
                </Button>
              </Link>
            </span>
          </div>
        </Link>
      </div>
      <SharedNavMenu>{children}</SharedNavMenu>
    </div>
  );
};

export default SharedArtifactContainer;
