import { ArtifactModel } from "../types";
import { useNavigate } from "react-router-dom";
import { IoDocumentTextOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { GlobalContext } from "@/GlobalProvider";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { ArtifactContext } from "../ArtifactProvider";
import useTypingEffect from "@/hooks/useTypingEffect";
import { PiSlideshow } from "react-icons/pi";
import ConfirmDialog from "./ConfirmDialog";

export default function RecentArtifact({
  artifact,
  deleteArtifact
}: {
  artifact?: ArtifactModel;
  deleteArtifact: (artifactId: string) => void;
}) {
  const navigate = useNavigate();
  const { artifactId } = useParams<{ artifactId: string }>();
  const { sendApiRequest } = useContext(AuthContext);
  const { artifactTitle, setArtifactTitle } = useContext(GlobalContext);
  const [entryArtifactTitle, setEntryArtifactTitle] = useState(
    artifact?.artifactTitle || "Untitled Artifact"
  );
  const [isDeletionPending, setIsDeletionPending] = useState(false);
  const { isArtifactLoading } = useContext(ArtifactContext);
  const [shouldStream, setShouldStream] = useState(false);
  const prevTitleRef = useRef(entryArtifactTitle);

  const displayedText = useTypingEffect(entryArtifactTitle, shouldStream, 50);

  useEffect(() => {
    if (
      artifactTitle &&
      artifactTitle !== entryArtifactTitle &&
      artifactId === artifact?.artifactId &&
      !isArtifactLoading
    ) {
      const timer = setTimeout(() => {
        if (prevTitleRef.current !== artifact?.artifactTitle) {
          setShouldStream(true);
        } else {
          setShouldStream(false);
        }
        setEntryArtifactTitle(artifactTitle);
        prevTitleRef.current = artifactTitle;
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [
    artifactTitle,
    entryArtifactTitle,
    artifactId,
    artifact?.artifactId,
    isArtifactLoading,
    artifact?.artifactTitle
  ]);

  const handleDeleteArtifact = async () => {
    setIsDeletionPending(true);
    await sendApiRequest(`/artifacts/${artifact?.artifactId}`, "DELETE");
    setIsDeletionPending(false);
    deleteArtifact(artifact?.artifactId ?? "");
    setArtifactTitle("");
    if (artifactId === artifact?.artifactId) {
      navigate(`/home`);
    }
  };

  return (
    <div
      className={`group ${artifact?.artifactId === artifactId ? "bg-light-hover dark:bg-dark-hover" : "bg-light-alt dark:bg-dark-alt"} relative flex rounded-[8px] cursor-pointer hover:bg-light-hover dark:hover:bg-dark-hover text-xs items-center transition-colors duration-500 ease-in-out`}
      onClick={() => {
        navigate(`/artifacts/${artifact?.artifactId}`);
        setArtifactTitle(artifact?.artifactTitle || "");
      }}
    >
      <div
        className="flex px-2 py-1.5 gap-2 items-center overflow-hidden"
        data-cy={`recent-artifact`}
        data-recent-artifact-id={`${artifact?.artifactId}`}
      >
        {artifact?.artifactMetadata?.format === "Presentation" ? (
          <PiSlideshow
            className="text-2xl min-w-3 text-dark-text dark:text-brev-light"
            size={12}
          />
        ) : (
          <IoDocumentTextOutline
            className="text-2xl min-w-3 text-dark-text dark:text-brev-light"
            size={12}
          />
        )}
        <span className="truncate text-dark-text dark:text-[#BCBEC6]">
          {displayedText}
        </span>
      </div>

      <div onClick={(e) => e.stopPropagation()}>
        <ConfirmDialog
          isLoading={isDeletionPending}
          onDelete={handleDeleteArtifact}
          itemToDelete={entryArtifactTitle}
        />
      </div>
    </div>
  );
}
