import Tile from "../../Tile/Tile";
import Mysql from "@/images/mysql.svg";

export default function MysqlConnector() {
  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "SNOWFLAKE"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  const authenticateClient = () => {};

  return (
    <Tile
      disabled={true}
      onPress={authenticateClient}
      label="MySQL"
      src={Mysql}
    />
  );
}
