import NewArtifactButton from "./NewArtifactButton";
import { RecentArtifacts } from "@/containers/ArtifactBuilder/RecentArtifacts/RecentArtifacts";

export default function ArtifactSubMenu() {
  return (
    <div className="flex flex-col flex-shrink-0 w-full min-w-0 transition-all duration-500 ease-in-out">
      <NewArtifactButton />
      <div className="px-3">
        <RecentArtifacts />
      </div>
    </div>
  );
}
