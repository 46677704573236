import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loading from "@/components/ui/loading";
import { ArtifactPublishModel } from "../ArtifactBuilder/types";
import { Helmet } from "react-helmet";
import axios from "axios";
import config from "@/config";
import MemoizedUserCard from "@/components/Card/UserCard";
import { useEditor } from "@tiptap/react";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { BulletListExtension } from "../ArtifactBuilder/ArtifactCanvas/TipTapExtensions/BulletListExtension";
import { SharedChartExtension } from "../ArtifactBuilder/ArtifactCanvas/TipTapExtensions/SharedChartExtension";
import { CitationExtensionWithoutContext } from "../ArtifactBuilder/ArtifactCanvas/TipTapExtensions/CitationExtensionWithoutContext";
import ReactDOM from "react-dom";
import StarterKit from "@tiptap/starter-kit";
import SharedArtifactContainer from "./SharedArtifactContainer";
import BadgeButton from "@/components/Buttons/BadgeButton";
import { LuCalendarDays } from "react-icons/lu";
// import SharedArtifactGrid from "./SharedArtifactGrid";
import type { Tile } from "@/containers/ReviewBuilder/review-types";
// import { Card, CardContent } from "@/components/ui/card";
// import GridTile from "../ArtifactBuilder/ArtifactCanvas/GridTiles/GridTile";
// import { TileType } from "@/containers/ReviewBuilder/review-types";
// import TileSources from "../ArtifactBuilder/ArtifactCanvas/GridTiles/TileSources";
import { Responsive, WidthProvider, Layout } from "react-grid-layout";
import {
  // GRID_COLS,
  ROW_HEIGHT
} from "../ArtifactBuilder/ArtifactCanvas/gridUtils";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import GridCard from "../ArtifactBuilder/ArtifactCanvas/GridTiles/GridCard";

const ResponsiveGridLayout = WidthProvider(Responsive);

const createResponsiveLayout = (baseLayout: Layout[], tiles: Tile[]) => {
  const sortedLayout = [...baseLayout].sort((a, b) => {
    if (a.y === b.y) {
      return a.x - b.x;
    }
    return a.y - b.y;
  });

  let currentY = 0;

  return sortedLayout.map((item) => {
    const tile = tiles.find((t) => t.id.toString() === item.i);
    const newItem = { ...item };

    newItem.w = 6;
    newItem.x = 0;

    if (tile) {
      if (tile.tile_type === "text") {
        newItem.h = Math.ceil(item.h * 1.2);
      } else if (tile.tile_type === "embed") {
        newItem.h = Math.max(4, Math.floor(item.h * 0.7));
      }
    }

    newItem.y = currentY;
    currentY += newItem.h;

    return newItem;
  });
};

const estimateReadingTime = (content: string) => {
  const wordsPerMinute = 200;
  const wordCount = content.trim().split(/\s+/).length;
  return Math.ceil(wordCount / wordsPerMinute);
};

const PublicArtifactViewer: React.FC = () => {
  const { publishId } = useParams<{ publishId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [artifactPublish, setArtifactPublish] = useState<
    ArtifactPublishModel | undefined
  >(undefined);
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [author, setAuthor] = useState({
    firstName: "",
    lastName: "",
    profilePictureUrl: ""
  });
  const editor = useEditor({
    extensions: [
      StarterKit,
      Table,
      TableCell,
      TableHeader,
      TableRow,
      BulletListExtension,
      SharedChartExtension,
      CitationExtensionWithoutContext
    ],
    editable: false,
    content: artifactPublish?.publishContent ?? "",
    editorProps: {
      attributes: {
        class:
          "p-2 prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl m-1 focus:outline-none"
      }
    }
  });
  const format = artifactPublish?.publishMetadata?.format || "";
  const audience = artifactPublish?.publishMetadata?.audience || "";
  const startAt = artifactPublish?.publishMetadata?.startAt
    ? new Date(artifactPublish.publishMetadata.startAt)
    : undefined;
  const endAt = artifactPublish?.publishMetadata?.endAt
    ? new Date(artifactPublish.publishMetadata.endAt)
    : undefined;

  useEffect(() => {
    async function fetchArtifact() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios({
          method: "GET",
          url: `${config.apiGateway.V3_API_URL}/artifact-publish/${publishId}`,
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.data && response.data.artifactPublish) {
          setArtifactPublish(response.data.artifactPublish);
          setAuthor(response.data.user);
          setTimeout(() => {
            ReactDOM.flushSync(() => {
              if (editor) {
                editor.commands.setContent(
                  response.data.artifactPublish.publishContent
                );
              }
            });
          }, 0);
        } else {
          setError("Artifact not found or no longer available");
        }
      } catch (err) {
        console.error("Error fetching artifact:", err);
        setError(
          "An error occurred while fetching the artifact. It may no longer be available."
        );
      } finally {
        setIsLoading(false);
      }
    }
    fetchArtifact();
  }, [editor, publishId]);

  useEffect(() => {
    if (artifactPublish?.publishTiles) {
      const tiles = JSON.parse(artifactPublish.publishTiles) as Tile[];
      if (tiles) {
        setTiles(tiles);
      }
    }
  }, [artifactPublish?.publishTiles]);

  if (isLoading) {
    return (
      <div className="absolute top-[50%] left-[50%]">
        <Loading />
      </div>
    );
  }

  if (error) {
    return (
      <SharedArtifactContainer>
        <div className="flex flex-col items-center justify-center h-screen w-screen">
          <h1 className="text-2xl font-bold mb-4">There's nothing here!</h1>
          <p className="text-center">{error}</p>
        </div>
      </SharedArtifactContainer>
    );
  }

  if (!artifactPublish) {
    return (
      <SharedArtifactContainer>
        <div className="flex flex-col items-center justify-center h-screen w-screen">
          <h1 className="text-2xl font-bold mb-4">Artifact Not Found</h1>
          <p className="text-center">
            The requested artifact is not available or may have been removed.
          </p>
        </div>
      </SharedArtifactContainer>
    );
  }

  const stripHtml = (html: string | undefined, maxLength: number) => {
    const content = html || "no content";
    const strippedText = content.replace(/<[^>]+>/g, "");
    return strippedText.length > maxLength
      ? strippedText.substring(0, maxLength) + "..."
      : strippedText;
  };

  return (
    <div className="bg-blue-200">
      <Helmet>
        <meta
          name="description"
          content={stripHtml(artifactPublish.publishContent, 160)}
        />
        <title>{artifactPublish.publishTitle}</title>

        {/* Open Graph tags */}
        <meta
          property="og:title"
          content={artifactPublish.publishTitle?.replace(/<[^>]+>/g, "")}
        />
        <meta
          property="og:description"
          content={stripHtml(artifactPublish.publishContent, 160)}
        />
        <meta
          property="og:url"
          content={`${config.domain}/artifacts/publish/${artifactPublish.publishId}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Brev.io" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={artifactPublish.publishTitle?.replace(/<[^>]+>/g, "")}
        />
        <meta
          name="twitter:description"
          content={stripHtml(artifactPublish.publishContent, 160)}
        />

        <meta name="twitter:label1" content="Written by" />
        <meta
          name="twitter:data1"
          content={`${author.firstName} ${author.lastName}`}
        />
        <meta name="twitter:label2" content="Reading time" />
        <meta
          name="twitter:data2"
          content={`${estimateReadingTime(artifactPublish.publishContent)} min read`}
        />
      </Helmet>
      <SharedArtifactContainer>
        <div className="flex flex-col gap-2 sm:gap-3 w-full md:w-[1200px] mx-auto mt-2 sm:mt-10 rounded-[8px] overflow-y-scroll no-scrollbar">
          <div className="flex items-start sm:justify-start flex-col sm:flex-row gap-2 sm:gap-4 p-1">
            <MemoizedUserCard
              firstName={author.firstName}
              lastName={author.lastName}
              email="Owner"
              profilePictureUrl={author.profilePictureUrl}
            />
            <div className="sm:ml-auto flex flex-wrap justify-start gap-2 sm:gap-3 items-center py-1">
              <BadgeButton data={format} />
              <BadgeButton data={audience} />
              <BadgeButton
                icon={<LuCalendarDays className="mt-0.5" size={14} />}
                data={
                  startAt && endAt
                    ? `${startAt.toLocaleDateString(undefined, { month: "short", day: "numeric" })} - ${endAt.toLocaleDateString(undefined, { month: "short", day: "numeric" })}`
                    : ""
                }
              />
              {/* <BadgeButton data="Source Data" /> */}
            </div>
          </div>

          <div className="flex flex-col h-20 w-full text-dark-text sticky top-0 z-50 bg-light-main">
            <div className="flex items-center justify-start text-[1.5rem] sm:text-[1.75rem] md:text-[2rem] font-bold">
              {artifactPublish.publishTitle}
            </div>
            <span className="text-[0.5rem] w-full">
              Last updated:{" "}
              {new Date(
                artifactPublish.updateAt || artifactPublish.createdAt
              ).toDateString()}
            </span>
          </div>

          <div className="p-2 w-full">
            {/* {format === "Document" && editor && (
              <EditorContent editor={editor}></EditorContent>
            )} */}
            {format.toLocaleLowerCase().startsWith("document") &&
              artifactPublish.publishLayout && (
                <ResponsiveGridLayout
                  className="layout"
                  layouts={{
                    lg: createResponsiveLayout(
                      artifactPublish.publishLayout,
                      tiles
                    ),
                    md: artifactPublish.publishLayout,
                    sm: createResponsiveLayout(
                      artifactPublish.publishLayout,
                      tiles
                    ),
                    xs: createResponsiveLayout(
                      artifactPublish.publishLayout,
                      tiles
                    ),
                    xxs: createResponsiveLayout(
                      artifactPublish.publishLayout,
                      tiles
                    )
                  }}
                  breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                  cols={{
                    lg: 12,
                    md: 12,
                    sm: 6,
                    xs: 6,
                    xxs: 6
                  }}
                  rowHeight={ROW_HEIGHT}
                  width={1200}
                  margin={[10, 10]}
                  containerPadding={[0, 0]}
                  isDraggable={false}
                  isResizable={false}
                  isDroppable={false}
                  preventCollision={true}
                  draggableHandle=""
                  draggableCancel=".react-grid-item"
                  compactType="vertical"
                  useCSSTransforms={true}
                >
                  {tiles.map((tile) => {
                    return (
                      <div
                        key={tile.id.toString()}
                        className={`h-full w-full transition-all duration-200 rounded-xl focus:ring-1 focus:ring-brev-active focus:shadow-lg`}
                        tabIndex={0}
                      >
                        <GridCard
                          tile={tile}
                          isEditMode={false}
                          isDragging={false}
                          isActive={false}
                          readonly
                        />
                      </div>
                    );
                  })}
                </ResponsiveGridLayout>
              )}
          </div>
        </div>
      </SharedArtifactContainer>
    </div>
  );
};

export default PublicArtifactViewer;
