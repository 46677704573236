import { Button } from "@/components/ui/button";
import { TabProps } from "./types";
import { FaChevronLeft } from "react-icons/fa6";
import ContextContainer from "@/containers/ContextBase/ContextBaseContainer";
import { useContext, useEffect, useRef } from "react";
import { WebSocketContext } from "@/containers/WebSocket/WebSocketProvider";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { ArtifactSettingsContext } from "./ArtifactSettingsProvider";
import { useParams } from "react-router-dom";
import { ArtifactContext } from "../ArtifactProvider";
import { MessageAction } from "@/containers/WebSocket/WebSocketManager";
import { ArtifactTemplateModel } from "../types";

export default function ConnectTab({ handleTabSelection }: TabProps) {
  const { artifactId } = useParams();
  const { userId } = useContext(AuthContext);
  const { webSocketManager, isConnectionOpen } = useContext(WebSocketContext);
  const templateCandidatesRequested = useRef<boolean>(false);
  const { llmModelValue } = useContext(ArtifactContext);
  const { artifactTemplates, setArtifactTemplates } = useContext(ArtifactSettingsContext);
  useEffect(() => {
    if (
      isConnectionOpen &&
      artifactTemplates.length < 1 &&
      !templateCandidatesRequested.current
    ) {
      templateCandidatesRequested.current = true;
      webSocketManager?.sendMessage({
        action: "generateArtifactTemplateCandidates",
        data: {
          userId,
          artifactId,
          llmModel: llmModelValue
        }
      });
    }
  }, [
    artifactId,
    artifactTemplates.length,
    isConnectionOpen,
    llmModelValue,
    userId,
    webSocketManager
  ]);

  useEffect(() => {
    if (!webSocketManager || !isConnectionOpen) {
      return;
    }
    webSocketManager.addListener(
      MessageAction.generateArtifactTemplateCandidatesComplete,
      (data) => {
        const { artifactTemplateModels } = data as {
          artifactTemplateModels: ArtifactTemplateModel[];
        };
        setArtifactTemplates((prev) => [...artifactTemplateModels, ...prev]);
      }
    );
    return () => {
      webSocketManager.removeListener(
        MessageAction.generateArtifactTemplateCandidatesComplete
      );
    };
  }, [
    artifactTemplates,
    isConnectionOpen,
    setArtifactTemplates,
    webSocketManager
  ]);
  return (
    <div className="relative flex flex-shrink-0 gap-3 h-full bg-brev-light dark:bg-dark-alt transition-colors duration-500 ease-in-out">
      <ContextContainer />
      <div className="flex gap-2 absolute bottom-5 right-5 ">
        <Button
          variant="link"
          type="submit"
          className="h-[28px] w-[37px] rounded-[8px] p-0"
          onClick={() => handleTabSelection(0)}
        >
          <FaChevronLeft />
        </Button>
        <Button
          variant="gradient"
          type="submit"
          disabled={!isConnectionOpen}
          onClick={() => {
            handleTabSelection(2);
          }}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
