import Loading from "@/components/ui/loading";
import { Suspense, useCallback, useContext, useEffect, useState } from "react";
import EntityPicker from "./components/EntityPicker";
import { useToast } from "@/components/ui/use-toast";
import { ConnectorDataEntity, IntegrationObject } from "./types";
import { ArtifactSettingsContext } from "@/containers/ArtifactBuilder/ArtifactSettings/ArtifactSettingsProvider";
import { useParams } from "react-router-dom";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { IntegrationApp, IntegrationConnection, IntegrationEntityModel } from "@/containers/Integrations/types";

type IntegrationObjectPickerProps = {
  title: string;
  placeholder: string;
  ctaName: string;
  integrationApp: IntegrationApp;
  queryTypes?: string[];
  defaultQueryType?: string;
  fetchOnSearch?: boolean;
  onFailure?: () => void;
  onSuccess?: () => void;
  onSubmit?: () => void;
  onRemove?: (integrationId: string) => void;
};

function IntegrationObjectPicker({
  title,
  placeholder,
  ctaName,
  integrationApp,
  queryTypes,
  defaultQueryType,
  fetchOnSearch,
  onRemove,
}: IntegrationObjectPickerProps) {
  const { sendApiRequest } = useContext(AuthContext);
  const { isAppConnected, getAppConnection } = useContext(IntegrationConnectionsContext);
  const [dataEntities, setDataEntities] = useState<ConnectorDataEntity[]>([]);
  const { artifactId } = useParams<{ artifactId: string }>();
  const { integrationEntities, setIntegrationEntities } = useContext(
    ArtifactSettingsContext
  );
  const [integrationConnection, setIntegrationConnection] = useState<IntegrationConnection | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { toast } = useToast();

  useEffect(() => {
    const integrationConnection = getAppConnection(integrationApp) as IntegrationConnection;
    setIntegrationConnection(integrationConnection);
  }, [getAppConnection, integrationApp]);

  const fetchObjects = useCallback(async (query?: string, queryType?: string) => {
    console.log("Fetching objects", query, queryType);
    if (!isAppConnected(integrationApp) || !integrationConnection) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await sendApiRequest(
        `/integrations/${integrationConnection.integrationId}/objects?queryType=${queryType || ''}&query=${query || ''}`,
        "GET"
      ) as { data: { integrationObjects: IntegrationObject[] } };
      setDataEntities([
        ...response.data.integrationObjects.map((io) => ({
          id: io.objectId,
          name: io.objectName,
          sourceType: io.objectType,
          connected: integrationEntities.filter((ie) => ie.entityId === io.objectId && ie.isConnected).length > 0,
        }))
      ]);
    } catch (e) {
      console.error("Error fetching files", e);
      toast({
        title: "Error",
        description: "Error fetching connection objects. Consider removing the connection and adding it again."
      });
      // if (integrationConnection.integrationId) {
      //   onRemove!(integrationConnection.integrationId);
      // }
    }
    
    setIsLoading(false);
  }, [integrationApp, integrationConnection, integrationEntities, isAppConnected, sendApiRequest, toast]);

  useEffect(() => {
    if (!dataEntities.length) {
      fetchObjects('', defaultQueryType);
    }
  }, [dataEntities.length, defaultQueryType, fetchObjects, integrationConnection]);

  useEffect(() => {
    console.log("Updating data entities", integrationEntities);
    console.log(integrationEntities.filter((ie) => ie.integrationId === integrationConnection?.integrationId))
    setDataEntities((prevDataEntities) => {
      const newEntities =[
        ...prevDataEntities.map((de) => ({
          ...de,
          connected: integrationEntities.some((ie) => ie.integrationId === integrationConnection?.integrationId && ie.entityId === de.id && ie.isConnected),
        }))
      ];
      console.log("new entities", newEntities);
      return newEntities;
    });
  }, [integrationConnection?.integrationId, integrationEntities]);

  const onApplyDataCallback = useCallback(async (de: ConnectorDataEntity) => {
    console.log("Applying data", de);
    const integrationConnection = getAppConnection(integrationApp) as IntegrationConnection;
    if (!integrationConnection) return;
    try {
      const integrationEntity = integrationEntities.find((ie) => ie.entityId === de.id);
      if (de.connected && integrationEntity) {
        await sendApiRequest(`/integration-entities/${integrationEntity.id}/contexts?artifactId=${artifactId}`, "DELETE");
        setIntegrationEntities([{
          ...integrationEntity,
          isConnected: false,
        },...integrationEntities.filter((entity) => entity.id !== integrationEntity.id)
        ]);
        toast({
          title: "Success",
          description: "Object disconnected successfully."
        });
      } else {
        const response = await sendApiRequest(`/integrations/${integrationConnection.integrationId}/connect-object`, "POST", 
          {
            object: {
              objectId: de.id,
              objectName: de.name,
              objectType: de.sourceType,
              integrationApp: integrationConnection.integrationApp,
            },
            artifactId,
          }
        ) as { data: { integrationEntity: IntegrationEntityModel, isContextTruncated: boolean } };
        setIntegrationEntities([{
          ...response.data.integrationEntity,
          isConnected: true,
        } as IntegrationEntityModel, ...integrationEntities.filter((ie) => ie.entityId !== de.id)]);
        if (!response.data.isContextTruncated) {
          toast({
            title: "Success",
            description: "Object selected successfully.",
          });
        } else {
          toast({
            title: "Warning",
            description: "Context truncated to fit the 40KB size limit. Consider resizing your content to get more accurate results.",
          });
        }
      }
    } catch (e) {
      console.error("Error fetching files", e);
      toast({
        title: "Error",
        description: "Error fetching connection object"
      });
    }
  }, [artifactId, getAppConnection, integrationApp, integrationEntities, sendApiRequest, setIntegrationEntities, toast]);

  return (
    <EntityPicker
      title={title}
      applyDataName={ctaName}
      removeConnectorCallback={() => onRemove?.(integrationConnection!.integrationId)}
      onEntityPressed={onApplyDataCallback}
      connectedEntities={dataEntities}
      placeholder={placeholder}
      isLoadingProp={isLoading}
      queryTypes={queryTypes}
      onSearchCallback={fetchOnSearch ? fetchObjects : undefined}
      showSearch
    />
  );
}

export default function IntegrationObjectPickerContainer(props: IntegrationObjectPickerProps) {
  return (
    <Suspense
      fallback={
        <Loading className="flex flex-col items-center justify-center min-h-[250px]" />
      }
    >
      <IntegrationObjectPicker {...props} />
    </Suspense>
  );
}
