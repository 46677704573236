import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import Tile from "../../Tile/Tile";
import SalesforceLogo from "@/images/salesforce.svg";
import { authenticateSalesforce } from "@/lib/utils";
import { useContext, useEffect } from "react";
import { IntegrationApp } from "@/containers/Integrations/types";
import { useParams, useSearchParams } from "react-router-dom";

export default function SalesforceConnector({ 
  onSuccess,
  activeIntegration
}: { onSuccess?: (stepName: string) => void, activeIntegration?: string }) {
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(IntegrationConnectionsContext);

  useEffect(() => {
    if (isAppActive(IntegrationApp.SALESFORCE)) {
      onSuccess?.("salesforce-picker");
      setSearchParams({ });
    }
  }, [isAppActive, onSuccess]);
  
  const authenticateClient = async () => {
    if (isAppConnected(IntegrationApp.SALESFORCE)) return onSuccess?.("salesforce-picker");
    authenticateSalesforce(artifactId);
  };

  return (
    <Tile
      onPress={authenticateClient}
      label="Salesforce"
      src={SalesforceLogo}
      connected={isAppConnected(IntegrationApp.SALESFORCE)}
      selected={activeIntegration === "salesforce-picker"}  
      isLoading={isLoading}
    />
  );
}
