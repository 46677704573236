import BrevLogo from "@/components/ui/brevlogo";
import { Separator } from "@/components/ui/separator";
import LoginGraphicSvg from "./LoginGraphicSvg";

const Header = () => {
  return (
    <div className="w-full min-h-[6rem] bg-light-main pl-16 pt-[2em]">
      <BrevLogo size={91} />
    </div>
  );
};

const Footer = () => {
  return (
    <div className="min-h-[18.875rem] w-full bg-dark-main px-[163px]">
      <div className="pb-[20px] pt-[5rem]">
        <BrevLogo dark size={91} />
      </div>
      <Separator
        className="text-white bg-brev-light mt-[3rem]"
        orientation="horizontal"
      />
      <div className="flex justify-between w-full text-xs text-brev-light font-normal pt-3 pb-10">
        <span>2024 Brev. Technologies Inc. All Rights Reserved.</span>
        <div className="flex gap-4">
          Terms of Service
          <Separator className="h-5" orientation="vertical" />
          Privacy Policy
        </div>
      </div>
    </div>
  );
};

const LoginVisual = () => {
  return (
    <div className="flex flex-col flex-grow h-screen">
      <Header />
      <div className="flex items-center flex-1 size-full text-dark-text pl-[163px] bg-light-main p-20">
        <div className="h-full w-full bg-light-alt rounded-[8px] px-20 pt-32">
          <div className="flex flex-col gap-2 w-[36rem] items-center justify-center">
            <div className="text-[3.5rem] font-[600] leading-[1.1]">
              Orchestrate better business results
            </div>
            <span className="text-[1rem] font-normal pt-4 leading-loose pr-20">
              Streamline strategy execution with AI-powered workflows and enable
              teams to achieve their goals, faster.
            </span>
            <div className="flex w-full h-full p-10">
              <LoginGraphicSvg />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LoginVisual;
