import { useEffect, useState } from "react";

export default function useTypingEffect(
    text: string,
    shouldStream: boolean,
    speed: number = 50
) {
    const [displayedText, setDisplayedText] = useState(text);

    useEffect(() => {
        if (shouldStream) {
            let i = 0;
            const typingInterval = setInterval(() => {
                if (i < text.length) {
                    setDisplayedText(text.substring(0, i + 1));
                    i++;
                } else {
                    clearInterval(typingInterval);
                }
            }, speed);

            return () => clearInterval(typingInterval);
        } else {
            setDisplayedText(text);
        }
    }, [text, shouldStream, speed]);

    return displayedText;
}