import {
  useState,
  forwardRef,
  useEffect,
  useCallback,
  ReactNode,
  useContext
} from "react";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import PencilIcon from "@/components/Icons/PencilIcon";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { ArtifactsListContext } from "./ArtifactsListProvider";
import { ArtifactModel } from "@/containers/ArtifactBuilder/types";

interface NewArtifactButtonProps {
  collapsed?: boolean;
  asChild?: boolean;
  children?: ReactNode;
}

const NewArtifactButton = forwardRef<HTMLButtonElement, NewArtifactButtonProps>(
  ({ collapsed = false, asChild = false, children }, ref) => {
    const navigate = useNavigate();
    const { sendApiRequest } = useContext(AuthContext);
    const { setArtifacts } = useContext(ArtifactsListContext);
    const [isLoading, setIsLoading] = useState(false);

    const handleKeyDown = useCallback((event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "k") {
        event.preventDefault();
        handleClick();
      }
    }, []);

    useEffect(() => {
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [handleKeyDown]);

    const handleClick = async () => {
      setIsLoading(true);
      try {
        const response = (await sendApiRequest("/artifacts", "POST", {
          artifactTitle: ""
        })) as { data: { artifactModel: ArtifactModel } };
        setArtifacts((prevArtifacts) => [{
          ...response.data.artifactModel,
        }, ...prevArtifacts]);
        navigate(`/artifacts/${response.data.artifactModel.artifactId}`);
      } catch (error) {
        console.error("Error creating new artifact:", error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div
        className={`${!asChild ? "w-full px-2 my-2 drop-shadow-[0_0px_5px_rgba(255, 255, 255, 0.25)]" : ""}`}
      >
        <Button
          variant="ghost"
          ref={ref}
          className={`${!asChild ? "rounded-[8px] w-full px-3 bg-[#FAFBFB] dark:bg-[#2D3436] active:scale-[98%] text-brev-border hover:text-brev-light overflow-hidden transition-colors duration-500 ease-in-out" : "p-0 m-0 bg-transparent w-6"}`}
          disabled={isLoading}
          onClick={handleClick}
        >
          {asChild ? (
            !isLoading ? (
              children
            ) : (
              <BrevLoader type="ring" />
            )
          ) : (
            <>
              {!collapsed && (
                <div className="flex w-full min-w-0 items-center flex-nowrap flex-shrink-0 justify-start gap-3">
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.2 }}
                    className="transition-all duration-200 flex flex-nowrap justify-between items-center w-full flex-shrink-0 gap-2 text-dark-text dark:text-brev-light"
                  >
                    <div className="h-4 w-4">
                      <PencilIcon className="fill-brev-accent" size={16} />
                    </div>
                    <span className="text-nowrap">Create New</span>
                    <span>
                      <BrevLoader
                        className={`${isLoading ? "" : "invisible"}`}
                        type="ring"
                      />
                    </span>
                  </motion.span>
                </div>
              )}
            </>
          )}
        </Button>
      </div>
    );
  }
);

NewArtifactButton.displayName = "NewArtifactButton";

export default NewArtifactButton;
