import Notion from "@/images/notion.svg";
// import Zoom from "@/images/zoom.svg";
import Asana from "@/images/asana-alt.svg";
import Slack from "@/images/slack-alt.svg";
import Looker from "@/images/looker.svg";
import Teams from "@/images/teams.svg";
// import Slides from "@/images/slides.svg";
import GDocs from "@/images/gdocs.svg";
import Salesforce from "@/images/salesforce-scroller.svg";
import Zendesk from "@/images/zendesk.svg";
import Monday from "@/images/monday.svg";
import Jira from "@/images/jira.svg";
// import Hubspot from "@/images/hubspot.svg";
import Github from "@/images/github.svg";
import Gitlab from "@/images/gitlab.svg";
// import Intercom from "@/images/intercom.svg";
import Excel from "@/images/excel.svg";
// import Msword from "@/images/msword.svg";
import Snowflake from "@/images/snowflake.svg";
// import Aha from "@/images/aha.svg";
// import Marketo from "@/images/marketo.svg";
// import Smartsheet from "@/images/smartsheet.svg";
import Mysql from "@/images/mysql.svg";
// import Powerpoint from "@/images/mspowerpoint.svg";
import Tableau from "@/images/tableau.svg";
import GDrive from "@/images/gdrive.svg";
import Gong from "@/images/gong.svg";
import GSheets from "@/images/gsheets.svg";
// import Coda from "@/images/coda.svg";
import Bigquery from "@/images/bigquery.svg";
import { Button } from "@/components/ui/button";
import PageContainer from "@/components/Layout/PageContainer";

type Integration = {
  title: string;
  icon: string;
  bg: string;
  available: boolean;
};

const IntegrationsList = [
  {
    title: "Slack",
    icon: Slack,
    bg: "bg-[#2EB67D]",
    available: true
  },
  {
    title: "Salesforce",
    icon: Salesforce,
    bg: "bg-[#00A2E1]",
    available: true
  },
  {
    title: "Asana",
    icon: Asana,
    bg: "bg-[#EF586E]",
    available: false
  },
  {
    title: "Big Query",
    icon: Bigquery,
    bg: "bg-[#699BF7]",
    available: false
  },
  {
    title: "Excel Online",
    icon: Excel,
    bg: "bg-[#21A366]",
    available: false
  },
  {
    title: "GitLab",
    icon: Gitlab,
    bg: "bg-[#242938]",
    available: false
  },
  {
    title: "GitHub",
    icon: Github,
    bg: "bg-[#222222]",
    available: false
  },
  {
    title: "Google Docs",
    icon: GDocs,
    bg: "bg-[#0085FF]",
    available: false
  },
  {
    title: "Google Drive",
    icon: GDrive,
    bg: "bg-[#0085FF]",
    available: false
  },
  {
    title: "Google Sheets",
    icon: GSheets,
    bg: "bg-[#129D59]",
    available: false
  },
  {
    title: "Gong",
    icon: Gong,
    bg: "bg-[#8039DF]",
    available: false
  },
  {
    title: "Jira",
    icon: Jira,
    bg: "bg-[#0052CC]",
    available: false
  },
  {
    title: "Looker",
    icon: Looker,
    bg: "bg-[#A1C2FA]",
    available: false
  },
  {
    title: "Monday",
    icon: Monday,
    bg: "bg-[#FFC908]",
    available: false
  },
  {
    title: "MySQL",
    icon: Mysql,
    bg: "bg-[#F29111]",
    available: false
  },
  {
    title: "Notion",
    icon: Notion,
    bg: "bg-[#222222]",
    available: false
  },
  {
    title: "Snowflake",
    icon: Snowflake,
    bg: "bg-[#21B3E6]",
    available: false
  },
  {
    title: "Teams",
    icon: Teams,
    bg: "bg-[#757DE1]",
    available: false
  },
  {
    title: "Tableau",
    icon: Tableau,
    bg: "bg-[#59879B]",
    available: false
  },
  {
    title: "Zendesk",
    icon: Zendesk,
    bg: "bg-[#06353C]",
    available: false
  }
];

const IntegrationCard = ({ integration }: { integration: Integration }) => (
  <>
    <div
      className={`h-[195px] w-[200px] rounded-[17px] overflow-hidden ${
        integration.available
          ? "hover:cursor-pointer hover:ring-1 hover:ring-brev-active hover:shadow-[0_0_15.5px_0_rgba(54,71,114,0.23)]"
          : "hover:cursor-not-allowed"
      }`}
    >
      <div className={`h-[64px] ${integration.bg}`}>
        <div className="flex flex-col justify-center items-center gap-[12px] pt-[104px] h-full">
          <div className="min-h-[44px] min-w-[44px] rounded-full overflow-hidden bg-white flex items-center justify-center">
            <img
              src={integration.icon}
              height={"30px"}
              width={"30px"}
              alt={integration.title}
            />
          </div>
          <span className="font-semibold text-sm  mb-2">
            {integration.title}
          </span>
          {integration.available ? (
            <Button variant="integrate" className="w-[124px] h-[26px]">
              Connect
            </Button>
          ) : (
            <Button variant="ghost" className="h-[26px]" disabled>
              Coming Soon
            </Button>
          )}
        </div>
      </div>
      <div className="h-[131px] bg-[#F7F7FA]"></div>
    </div>
  </>
);

export default function IntegrationContainer() {
  return (
    <PageContainer
      title="Integrations"
      subtitle="Connect your data to Brev so we can help you build the best reviews."
    >
      <div className="grid grid-cols-[repeat(2,200px)] md:grid-cols-[repeat(3,200px)] lg:grid-cols-[repeat(4,200px)] xl:grid-cols-[repeat(5,200px)] 2xl:grid-cols-[repeat(5,200px)] max-w-[1200px] gap-3 mt-5">
        {IntegrationsList.map((item: Integration) => (
          <IntegrationCard integration={item} key={item.title} />
        ))}
      </div>
    </PageContainer>
  );
}
