import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { useState } from "react";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent
} from "@/components/ui/chart";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSeparator,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent
} from "@/components/ui/dropdown-menu";
import { RxColorWheel } from "react-icons/rx";
import { formatNumber } from "@/lib/utils";
import { gradientOptions, ChartGradient } from "./gradients";

type LineWidgetProps = {
  content: { [key: string]: string | number }[];
  isPreview?: boolean;
  isEditable?: boolean;
};

export function LineWidget({ content, isPreview, isEditable }: LineWidgetProps) {
  const [gradients, setGradients] = useState<ChartGradient[]>(
    content.length > 0
      ? gradientOptions.slice(0, Object.keys(content[0]).length - 1)
      : []
  );

  const chartConfig: ChartConfig = {};
  if (content.length > 0) {
    Object.keys(content[0]).forEach((key, index) => {
      if (key !== "name") {
        chartConfig[key] = {
          label: key,
          color: `url(#${gradients[index - 1]?.id || gradientOptions[0].id})`
        };
      }
    });
  }

  return (
    <div className="relative group">
      {!isPreview && isEditable && (
        <div className="absolute right-4 top-10">
          <DropdownMenu>
            <DropdownMenuTrigger className="opacity-[0] group-hover:opacity-100">
              <div className="ml-4">
                <RxColorWheel
                  size={24}
                  className="hover:text-blue-400 cursor-pointer"
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuLabel>Line Gradients</DropdownMenuLabel>
              <DropdownMenuSeparator />
              {Object.keys(chartConfig).map((key, index) => (
                <DropdownMenuSub key={key}>
                  <DropdownMenuSubTrigger>
                    <DropdownMenuLabel>{key}</DropdownMenuLabel>
                  </DropdownMenuSubTrigger>
                  <DropdownMenuSubContent>
                    <div className="grid grid-cols-4 gap-2 p-2">
                      {gradientOptions.map((gradient) => (
                        <div
                          key={gradient.id}
                          className={`cursor-pointer h-[28px] w-[28px] rounded-[8px] ${
                            gradients[index]?.id === gradient.id
                              ? "ring-1 ring-brev-active"
                              : ""
                          }`}
                          style={{
                            background: `linear-gradient(to bottom, ${gradient.colors.join(", ")})`
                          }}
                          onMouseDown={() => {
                            const newGradients = [...gradients];
                            newGradients[index] = gradient;
                            setGradients(newGradients);
                          }}
                        ></div>
                      ))}
                    </div>
                  </DropdownMenuSubContent>
                </DropdownMenuSub>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}

      <ChartContainer
        config={chartConfig}
        className={`aspect-auto w-full h-[300px] md:h-[400px] min-h-[100px] border border-transparent bg-brev-light dark:bg-dark-alt dark:border-brev ${
          isPreview
            ? "p-0 bg-transparent"
            : "pl-2 md:pl-6 py-4 md:py-10 pr-4 md:pr-10 mt-4 md:mt-8"
        } rounded-xl transition-colors duration-500 ease-in-out`}
      >
        <LineChart
          accessibilityLayer
          data={content}
          margin={{
            left: window.innerWidth < 768 ? 0 : 6,
            right: window.innerWidth < 768 ? 6 : 12,
            bottom: window.innerWidth < 768 ? 6 : 12,
            top: window.innerWidth < 768 ? 6 : 12
          }}
        >
          <defs>
            {gradientOptions.map((gradient) => (
              <linearGradient
                key={gradient.id}
                id={gradient.id}
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                {gradient.stops.map((stop, index) => (
                  <stop
                    key={index}
                    offset={`${stop}%`}
                    stopColor={gradient.colors[index]}
                  />
                ))}
              </linearGradient>
            ))}
          </defs>
          {!isPreview && (
            <>
              <CartesianGrid vertical={false} stroke="#CAC9C9" />
              <XAxis
                dataKey="name"
                tickLine={false}
                tickMargin={window.innerWidth < 768 ? 5 : 10}
                axisLine={false}
                tickFormatter={(value) => {
                  const maxLength = window.innerWidth < 768 ? 8 : 15;
                  return value.toString().slice(0, maxLength);
                }}
                fontSize={window.innerWidth < 768 ? 12 : 14}
              />
              <YAxis
                tickLine={true}
                axisLine={true}
                tickMargin={0}
                tickFormatter={(value) => formatNumber(value)}
                fontSize={window.innerWidth < 768 ? 12 : 14}
                width={window.innerWidth < 768 ? 35 : 45}
              />
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator="line" />}
              />
            </>
          )}
          {Object.keys(chartConfig).map((key) => (
            <Line
              key={key}
              dataKey={key}
              type="natural"
              stroke={isPreview ? "gray" : chartConfig[key].color}
              strokeWidth={2}
              dot={{
                fill: isPreview ? "none" : chartConfig[key].color,
                stroke: isPreview ? "none" : chartConfig[key].color
              }}
              activeDot={{
                r: 6
              }}
            />
          ))}
        </LineChart>
      </ChartContainer>
    </div>
  );
}
