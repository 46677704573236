import OTPInput from "@/components/Inputs/OTPInput";
import { Button } from "@/components/ui/button";
import config from "@/config";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoginVisual from "../WebsiteVisuals/LoginVisual";
import { motion } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";

export default function LinkSent() {
  const [code, setCode] = useState("");
  const invalidCode = window.location.search.includes("invalid-code");
  const [showLogin, setShowLogin] = useState(true);

  const sendCode = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let token = urlSearchParams.get("token");
    if (token) {
      localStorage.setItem("emailLoginToken", token);
    } else {
      token = localStorage.getItem("emailLoginToken");
    }
    window.location.href = `${config.apiGateway.V3_API_URL}/auth/link/verify?code=${code}&token=${encodeURIComponent(token!)}`;
  };

  useEffect(() => {
    // @ts-expect-error Turnstile is defined in the global scope
    window.onloadTurnstileCallback = function () {
      // @ts-expect-error Turnstile is defined in the global scope
      turnstile.render("#cf-turnstile-container", {
        sitekey: "0x4AAAAAAAhHIa3d-pAm2unE",
        // @ts-expect-error Turnstile is defined in the global scope
        callback: function (token) {
          console.log(`Challenge Success ${token}`);
        }
      });
    };
  }, []);
  return (
    <div className="flex p-0 overflow-hidden no-scrollbar">
      <LoginVisual />
      {showLogin ? (
        <motion.div
          className="fixed top-0 right-0 bg-white w-full h-screen sm:w-[572px] pt-10 flex flex-col drop-shadow-[0_15px_15px_rgba(0,0,0,0.15)]"
          initial={{ x: 150, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "tween",
            duration: "0.3"
          }}
        >
          <div className="overflow-y-auto no-scrollbar">
            <Button
              variant="ghost"
              className="fixed top-5 right-5 rounded-full"
              onClick={() => {
                setShowLogin(false);
              }}
            >
              <IoCloseOutline />
            </Button>
            <TempCodeHeader />
            <div className="NotFound text-center w-full flex flex-col justify-center items-center gap-2">
              <div className="flex flex-col gap-10 justify-center items-center py-10">
                <OTPInput value={code} onChange={(e) => setCode(e)} />
                <div className="flex flex-col items-center gap-4">
                  <Button
                    disabled={code.length !== 6}
                    onClick={() => {
                      sendCode();
                    }}
                  >
                    Log in
                  </Button>
                  <span className="text-xs font-normal">
                    Cant find your code? Check your spam folder!
                  </span>
                </div>
              </div>
              {invalidCode && (
                <div className="text-xs font-bold text-red-500">
                  Invalid code
                </div>
              )}
              <div id="cf-turnstile-container"></div>
              <Link className="text-xs text-slate-500 mt-10" to="/login">
                Back to login
              </Link>
            </div>
          </div>
          <div className="fixed flex items-center justify-center text-brev-gray bottom-0 left-0 h-20 w-full bg-light-main/20">
            Brev is hand crafted in the 415, 416, and 206.
          </div>
        </motion.div>
      ) : (
        <Button
          variant="negative"
          className="fixed top-5 right-5 w-[148px] hover:ring-0 hover:shadow-xl transition-all disabled:pointer-events-none"
          onClick={() => {
            setShowLogin(true);
          }}
        >
          Log In
        </Button>
      )}
    </div>
  );
}

export const TempCodeHeader = () => {
  return (
    <div className="px-16 pt-24">
      <div className="text-dark-text text-3xl font-[900] mb-2">
        Check your email for a code
      </div>
      <div className="text-dark-text text-sm font-normal pt-3 text-center mt-10 px-16">
        We’ve sent a 6-digit code to your email. The code expires shortly, so
        please enter it soon.
      </div>
    </div>
  );
};