import EditableText from "../../EditableText";
import { EmbedData } from "@/containers/ReviewBuilder/review-types";
import { cn } from "@/lib/utils";
import { useState, useMemo } from "react";
import { Code2Icon } from "lucide-react";
import { domPurify } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { TableauEmbed } from "./TableauEmbed";
import { isEmbedCode } from "./embedUtils";
import { EmbedInput } from "./EmbedInput";
import { getEmbedType } from "./embedUtils";
import { DEFAULT_ASPECT_RATIO, MAX_WIDTH, EmbedType } from "./embed_types";
import { memo } from "react";

interface EmbedTileProps {
  content: EmbedData;
  className?: string;
  updateTileContent: (embed: Partial<EmbedData>) => void;
  published?: boolean;
}

export default function EmbedTile({
  content,
  className,
  updateTileContent,
  published
}: EmbedTileProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [input, setInput] = useState(content.embed_url || "");
  const [showEmbedInput, setShowEmbedInput] = useState(!content.embed_url);

  const aspectRatio = useMemo(() => {
    if (!content.embed_url || !isEmbedCode(content.embed_url))
      return DEFAULT_ASPECT_RATIO;

    const widthMatch = content.embed_url.match(/width="(\d+)"/);
    const heightMatch = content.embed_url.match(/height="(\d+)"/);
    const originalWidth = widthMatch ? parseInt(widthMatch[1]) : null;
    const originalHeight = heightMatch ? parseInt(heightMatch[1]) : null;

    return originalWidth && originalHeight
      ? (originalHeight / originalWidth) * 100
      : DEFAULT_ASPECT_RATIO;
  }, [content.embed_url]);

  const handleTitleChange = (value: string) => {
    updateTileContent({ title: value });
  };

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  const renderImage = (url: string) => (
    <div className="w-full h-full flex items-center justify-center">
      <img
        src={url}
        alt={content.title || "Embedded image"}
        className="object-contain max-w-full max-h-full"
        onLoad={handleIframeLoad}
        onError={() => setIsLoading(false)}
      />
    </div>
  );

  const renderIframeEmbed = (url: string) => (
    <div
      className="w-full h-full"
      style={{
        maxWidth: MAX_WIDTH,
        margin: "0 auto",
        isolation: "isolate"
      }}
    >
      <div className="relative h-full">
        <iframe
          src={url}
          sandbox=""
          className="absolute inset-0 w-full h-full"
          onLoad={handleIframeLoad}
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      </div>
    </div>
  );

  const renderEmbed = () => {
    if (!content.embed_url) return null;

    const embedType = getEmbedType(content.embed_url);

    switch (embedType) {
      case EmbedType.IMAGE:
        return renderImage(content.embed_url);

      case EmbedType.TABLEAU:
        return <TableauEmbed embedCode={content.embed_url} />;

      case EmbedType.EMBED:
        return (
          <div
            className="w-full"
            style={{ maxWidth: MAX_WIDTH, margin: "0 auto" }}
          >
            <div
              style={{
                position: "relative",
                paddingBottom: `${aspectRatio}%`,
                height: 0
              }}
              dangerouslySetInnerHTML={{
                __html: domPurify(
                  content.embed_url.replace(
                    /<iframe/i,
                    '<iframe class="responsive-iframe"'
                  )
                )
              }}
            />
          </div>
        );

      case EmbedType.IFRAME:
        return renderIframeEmbed(content.embed_url);

      default:
        return null;
    }
  };

  return (
    <div
      className={cn(
        "w-full rounded-lg h-auto sm:h-full sm:overflow-y-scroll no-scrollbar relative",
        className
      )}
    >
      <EmbedInput
        visible={showEmbedInput && !published}
        input={input}
        onInputChange={setInput}
        onSave={() => {
          setIsLoading(true);
          updateTileContent({ embed_url: input });
          setShowEmbedInput(false);
        }}
        onCancel={() => {
          setShowEmbedInput(false);
          setInput(content.embed_url || "");
        }}
      />
      <div className="sticky top-0 z-10 bg-light-main/50 dark:bg-dark-main/50 backdrop-blur-sm p-4">
        {!published && (
          <Button
            variant="ghost"
            onClick={() => setShowEmbedInput(true)}
            className="absolute top-4 right-4 bg-light-alt dark:bg-dark-alt p-2 rounded-full shadow-lg hover:bg-light-main dark:hover:bg-dark-main"
          >
            <Code2Icon className="w-4 h-4" />
          </Button>
        )}

        {content.title && (
          <EditableText
            value={content.title}
            onSave={handleTitleChange}
            className="text-2xl font-bold text-wrap max-w-[640px]"
            editable={!published}
          />
        )}
      </div>
      <div className="flex-1 min-h-0">
        {" "}
        {/* Added flex-1 and min-h-0 */}
        {content.embed_url ? (
          <div className="relative w-full h-full">
            {" "}
            {/* Added h-full */}
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center dark:bg-dark-alt">
                <div className="animate-pulse">Loading embedded content...</div>
              </div>
            )}
            <EmbedContainer>{renderEmbed()}</EmbedContainer>
          </div>
        ) : (
          <div className="w-full h-[400px] flex items-center justify-center bg-light-alt dark:bg-dark-alt rounded-lg">
            <div className="text-center text-dark-text dark:text-light-text">
              <p className="mb-2">Nothing to display yet</p>
              <Button
                variant="negative"
                onClick={() => setShowEmbedInput(true)}
                disabled={published}
              >
                Add embed code
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const EmbedContainer = memo(({ children }: { children: React.ReactNode }) => (
  <div className="embed-container">{children}</div>
));
