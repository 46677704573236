import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from "@/components/ui/accordion";
import type { CalendarEvent } from "./calendar-types";
import { parseRrule } from "./utils";
import useRhythmContext from "./useRhythmContext";
import useGenerateBrief from "./useGenerateBrief";
import { useGenerateText } from "./useGenerateText";
import { PulseLoader } from "react-spinners";

export function RhythmAccordion({
  event,
  isHighlighted
}: {
  event: CalendarEvent;
  isHighlighted: boolean;
}) {
  const { openEventId, setOpenEventId } = useRhythmContext();
  const tempBrief = useGenerateBrief(event);
  const { text, isGenerating } = useGenerateText(tempBrief, 10);

  const parsedRrule = event.recurrence
    ? parseRrule(event.recurrence[event.recurrence.length - 1])
    : undefined;

  const extraAttendees =
    event.attendees && event.attendees?.length > 3
      ? event.attendees?.length - 3
      : 0;

  return (
    <Accordion
      type="single"
      collapsible
      className={`min-w-[230px] max-w-[230px] rounded-[8px] bg-brev-light data-[state=open]:text-[#000] data-[state=closed]:text-[#FFF]`}
      style={{
        border: `2px solid ${isHighlighted ? event.colour : "#C1C6D6"}`
      }}
      value={openEventId}
      onValueChange={(value) => setOpenEventId(value)}
    >
      <AccordionItem value={event.id}>
        <AccordionTrigger className="place-items-center text-[1rem] font-bold pr-4 pl-4 py-0 h-[33px] overflow-hidden">
          <span className="text-xs font-semibold truncate pr-2">
            {event.summary}
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <div className="flex flex-col rounded-[8px] h-[182px] overflow-y-scroll no-scrollbar overflow-x-hidden px-3">
            <div className="flex justify-between items-center text-[10px]">
              {/* <span>Attendees: {event.attendees?.length}</span> */}
              <div className="flex">
                {event.attendees?.slice(0, 3).map((a) => {
                  return (
                    <div
                      key={a.email}
                      className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border border-black bg-white text-black"
                    >
                      {a.email.charAt(0)}
                    </div>
                  );
                })}
                {extraAttendees > 0 && (
                  <div className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border-2 border-black bg-black text-white font-medium">
                    {extraAttendees}
                  </div>
                )}
              </div>
              <span className="lowercase first-letter:uppercase">
                {parsedRrule?.frequency}
              </span>
            </div>
            <div className="flex gap-4">
              <span className="text-[10px] font-semibold">Description:</span>
              {isGenerating && <PulseLoader color="#3FCFF1" size={8} />}
            </div>
            <div className="break-words text-[10px] font-normal">{text}</div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
}
