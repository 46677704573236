import { useState, useContext, useEffect, useCallback, useRef } from "react";
import { Editor } from "@tiptap/react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import {
  LuAlignCenter,
  LuAlignJustify,
  LuAlignLeft,
  LuAlignRight,
  LuBold,
  LuHighlighter,
  LuItalic,
  LuLink,
  LuStrikethrough
} from "react-icons/lu";
import { GridContext } from "../GridProvider";

export default function FormattingToolbar() {
  const { activeEditorInfo } = useContext(GridContext);
  const [currentEditor, setCurrentEditor] = useState<Editor | null>(
    activeEditorInfo.editor
  );
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [linkUrl, setLinkUrl] = useState("");
  const linkInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (activeEditorInfo.editor) {
      setCurrentEditor(activeEditorInfo.editor);
    }
  }, [activeEditorInfo.editor]);

  useEffect(() => {
    if (showLinkInput && linkInputRef.current) {
      linkInputRef.current.focus();
      setLinkUrl(currentEditor?.getAttributes("link").href || "");
    }
  }, [showLinkInput, currentEditor]);

  const handleLinkSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && currentEditor) {
      if (linkUrl === "") {
        currentEditor.chain().focus().extendMarkRange("link").unsetLink().run();
      } else {
        currentEditor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: linkUrl })
          .run();
      }
      setShowLinkInput(false);
    } else if (e.key === "Escape") {
      setShowLinkInput(false);
    }
  };

  const setLink = useCallback(() => {
    if (!currentEditor) return;
    setShowLinkInput(true);
  }, [currentEditor]);

  if (!currentEditor) {
    return <div className="flex w-full"></div>;
  }

  return (
    <div className="flex gap-0.5 items-center justify-center flex-nowrap px-3 py-1.5">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="text-[10px] gap-2">
            Format
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="top"
          className="border-none shadow min-w-[6rem]"
        >
          <DropdownMenuItem
            onSelect={() => {
              currentEditor.chain().focus().toggleHeading({ level: 1 }).run();
            }}
          >
            <span
              className={`${currentEditor.isActive("heading", { level: 1 }) ? "text-brev-accent" : ""}`}
            >
              Heading 1
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() =>
              currentEditor.chain().focus().toggleHeading({ level: 2 }).run()
            }
          >
            <span
              className={`${currentEditor.isActive("heading", { level: 2 }) ? "text-brev-accent" : ""}`}
            >
              Heading 2
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() =>
              currentEditor.chain().focus().toggleHeading({ level: 3 }).run()
            }
          >
            <span
              className={`${currentEditor.isActive("heading", { level: 3 }) ? "text-brev-accent" : ""}`}
            >
              Heading 3
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => currentEditor.chain().focus().setParagraph().run()}
          >
            <span
              className={
                currentEditor.isActive("paragraph") ? "text-brev-accent" : ""
              }
            >
              Paragraph
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              currentEditor.chain().focus().toggleBulletList().run();
            }}
          >
            <span
              className={`${currentEditor.isActive("bulletList") ? "text-brev-accent" : ""}`}
            >
              Bullet List
            </span>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              currentEditor.chain().focus().toggleOrderedList().run();
            }}
          >
            <span
              className={`${currentEditor.isActive("orderedList") ? "text-brev-accent" : ""}`}
            >
              Numbered List
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <MenuDivider />
      <Button
        variant="ghost"
        onClick={() => currentEditor.chain().focus().toggleBold().run()}
        className={`border ${currentEditor.isActive("bold") ? "border-dark-border dark:border-brev-border" : "border-transparent"}`}
      >
        <LuBold size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() => currentEditor.chain().focus().toggleItalic().run()}
        className={`border ${currentEditor.isActive("italic") ? "border-dark-border dark:border-brev-border" : "border-transparent"}`}
      >
        <LuItalic size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() => currentEditor.chain().focus().toggleStrike().run()}
        className={`border ${currentEditor.isActive("strike") ? "border-dark-border dark:border-brev-border" : "border-transparent"}`}
      >
        <LuStrikethrough size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() =>
          currentEditor
            .chain()
            .focus()
            .toggleHighlight({ color: "#3FCFF150" })
            .run()
        }
        className={`border ${currentEditor.isActive("highlight", { color: "#3FCFF150" }) ? "border-dark-border dark:border-brev-border" : "border-transparent"}`}
      >
        <LuHighlighter size={12} />
      </Button>
      <div className="relative">
        {showLinkInput && (
          <div className="absolute bottom-full mb-2 left-0">
            <input
              ref={linkInputRef}
              type="url"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
              onKeyDown={handleLinkSubmit}
              placeholder="Enter URL and press Enter"
              className="w-64 px-2 py-1 text-sm rounded border border-gray-200
                     dark:border-gray-700 dark:bg-gray-800
                     focus:outline-none focus:ring-1 focus:ring-brev-accent
                     shadow-lg"
              onBlur={() => setShowLinkInput(false)}
            />
          </div>
        )}
        <Button
          variant="ghost"
          onClick={setLink}
          className={`border ${
            currentEditor.isActive("link")
              ? "border-dark-border dark:border-brev-border"
              : "border-transparent"
          }`}
        >
          <LuLink size={12} />
        </Button>
      </div>
      <MenuDivider />
      <Button
        variant="ghost"
        onClick={() => currentEditor.chain().focus().setTextAlign("left").run()}
        className={`border ${
          currentEditor.isActive({ textAlign: "left" })
            ? "border-dark-border dark:border-brev-border"
            : "border-tra"
        }`}
      >
        <LuAlignLeft size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() =>
          currentEditor.chain().focus().setTextAlign("center").run()
        }
        className={`border ${
          currentEditor.isActive({ textAlign: "center" })
            ? "border-dark-border dark:border-brev-border"
            : "border-transparent"
        }`}
      >
        <LuAlignCenter size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() =>
          currentEditor.chain().focus().setTextAlign("right").run()
        }
        className={`border ${
          currentEditor.isActive({ textAlign: "right" })
            ? "border-dark-border dark:border-brev-border"
            : "border-transparent"
        }`}
      >
        <LuAlignRight size={12} />
      </Button>
      <Button
        variant="ghost"
        onClick={() =>
          currentEditor.chain().focus().setTextAlign("justify").run()
        }
        className={`border ${
          currentEditor.isActive({ textAlign: "justify" })
            ? "border-dark-border dark:border-brev-border"
            : "border-transparent"
        }`}
      >
        <LuAlignJustify size={12} />
      </Button>
    </div>
  );
}

const MenuDivider = () => {
  return (
    <div className="min-w-[1px] mx-2 rounded-full bg-dark-main/30 dark:bg-light-main/30 min-h-6" />
  );
};
