export default function TealGraphic() {
  return (
    <svg
      width="115"
      height="87"
      viewBox="0 0 115 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3516 16.7559H71.7858"
        stroke="#7099A6"
        strokeWidth="5.24026"
      />
      <rect
        x="2.86649"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect
        x="64.7656"
        y="38.1797"
        width="3.27516"
        height="15.5833"
        fill="#E7E9F2"
      />
      <rect
        x="64.7656"
        y="38.1797"
        width="3.27516"
        height="15.5833"
        fill="#E7E9F2"
      />
      <rect
        x="64.7656"
        y="38.1797"
        width="3.27516"
        height="15.5833"
        fill="#7099A6"
      />
      <rect
        x="70.3809"
        y="46.04"
        width="3.27516"
        height="7.72294"
        fill="#E7E9F2"
      />
      <rect
        x="70.3809"
        y="46.04"
        width="3.27516"
        height="7.72294"
        fill="#E7E9F2"
      />
      <rect
        x="70.3809"
        y="46.04"
        width="3.27516"
        height="7.72294"
        fill="#6BC4B0"
      />
      <rect
        x="75.8086"
        y="41.9229"
        width="3.27516"
        height="11.8403"
        fill="#E7E9F2"
      />
      <rect
        x="75.8086"
        y="41.9229"
        width="3.27516"
        height="11.8403"
        fill="#E7E9F2"
      />
      <rect
        x="75.8086"
        y="41.9229"
        width="3.27516"
        height="11.8403"
        fill="#6BC4B0"
      />
      <rect
        x="81.2363"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#E7E9F2"
      />
      <rect
        x="81.2363"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#E7E9F2"
      />
      <rect
        x="81.2363"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#7099A6"
      />
      <rect
        x="86.6641"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#E7E9F2"
      />
      <rect
        x="86.6641"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#E7E9F2"
      />
      <rect
        x="86.6641"
        y="40.7061"
        width="3.27516"
        height="13.0568"
        fill="#6BC4B0"
      />
      <rect
        x="92.0898"
        y="38.9277"
        width="3.27516"
        height="14.8347"
        fill="#E7E9F2"
      />
      <rect
        x="92.0898"
        y="38.9277"
        width="3.27516"
        height="14.8347"
        fill="#E7E9F2"
      />
      <rect
        x="92.0898"
        y="38.9277"
        width="3.27516"
        height="14.8347"
        fill="#7099A6"
      />
      <rect
        x="97.4121"
        y="41.4541"
        width="3.27516"
        height="12.3082"
        fill="#E7E9F2"
      />
      <rect
        x="97.4121"
        y="41.4541"
        width="3.27516"
        height="12.3082"
        fill="#E7E9F2"
      />
      <rect
        x="97.4121"
        y="41.4541"
        width="3.27516"
        height="12.3082"
        fill="#6BC4B0"
      />
      <circle
        cx="34.1784"
        cy="41.9225"
        r="9.63833"
        stroke="#38B195"
        strokeOpacity="0.41"
        strokeWidth="5.24026"
      />
      <path
        d="M43.8876 41.9227C43.8876 43.8539 43.2903 45.7396 42.1747 47.3309C41.059 48.9221 39.4774 50.144 37.6388 50.8352C35.8001 51.5264 33.791 51.6543 31.8767 51.202C29.9624 50.7498 28.233 49.7387 26.917 48.3022C25.6009 46.8658 24.7601 45.0716 24.5055 43.1566C24.2509 41.2416 24.5947 39.296 25.4912 37.5766C26.3878 35.8572 27.7951 34.4451 29.5272 33.5266C31.2592 32.6081 33.2347 32.2265 35.1925 32.4323"
        stroke="#1BA685"
        strokeOpacity="0.64"
        strokeWidth="5.24026"
      />
      <path
        d="M12 67H101.084"
        stroke="#50BAA2"
        strokeOpacity="0.75"
        strokeWidth="0.748608"
      />
      <path
        d="M12 62H101.084"
        stroke="#50BAA2"
        strokeOpacity="0.55"
        strokeWidth="0.748608"
      />
      <path
        d="M12 71.4922H101.084"
        stroke="#50BAA2"
        strokeOpacity="0.35"
        strokeWidth="0.748608"
      />
      <rect y="69" width="115" height="84" fill="url(#paint0_linear_9047_13)" />
    </svg>
  );
}
