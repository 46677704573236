import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Loading({ className }: { className?: string }) {
  return (
    <div className={`${className}`}>
      <AiOutlineLoading3Quarters
        size="30"
        color="#3FCFF1"
        className="spinning animate-spin"
      />
    </div>
  );
}
