import { useEffect, useRef } from "react";
import { parseTableauEmbed } from "./embedUtils";
import { TABLEAU_PLACEHOLDER } from "./embed_types";

interface TableauEmbedProps {
  embedCode: string;
}

export const TableauEmbed = ({ embedCode }: TableauEmbedProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { vizId } = parseTableauEmbed(embedCode);

  useEffect(() => {
    if (!containerRef.current) return;

    containerRef.current.innerHTML = "";

    const parser = new DOMParser();
    const doc = parser.parseFromString(embedCode, "text/html");
    const placeholderDiv = doc.querySelector(`.${TABLEAU_PLACEHOLDER}`);

    if (placeholderDiv) {
      placeholderDiv.setAttribute(
        "style",
        "width: 100%; height: 100%; min-width: 1000px; min-height: 500px;"
      );
      containerRef.current.appendChild(placeholderDiv);
    }

    const scriptElement = document.createElement("script");
    scriptElement.src = "https://public.tableau.com/javascripts/api/viz_v1.js";
    containerRef.current.appendChild(scriptElement);

    scriptElement.onload = () => {
      const divElement = document.getElementById(vizId!);
      if (divElement) {
        const vizElement = divElement.getElementsByTagName("object")[0];
        if (vizElement) {
          vizElement.style.width = "100%";
          vizElement.style.height = "100%";
          vizElement.style.minWidth = "1000px";
          vizElement.style.minHeight = "500px";
          vizElement.style.display = "block";
        }
      }
    };

    return () => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, [embedCode, vizId]);

  return (
    <div
      ref={containerRef}
      className="tableau-container w-full h-full"
      style={{
        minWidth: "1000px",
        minHeight: "500px",
        width: "100%",
        height: "100%",
        position: "relative",
        overflow: "auto"
      }}
    />
  );
};
