import Tile from "../../Tile/Tile";
import Snowflake from "@/images/snowflake.svg";

export default function Snowflakeconnector() {
  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "SNOWFLAKE"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  const authenticateClient = () => {};

  return (
    <Tile
      disabled={true}
      onPress={authenticateClient}
      label="Snowflake"
      src={Snowflake}
    />
  );
}
