export default function LightGreenGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.83915"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect
        x="16"
        y="28.6621"
        width="19.3133"
        height="13.4749"
        rx="2.24582"
        fill="#3EB421"
        fillOpacity="0.5"
      />
      <rect
        x="39.2559"
        y="28.6621"
        width="19.3133"
        height="13.4749"
        rx="2.24582"
        fill="#3EB421"
        fillOpacity="0.5"
      />
      <rect
        x="62.5137"
        y="28.6621"
        width="19.3133"
        height="13.4749"
        rx="2.24582"
        fill="#3EB421"
        fillOpacity="0.5"
      />
      <rect
        x="85.7715"
        y="28.6621"
        width="19.3133"
        height="13.4749"
        rx="2.24582"
        fill="#3EB421"
        fillOpacity="0.5"
      />
      <path d="M16 51.8682H105.084" stroke="#E7E9F2" strokeWidth="0.748608" />
      <path d="M16.7461 15H61.2898" stroke="#3FCFF1" strokeWidth="5.24026" />
      <path d="M16 56.7344H105.084" stroke="#E7E9F2" strokeWidth="0.748608" />
      <path d="M16 61.9746H105.084" stroke="#E7E9F2" strokeWidth="0.748608" />
      <path d="M16 66.4668H105.084" stroke="#E7E9F2" strokeWidth="0.748608" />
      <path d="M16 72.0811H56.7991" stroke="#E7E9F2" strokeWidth="0.748608" />
      <rect y="35" width="121" height="94" fill="url(#paint0_linear_9047_16)" />
    </svg>
  );
}
