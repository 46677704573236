import { useContext } from "react";
import { GridContext } from "../GridProvider";
import FormattingToolbar from "./FormattingToolbar";
import ChartToolbar from "./ChartToolbar";
import KpiToolbar from "./KpiToolbar";

export default function TileControlPanel() {
  const { activeTile } = useContext(GridContext);

  const renderToolbar = () => {
    switch (activeTile?.tile_type) {
      case "chart":
        return <ChartToolbar tile={activeTile} />;
      case "kpi":
        return <KpiToolbar tile={activeTile} />;
      case "text":
        return <FormattingToolbar />;
      default:
        return null;
    }
  };

  return (
    <div className="relative overflow-visible">
      <div className="flex items-center justify-center shadow-lg dark:shadow-sm shadow-black/15 dark:shadow-white/20 rounded-full bg-light-alt dark:bg-dark-alt">
        {renderToolbar()}
      </div>
    </div>
  );
}
