import { useContext, useEffect, useRef, useState } from "react";
import { SnippetModel } from "../ArtifactCopilotProvider";
import { MessageAction } from "@/containers/WebSocket/WebSocketManager";
import { WebSocketContext } from "@/containers/WebSocket/WebSocketProvider";
import { useParams } from "react-router-dom";
import { ArtifactContext } from "../../ArtifactProvider";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import QuickViewTextBlock from "@/components/QuickView/QuickViewTextBlock";
import useChunkBuffer from "@/hooks/useChunkBuffer";
import ArtifactContextCitationCard from "../ArtifactContextCitationCard";
import { Skeleton } from "@/components/ui/skeleton";
import { IntegrationEntityModel } from "@/containers/Integrations/types";

type ArtifactSnippetInsightProps = {
  snippet: SnippetModel;
};

export default function ArtifactSnippetInsight({
  snippet
}: ArtifactSnippetInsightProps) {
  const { webSocketManager, isConnectionOpen } = useContext(WebSocketContext);
  const { userId } = useContext(AuthContext);
  const { artifactId } = useParams<{ artifactId: string }>();
  const { llmModelValue } = useContext(ArtifactContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [insightsContent, setInsightsContent] = useState<string | undefined>();
  const [integrationEntities, setIntegrationEntities] = useState<IntegrationEntityModel[]>([]);
  const { addChunk, cleanup } = useChunkBuffer<string>({
    processChunk: (chunk) => setInsightsContent((prev) => prev + chunk),
    // onComplete: () => console.log('All chunks processed'),
    bufferTimeoutMs: 3000
  });
  const snippetId = useRef<string | null>(null);

  useEffect(() => {
    if (snippet.snippetId && snippetId.current !== snippet.snippetId && isConnectionOpen) {
      console.log("sending explainArtifactSnippet", snippet);
      snippetId.current = snippet.snippetId;
      setIsLoading(true);
      setInsightsContent("");
      cleanup();
      webSocketManager?.sendMessage({
        action: "explainArtifactSnippet",
        data: {
          userId,
          artifactId,
          snippetContent: snippet.snippetContent,
          llmModel: llmModelValue
        }
      });
      webSocketManager?.sendMessage({
        action: "explainArtifactSnippetSources",
        data: {
          userId,
          artifactId,
          snippetContent: snippet.snippetContent,
          llmModel: llmModelValue
        }
      });
    }
  }, [artifactId, cleanup, llmModelValue, snippet, userId, webSocketManager]);

  useEffect(() => {
    if (webSocketManager) {
      webSocketManager.addListener(
        MessageAction.explainArtifactSnippetChunk,
        (data) => {
          const { chunk, index } = data as { chunk: string; index: number };
          addChunk({ chunk, index });
        }
      );
      webSocketManager.addListener(
        MessageAction.explainArtifactSnippetComplete,
        () => {
          setIsLoading(false);
        }
      );
      webSocketManager.addListener(
        MessageAction.explainArtifactSnippetSourcesComplete,
        (data) => {
          const { integrationEntities } = data as { integrationEntities: IntegrationEntityModel[] };
          setIntegrationEntities(integrationEntities);
        }
      );
    }

    return () => {
      if (webSocketManager) {
        webSocketManager.removeListener(
          MessageAction.explainArtifactSnippetChunk
        );
        webSocketManager.removeListener(
          MessageAction.explainArtifactSnippetComplete
        );
        webSocketManager.removeListener(
          MessageAction.explainArtifactSnippetSourcesComplete
        );
      }
    };
  }, [addChunk, snippet, webSocketManager]);
  return (
    <div className="flex flex-col gap-2">
      <QuickViewTextBlock
        title="Explain Snippet"
        content={snippet.snippetContent}
      />
      <QuickViewTextBlock
        title="Source"
        isGenerating={isLoading}
        loader={
            <Skeleton
              className={`w-full h-[36px] border pl-2 py-2 rounded-[8px] bg-brev-gray`}
              data-loadingid="snippet-source"
            />
        }
      >
        <div className="flex flex-col gap-2">
          {integrationEntities.map((integrationEntity) => {
            return (
              <ArtifactContextCitationCard integrationEntity={integrationEntity} />
            )
          })}
        </div>
      </QuickViewTextBlock>
      <QuickViewTextBlock
        title="Brev Intelligence"
        content={insightsContent ?? ""}
        isGenerating={isLoading}
      />
    </div>
  );
}
