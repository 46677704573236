export default function DarkGreenGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 47.5L36 43.2676H51.784L66.0075 38.4017L84.5 36L91.8345 31.2899L104.5 28"
        stroke="#3FCFF1"
        strokeOpacity="0.15"
        strokeWidth="2.24582"
      />
      <path
        d="M21 56L37.5 47.5L51 51.5L62.5 42.5L76.0498 39.62L88.2036 24.8L100 17"
        stroke="#67CD00"
        strokeOpacity="0.17"
        strokeWidth="2.24582"
      />
      <path
        d="M18.3218 17.2192H45.7953"
        stroke="#017B4F"
        strokeOpacity="0.77"
        strokeWidth="5.24026"
      />
      <path
        d="M18.3218 24.2188H45.7953"
        stroke="#017B4F"
        strokeOpacity="0.77"
        strokeWidth="3"
      />
      <path
        d="M17.5762 64.3472H106.661"
        stroke="#017B4F"
        strokeOpacity="0.75"
      />
      <path
        d="M17.5762 68.8389H106.661"
        stroke="#017B4F"
        strokeOpacity="0.55"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5762 74.4531H58.3753"
        stroke="#017B4F"
        strokeOpacity="0.35"
        strokeWidth="0.748608"
      />
      <rect
        x="8.8387"
        y="1.08964"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect y="67" width="121" height="66" fill="url(#paint0_linear_9047_17)" />
      <circle cx="99.5" cy="17.5" r="1.5" fill="#D9D9D9" />
      <circle cx="87.5" cy="25.5" r="1.5" fill="#D9D9D9" />
      <circle cx="65.5" cy="38.5" r="1.5" fill="#D9D9D9" />
      <circle cx="51.5" cy="43.5" r="1.5" fill="#D9D9D9" />
      <circle cx="50.5" cy="51.5" r="1.5" fill="#D9D9D9" />
      <circle cx="37.5" cy="47.5" r="1.5" fill="#D9D9D9" />
      <circle cx="36.5" cy="43.5" r="1.5" fill="#D9D9D9" />
      <circle cx="21.5" cy="47.5" r="1.5" fill="#D9D9D9" />
      <circle cx="21.5" cy="55.5" r="1.5" fill="#D9D9D9" />
      <circle cx="62.5" cy="42.5" r="1.5" fill="#D9D9D9" />
      <circle cx="76.5" cy="39.5" r="1.5" fill="#D9D9D9" />
      <circle cx="103.5" cy="28.5" r="1.5" fill="#D9D9D9" />
      <circle cx="91.5" cy="31.5" r="1.5" fill="#D9D9D9" />
      <circle cx="84.5" cy="35.5" r="1.5" fill="#D9D9D9" />
    </svg>
  );
}
