export function SmallGDocSvg() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9.4754" cy="9.67755" r="9.28595" fill="white" />
      <g clipPath="url(#clip0_8121_260)">
        <mask
          id="mask0_8121_260"
          maskUnits="userSpaceOnUse"
          x="6"
          y="4"
          width="8"
          height="12"
        >
          <path
            d="M13.8723 4.92871H6.56641V15.0327H13.8723V4.92871Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_8121_260)">
          <mask
            id="mask1_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_8121_260)">
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L12.2742 6.53616L11.1326 4.92871Z"
              fill="#4285F4"
            />
          </g>
          <mask
            id="mask2_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_8121_260)">
            <path
              d="M11.334 7.48242L13.8734 10.036V7.68392L11.334 7.48242Z"
              fill="url(#paint0_linear_8121_260)"
            />
          </g>
          <mask
            id="mask3_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_8121_260)">
            <path
              d="M8.39307 12.2768H12.046V11.8175H8.39307V12.2768ZM8.39307 13.1954H11.1328V12.7361H8.39307V13.1954ZM8.39307 9.98047V10.4397H12.046V9.98047H8.39307ZM8.39307 11.3583H12.046V10.899H8.39307V11.3583Z"
              fill="#F1F1F1"
            />
          </g>
          <mask
            id="mask4_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_8121_260)">
            <path
              d="M11.1348 4.92871V6.99542C11.1348 7.37605 11.4413 7.68433 11.8197 7.68433H13.8745L11.1348 4.92871Z"
              fill="#A1C2FA"
            />
          </g>
          <mask
            id="mask5_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_8121_260)">
            <path
              d="M7.25134 4.92871C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V5.67503C6.56641 5.29613 6.87463 4.98612 7.25134 4.98612H11.1326V4.92871H7.25134Z"
              fill="white"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask6_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_8121_260)">
            <path
              d="M13.1874 14.975H7.25134C6.87463 14.975 6.56641 14.665 6.56641 14.2861V14.3435C6.56641 14.7224 6.87463 15.0325 7.25134 15.0325H13.1874C13.5641 15.0325 13.8723 14.7224 13.8723 14.3435V14.2861C13.8723 14.665 13.5641 14.975 13.1874 14.975Z"
              fill="#1A237E"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask7_8121_260"
            maskUnits="userSpaceOnUse"
            x="6"
            y="4"
            width="8"
            height="12"
          >
            <path
              d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask7_8121_260)">
            <path
              d="M11.8197 7.68402C11.4413 7.68402 11.1348 7.37574 11.1348 6.99512V7.05253C11.1348 7.43315 11.4413 7.74144 11.8197 7.74144H13.8745V7.68402H11.8197Z"
              fill="#1A237E"
              fillOpacity="0.1"
            />
          </g>
          <path
            d="M11.1326 4.92871H7.25134C6.87463 4.92871 6.56641 5.23872 6.56641 5.61762V14.3438C6.56641 14.7227 6.87463 15.0327 7.25134 15.0327H13.1874C13.5641 15.0327 13.8723 14.7227 13.8723 14.3438V7.68433L11.1326 4.92871Z"
            fill="url(#paint1_radial_8121_260)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_8121_260"
          x1="12.6038"
          y1="7.70168"
          x2="12.6038"
          y2="10.0363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" stopOpacity="0.2" />
          <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_8121_260"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.79786 5.12842) scale(11.7807)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_8121_260">
          <rect
            width="7.81844"
            height="10.7102"
            fill="white"
            transform="translate(6.56641 4.32227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
