import { ResourceModel } from "@/components/Connector/types";
import {
  ContextBaseModel,
  ContextStatus,
  ContextType
} from "../ContextBase/types";
import { Layout } from "react-grid-layout";

export type ArtifactCitationModel = {
  pageContent: string;
  metadata: {
    resourceId: string;
    resourceModel: ResourceModel;
    contextId: string;
    contextName: string;
    contextType: ContextType;
    id: number;
  };
};

export type ContextModel = {
  contextId: string;
  userId: string;
  contextName: string;
  contextType: ContextType;
  contextContent: string;
  contextBaseId: string;
  contextBaseModel: ContextBaseModel;
  resourceId?: string;
  resourceModel?: ResourceModel;
  status: ContextStatus;
  createdAt: number;
  latencyInMs: number;
};

export enum ArtifactType {
  DOCUMENT = "DOCUMENT",
  DASHBOARD = "DASHBOARD",
  PRESENTATION = "PRESENTATION",
  DOCUMENTV4 = "DOCUMENTV4"
}

export type ArtifactMetadata = {
  startAt?: Date;
  endAt?: Date;
  audience?: string;
  format?: string;
  purpose?: string;
};

export type ArtifactModel = {
  artifactId: string;
  artifactTitle: string;
  artifactType: ArtifactType;
  artifactMetadata?: ArtifactMetadata;
  artifactTiles?: string;
  kpiDatasets?: string;
  chartDatasets?: string;
  artifactContent: string;
  artifactTemplateId: string;
  artifactLayout?: Layout[];
  userId: string;
  createdAt: number;
  updatedAt?: number;
};

export enum ArtifactTemplateStatus {
  GENERATING = "GENERATING",
  COMPLETE = "COMPLETE"
}

export enum ArtifactTemplateType {
  PLACEHOLDER = "PLACEHOLDER",
  DOCUMENT = "DOCUMENT",
  DASHBOARD = "DASHBOARD",
  PRESENTATION = "PRESENTATION"
}

export type ArtifactTemplateModel = {
  templateId: string;
  templateTitle: string;
  templateDescription: string;
  templateType: string;
  artifactId?: string;
  userId: string;
  status: ArtifactTemplateStatus;
  templateContent?: string;
  createdAt: number;
};

export type ArtifactPublishModel = {
  artifactId: string;
  userId: string;
  publishId: string;
  publishContent: string;
  publishTiles?: string;
  publishTitle: string;
  publishMetadata: ArtifactMetadata;
  publishLayout?: Layout[];
  createdAt: number;
  updateAt?: number;
};
