// import { CheckCircle2Icon } from "lucide-react";
// import { CircleIcon } from "lucide-react";
// import { CrossIcon } from "lucide-react";
import { HiQuestionMarkCircle } from "react-icons/hi2";
// import { IoStopwatchOutline } from "react-icons/io5";
import { SiOpenai } from "react-icons/si";
import { RxCheck } from "react-icons/rx";
import { RxCross2 } from "react-icons/rx";

export const labels = [
  {
    value: "admin",
    label: "Admin"
  },
  {
    value: "basic",
    label: "Basic"
  },
  {
    value: "pro",
    label: "Pro"
  }
];

export const services = [
  {
    value: "chatgpt",
    label: "OpenAI",
    icon: SiOpenai
  },
  {
    value: "claude",
    label: "Claude",
    icon: HiQuestionMarkCircle
  }
];

export const booleanOptions = [
  { label: "Enabled", value: "true", icon: RxCheck },
  { label: "Disabled", value: "false", icon: RxCross2 }
];
