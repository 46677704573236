import { ArtifactModel } from "@/containers/ArtifactBuilder/types";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { createContext, useCallback, useContext, useEffect, useState } from "react";

interface ArtifactsListContextProps {
  artifacts: ArtifactModel[];
  setArtifacts: React.Dispatch<React.SetStateAction<ArtifactModel[]>>;
  isArtifactsListLoading: boolean;
}

const ArtifactsListContext = createContext<ArtifactsListContextProps>({
  artifacts: [],
  setArtifacts: () => {
    throw new Error('not implemented');
  },
  isArtifactsListLoading: false
});

const ArtifactsListProvider = ({ children }: { children: React.ReactNode }) => {
  const { sendApiRequest } = useContext(AuthContext);
  const [artifacts, setArtifacts] = useState<ArtifactModel[]>([]);
  const [isArtifactsListLoading, setIsArtifactsListLoading] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsArtifactsListLoading(true);
      const artifactsResponse = (await sendApiRequest(
        "/artifacts",
        "GET"
      )) as { data: { artifacts: ArtifactModel[] } };
      setArtifacts(artifactsResponse.data.artifacts);
    } catch (error) {
      console.error(error);
    } finally {
      setIsArtifactsListLoading(false);
    }
  }, [sendApiRequest]);
  
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const contextValue = {
    artifacts,
    setArtifacts,
    isArtifactsListLoading
  };

  return (
    <ArtifactsListContext.Provider value={contextValue}>
      {children}
    </ArtifactsListContext.Provider>
  );
};

export { ArtifactsListProvider, ArtifactsListContext };