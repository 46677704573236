import { useContext, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  LuPaintBucket,
  LuBarChartBig,
  LuDatabase,
  LuAlertCircle
} from "react-icons/lu";
import {
  ChartTile,
  VisualType,
  TabularData
} from "@/containers/ReviewBuilder/review-types";
import { ArtifactContext } from "@/containers/ArtifactBuilder/ArtifactProvider";
import Hint from "@/components/Tooltips/Hint";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger
} from "@/components/ui/select";
import { capitalizeFirstLetter } from "@/lib/utils";

export default function ChartToolbar({ tile }: { tile: ChartTile }) {
  const { chartDatasets, updateTileContent } = useContext(ArtifactContext);
  const [chartData, setChartData] = useState<TabularData>(tile.content);

  const handleTypeChange = (type: VisualType) => {
    const updatedData = {
      ...chartData,
      visual_type: type
    };

    updateTileContent(tile.id, updatedData);
    setChartData(updatedData);
  };

  const handleContentUpdate = (selectedTitle: string) => {
    const selectedDataset = chartDatasets.find(
      (d) => d.title === selectedTitle
    );
    if (selectedDataset) {
      setChartData(selectedDataset);
      updateTileContent(tile.id, selectedDataset);
    }
  };

  const isValidVisualization = (type: VisualType) => {
    if (!chartData.rows || !chartData.rows.length) return false;

    if (type === VisualType.TABLE) return true;

    const hasNumericValues = chartData.rows.some((row) =>
      row.slice(1).some((val) => {
        const numeric = typeof val === "number" ? val : parseFloat(val);
        return !isNaN(numeric);
      })
    );

    if (!hasNumericValues) {
      return false;
    }

    const numericValues = chartData.rows
      .flatMap((row) =>
        row
          .slice(1)
          .map((val) => (typeof val === "number" ? val : parseFloat(val)))
      )
      .filter((val) => !isNaN(val));

    const max = Math.max(...numericValues);
    const min = Math.min(...numericValues);

    if (max / min > 1000) {
      return false;
    }

    return true;
  };

  return (
    <div className="flex flex-nowrap gap-1 overflow-x-scroll no-scrollbar w-full px-3 py-1.5">
      <Select
        value={chartData.visual_type?.toUpperCase()}
        onValueChange={handleTypeChange}
      >
        <SelectTrigger asChild>
          <Button variant="ghost" className="hover:opacity-100 relative">
            <Hint
              message={"select chart type"}
              side="bottom"
              offset={20}
              className="opacity-100"
            >
              <LuBarChartBig size={16} />
            </Hint>
          </Button>
        </SelectTrigger>
        <SelectContent className="data-[state=open]:rounded-t-lg my-3 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
          <SelectGroup>
            <SelectLabel className="text-dark-text dark:text-light-text">
              Chart Type
            </SelectLabel>
            {[
              VisualType.TABLE,
              VisualType.LINE,
              VisualType.AREA,
              VisualType.BAR
            ].map((type) => (
              <SelectItem key={type} value={type}>
                {!isValidVisualization(type) ? (
                  <Hint
                    message="Not recommended for this data"
                    side="right"
                    offset={5}
                  >
                    <div className="flex gap-1 items-center">
                      {capitalizeFirstLetter(type)}
                      <LuAlertCircle className="text-brev-gray/50" size={16} />
                    </div>
                  </Hint>
                ) : (
                  <div className="flex gap-1 items-center">
                    {capitalizeFirstLetter(type)}
                  </div>
                )}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select value={chartData.title} onValueChange={handleContentUpdate}>
        <SelectTrigger asChild>
          <Button variant="ghost" className="hover:opacity-100">
            <Hint message="Select dataset" side="bottom" offset={20}>
              <LuDatabase size={16} />
            </Hint>
          </Button>
        </SelectTrigger>
        <SelectContent className="data-[state=open]:rounded-t-lg my-3 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text ">
          <SelectGroup>
            <SelectLabel className="text-dark-text dark:text-light-text">
              Chart datasets
            </SelectLabel>
            {chartDatasets
              .filter((d) => d?.title)
              .map((d, index) => (
                <SelectItem
                  key={`dataset-${index}`}
                  value={d.title}
                  className="text-[12px] text-dark-text dark:text-light-text"
                >
                  {d?.title}
                </SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>

      <Select value={""} disabled>
        <SelectTrigger asChild>
          <Button variant="ghost" className="hover:opacity-100" disabled>
            <Hint message="Color scheme" side="bottom" offset={20}>
              <LuPaintBucket size={16} />
            </Hint>
          </Button>
        </SelectTrigger>
        <SelectContent className="data-[state=open]:rounded-t-lg my-3 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text ">
          <SelectGroup>
            <SelectLabel className="text-dark-text dark:text-light-text">
              Colors
            </SelectLabel>
            {["red", "blue", "green", "yellow", "orange", "purple"].map(
              (color) => (
                <SelectItem
                  key={color}
                  value={color}
                  className="text-[12px] text-dark-text dark:text-light-text"
                >
                  {color}
                </SelectItem>
              )
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </div>
  );
}
