import { useEffect, useState } from "react";
import { NodeViewWrapper } from "@tiptap/react";
import {
  TabularData,
  VisualType
} from "@/containers/ReviewBuilder/review-types";
import GenericDataWidget from "@/containers/ReviewBuilder/DataVisualWidgets/GenericDataWidget";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function SharedChart(props: any) {
  const [generatedDataset, setGeneratedDataset] = useState<TabularData | null>(
    props.node.attrs.generatedvalue
      ? JSON.parse(props.node.attrs.generatedvalue).dataset
      : null
  );

  const chartType = props.node.attrs.visual || VisualType.LINE;

  useEffect(() => {
    if (props.node.attrs.generatedvalue) {
      const dataset = JSON.parse(props.node.attrs.generatedvalue);
      setGeneratedDataset(dataset);
    }
  }, [props.node.attrs.generatedvalue]);

  return (
    <NodeViewWrapper as="div" className="chartnode">
      <div
        className={`${props.node.attrs.className ?? ""} my-4 w-full p-3 text-[10px] text-dark-text dark:text-brev-light/90 mr-1 rounded-lg border-solid border group`}
      >
        <div className="flex flex-col w-full gap-2">
          <div className="group flex w-full justify-between">
            <div className="flex flex-col">
              {generatedDataset && generatedDataset.title && (
                <span className="text-lg font-bold m-2">
                  {generatedDataset.title}
                </span>
              )}
              {generatedDataset && generatedDataset.data_description && (
                <span className="text-sm m-2">
                  {generatedDataset.data_description}
                </span>
              )}
            </div>
          </div>
          {generatedDataset && (
            <GenericDataWidget
              visualWidget={{
                visualType: chartType,
                dataset: generatedDataset
              }}
            />
          )}
        </div>
      </div>
    </NodeViewWrapper>
  );
}

export default SharedChart;
