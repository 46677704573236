type SlideContent = {
    type: "subtitle" | "bulletList" | "paragraph" | "chart" | "kpi" | "goal";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    className?: string;
    gridArea?: string;
};


type SlideData = {
    title: string;
    content: SlideContent[];
};


export const sampleSlides: SlideData[] = [
    {
        title: "Engineering Performance Overview",
        content: [
            {
                type: "subtitle",
                data: "Key Highlights for August 2024",
                gridArea: "1 / 1 / 2 / 7"
            },
            {
                type: "bulletList",
                data: [
                    "Successful promotion to staging environment after over two months",
                    "Significant progress on PDE tasks and Cypress test automation",
                    "Multiple bug fixes and UI improvements implemented",
                    "Preparation for pre-launch testing phase"
                ],
                gridArea: "2 / 1 / 5 / 4"
            },
            {
                type: "kpi",
                data: {
                    value: 100,
                    previous: 0,
                    title: "AI Feature Success Rate",
                    period: "August 2024"
                },
                gridArea: "2 / 4 / 4 / 7"
            },
            {
                type: "kpi",
                data: {
                    value: 31,
                    previous: 0,
                    title: "Commits",
                    period: "August 2024"
                },
                gridArea: "4 / 4 / 6 / 7"
            }
        ]
    },
    {
        title: "Key Development Milestones",
        content: [
            {
                type: "bulletList",
                data: [
                    "Implemented chart regeneration with LLM-supplied visual types",
                    "Fixed permission issues in Slack and Google Drive integrations",
                    "Resolved duplicate connection bugs",
                    "Improved artifact context persistence",
                    "Enhanced UI stability and user experience"
                ],
                gridArea: "1 / 1 / 4 / 4"
            },
            {
                type: "bulletList",
                data: [
                    "Implemented dynamic Google Drive search",
                    "Fixed Slack token refresh mechanism",
                    "Resolved artifact context persistence issues",
                    "Improved integration entity state updates",
                    "Enhanced chart regeneration functionality"
                ],
                gridArea: "1 / 4 / 4 / 7"
            },
            {
                type: "paragraph",
                data: "The team has made significant strides in improving the overall stability and functionality of the application. These enhancements have brought us closer to a launch-ready state, with a focus on user experience and reliability.",
                gridArea: "4 / 1 / 7 / 7"
            }
        ]
    },
    {
        title: "Testing and Quality Assurance",
        content: [
            {
                type: "paragraph",
                data: "Benn has made substantial progress in implementing Cypress test automation, which will significantly enhance our ability to catch bugs and ensure product stability. The team has observed a 100% success rate in AI-powered features, including template generation, artifact streaming, and dataset generation.",
                gridArea: "1 / 1 / 3 / 7"
            },
            {
                type: "goal",
                data: {
                    title: "Test Automation Coverage",
                    goal: 100,
                    current: 80,
                    unit: "%"
                },
                gridArea: "3 / 1 / 6 / 4"
            },
            {
                type: "bulletList",
                data: [
                    "Implemented Cypress tests for shared artifacts",
                    "Added chart comparison tests",
                    "Improved email login Cypress test",
                    "Enhanced overall test suite robustness"
                ],
                gridArea: "3 / 4 / 6 / 7"
            }
        ]
    },
    {
        title: "Upcoming Priorities",
        content: [
            {
                type: "bulletList",
                data: [
                    "Complete remaining PDE tasks",
                    "Finalize Cypress test automation",
                    "Conduct thorough pre-launch testing",
                    "Implement error logging solutions",
                    "Address feedback from Chris and make necessary adjustments",
                    "Prepare for promotion to production environment"
                ],
                gridArea: "1 / 1 / 4 / 7"
            },
            {
                type: "paragraph",
                data: "The team is focused on finalizing key features and ensuring robust testing coverage before the upcoming launch. Emphasis will be placed on addressing any remaining bugs, optimizing performance, and implementing comprehensive error logging to facilitate quick issue resolution in production.",
                gridArea: "4 / 1 / 7 / 7"
            }
        ]
    },
    {
        title: "Commit Activity",
        content: [
            {
                type: "chart",
                data: {
                    title: "Daily Commit Count",
                    description: "Number of commits per day in August 2024",
                    xLabel: "Date",
                    yLabel: "Commits",
                    data: [
                        { x: "2024-08-28", y: 9 },
                        { x: "2024-08-29", y: 11 },
                        { x: "2024-08-30", y: 11 }
                    ],
                    visualType: "BAR"
                },
                gridArea: "1 / 1 / 7 / 7"
            }
        ]
    },
    {
        title: "Team Collaboration and Communication",
        content: [
            {
                type: "paragraph",
                data: "The engineering team has demonstrated excellent collaboration throughout August. Regular syncs and clear communication have facilitated rapid problem-solving and efficient task allocation. The team's ability to adapt to challenges and maintain a high level of productivity has been crucial in preparing for the upcoming launch.",
                gridArea: "1 / 1 / 3 / 7"
            },
            {
                type: "bulletList",
                data: [
                    "Effective use of Slack for real-time updates and issue tracking",
                    "Timely code reviews and pair programming sessions",
                    "Transparent sharing of progress and blockers in daily standups",
                    "Collaborative problem-solving on complex integration issues"
                ],
                gridArea: "3 / 1 / 6 / 4"
            },
            {
                type: "kpi",
                data: {
                    value: 95,
                    previous: 85,
                    title: "Team Collaboration Score",
                    period: "August 2024"
                },
                gridArea: "3 / 4 / 5 / 7"
            }
        ]
    },
    {
        title: "Testing and Quality Assurance",
        content: [
            {
                type: "kpi",
                data: {
                    value: 100,
                    previous: 0,
                    title: "AI Feature Success Rate",
                    period: "August"
                },
                gridArea: "1 / 1 / 2 / 4"
            },
            {
                type: "kpi",
                data: {
                    value: 3,
                    previous: 0,
                    title: "Major Test Suites Completed",
                    period: "August"
                },
                gridArea: "1 / 4 / 2 / 7"
            },
            {
                type: "paragraph",
                data: "Our focus on testing has yielded impressive results, with a 100% success rate in AI-powered features. The implementation of Cypress tests for shared artifacts and chart comparisons has significantly enhanced our QA process.",
                gridArea: "2 / 1 / 4 / 7"
            },
            {
                type: "bulletList",
                data: [
                    "Implemented Cypress tests for shared artifacts",
                    "Developed chart comparison tests",
                    "Enhanced overall test automation framework",
                    "Achieved 100% success rate in AI feature testing"
                ],
                gridArea: "4 / 1 / 6 / 7"
            }
        ]
    }
];
