import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import SharedChart from "../TemplateComponents/SharedChart";

export const SharedChartExtension = Node.create({
  name: "chartnode",
  group: "block",
  content: "inline*",
  draggable: true,
  atom: true,

  addAttributes() {
    return {
      generatedvalue: {
        default: null
      },
      visual: {
        default: null
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "chartnode",
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) return {};
          return {
            generatedvalue: element.getAttribute("data-generated-value"),
            visual: element.getAttribute("data-visual")
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, {
      class: "chartnode",
      "data-type": "chart"
    });
    return ["chartnode", attrs, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(SharedChart);
  }
});
