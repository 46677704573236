import ReactDOM from "react-dom/client";
import "./index.css";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";
import { StrictMode } from "react";
import HomeLayout from "./containers/Home/HomeLayout.tsx";
import Rhythms from "./containers/Rhythms/Rhythms.tsx";
import Login from "./containers/Login/Login.tsx";
import { Toaster } from "./components/ui/toaster";
import OnboardingContainer from "@/containers/Onboarding/OnboardingContainer.tsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Upgrade from "./containers/Upgrade/Upgrade.tsx";
import ComponentsContainer from "./containers/Components/ComponentsContainer";
import * as Sentry from "@sentry/react";
import OAuth2Callback from "./containers/OAuth2Callback.tsx";
import SettingsContainer from "./containers/Settings/Settings.tsx";
import LogoutButton from "./containers/Onboarding/LogoutButton.tsx";
import { RhythmsProvider } from "./containers/Rhythms/RhythmsProvider";
import ArtifactBuilderPage from "./containers/ArtifactBuilder/ArtifactBuilderPage.tsx";
import { ArtifactProvider } from "./containers/ArtifactBuilder/ArtifactProvider.tsx";
import SharedArtifact from "./containers/Shared/SharedArtifact.tsx";
import AdminContainer from "./containers/Admin/AdminContainer.tsx";
import ArtifactHome from "./containers/ArtifactBuilder/ArtifactHome.tsx";
import { AuthProvider } from "./containers/Auth/AuthProvider.tsx";
import AuthCallback from "./containers/Auth/AuthCallback.tsx";
import LinkSent from "./containers/Auth/LinkSent.tsx";
import { WebSocketProvider } from "./containers/WebSocket/WebSocketProvider.tsx";
import { GlobalProvider } from "./GlobalProvider.tsx";
import ErrorPage from "./Error/ErrorPage.tsx";
import GridProvider from "./containers/ArtifactBuilder/ArtifactCanvas/GridProvider";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={"/home"} />,
    errorElement: <ErrorPage />
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />
  },
  {
    path: "/signup",
    element: <Login />,
    errorElement: <ErrorPage />
  },
  {
    path: "/auth/link-sent",
    element: <LinkSent />,
    errorElement: <ErrorPage />
  },

  {
    path: "/logout",
    element: <LogoutButton />,
    errorElement: <ErrorPage />
  },
  {
    path: "/artifacts/publish/:publishId",
    element: <SharedArtifact />,
    errorElement: <ErrorPage />
  },
  {
    path: "/auth/callback",
    element: <AuthCallback />,
    errorElement: <ErrorPage />
  },
  {
    element: (
      <GlobalProvider>
        <AuthProvider>
          <HomeLayout />
        </AuthProvider>
      </GlobalProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/home",
        element: <ArtifactHome />
      },
      {
        path: "/artifacts/:artifactId",
        element: (
          <WebSocketProvider>
            <ArtifactProvider>
              <GridProvider>
                <ArtifactBuilderPage />
              </GridProvider>
            </ArtifactProvider>
          </WebSocketProvider>
        )
      },
      {
        path: "/rhythms",
        element: (
          <GoogleOAuthProvider clientId="1019588479745-hi56ga4o2656shnnku3thkskqali1qbl.apps.googleusercontent.com">
            <RhythmsProvider>
              <Rhythms />
            </RhythmsProvider>
          </GoogleOAuthProvider>
        )
      },
      {
        path: "/settings",
        element: <SettingsContainer />
      },
      {
        path: "/onboarding",
        element: <OnboardingContainer />
      },
      {
        path: "/oauth2/callback",
        element: <OAuth2Callback />
      },
      {
        path: "/upgrade",
        element: <Upgrade />
      },
      {
        path: "/admin",
        element: <AdminContainer />
      },
      {
        path: "/components",
        element: <ComponentsContainer />
      }
    ]
  },
  {
    path: "*",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
    loader: () => {
      throw new Response("Not Found", { status: 404 });
    }
  }
]);

Sentry.init({
  dsn: "https://efed2baa8441c2fa04c16153b9554e80@o4507901385965568.ingest.us.sentry.io/4507901390356480",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/api-dev.brevcloud\.com\/v3/, /^https:\/\/api-staging.brevapp\.com\/v3/, /^https:\/\/api.brevapp\.com\/v3/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <RouterProvider router={router} />
    <Toaster />
  </StrictMode>
);
