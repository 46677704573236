import { motion, AnimatePresence } from "framer-motion";
import { useState, useContext, useEffect } from "react";
import { GridContext } from "./GridProvider";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { LuSendHorizonal } from "react-icons/lu";
import DrawerToolbar from "./DrawerToolbar";

export default function ArtifactDrawer() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [inputValue, setInputValue] = useState("");

  const { activeTile } = useContext(GridContext);

  useEffect(() => {
    if (activeTile && activeTile.content?.title) {
      setIsDrawerOpen(true);
    }
  }, [activeTile]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  // const suggestions = ["Suggestion 1", "Suggestion 2", "Suggestion 3"];

  return (
    <div className="absolute bottom-0 left-0 right-0">
      <Button
        variant="ghost"
        onClick={toggleDrawer}
        className="absolute top-0 left-1/2 -translate-x-1/2 transform w-12 h-6 flex items-center justify-center transition-colors z-10 hover:bg-transparent"
      >
        <motion.svg
          animate={{ rotate: isDrawerOpen ? 180 : 0 }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="w-5 h-5 text-gray-500 dark:text-gray-400"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 15l7-7 7 7"
          />
        </motion.svg>
      </Button>
      <motion.div
        initial={false}
        animate={{
          height: isDrawerOpen ? "108px" : "24px"
        }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="w-full bg-transparent overflow-hidden"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: isDrawerOpen ? 1 : 0
          }}
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
          className="flex-1 flex justify-center items-end h-full"
          style={{
            pointerEvents: isDrawerOpen ? "auto" : "none"
          }}
        >
          <div className="flex-1 flex flex-col items-center justify-end h-full">
            <div className="w-full max-w-3xl relative">
              <div className="absolute left-0 -top-[0.125rem]">
                <DrawerToolbar />
              </div>
              <div className="flex items-end gap-2">
                <div className="flex-1 flex flex-col pl-12 mb-2">
                  <div className="h-[34px] flex w-full">
                    <AnimatePresence>
                      {activeTile && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          transition={{
                            type: "spring",
                            stiffness: 300,
                            damping: 30
                          }}
                          className="flex w-full justify-between items-center pl-2 pr-1 py-2 bg-light-alt dark:bg-dark-alt rounded-t-lg border-t border-x border-brev-accent text-xs z-0"
                        >
                          <div className="flex gap-2 items-center">
                            <Badge className="py-0 px-1 text-[10px]">
                              {activeTile.tile_type}
                            </Badge>
                            <span>{activeTile.content?.title}</span>
                          </div>
                          {/* <div className="flex gap-1">
                            {suggestions.map((suggestion) => (
                              <Button
                                variant="outline"
                                className="w-[8rem] h-[1.65rem]"
                              >
                                <div className="line-clamp-1 truncate">
                                  {suggestion}
                                </div>
                              </Button>
                            ))}
                          </div> */}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                  <Input
                    type="text"
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className={`${
                      activeTile
                        ? "border-b-brev-accent border-x-brev-accent dark:border-b-brev-accent dark:border-x-brev-accent  rounded-b-lg rounded-t-none"
                        : "rounded-lg"
                    } w-full px-6 py-2 text-base bg-light-main dark:bg-dark-main border border-brev-border dark:border-dark-border shadow-sm outline-none transition-all duration-500 ease-in-out
                          focus:border-brev-border dark:focus:border-dark-border text-dark-text dark:text-light-text focus-within:bg-light-main dark:focus-within:bg-dark-main`}
                    placeholder="Type your message here..."
                    outerClassName="z-10"
                    autoFocus={isDrawerOpen}
                    end={
                      <Button variant="ghost" className="p-0 m-0">
                        <LuSendHorizonal
                          size={20}
                          className="text-dark-text dark:text-light-text"
                        />
                      </Button>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}
