import ArtifactSvg from "@/components/Icons/ArtifactSvg";
import NavSubMenu from "./NavSubMenu";
import type { NavSubMenuContent } from "./types";
import { useState } from "react";
import ArtifactSubMenu from "./ArtifactSubMenu";
import { useLocation } from "react-router-dom";
import RhythmsSvg from "@/components/Icons/RhythmsSvg";
import ObserveSvg from "@/components/Icons/ObserveSvg";
import GoalsSvg from "@/components/Icons/GoalsSvg";
import SettingsSvg from "@/components/Icons/SettingsSvg";
import NavButton from "./NavButton";
import Hint from "../Tooltips/Hint";
import { ArtifactsListProvider } from "./ArtifactsListProvider";
import UserSettingsDialog from "@/containers/UserSettings/UserSettingsDialog";
import { Link } from "react-router-dom";
import BrevLogo from "../ui/brevlogo";

export default function NavMenu({ children }: { children: React.ReactNode }) {
  const [sub, setSub] = useState<NavSubMenuContent>({
    name: "artifacts",
    component: <ArtifactSubMenu />
  });
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="flex flex-1 min-h-0 w-full overflow-hidden pr-2 pb-2 bg-brev font-[Poppins]">
      <div className="flex flex-shrink-0 transition-all duration-300 overflow-hidden">
        <div className="flex flex-col gap-3 h-full w-24 flex-shrink-0 bg-brev py-3 relative">
          <div className="absolute flex w-full items-center justify-center bottom-6">
            <UserSettingsDialog openTab={0} />
          </div>
          <div className="flex w-full items-center justify-center mb-10">
            <Link to="/home" className="text-white no-underline">
              <BrevLogo dark isMinimal size={36} />
            </Link>
          </div>
          <NavButton
            onClick={() => {
              setSub({
                name: "artifacts",
                component: <ArtifactSubMenu />
              });
              setIsOpen(!isOpen);
            }}
            icon={
              <ArtifactSvg
                size={20}
                isActive={location.pathname.includes("/artifacts")}
              />
            }
            label="Artifacts"
            active={sub.name === "artifacts"}
          />
          <Hint message="Contact sales">
            <NavButton
              disabled
              onClick={() => setSub({ name: "rhythms" })}
              icon={
                <RhythmsSvg
                  size={20}
                  isActive={location.pathname.includes("/rhythms")}
                />
              }
              label="Rhythms"
              active={sub.name === "rhythms"}
            />
          </Hint>
          <Hint message="Contact sales">
            <NavButton
              disabled
              onClick={() => setSub({ name: "rhythms" })}
              icon={
                <ObserveSvg
                  size={20}
                  isActive={location.pathname.includes("/observe")}
                />
              }
              label="Observe"
              active={sub.name === "observe"}
            />
          </Hint>
          <Hint message="Contact sales">
            <NavButton
              disabled
              onClick={() => setSub({ name: "rhythms" })}
              icon={
                <GoalsSvg
                  size={20}
                  isActive={location.pathname.includes("/goals")}
                />
              }
              label="Goals"
              active={sub.name === "goals"}
            />
          </Hint>
          <Hint message="Contact sales">
            <NavButton
              disabled
              onClick={() => setSub({ name: "rhythms" })}
              icon={
                <SettingsSvg
                  size={20}
                  isActive={location.pathname.includes("/settings")}
                />
              }
              label="Settings"
              active={sub.name === "settings"}
            />
          </Hint>
        </div>
      </div>
      <div className="flex flex-grow rounded-[8px] overflow-hidden">
        {/* Artifacts list provider is needed for the recent artifacts component; to be optimized */}
        {sub.name === "artifacts" && (
          <ArtifactsListProvider>
            <NavSubMenu sub={sub} isOpen={isOpen} setIsOpen={setIsOpen} />
            {children}
          </ArtifactsListProvider>
        )}
        {sub.name !== "artifacts" && (
          <>
            <NavSubMenu sub={sub} isOpen={isOpen} setIsOpen={setIsOpen} />
            {children}
          </>
        )}
      </div>
    </div>
  );
}
