import { useEffect, useState } from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { MemberRole } from "@/lib/types";
import AddOrganizationUser from "./AddOrganizationMember";
import {
  getOrgId,
  getOrgMembers,
  getOrgRole,
  updateOrgMember
} from "@/lib/utils";
import { toast } from "@/components/ui/use-toast";
// import { Auth } from "aws-amplify";
import { InvitationStatus, OrgUser } from "./types";
import { ChevronDown } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
// import { Input } from "@/components/ui/input";
import MemoizedUserCard from "@/components/Card/UserCard";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Auth } from "aws-amplify";
import BrevLoader from "@/components/Loaders/BrevLoader";

export default function OrganizationMembers() {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [userId, setUserid] = useState<string>("");
  const [rowSelection, setRowSelection] = useState({});
  const [users, setUsers] = useState<OrgUser[]>([]);
  const orgId = getOrgId();
  const orgRole = getOrgRole();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    Auth.currentUserInfo()
      .then((u) => u.id)
      .then((userId) => {
        setUserid(userId);
      });
  }, []);

  useEffect(() => {
    if (orgId) {
      setIsLoading(true);
      getOrgMembers(orgId).then((response) => {
        setUsers(response.data);
        setIsLoading(false);
      });
    }
  }, [orgId]);

  const columns: ColumnDef<OrgUser>[] = [
    {
      id: "userName",
      accessorKey: "userName",
      cell: ({ row }) => {
        return (
          <div className="pr-3 flex flex-column">
            {/* <span>{row.getValue("userName")}</span>
            <span className="text-xs text-slate-500">{row.original.email}</span> */}
            <MemoizedUserCard
              dark
              firstName={row.original.userName}
              lastName=""
              email={row.original.email}
            />
          </div>
        );
      },
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            User
            <ChevronDown
              className={`ml-2 h-4 w-4 shrink-0 transform ${column.getIsSorted() === "asc" ? "rotate-0" : "rotate-180"} transition-transform duration-200`}
            />
          </Button>
        );
      }
    },
    {
      id: "role",
      accessorKey: "role",
      cell: ({ row }) => {
        const role = row.getValue("role") as MemberRole;
        const isCurrUser = userId && row.original.userId === userId;
        if (isCurrUser || orgRole !== MemberRole.ADMIN) {
          return (
            <div className="text-xs">
              {row.getValue("role")}
              {isCurrUser && (
                <span className="bg-slate-200 mx-2 p-1 text-xs rounded-[8px] text-slate-500 border-2">
                  current user
                </span>
              )}
            </div>
          );
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button className="flex center w-[82px] h-[24px] font-medium text-[10px] rounded-[8px] select-none">
                <div className="capitalize">{role}</div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="rounded-[8px] text-white text-xs bg-brev"
            >
              {Object.values(MemberRole).map((role) => (
                <DropdownMenuCheckboxItem
                  key={role}
                  checked={row.getValue("role") === role}
                  onCheckedChange={() => {
                    const memberId = row.original.memberId;
                    const updates = {
                      role: role as MemberRole,
                      memberStatus: row.original.memberStatus
                    };
                    setIsLoading(true);
                    updateOrgMember(orgId!, memberId, updates)
                      .then((_response) => {
                        toast({
                          title: "User updated",
                          description: `"${row.original.userName ?? row.original.email}" has been updated to role "${row.getValue("role")}"`
                        });
                      })
                      .catch((reason) => {
                        toast({
                          title: "Error updating member",
                          description: reason.toString()
                        });
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                    setUsers((currentUsers: OrgUser[]) => {
                      return currentUsers.map((user) => {
                        if (user.memberId === memberId) {
                          // Spread the existing user and apply updates
                          return { ...user, ...updates };
                        }
                        return user; // Return unchanged user
                      });
                    });
                  }}
                  className="capitalize"
                >
                  {role}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Role
            <ChevronDown
              className={`ml-2 h-4 w-4 shrink-0 ${column.getIsSorted() === "asc" ? "rotate-0" : "rotate-180"} transition-transform duration-200`}
            />
          </Button>
        );
      }
    },
    {
      id: "team",
      accessorKey: "team",
      cell: ({ row }) => {
        return <div className="text-xs">{row.getValue("team")}</div>;
      },
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Team
            <ChevronDown
              className={`ml-2 h-4 w-4 shrink-0 ${column.getIsSorted() === "asc" ? "rotate-0" : "rotate-180"} transition-transform duration-200`}
            />
          </Button>
        );
      }
    },
    {
      id: "memberStatus",
      accessorKey: "memberStatus",
      cell: ({ row }) => {
        const status = row.getValue("memberStatus") as InvitationStatus;
        let stylingColor = "bg-slate-200 text-slate-700";
        if (status === InvitationStatus.ACTIVE) {
          stylingColor = "bg-[#3FCFF150]";
        } else if (status === InvitationStatus.PENDING) {
          stylingColor = "bg-[#FFC64550]";
        } else if (status === InvitationStatus.INACTIVE) {
          stylingColor = "bg-[#FF668A50]";
        }

        // const memberStatusArray = Object.entries(InvitationStatus).map(([name, value]) => {
        //   return { name, value };
        // });

        if (
          (userId && row.original.userId === userId) ||
          orgRole !== MemberRole.ADMIN
        ) {
          return (
            <div
              className={`flex items-center justify-center ${stylingColor} rounded-[8px] text-[10px] font-medium w-[82px] h-[24px]`}
            >
              {row.getValue("memberStatus")}
            </div>
          );
        }

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                className={`flex center w-[82px] h-[24px] font-medium text-[10px] rounded-[8px] select-none
                  ${
                    row.getValue("memberStatus") === InvitationStatus.ACTIVE
                      ? "bg-[#3FCFF150]"
                      : row.getValue("memberStatus") ===
                          InvitationStatus.PENDING
                        ? "bg-[#FFC64550]"
                        : "bg-[#FF668A50]"
                  }
                `}
              >
                <div className="capitalize">{status}</div>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="end"
              className="rounded-[8px] text-white text-xs bg-brev"
            >
              {Object.values(InvitationStatus).map((status) => (
                <DropdownMenuCheckboxItem
                  key={status}
                  checked={row.getValue("memberStatus") === status}
                  onCheckedChange={() => {
                    const memberId = row.original.memberId;
                    const updates = {
                      memberStatus: status as InvitationStatus,
                      role: row.original.role
                    };
                    setIsLoading(true);
                    updateOrgMember(orgId!, memberId, updates)
                      .then((_response) => {
                        toast({
                          title: "User updated",
                          description: `"${row.original.userName ?? row.original.email}" has been updated to status "${status}"`
                        });
                      })
                      .catch((reason) => {
                        toast({
                          title: "Error updating member",
                          description: reason.toString()
                        });
                      })
                      .finally(() => {
                        setIsLoading(false);
                      });
                    setUsers((currentUsers: OrgUser[]) => {
                      return currentUsers.map((user) => {
                        if (user.memberId === memberId) {
                          // Spread the existing user and apply updates
                          return { ...user, ...updates };
                        }
                        return user; // Return unchanged user
                      });
                    });
                  }}
                  className="capitalize"
                >
                  {status}
                </DropdownMenuCheckboxItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            className="p-0"
          >
            Status
            <ChevronDown
              className={`ml-2 h-4 w-4 shrink-0 ${column.getIsSorted() === "asc" ? "rotate-0" : "rotate-180"} transition-transform duration-200`}
            />
          </Button>
        );
      }
    },
    {
      id: "actions",
      cell: (_props) => {},
      header: (_props) => {
        return (
          <div className="flex gap-2 items-center justify-center">
            <Button
              variant="negative"
              className="text-[11px] font-semibold rounded-[8px] h-[25px] w-[109px] p-0"
            >
              Export
            </Button>
            <AddOrganizationUser
              addUser={(user: OrgUser) => {
                setUsers([...users, user]);
              }}
            />
          </div>
        );
      }
    }
  ];

  const table = useReactTable<OrgUser>({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      rowSelection
    }
  });
  return (
    <div>
      {isLoading && (
        <div className="grid place-content-center my-8">
          <BrevLoader type="propagate" />
        </div>
      )}
      {!isLoading && (
        <div>
          <div className="rounded-2xl bg-brev-light max-w-[1068px] max-h-[818px] mt-4">
            <Table className="border-transparent">
              <TableHeader className="border-b-2 border-brev-border">
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} className="text-dark-text">
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} className="py-1">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={columns.length}
                      className="h-24 text-center"
                    >
                      No results.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-end space-x-2 py-4">
            {/* <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div> */}
          </div>
        </div>
      )}
    </div>
  );
}
