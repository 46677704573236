import { cn } from "@/lib/utils";
import PaneOpenCloseButton from "@/components/Buttons/PaneOpenCloseButton";
import { AnimatePresence, motion } from "framer-motion";

type QuickViewProps = {
  className?: string;
  children: React.ReactNode;
  collapsed?: boolean;
  openWidth?: string;
  closedWidth?: string;
  collapse: () => void;
  headerColour?: string;
  title?: string;
};

export const QuickView = ({
  className,
  children,
  collapsed,
  openWidth,
  closedWidth,
  collapse,
  title
}: QuickViewProps) => {
  const defaultWidth = "25rem";
  return (
    <div
      className={cn(
        "relative flex flex-col h-full transition-all duration-500 flex-shrink-0 overflow-hidden text-dark-text dark:text-light-text bg-light-alt dark:bg-dark-alt ease-in-out",
        className
      )}
      style={{
        width: collapsed ? closedWidth || "2.5rem" : openWidth || defaultWidth
      }}
    >
      <div
        className={`relative flex flex-shrink-0`}
        style={{ width: openWidth || defaultWidth }}
      >
        <div
          className={`flex w-full h-20 items-center ${collapsed ? "px-0" : "px-2"} gap-3 border border-transparent border-b-brev-border dark:border-b-brev transition-all duration-500 ease-in-out`}
        >
          <PaneOpenCloseButton onClick={collapse} open={!collapsed} reversed />
          <span className="text-[1rem] font-semibold capitalize">
            {title || "title"}
          </span>
        </div>
      </div>
      <AnimatePresence>
        {!collapsed && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
            exit={{ opacity: 0 }}
            className="flex flex-col flex-grow overflow-x-hidden overflow-y-scroll no-scrollbar transition-colors duration-500 ease-in-out"
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
