import SlackConnector from "../Slack/SlackConnector";
import AsanaConnector from "../AsanaConnector";
import SalesforceConnector from "../Salesforce/SalesforceConnector";
import UploadFileConnector from "../UploadFile/UploadFileConnector";
import SnowflakeConnector from "../Snowflake/SnowflakeConnector";
import NotionConnector from "../Notion/NotionConnector";
import HubspotConnector from "../Hubspot/HubspotConnector";
import ZoomConnector from "../Zoom/ZoomConnector";
import ZendeskConnector from "../Zendesk/ZendeskConnector";
import JiraConnector from "../Jira/JiraConnector";
import GongConnector from "../Gong/GongConnector";
import TableauConnector from "../Tableau/TableauConnector";
import MysqlConnector from "../MySQL/MysqlConnector";
import LookerConnector from "../Looker/LookerConnector";
import GDriveConnector from "../GDrive/GDriveConnector";
import { useContext, useEffect } from "react";
import InternalConnector from "../Internal/InternalConnector";
import { cn } from "@/lib/utils";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { StepNames } from "@/containers/Integrations/ConnectionSteps";
import Tile from "@/components/Tile/Tile";
import Amazonredshift from "@/images/amazon-redshift.svg";
import NetSuite from "@/images/netsuite.svg";
import GitHubConnector from "../GitHub/GitHubConnector";

type ConnectionItemProps = {
  onSuccess?: () => void;
  setStep?: (step: StepNames) => void;
};

const items = [
  {
    id: "uploadfile",
    label: "Upload",
    Component: ({ setStep }: ConnectionItemProps) => (
      <UploadFileConnector setStep={() => setStep?.("uploadFile-picker")} />
    )
  },
  {
    id: "slack",
    label: "Slack",
    Component: ({ setStep }: ConnectionItemProps) => (
      <SlackConnector onSuccess={() => setStep?.("slack-picker")} />
    )
  },
  {
    id: "salesforce",
    label: "Salesforce",
    Component: ({ setStep }: ConnectionItemProps) => (
      <SalesforceConnector
        onSuccess={() => {
          setStep?.("salesforce-picker");
        }}
      />
    )
  },
  {
    id: "gdrive",
    label: "Google",
    Component: ({ setStep }: ConnectionItemProps) => (
      <GDriveConnector
        onSuccess={() => {
          setStep?.("gdrive-picker");
        }}
      />
    )
  },
  {
    id: "notion",
    label: "Notion",
    Component: ({ setStep }: ConnectionItemProps) => (
      <NotionConnector
        onSuccess={() => {
          setStep?.("notion-picker");
        }}
      />
    )
  },
  {
    id: "github",
    label: "GitHub",
    Component: ({ setStep }: ConnectionItemProps) => (
      <GitHubConnector
        onSuccess={() => {
          setStep?.("github-picker");
        }}
      />
    )
  },
  {
    id: "hubspot",
    label: "Hubspot",
    Component: ({ setStep }: ConnectionItemProps) => (
      <HubspotConnector
        onSuccess={() => {
          setStep?.("hubspot-picker");
        }}
      />
    )
  },
  {
    id: "snowflake",
    label: "Snowflake",
    Component: () => <SnowflakeConnector />
  },
  {
    id: "asana",
    label: "Asana",
    Component: () => <AsanaConnector />
  },
  {
    id: "jira",
    label: "Jira",
    Component: () => <JiraConnector />
  },
  {
    id: "gong",
    label: "Gong",
    Component: () => <GongConnector />
  },
  {
    id: "zendesk",
    label: "Zendesk",
    Component: () => <ZendeskConnector />
  },
  {
    id: "tableau",
    label: "Tableau",
    Component: () => <TableauConnector />
  },
  {
    id: "mysql",
    label: "MySQL",
    Component: () => <MysqlConnector />
  },
  {
    id: "looker",
    label: "Looker",
    Component: () => <LookerConnector />
  },
  {
    id: "zoom",
    label: "Zoom",
    Component: () => <ZoomConnector />
  },
  {
    id: "redshift",
    label: "Amazon Redshift",
    Component: () => (
      <Tile
        disabled={true}
        onPress={() => {}}
        label="Redshift"
        src={Amazonredshift}
      />
    )
  },
  {
    id: "netsuite",
    label: "NetSuite",
    Component: () => (
      <Tile
        disabled={true}
        onPress={() => {}}
        label="NetSuite"
        src={NetSuite}
      />
    )
  }
];

const internalConnectorItem = {
  id: "internal",
  label: "Internal",
  Component: ({ setStep }: ConnectionItemProps) => (
    <InternalConnector
      onSuccess={() => {
        setStep?.("internal-picker");
      }}
    />
  )
};

type ConnectionsProps = {
  onSuccess?: () => void;
  setStep?: (step: StepNames) => void;
  className?: string;
};

const Connections = ({ onSuccess, setStep, className }: ConnectionsProps) => {
  const { user } = useContext(AuthContext);
  const email = user?.email;
  const isInternal = email?.endsWith("@brev.io");

  useEffect(() => {
    if (isInternal && !items.includes(internalConnectorItem)) {
      items.push(internalConnectorItem);
    }
  }, [isInternal]);

  return (
    <>
      <div
        className={cn(
          "grid grid-cols-3 gap-x-[1rem] gap-y-3 pr-4 overflow-y-scroll scrollbar-webkit-standard dark:scrollbar-webkit-neutral w-full",
          className
        )}
      >
        {items.map((t) => {
          return (
            <t.Component onSuccess={onSuccess} key={t.id} setStep={setStep} />
          );
        })}
      </div>
    </>
  );
};

export default Connections;
