import * as React from "react"
import { format } from "date-fns"
import { ChevronDown } from "lucide-react"
 
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

type DayPickerProps = { 
  onDateChange: (date: Date) => void
  className?: string
};

export function DayPicker({ className, onDateChange }: DayPickerProps) {
  const [date, setDate] = React.useState<Date>()
 
  React.useEffect(() => {
    if (date) 
      onDateChange(date)
  }, [date]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          size="noPadding"
          className={cn(
            "w-[240px] justify-start text-left font-normal text-xs px-3",
            !date && "text-muted-foreground",
            className
          )}
        >
          {date ? format(date, "PPP") : <span>Pick a date</span>}
          <ChevronDown className="ml-auto" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          className="rounded-[16.5px] border-none outline-none"
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}