import NavButton from "@/components/SidePane/NavButton";
import { Button } from "@/components/ui/button";
import { FaPlus } from "react-icons/fa";
import IconButton from "@/components/Buttons/IconButton";
import { Selector } from "@/components/Selector/Selector";
import Hint from "@/components/Tooltips/Hint";
import BrevLoader from "@/components/Loaders/BrevLoader";
import React from "react";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import SearchInput from "@/components/Inputs/SearchInput";
import OTPInput from "@/components/Inputs/OTPInput";
import DateSelect from "@/components/Date/DateSelect";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  DropdownMenuItem
} from "@/components/ui/dropdown-menu";
import { IoSparklesSharp } from "react-icons/io5";

export default function ComponentsContainer() {
  return (
    <div className="w-full h-full grid grid-cols-3 2xl:grid-cols-5 gap-2 place-items-center bg-light-main dark:bg-dark-main p-4 transition-colors duration-500 ease-in-out overflow-y-scroll no-scrollbar overflow-x-hidden">
      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Buttons
      </div>
      <ComponentCell code={`<Button>Default</Button>`}>
        <Button>Default</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="negative">Negative</Button>`}>
        <Button variant="negative">Negative</Button>
      </ComponentCell>
      <ComponentCell
        code={`<Button variant="destructive">Destructive</Button>`}
      >
        <Button variant="destructive">Destructive</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="outline">Outline</Button>`}>
        <Button variant="outline">Outline</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="gradient">Gradient</Button>`}>
        <Button variant="gradient">Gradient</Button>
      </ComponentCell>
      <ComponentCell
        code={`<Button variant="gradient" isLoading>Loading</Button>`}
      >
        <Button variant="gradient" isLoading>
          Loading
        </Button>
      </ComponentCell>
      <ComponentCell
        code={`<IconButton start={<IoSparklesSharp size={14} />}>{\`<IconButton />\`}</IconButton>`}
      >
        <IconButton
          start={<IoSparklesSharp size={14} />}
        >{`<IconButton />`}</IconButton>
      </ComponentCell>
      <ComponentCell
        code={`<IconButton end={<IoSparklesSharp size={14} />}>{\`<IconButton />\`}</IconButton>`}
      >
        <IconButton
          end={<IoSparklesSharp size={14} />}
        >{`<IconButton />`}</IconButton>
      </ComponentCell>
      <ComponentCell code={`<Button variant="ghost">Ghost</Button>`}>
        <Button variant="ghost">Ghost</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="link">Link</Button>`}>
        <Button variant="link">Link</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="dropdown">Dropdown</Button>`}>
        <Button variant="dropdown">Dropdown</Button>
      </ComponentCell>
      <ComponentCell
        code={`<NavButton onClick={() => {}} icon={<FaPlus />} label="Nav" />`}
      >
        <NavButton onClick={() => {}} icon={<FaPlus />} label="Nav" />
      </ComponentCell>
      <ComponentCell code={`<Button variant="badge">Badge</Button>`}>
        <Button variant="badge">Badge</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="bubbleMenu">BubbleMenu</Button>`}>
        <Button variant="bubbleMenu">BubbleMenu</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="disabled">Disabled</Button>`}>
        <Button variant="disabled">Disabled</Button>
      </ComponentCell>
      <ComponentCell code={`<Button variant="integrate">Integrate</Button>`}>
        <Button variant="integrate">Integrate</Button>
      </ComponentCell>

      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Spinners
      </div>
      <ComponentCell code={`<BrevLoader type="pulse" />`}>
        <BrevLoader type="pulse" />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="grid" />`}>
        <BrevLoader type="grid" />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="puff" />`}>
        <BrevLoader type="puff" size={36} />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="propagate" />`}>
        <BrevLoader type="propagate" />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="ring" />`}>
        <BrevLoader type="ring" />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="clip" />`}>
        <BrevLoader type="clip" />
      </ComponentCell>
      <ComponentCell code={`<BrevLoader type="bounce" />`}>
        <BrevLoader type="bounce" />
      </ComponentCell>

      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Misc
      </div>
      <ComponentCell
        code={`<Selector itemList={["1", "2", "3", "4", "5"]} title="Selector" />`}
      >
        <Selector itemList={["1", "2", "3", "4", "5"]} title="Selector" />
      </ComponentCell>
      <ComponentCell
        code={`<Hint message="Hint Message" side="top"><Button>Hint</Button></Hint>`}
      >
        <Hint message="Hint Message" side="top">
          <Button>Hint</Button>
        </Hint>
      </ComponentCell>
      <ComponentCell code={`<DateSelect />`}>
        <DateSelect />
      </ComponentCell>
      <ComponentCell
        code={`<DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="dropdown">Dropdown</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {["1", "2", "3", "4", "5"].map((item) => (
              <DropdownMenuItem key={item}>{item}</DropdownMenuItem>
            ))}
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <DropdownMenuLabel>Sub menu</DropdownMenuLabel>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {["1", "2", "3", "4", "5"].map((item) => (
                  <DropdownMenuItem key={item}>{item}</DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>`}
      >
        <DropdownMenu>
          <DropdownMenuTrigger>
            <Button variant="dropdown">Dropdown</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {["1", "2", "3", "4", "5"].map((item) => (
              <DropdownMenuItem key={item}>{item}</DropdownMenuItem>
            ))}
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <DropdownMenuLabel>Sub menu</DropdownMenuLabel>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {["1", "2", "3", "4", "5"].map((item) => (
                  <DropdownMenuItem key={item}>{item}</DropdownMenuItem>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
          </DropdownMenuContent>
        </DropdownMenu>
      </ComponentCell>
      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Inputs
      </div>
      <ComponentCell
        code={`<Input variant="default" value="Default" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="default" />
      </ComponentCell>
      <ComponentCell
        code={`<Input variant="login" value="Login" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="login" />
      </ComponentCell>
      <ComponentCell
        code={`<Input variant="ghost" value="Ghost" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="ghost" />
      </ComponentCell>
      <ComponentCell
        code={`<Input variant="destructive" value="Destructive" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="destructive" />
      </ComponentCell>
      <ComponentCell
        code={`<Input variant="profile" value="Profile" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="profile" />
      </ComponentCell>
      <ComponentCell
        code={`<Input variant="title" value="Title" fontSize="title" onChange={(e) => console.log(e.target.value)} />`}
      >
        <InputExample variant="title" fontSize="title" />
      </ComponentCell>
      <ComponentCell
        code={`<SearchInput placeholder="<SearchInput />" value="" onChange={() => {}} />`}
      >
        <SearchInput
          placeholder="<SearchInput />"
          value=""
          onChange={() => {}}
        />
      </ComponentCell>
      <ComponentCell code={`<OTPInput />`}>
        <OTPInput />
      </ComponentCell>
      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Background Colours
      </div>
      <ComponentCell code={`#070F11`}>
        <BackgroundExample
          colour="#070F11"
          colourName="brev"
          fontColour="#D9D9D9"
        />
      </ComponentCell>
      <ComponentCell code={`#3FCFF1`}>
        <BackgroundExample colour="#3FCFF1" colourName="brev-accent" />
      </ComponentCell>
      <ComponentCell code={`#131A1D`}>
        <BackgroundExample
          colour="#131A1D"
          colourName="dark-main"
          fontColour="#D9D9D9"
        />
      </ComponentCell>
      <ComponentCell code={`#1B2224`}>
        <BackgroundExample
          colour="#1B2224"
          colourName="dark-alt"
          fontColour="#D9D9D9"
        />
      </ComponentCell>
      <ComponentCell code={`#FAF9F9`}>
        <BackgroundExample
          colour="#FAF9F9"
          colourName="light-main"
          fontColour="#070F11"
        />
      </ComponentCell>
      <ComponentCell code={`#EBF1F3`}>
        <BackgroundExample
          colour="#EBF1F3"
          colourName="light-alt"
          fontColour="#070F11"
        />
      </ComponentCell>
      <ComponentCell code={`#2D3436`}>
        <BackgroundExample
          colour="#2D3436"
          colourName="dark-hover"
          fontColour="#D9D9D9"
        />
      </ComponentCell>
      <ComponentCell code={`#FAFBFB`}>
        <BackgroundExample
          colour="#FAFBFB"
          colourName="light-hover"
          fontColour="#070F11"
        />
      </ComponentCell>
      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Text Colours
      </div>
      <ComponentCell code={`#070F11`}>
        <TextExample colour="#070F11" colourName="dark-text" />
      </ComponentCell>
      <ComponentCell code={`#D9D9D9`}>
        <TextExample
          colour="#D9D9D9"
          colourName="light-text"
          backgroundColor="#131A1D"
        />
      </ComponentCell>
      <ComponentCell code={`#656A6B`}>
        <TextExample colour="#656A6B" colourName="brev-text-gray" />
      </ComponentCell>
      <div className="flex flex-1 w-full items-center justify-center row-span-1 col-span-3 2xl:col-span-5 h-10 bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text">
        Border Colours
      </div>
      <ComponentCell code={`#E2E2E9`}>
        <BorderExample colour="#E2E2E9" colourName="brev-border" />
      </ComponentCell>
      <ComponentCell code={`#1A292C`}>
        <BorderExample colour="#1A292C" colourName="dark-border" />
      </ComponentCell>
      <ComponentCell code={`#696979`}>
        <BorderExample colour="#696979" colourName="light-border" />
      </ComponentCell>
      <ComponentCell code={`#00A3FF`}>
        <BorderExample colour="#00A3FF" colourName="brev-active" />
      </ComponentCell>
    </div>
  );
}

type ComponentCellProps = {
  children: React.ReactNode;
  code: string;
  name?: string;
};

const ComponentCell = (props: ComponentCellProps) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.code);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div className="flex flex-col flex-1 gap-4 size-full items-center justify-center rounded-[8px]">
      <span>{props.name}</span>
      {props.children}
      <button
        onClick={copyToClipboard}
        className="text-sm text-blue-500 flex items-center gap-2"
      >
        Copy Code {copied && <FaCheck className="text-green-500" />}
      </button>
    </div>
  );
};

const InputExample = ({
  variant,
  fontSize
}: {
  variant: "default" | "login" | "ghost" | "destructive" | "profile" | "title";
  fontSize?: "default" | "xs" | "sm" | "lg" | "xl" | "title";
}) => {
  const [value, setValue] = useState("");
  return (
    <Input
      variant={variant}
      value={value}
      fontSize={fontSize}
      onChange={(e) => setValue(e.target.value)}
      placeholder={`${variant}`}
    />
  );
};

const BackgroundExample = ({
  colour,
  colourName,
  fontColour
}: {
  colour: string;
  colourName?: string;
  fontColour?: string;
}) => {
  return (
    <div
      className="flex flex-col gap-2 p-4 rounded-[8px] w-[12rem]"
      style={{ backgroundColor: colour, color: fontColour }}
    >
      <span>{colourName || colour}</span>
      <span className="text-xs text-slate-500">{colour}</span>
    </div>
  );
};

const TextExample = ({
  colour,
  colourName,
  backgroundColor = "#FAF9F9"
}: {
  colour: string;
  colourName?: string;
  backgroundColor?: string;
}) => {
  return (
    <div
      className="flex flex-col gap-2 p-4 rounded-[8px]"
      style={{ backgroundColor: backgroundColor, color: colour }}
    >
      <span>{colourName || colour}</span>
      <span className="text-xs text-slate-500">{colour}</span>
    </div>
  );
};

const BorderExample = ({
  colour,
  colourName
}: {
  colour: string;
  colourName?: string;
  backgroundColor?: string;
}) => {
  return (
    <div
      className="flex flex-col gap-2 p-4 rounded-[8px] border-2 text-dark-text dark:text-light-text bg-light-alt dark:bg-dark-alt transition-colors duration-500 ease-in-out w-[12rem] text-sm font-normal"
      style={{ borderColor: colour }}
    >
      <span>{colourName || colour}</span>
      <span className="text-xs text-slate-500">{colour}</span>
    </div>
  );
};
