import { useContext, useRef, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { IntegrationEntityModel } from "../Integrations/types";
import { useParams } from "react-router-dom";
import { toast } from "@/components/ui/use-toast";
import { ArtifactSettingsContext } from "../ArtifactBuilder/ArtifactSettings/ArtifactSettingsProvider";
import EntityPickerOption from "@/components/Connector/components/EntityPickerOption";

type IntegrationEntityListItemProps = {
  integrationEntity: IntegrationEntityModel;
}

export default function IntegrationEntityListItem({ integrationEntity }: IntegrationEntityListItemProps) {
  const { sendApiRequest } = useContext(AuthContext);
  const { integrationEntities, setIntegrationEntities } = useContext(ArtifactSettingsContext);
  const { artifactId } = useParams<{ artifactId: string }>();
  const [ isEntityConnecting, setIsEntityConnecting ] = useState<boolean>(false);
  const isNew = useRef<boolean>(integrationEntity.createdAt > Date.now() - 1000 * 60 * 5);
  return (
    <EntityPickerOption
      dataEntity={{
        id: integrationEntity.entityId,
        name: integrationEntity.entityName,
        sourceType: integrationEntity.entityType,
        isPending: isEntityConnecting,
        connected: integrationEntity.isConnected,
        isNew: isNew.current,
      }}
      onEntityPressed={async () => {
        try {
          setIsEntityConnecting(true);
          if (integrationEntity.isConnected) {
            await sendApiRequest(`/integration-entities/${integrationEntity.id}/contexts?artifactId=${artifactId}`, "DELETE");
            setIntegrationEntities([{
              ...integrationEntity,
              isConnected: false,
            },
              ...integrationEntities.filter((entity) => entity.id !== integrationEntity.id)]);
            toast({
              title: "Success",
              description: "Object disconnected successfully."
            });
          } else {
            const response = await sendApiRequest(`/integrations/${integrationEntity.integrationId}/connect-object`, "POST", 
              {
                integrationEntityId: integrationEntity.id,
                object: {
                  objectId: integrationEntity.entityId,
                  objectName: integrationEntity.entityName,
                  objectType: integrationEntity.entityType,
                  integrationApp: integrationEntity.integrationApp,
                },
                artifactId,
              }
            ) as { data: { integrationEntity: IntegrationEntityModel, isContextTruncated: boolean } };
            setIntegrationEntities([{
              ...response.data.integrationEntity,
              isConnected: true,
            } as IntegrationEntityModel, ...integrationEntities.filter((ie) => ie.id !== response.data.integrationEntity.id)]);
            if (!response.data.isContextTruncated) {
              toast({
                title: "Success",
                description: "Object selected successfully.",
              });
            } else {
              toast({
                title: "Warning",
                description: "Context truncated to fit the 40KB size limit. Consider resizing your content to get more accurate results.",
              });
            }
          }
          setIsEntityConnecting(false);
        } catch (e) {
          console.error("Error fetching files", e);
          toast({
            title: "Error",
            description: "Error fetching connection object"
          });
        }
      }}
    />
  )
}
