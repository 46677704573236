import { motion } from "framer-motion";
import BrevLoader from "@/components/Loaders/BrevLoader";

export default function LoadingPage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
      className="fixed w-screen top-0 left-0 flex flex-col items-center justify-center h-screen bg-brev z-[999]"
    >
      <BrevLoader type="grid" />
    </motion.div>
  );
}
