import Tile from "../../Tile/Tile";
import Looker from "@/images/looker.svg";

export default function LookerConnector() {
  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "SNOWFLAKE"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  const authenticateClient = () => {};

  return (
    <Tile
      disabled={true}
      onPress={authenticateClient}
      label="Looker"
      src={Looker}
    />
  );
}
