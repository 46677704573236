import { IntegrationApp } from "@/containers/Integrations/types";

export type IntegrationObject = {
  objectId: string;
  objectName: string;
  integrationApp: IntegrationApp;
  objectType: SourceType;
}

export type ConnectorDataEntity = {
  id: string;
  name: string;
  connected: boolean;
  isPending?: boolean;
  sourceType?: SourceType;
  isNew?: boolean;
  resourceModel?: ResourceModel;
  data?: string;
}

export enum SourceType {
  SALESFORCE = "SALESFORCE",
  SLACK_CHANNEL = "SLACK_CHANNEL",
  CSV = "CSV",
  TEXT = "TEXT",
  GOOGLE_DOC = "GOOGLE_DOC",
  GOOGLE_SHEET = "GOOGLE_SHEET",
  NOTION_PAGE = "NOTION_PAGE",
  NOTION_DB = "NOTION_DB",
  GITHUB_REPO = "GITHUB_REPO",
  EXCEL = "EXCEL",
  HUBSPOT_REPORT = "HUBSPOT_REPORT",
  HUBSPOT_DEAL = "HUBSPOT_DEAL",
  HUBSPOT_CONTACT = "HUBSPOT_CONTACT",
  HUBSPOT_COMPANY = "HUBSPOT_COMPANY",
  HUBSPOT_LIST = "HUBSPOT_LIST",
  HUBSPOT_TICKET = "HUBSPOT_TICKET",
  HUBSPOT_PRODUCT = "HUBSPOT_PRODUCT",
}

export type ResourceSource = {
  sourceType: SourceType,
  entityId?: string,
}

export type ResourceModel = {
  resourceId: string,
  resourceName: string,
  resourceSource: ResourceSource,
  userId?: string,
  s3Url: string,
  createdAt: number
}

export enum LlmModel {
  GPT_4O = "GPT-4o",
  GPT_4O_MINI = "GPT-4o-mini",
  CLAUDE_3_5 = "Claude 3.5",
}