// import { formatDollarValue } from "@/lib/utils";
import { LuArrowDownRight, LuArrowUpRight } from "react-icons/lu";
import EditableText from "../EditableText";
import { formatCurrency } from "@/lib/utils";
import {
  KPIData
  // KPIType
} from "@/containers/ReviewBuilder/review-types";

type SlideKPIProps = {
  kpi: KPIData;
  className?: string;
  updateTileContent: (kpi: Partial<KPIData>) => void;
  editable?: boolean;
};

// const formatValue = (value: number, kpi_type: string | undefined) => {
//   if (kpi_type === KPIType.CURRENCY) {
//     return formatDollarValue(value);
//   }
//   return formatNumber(value);
// };

const extractNumber = (value: string | number): number | null => {
  if (typeof value === "number") return value;
  const match = value.match(/[0-9.]+/);
  return match ? parseFloat(match[0]) : null;
};

export default function SlideKPI({
  kpi,
  className = "",
  updateTileContent,
  editable = true
}: SlideKPIProps) {
  const calcChange = (value: number, previous: number) => {
    if (value === 0 || previous === 0) {
      return 0;
    }
    return ((value - previous) / previous) * 100;
  };

  if (!kpi || !kpi.value) {
    return null;
  }

  const currentNum = extractNumber(kpi.value);
  const previousNum = kpi.previous_value
    ? extractNumber(kpi.previous_value)
    : null;

  const change =
    currentNum && previousNum ? calcChange(currentNum, previousNum) : null;

  const displayValue = (() => {
    if (typeof kpi.value === "number" && kpi.kpi_type === "money") {
      return formatCurrency(kpi.value);
    }
    return kpi.value.toString();
  })();

  const handleTitleChange = (value: string) => {
    updateTileContent({ title: value });
  };

  const handlePeriodChange = (value: string) => {
    updateTileContent({ period: value });
  };

  const handleValueChange = (value: string) => {
    updateTileContent({ value: value });
  };

  return (
    <div
      className={`flex flex-col h-full w-full justify-center items-center ${className}`}
    >
      <EditableText
        value={kpi.title || "KPI Title"}
        onSave={handleTitleChange}
        className="absolute top-4 left-4 text-[1rem] text-dark-text dark:text-light-text text-opacity-50 font-semibold max-w-full line-clamp-1 overflow-hidden"
        placeholder={"Enter title"}
        editable={editable}
      />
      <EditableText
        value={displayValue}
        onSave={handleValueChange}
        className="font-bold max-w-full truncate text-center pt-8 overflow-hidden"
        scaleFontSize={true}
        minFontSize={20}
        maxFontSize={32}
        maxLength={20}
        editable={editable}
      />
      <div className="flex items-center gap-1">
        {!!change && (
          <span
            className={`${change > 0 ? "text-green-500" : "text-red-500"} font-normal text-md flex items-center justify-center`}
          >
            {change > 0 ? (
              <LuArrowUpRight className="text-green-500" />
            ) : (
              <LuArrowDownRight className="text-red-500" />
            )}
            {change.toFixed(0)}%
          </span>
        )}
        <EditableText
          value={kpi.period || ""}
          onSave={handlePeriodChange}
          className="text-dark-text dark:text-light-text text-md"
          editable={editable}
        />
      </div>
    </div>
  );
}
