import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";

type HintProps = {
  message: string;
  children: React.ReactNode;
  side?: "top" | "bottom" | "left" | "right";
  offset?: number;
  className?: string;
};

export default function Hint({
  message,
  children,
  side = "right",
  offset = 4,
  className
}: HintProps) {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          <div className="grid place-items-center">{children}</div>
        </TooltipTrigger>
        <TooltipContent
          side={side}
          sideOffset={side === "right" ? (offset ? offset : -15) : offset}
          className={cn(
            "bg-light-alt dark:bg-dark-alt text-xs font-normal transition-colors duration-300 ease-in-out text-dark-text dark:text-brev-light border border-brev-border dark:border-brev z-[1006]",
            className
          )}
        >
          <span>{message}</span>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
