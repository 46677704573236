import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import Tile from "../../Tile/Tile";
import UploadFile from "@/images/uploadfile.svg";
import { useContext } from "react";
import { IntegrationApp } from "@/containers/Integrations/types";

export type UploadFileConnectorProps = {
  setStep?: () => void;
};

const UploadFileConnector = ({ setStep }: UploadFileConnectorProps) => {
  const { isAppConnected } = useContext(IntegrationConnectionsContext);
  return (
    <Tile
      disabled={!isAppConnected(IntegrationApp.UPLOADFILE)}
      onPress={() => {
        setStep && setStep();
      }}
      label="Upload"
      src={UploadFile}
    />
  );
};

export default UploadFileConnector;
