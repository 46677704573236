export default function SlackSvg({ size }: { size?: number }) {
  return (
    <svg
      width={size || 20}
      height={size || 21}
      viewBox="0 0 20 21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.24327 13.3574C4.24327 14.5173 3.30861 15.4546 2.15215 15.4546C0.995702 15.4546 0.0610352 14.5173 0.0610352 13.3574C0.0610352 12.1976 0.995702 11.2602 2.15215 11.2602H4.24327V13.3574Z"
        fill="#E01E5A"
      />
      <path
        d="M5.28906 13.3574C5.28906 12.1976 6.22373 11.2602 7.38018 11.2602C8.53663 11.2602 9.4713 12.1976 9.4713 13.3574V18.6005C9.4713 19.7603 8.53663 20.6977 7.38018 20.6977C6.22373 20.6977 5.28906 19.7603 5.28906 18.6005V13.3574Z"
        fill="#E01E5A"
      />
      <path
        d="M7.38018 4.93634C6.22373 4.93634 5.28906 3.99894 5.28906 2.83911C5.28906 1.67928 6.22373 0.741882 7.38018 0.741882C8.53663 0.741882 9.4713 1.67928 9.4713 2.83911V4.93634H7.38018Z"
        fill="#36C5F0"
      />
      <path
        d="M7.37968 6.00073C8.53614 6.00073 9.4708 6.93813 9.4708 8.09796C9.4708 9.25779 8.53614 10.1952 7.37968 10.1952H2.13604C0.979589 10.1952 0.0449219 9.25779 0.0449219 8.09796C0.0449219 6.93813 0.979589 6.00073 2.13604 6.00073H7.37968Z"
        fill="#36C5F0"
      />
      <path
        d="M15.7603 8.09811C15.7603 6.93828 16.6949 6.00089 17.8514 6.00089C19.0078 6.00089 19.9425 6.93828 19.9425 8.09811C19.9425 9.25794 19.0078 10.1953 17.8514 10.1953H15.7603V8.09811Z"
        fill="#2EB67D"
      />
      <path
        d="M14.7145 8.09822C14.7145 9.25805 13.7798 10.1954 12.6233 10.1954C11.4669 10.1954 10.5322 9.25805 10.5322 8.09822V2.83926C10.5322 1.67943 11.4669 0.742035 12.6233 0.742035C13.7798 0.742035 14.7145 1.67943 14.7145 2.83926V8.09822Z"
        fill="#2EB67D"
      />
      <path
        d="M12.6233 16.5031C13.7798 16.5031 14.7145 17.4405 14.7145 18.6004C14.7145 19.7602 13.7798 20.6976 12.6233 20.6976C11.4669 20.6976 10.5322 19.7602 10.5322 18.6004V16.5031H12.6233Z"
        fill="#ECB22E"
      />
      <path
        d="M12.6233 15.4546C11.4669 15.4546 10.5322 14.5173 10.5322 13.3574C10.5322 12.1976 11.4669 11.2602 12.6233 11.2602H17.867C19.0234 11.2602 19.9581 12.1976 19.9581 13.3574C19.9581 14.5173 19.0234 15.4546 17.867 15.4546H12.6233Z"
        fill="#ECB22E"
      />
    </svg>
  );
}
