import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel
} from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";

const FormSchema = z.object({
  company_services: z.string(),
  company_objectives: z.string(),
  company_values: z.string(),
  company_competitors: z.string(),
  company_additional_info: z.string()
});

export default function CompanyMemory() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="pb-4">
        <div className=" w-full h-[661px] rounded-2xl bg-brev-light text-xs font-semibold p-4 space-y-4">
          <span>Manage Company Memory</span>
          <p className="text-xs font-normal">
            Brev provides better quality results with more context about your
            company
          </p>
          <div className="pl-2 space-y-6">
            <FormField
              control={form.control}
              name="company_services"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Describe your company's core products and services
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="w-[934px]"></Textarea>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company_objectives"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    What are the 3-5 core objectives your company is trying to
                    accomplish in the next year? (be specific)
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="w-[934px]"></Textarea>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company_values"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    What are the key KPIs that matter most to your company’s
                    performance?
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="w-[934px]"></Textarea>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company_competitors"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Who are your company’s main competitors?
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="w-[934px]"></Textarea>
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="company_additional_info"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    What else would you like Brev to know about your company?
                  </FormLabel>
                  <FormControl>
                    <Textarea {...field} className="w-[934px]"></Textarea>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
