import GoogleSvg from "@/components/Icons/GoogleSvg";
import { Button } from "@/components/ui/button";
import config from "@/config";
import { useState } from "react";

export default function GoogleLogin() {
  const [loading, setLoading] = useState(false);

  return (
    <Button
      className={`relative flex h-[3.25rem] items-center justify-center gap-2 w-[25rem] rounded-full text-white ${loading ? "bg-[#00000020]" : "bg-[#000000]"}`}
      onClick={() => {
        setLoading(true);
        window.location.href = `${config.apiGateway.V3_API_URL}/auth/google/authorize`;
      }}
      disabled={loading}
      isLoading={loading}
    >
      <GoogleSvg className="absolute left-4 top-3.25" />
      <span>Continue with Google</span>
    </Button>
  );
}
