import { Button } from "@/components/ui/button";
import { SnippetModel } from "../ArtifactCopilotProvider";
import InfoSvg from "@/components/Icons/InfoSvg";

type ArtifactSnippetListItemProps = {
  snippet: SnippetModel;
  onClick: () => void;
};

export default function ArtifactSnippetListItem({
  snippet,
  onClick
}: ArtifactSnippetListItemProps) {
  return (
      <Button
      variant="outline"
        className="w-full justify-start gap-2"
        onClick={onClick}
        data-cy={"snippetCard"}
      >
        <InfoSvg size={11} className="min-w-3" />
        <div className="pr-2 text-xs truncate">{snippet.snippetContent}</div>
      </Button>

  );
}
