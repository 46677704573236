import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { useState, useEffect } from "react";
import SettingsOption from "./SettingsOption";

export default function ToggleOptions({
  values,
  option,
  setOption,
  className,
  useCustom,
  disabledOptions,
  defaultOption
}: {
  values: string[];
  option: string;
  setOption: (value: string) => void;
  className?: string;
  useCustom?: boolean;
  disabledOptions?: string[];
  defaultOption?: string;
}) {
  const [custom, setCustom] = useState("Custom");
  const [customVisible, setCustomVisible] = useState(false);

  useEffect(() => {
    if (defaultOption) {
      setOption(defaultOption);
    }
  }, []);

  const handleCustomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustom(e.target.value);
  };

  const handleCustomSubmit = () => {
    if (custom) {
      setCustomVisible(false);
      setOption(custom);
    }
  };

  const handleAddCustom = () => {
    if (custom === "Custom") {
      setCustom("");
    }
    setCustomVisible(true);
  };

  return (
    <div className={cn("flex gap-2 flex-wrap", className)}>
      {values.map((o) => (
        <SettingsOption
          key={o}
          option={o}
          setOption={setOption}
          active={option}
          disabled={disabledOptions?.includes(o)}
        />
      ))}
      {useCustom && (
        <>
          <div
            className={`${option === custom ? "hover:bg-brev hover:text-brev-light bg-brev text-brev-light" : "hover:bg-brev-light text-dark-text bg-brev-light"} flex justify-center items-center font-normal truncate rounded-[8px] h-7 px-2 cursor-pointer transition-colors duration-300`}
            onClick={() => handleAddCustom()}
          >
            <span className="normal-case">{custom || "Custom"}</span>
          </div>
          {customVisible && (
            <div className="flex items-center gap-2 mt-2">
              <input
                type="text"
                value={custom}
                onChange={handleCustomChange}
                className="border px-1 py-2 text-sm rounded-[8px]"
                autoFocus={true}
              />
              <Button
                variant="outline"
                className="text-brev-light bg-brev"
                onClick={handleCustomSubmit}
              >
                Set
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
