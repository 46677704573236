import { SourceType } from "@/components/Connector/types";

export enum IntegrationApp {
  SLACK = "SLACK",
  SALESFORCE = "SALESFORCE",
  GDRIVE = "GDRIVE",
  UPLOADFILE = "UPLOADFILE",
  INTERNAL = "INTERNAL",
  NOTION = "NOTION",
  GITHUB = "GITHUB",
  HUBSPOT = "HUBSPOT",
}

export enum IntegrationStatus {
  CONNECTED = "CONNECTED",
  EXPIRED = "EXPIRED",
  DISCONNECTED = "DISCONNECTED",
}

export type IntegrationConnection = {
  integrationId: string;
  integrationApp: IntegrationApp;
  integrationStatus: IntegrationStatus;
  instanceUrl?: string;
}

export enum IntegrationEntityStatus {
  ACTIVE = "ACTIVE",
  CONNECTED = "CONNECTED",
  INACTIVE = "INACTIVE",
}

export interface IntegrationEntityModel {
  id: string;
  userId: string;
  integrationId: string;
  integrationApp: IntegrationApp;
  entityId: string;
  entityType: SourceType;
  entityName: string;
  entityStatus: IntegrationEntityStatus;
  isConnected: boolean;
  createdAt: number;
}