import React from "react";
import { cn } from "@/lib/utils";

type LayoutContainerProps = {
  className?: string;
  children: React.ReactNode;
};

export default function LayoutContainer({
  children,
  className
}: LayoutContainerProps) {
  return (
    <div
      className={cn(
        "layout-container flex flex-grow h-full min-h-0 w-full px-16 py-4 bg-light-main dark:bg-dark-main border border-x-[#D8D8E6] dark:border-x-brev transition-colors duration-500 ease-in-out shadow-inner",
        className
      )}
    >
      {children}
    </div>
  );
}
