import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { createPaymentIntent } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import BrevLoader from "@/components/Loaders/BrevLoader";

// Replace with your Stripe publishable key
const stripePromise = loadStripe(
  "pk_test_51Pb6vURtwPBWkmKu6i7HZZEQfIpvHYbmjw3S9kEDSicdZbb4terj7jybl2lpNrV6jo3MHgmMUF8Qc0AczwDQxlHV00oIEmRaSd"
);

const CheckoutForm: React.FC = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: ""
      }
    });

    if (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="mt-4 px-10 py-16 rounded-xl h-full w-full bg-brev/70 border-2 border-brev"
    >
      <PaymentElement />
      <div className="flex w-full items-center justify-center p-4">
        <Button
          variant="gradient"
          disabled={!stripe}
          className="px-10 rounded-[8px] mt-10"
        >
          Submit
        </Button>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default function StripePaymentElement() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    const getClientSecret = async () => {
      const result = await createPaymentIntent();
      if (result) {
        setClientSecret(result.clientSecret);
      }
    };

    getClientSecret();
  }, []);

  type appearanceType = {
    theme: "night" | "stripe" | "flat" | undefined;
  };

  const appearance: appearanceType = {
    theme: "night"
  };

  const options = {
    clientSecret,
    appearance
  };

  return (
    <div>
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      ) : (
        <div className="flex w-full min-h-[200px] items-center justify-center">
          <BrevLoader type="propagate" />
        </div>
      )}
    </div>
  );
}
