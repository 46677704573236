import { useState, useEffect, useCallback, useRef, useContext } from "react";
import { GridContext } from "../GridProvider";
import { Slide } from "@/containers/ReviewBuilder/review-types";
import { WidthProvider, Responsive } from "react-grid-layout";
import { LuChevronLeft, LuChevronRight, LuMinimize } from "react-icons/lu";
import { GRID_COLS, SLIDE_DIVIDER_ID } from "../gridUtils";
import GridCard from "../GridTiles/GridCard";
import { ArtifactContext } from "../../ArtifactProvider";
import { Layout } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface SlideViewerProps {
  slides: Slide[];
  startFullScreen?: boolean;
}

export default function SlideViewer({
  slides,
  startFullScreen = true
}: SlideViewerProps) {
  const { setIsSlideView } = useContext(GridContext);
  const { artifactLayout, setArtifactLayout, artifactTiles } =
    useContext(ArtifactContext);
  const [currentSlide, setCurrentSlide] = useState(0);
  // const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  }, [slides.length]);

  const exitFullScreen = useCallback(() => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
        );
      });
    }
  }, []);

  const handleFullScreenChange = useCallback(() => {
    const fullScreenElement = document.fullscreenElement;
    if (!fullScreenElement) {
      setIsSlideView(false);
    }
  }, [setIsSlideView]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [handleFullScreenChange]);

  useEffect(() => {
    if (startFullScreen) {
      containerRef.current?.requestFullscreen();
    }
  }, [startFullScreen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "ArrowRight") {
        nextSlide();
      } else if (event.key === "ArrowLeft") {
        prevSlide();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [nextSlide, prevSlide]);

  const onLayoutChange = (newLayout: Layout[]) => {
    const updatedArtifactLayout = artifactLayout.map((tile) => {
      const newTile = newLayout.find((newItem) => newItem.i === tile.i);
      if (newTile) {
        return {
          ...tile,
          h: newTile.h,
          w: newTile.w
        };
      }
      return tile;
    });
    setArtifactLayout(updatedArtifactLayout);
  };

  if (slides.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-xl">
        No slides available
      </div>
    );
  }

  return (
    <div
      ref={containerRef}
      className={`relative rounded-lg w-full bg-light-alt px-24 2xl:px-[10%] my-auto max-w-3/4 h-full`}
    >
      <div className="relative h-full">
        <ResponsiveGridLayout
          className={`layout absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2`}
          layouts={{
            lg: slides[currentSlide].items.filter(
              (item) => !item.i.startsWith(SLIDE_DIVIDER_ID)
            )
          }}
          cols={{
            lg: GRID_COLS,
            md: GRID_COLS,
            sm: GRID_COLS,
            xs: GRID_COLS,
            xxs: GRID_COLS
          }}
          rowHeight={40}
          onLayoutChange={onLayoutChange}
          isDraggable={true}
          isResizable={true}
          compactType={null}
          preventCollision={true}
          margin={[10, 10]}
        >
          {slides[currentSlide].items
            .filter((item) => !item.i.startsWith(SLIDE_DIVIDER_ID))
            .map((item) => {
              const tile = artifactTiles.find((t) => t.id === item.i);
              if (!tile || !tile.content) return null;
              return (
                <div key={item.i} className="overflow-hidden select-none">
                  <GridCard tile={tile} isEditMode={false} isDragging={false} />
                </div>
              );
            })}
        </ResponsiveGridLayout>
        <div className="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full text-sm">
          {currentSlide + 1} / {slides.length}
        </div>
      </div>
      <div className="absolute -bottom-[3rem] left-0 right-0 flex justify-center items-center space-x-4">
        <button
          className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-colors duration-300"
          onClick={prevSlide}
        >
          <LuChevronLeft size={24} />
        </button>
        <div className="bg-black bg-opacity-50 text-white px-3 py-1 rounded-full">
          {currentSlide + 1} / {slides.length}
        </div>
        <button
          className="bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-colors duration-300"
          onClick={nextSlide}
        >
          <LuChevronRight size={24} />
        </button>
      </div>
      <button
        className="absolute top-5 right-5 bg-black bg-opacity-50 text-white rounded-full p-2 hover:bg-opacity-70 transition-colors duration-300"
        onClick={exitFullScreen}
      >
        <LuMinimize size={24} />
      </button>
    </div>
  );
}
