import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const FormSchema = z.object({
  name: z.string(),
  location: z.string(),
  size: z.string(),
  industry: z.string(),
  subIndustry: z.string(),
  type: z.string(),
  revenue: z.string(),
  founded: z.string(),
  capital: z.string()
});

export default function CompanyDetails() {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {}
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    console.log(data);
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="py-4">
        <div className=" w-full h-[322px] rounded-2xl bg-brev-light text-xs font-semibold p-4 space-y-4">
          <span>Manage company details</span>
          <p className="text-xs font-normal">
            Brev provides better quality results with more context about your
            company
          </p>
          <div className="grid grid-cols-3 grid-rows-3 gap-8 h-[200px]">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="industry"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Industry
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="revenue"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">Revenue</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="location"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Location
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subIndustry"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Sub-industry
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="founded"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Year founded
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="size"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Headcount range
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Company type
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="capital"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-between">
                  <FormLabel className="text-xs font-medium">
                    Capital raised
                  </FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      variant="profile"
                      className="rounded-[8px]"
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
      </form>
    </Form>
  );
}
