import React from "react";
import NavMenu from "../SidePane/NavMenu";
// import UserSettingsDialog from "../../containers/UserSettings/UserSettingsDialog";
// import BrevLogo from "../ui/brevlogo";
// import { Link } from "react-router-dom";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className="flex flex-col h-screen">
      <div className="relative w-screen h-2 bg-brev"></div>
      <NavMenu>{children}</NavMenu>
    </div>
  );
};

export default Layout;
