import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  // FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { Selector } from "@/components/Selector/Selector";
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";
import UserCard from "@/components/Card/UserCard";
import AddUsersSvg from "@/components/Icons/AddUsersSvg";
import { BounceLoader } from "react-spinners";
import { IoSparklesSharp } from "react-icons/io5";

export default function CreateQuickView() {
  const formSchema = z.object({
    businessPeriod: z.string(),
    rhythmType: z.string(),
    rhythmFrequency: z.string(),
    rhythmDay: z.number(),
    reminderFrequency: z.number(),
    addToCalendar: z.boolean(),
    sendPreread: z.boolean(),
    summarizeRhythm: z.boolean()
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      businessPeriod: "Q2 2024",
      rhythmFrequency: "Week",
      rhythmDay: 3
    }
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values);
  }

  const rhythmTypes = [
    "Team sync",
    "Cross functional",
    "Business review",
    "OKR review",
    "One to one",
    "Custom"
  ];

  const days = ["S", "M", "T", "W", "T", "F", "S"];
  const reminders = [1, 3, 7];

  return (
    <div
      className={`relative pt-[33px] h-full overflow-hidden transition-all duration-300`}
    >
      <div
        className={`text-white text-xs font-normal font-[Poppins] w-full text-center justify-center`}
      >
        <span className="text-lg">Create a Rhythm</span>
        <div className="flex w-full justify-center my-[20px]">
          <Input
            placeholder="Name your Rhythm"
            className="w-[396px] h-[40px] rounded-[8px] bg-white/10 text-sm m-0"
            autoFocus={true}
          ></Input>
        </div>
      </div>
      <div className="relative flex flex-col h-[calc(100vh-173.5px)] rounded-tl-3xl bg-white">
        <div className="sticky top-0 px-4 py-3 z-1 bg-white rounded-2xl">
          <div className="h-[50px] w-full flex justify-around pt-3">
            <div className="w-[111px] h-[27px] flex items-center justify-center">
              <UserCard dark email="Owner" />
            </div>
            <div className="w-[111px] h-[27px] text-xs font-medium cursor-pointer hover:bg-brev-light text-[#000] flex items-center justify-center rounded-[8px]">
              <AddUsersSvg />
              <span className="pl-2">Assign</span>
            </div>
            <div className="w-[111px] h-[27px] text-xs font-medium cursor-pointer hover:bg-brev-light text-[#000] flex items-center justify-center rounded-[8px]">
              <AddUsersSvg />
              <span className="pl-2">Invite</span>
            </div>
          </div>
        </div>
        <div className="flex flex-col h-full px-[36px] py-8 overflow-y-scroll no-scrollbar w-[430px]">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              <FormField
                control={form.control}
                name="businessPeriod"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Select Business Period</FormLabel>
                    <FormControl>
                      <Selector
                        title="Select"
                        itemList={[
                          "Q1 2024",
                          "Q2 2024",
                          "Q3 2024",
                          "Q4 2024",
                          "Annual 2024",
                          "Custom"
                        ]}
                        value={field.value}
                        defaultValue="Period"
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="rhythmType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-xs font-bold">
                      What type of Rhythm is this?
                    </FormLabel>
                    <FormControl>
                      <div className="grid grid-cols-2 gap-y-3 gap-x-6">
                        {rhythmTypes.map((rhythmType, index) => (
                          <div
                            key={index}
                            className={`${rhythmType === field.value ? "bg-brev text-[#FFF]" : "bg-brev-light text-[#000]"} cursor-pointer hover:bg-brev hover:text-white flex items-center pl-4 w-[166px] h-[34px] text-xs rounded-[8px]`}
                            onClick={() => field.onChange(rhythmType)}
                          >
                            {rhythmType}
                          </div>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-col gap-2 text-[10px] font-normal mb-2">
                <span className="text-xs font-bold">Set Rhythm cadences</span>
                <span className="text-[10px] font-normal">
                  Set the frequency for this Rhythm
                </span>
              </div>
              <span className="text-[10px] font-normal">Repeat every</span>
              <div className="flex items-center gap-2 mt-2">
                <FormField
                  control={form.control}
                  name="rhythmFrequency"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <Selector
                          title="Select"
                          itemList={[
                            "Week",
                            "Month",
                            "Quarter",
                            "Bi-Annual",
                            "Annual",
                            "Custom"
                          ]}
                          value={field.value}
                          defaultValue="Period"
                          onChange={field.onChange}
                          className="w-[127px]  h-[33px] rounded-[8px]"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <span className="text-xs font-semibold">on</span>
                <FormField
                  control={form.control}
                  name="rhythmDay"
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="flex gap-1 text-xs font-normal">
                          {days.map((day, index) => (
                            <div
                              key={index}
                              className={`${index === field.value ? "bg-brev text-white" : "bg-brev-light text-dark-text"} cursor-pointer hover:bg-brev hover:text-white flex items-center justify-center w-[26px] h-[33px] rounded-[8px]`}
                              onMouseDown={() => field.onChange(index)}
                            >
                              {day}
                            </div>
                          ))}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <hr></hr>
              <div className="flex flex-col gap-2 text-xs font-normal mb-2">
                <div className="flex items-center">
                  <BounceLoader
                    color="#3FCFF1"
                    speedMultiplier={0.5}
                    size={20}
                  />
                  <span className="text-sm font-bold pl-1">Brev Agent</span>
                </div>
                <span>Send reminders:</span>
              </div>
              <FormField
                control={form.control}
                name="reminderFrequency"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex gap-3">
                        {reminders.map((reminder) => (
                          <div
                            key={reminder}
                            className={`${reminder === field.value ? "bg-brev text-[#FFF]" : "bg-brev-light text-[#000]"} cursor-pointer hover:bg-brev hover:text-white flex items-center justify-center w-[166px] h-[34px] text-xs rounded-[8px]`}
                            onClick={() => field.onChange(reminder)}
                          >
                            {reminder} day{reminder > 1 ? "s" : ""} before
                          </div>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="addToCalendar"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex w-full justify-between pr-10 text-xs font-normal">
                        <span>Add to everyone's calendar</span>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          className="data-[state=checked]:bg-brev-light data-[state=unchecked]:bg-brev-light w-[44px] h-[18px]"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sendPreread"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex justify-between pr-10 text-xs font-normal">
                        <span>Ensure users receives a pre-read</span>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          className="data-[state=checked]:bg-brev-light data-[state=unchecked]:bg-brev-light w-[44px] h-[18px]"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="summarizeRhythm"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <div className="flex justify-between pr-10 text-xs font-normal">
                        <span>Summarize after the event</span>
                        <Switch
                          checked={field.value}
                          onCheckedChange={field.onChange}
                          className="data-[state=checked]:bg-brev-light data-[state=unchecked]:bg-brev-light w-[44px] h-[18px]"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                onClick={() => console.log("submitted")}
                variant="gradient"
                className="flex justify-centerh-[47px] w-[223px] mx-auto rounded-[8px] text-xs font-medium"
              >
                <IoSparklesSharp />
                <span className="pl-4">Create Rhythm</span>
              </Button>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
}
