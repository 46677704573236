import { useEffect, useState } from "react";

export const useGenerateText = (value: string, speed: number) => {
  const [text, setText] = useState("");
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (value) {
      setText("");
      setIsGenerating(false);
      const characters = value.split("");
      let index = 0;

      setIsGenerating(true);
      const interval = setInterval(() => {
        if (index < characters.length - 1) {
          setText((prev) => prev + characters[index]);
          index++;
        } else {
          clearInterval(interval);
        }

        if (value.length - 1 === index) {
          setText(value);
          setIsGenerating(false);
        }
      }, speed);

      return () => {
        clearInterval(interval);
      };
    } else {
      setIsGenerating(false);
    }
  }, [value, speed]);

  return { text, isGenerating };
};
