import { motion } from "framer-motion";
import { useState, useMemo, useEffect, useCallback } from "react";
import {
  SectionOutline,
  KPIData,
  TabularData
} from "@/containers/ReviewBuilder/review-types";

type LoadingStage = "header" | "kpis" | "charts" | "complete";
type LoadingStages = Record<number, LoadingStage>;
type VisibleCounts = Record<number, { kpis: number; charts: number }>;

const TIMING = {
  HEADER: 1500,
  KPI: 500,
  KPI_TO_CHARTS: 800,
  CHART: 800,
  SECTION_COMPLETE: 1000
};

const removeDuplicates = <T extends { title: string }>(items: T[]): T[] => {
  const uniqueMap = new Map<string, T>();
  items.forEach((item) => {
    uniqueMap.set(item.title.toLowerCase(), item);
  });
  return Array.from(uniqueMap.values());
};

export const LoadingSequence = ({
  sectionOutlines,
  kpiDatasets,
  chartDatasets
}: {
  sectionOutlines: SectionOutline[];
  kpiDatasets: KPIData[];
  chartDatasets: TabularData[];
}) => {
  const [visibleSections, setVisibleSections] = useState<number>(0);
  const [loadingStage, setLoadingStage] = useState<LoadingStages>({});
  const [visibleCounts, setVisibleCounts] = useState<VisibleCounts>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uniqueKPIs = useMemo(
    () => removeDuplicates(kpiDatasets),
    [kpiDatasets]
  );
  const uniqueCharts = useMemo(
    () => removeDuplicates(chartDatasets),
    [chartDatasets]
  );

  const distributedDatasets = useMemo(() => {
    const kpisPerSection = Math.ceil(
      uniqueKPIs.length / sectionOutlines.length
    );
    const chartsPerSection = Math.ceil(
      uniqueCharts.length / sectionOutlines.length
    );

    return sectionOutlines.map((_, index) => ({
      kpis: uniqueKPIs.slice(
        index * kpisPerSection,
        (index + 1) * kpisPerSection
      ),
      charts: uniqueCharts.slice(
        index * chartsPerSection,
        (index + 1) * chartsPerSection
      )
    }));
  }, [uniqueKPIs, uniqueCharts, sectionOutlines]);

  const processNextStep = useCallback(async () => {
    if (isLoading) return;

    const currentSection = visibleSections - 1;
    if (currentSection < 0) return;

    const stage = loadingStage[currentSection];
    const currentCounts = visibleCounts[currentSection] || {
      kpis: 0,
      charts: 0
    };
    const sectionDatasets = distributedDatasets[currentSection];

    setIsLoading(true);

    try {
      switch (stage) {
        case "header":
          await new Promise((resolve) => setTimeout(resolve, TIMING.HEADER));
          setLoadingStage((prev) => ({ ...prev, [currentSection]: "kpis" }));
          break;

        case "kpis":
          if (
            sectionDatasets &&
            currentCounts.kpis < (sectionDatasets.kpis || []).length
          ) {
            await new Promise((resolve) => setTimeout(resolve, TIMING.KPI));
            setVisibleCounts((prev) => ({
              ...prev,
              [currentSection]: {
                ...prev[currentSection],
                kpis: currentCounts.kpis + 1
              }
            }));
          } else {
            await new Promise((resolve) =>
              setTimeout(resolve, TIMING.KPI_TO_CHARTS)
            );
            setLoadingStage((prev) => ({
              ...prev,
              [currentSection]: "charts"
            }));
          }
          break;

        case "charts":
          if (
            sectionDatasets &&
            currentCounts.charts < (sectionDatasets.charts || []).length
          ) {
            await new Promise((resolve) => setTimeout(resolve, TIMING.CHART));
            setVisibleCounts((prev) => ({
              ...prev,
              [currentSection]: {
                ...prev[currentSection],
                charts: currentCounts.charts + 1
              }
            }));
          } else {
            await new Promise((resolve) =>
              setTimeout(resolve, TIMING.SECTION_COMPLETE)
            );
            setLoadingStage((prev) => ({
              ...prev,
              [currentSection]: "complete"
            }));

            if (currentSection + 1 < sectionOutlines.length) {
              setVisibleSections((prev) => prev + 1);
              setLoadingStage((prev) => ({
                ...prev,
                [currentSection + 1]: "header"
              }));
              setVisibleCounts((prev) => ({
                ...prev,
                [currentSection + 1]: { kpis: 0, charts: 0 }
              }));
            }
          }
          break;
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    isLoading,
    visibleSections,
    loadingStage,
    visibleCounts,
    distributedDatasets,
    sectionOutlines.length
  ]);

  useEffect(() => {
    if (visibleSections === 0) {
      setVisibleSections(1);
      setLoadingStage({ 0: "header" });
      setVisibleCounts({ 0: { kpis: 0, charts: 0 } });
    }
  }, []);

  useEffect(() => {
    processNextStep();
  }, [processNextStep]);

  return (
    <div className="max-w-[1080px] 2xl:max-w-[1200px] mx-auto space-y-16 p-4">
      {sectionOutlines
        .slice(0, visibleSections)
        .map((section, sectionIndex) => {
          const stage = loadingStage[sectionIndex];
          const sectionDatasets = distributedDatasets[sectionIndex];
          const currentCounts = visibleCounts[sectionIndex] || {
            kpis: 0,
            charts: 0
          };

          return (
            <motion.div
              key={sectionIndex}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="space-y-8"
            >
              <motion.div
                className="space-y-3"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <h3 className="text-2xl font-semibold">{section.header}</h3>
                <p className="text-gray-600 dark:text-gray-300 text-base">
                  {section.description}
                </p>
              </motion.div>

              {stage !== "header" && sectionDatasets.kpis.length > 0 && (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                  {sectionDatasets.kpis
                    .slice(0, currentCounts.kpis)
                    .map((kpi, i) => (
                      <motion.div
                        key={`kpi-${kpi.title}-${i}`}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.4 }}
                        className="bg-light-main dark:bg-dark-main rounded-lg shadow-sm border border-brev-border dark:border-dark-border overflow-hidden"
                      >
                        <div className="px-4 py-3">
                          <h4 className="text-sm font-medium text-brev-gray truncate">
                            {kpi.title}
                          </h4>
                        </div>

                        <div className="p-4 space-y-3">
                          <motion.div
                            className="h-8 bg-light-alt dark:bg-dark-alt rounded w-2/3"
                            animate={{ opacity: [0.5, 0.8, 0.5] }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}
                          />
                          <motion.div
                            className="h-5 bg-light-alt dark:bg-dark-alt rounded w-1/2"
                            animate={{ opacity: [0.5, 0.8, 0.5] }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut",
                              delay: 0.2
                            }}
                          />
                        </div>
                      </motion.div>
                    ))}
                </div>
              )}

              {(stage === "charts" || stage === "complete") &&
                sectionDatasets.charts.length > 0 && (
                  <div className="space-y-8">
                    {sectionDatasets.charts
                      .slice(0, currentCounts.charts)
                      .map((chart, i) => (
                        <motion.div
                          key={`chart-${chart.title}-${i}`}
                          initial={{ opacity: 0, scale: 0.98 }}
                          animate={{ opacity: 1, scale: 1 }}
                          transition={{ duration: 0.5 }}
                          className="bg-light-main dark:bg-dark-main rounded-lg shadow-sm border border-brev-border dark:border-dark-border overflow-hidden"
                        >
                          <div className="px-6 py-4">
                            <h4 className="text-base font-medium text-brev-gray">
                              {chart.title}
                            </h4>
                          </div>

                          <motion.div
                            className="w-full h-[400px] bg-light-alt dark:bg-dark-alt p-6"
                            animate={{ opacity: [0.5, 0.8, 0.5] }}
                            transition={{
                              duration: 2,
                              repeat: Infinity,
                              ease: "easeInOut"
                            }}
                          />
                        </motion.div>
                      ))}
                  </div>
                )}
            </motion.div>
          );
        })}
    </div>
  );
};
