import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import CitationWithoutContext from "../TemplateComponents/CitationWithoutContext";

export const CitationExtensionWithoutContext = Node.create({
  name: "citation",
  group: "inline",
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      contextId: {
        default: null
      }
    };
  },

  parseHTML() {
    return [
      {
        tag: "citation",
        getAttrs: (element) => {
          if (!(element instanceof HTMLElement)) return {};
          return {
            contextId: element.getAttribute("data-citation")
          };
        }
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = mergeAttributes(HTMLAttributes, {
      class: `citatin ${HTMLAttributes.className || ""}`.trim(),
      "data-type": "citation"
    });
    return ["citation", attrs, 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CitationWithoutContext, {
      contentDOMElementTag: "span",
      update: null,
      className: "inline-flex",
      as: "span"
    });
  }
});
