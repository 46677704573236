import {
  isDevEnvironment,
  isProdEnvironment,
  isStagingEnvironment
} from "./lib/stageUtils";

// TODO; Create a common config and derive from it so that maintenance becomes easy
// TODO; Move secrets to SST secrets store

const local = {
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  domain: "test.brev.io",
  apiGateway: {
    V3_API_URL: `https://api-${process.env.BACKEND_STAGE}.brevcloud.com/v3`,
    REGION: "us-west-2",
    COPILOT_WEBSOCKET:
      `wss://wss-${process.env.BACKEND_STAGE}.brevcloud.com`
  },
  env: {
    SLACK_CLIENT_ID: "4905017205447.6300523562066",
    SF_CONSUMER_KEY:
      "3MVG95nWQGdmAiEqB.cO5fe7UXVpcwOnROWC2htG6z6EOpKScureE0CpBpG9kkP1DF9RAiMGvsJyxLK8g_P59",
    REDIRECT_URL_ROOT: "https://redirectmeto.com/http://localhost:5173", // https://test.brev.io
    SALESFORCE_INSTANCE_URL: "https://login.salesforce.com/",
    NOTION_CLIENT_ID: "2fc56c4a-68b1-4097-b197-d58d3e5786d0",
    GDRIVE_CLIENT_ID: "475338433669-ngid5skfhs3ancd4n80trcfvce9j20e4.apps.googleusercontent.com",
    GITHUB_CLIENT_ID: "Ov23lib0df1cRYv9AcJ3",
    HUBSPOT_CLIENT_ID: "9cacc4ce-93bc-4737-a4f2-547af6688b84",
  }
};

const dev = {
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  domain: "test.brev.io",
  apiGateway: {
    REGION: "us-west-2",
    V3_API_URL: "https://api-dev.brevcloud.com/v3",
    COPILOT_WEBSOCKET:
      "wss://wss-dev.brevcloud.com"
  },
  env: {
    SLACK_CLIENT_ID: "4905017205447.6300523562066",
    SF_CONSUMER_KEY:
      "3MVG95nWQGdmAiEqB.cO5fe7UXVpcwOnROWC2htG6z6EOpKScureE0CpBpG9kkP1DF9RAiMGvsJyxLK8g_P59",
    REDIRECT_URL_ROOT: "https://test.brev.io",
    SALESFORCE_INSTANCE_URL: "https://login.salesforce.com/",
    NOTION_CLIENT_ID: "2fc56c4a-68b1-4097-b197-d58d3e5786d0",
    GDRIVE_CLIENT_ID: "475338433669-ngid5skfhs3ancd4n80trcfvce9j20e4.apps.googleusercontent.com",
    GITHUB_CLIENT_ID: "Ov23lidIttNzaAeEj5O4",
    HUBSPOT_CLIENT_ID: "9cacc4ce-93bc-4737-a4f2-547af6688b84",
  }
};

// Staging has the same environment as PROD, only some redirect URLs change
const staging = {
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  domain: "staging.brev.io",
  apiGateway: {
    REGION: "us-west-2",
    V3_API_URL: "https://api-staging.brev.io/v3",
    COPILOT_WEBSOCKET:
      "wss://wss-staging.brev.io"
  },
  env: {
    SLACK_CLIENT_ID: "4905017205447.6300523562066",
    SF_CONSUMER_KEY:
      "3MVG95nWQGdmAiEqB.cO5fe7UXVpcwOnROWC2htG6z6EOpKScureE0CpBpG9kkP1DF9RAiMGvsJyxLK8g_P59",
    REDIRECT_URL_ROOT: "https://staging.brev.io",
    SALESFORCE_INSTANCE_URL: "https://login.salesforce.com/",
    NOTION_CLIENT_ID: "2fc56c4a-68b1-4097-b197-d58d3e5786d0",
    GDRIVE_CLIENT_ID: "475338433669-ngid5skfhs3ancd4n80trcfvce9j20e4.apps.googleusercontent.com",
    GITHUB_CLIENT_ID: "Ov23likhwl2AHHtKCW16",
    HUBSPOT_CLIENT_ID: "9cacc4ce-93bc-4737-a4f2-547af6688b84",
  }
};

const prod = {
  // Frontend config
  MAX_ATTACHMENT_SIZE: 5000000,
  domain: "app.brev.io",
  apiGateway: {
    REGION: "us-west-2",
    V3_API_URL: "https://api.brev.io/v3",
    COPILOT_WEBSOCKET:
      "wss://wss.brev.io"
  },
  env: {
    SLACK_CLIENT_ID: "4905017205447.6300523562066",
    SF_CONSUMER_KEY:
      "3MVG95nWQGdmAiEqB.cO5fe7UXVpcwOnROWC2htG6z6EOpKScureE0CpBpG9kkP1DF9RAiMGvsJyxLK8g_P59",
    REDIRECT_URL_ROOT: "https://app.brev.io",
    SALESFORCE_INSTANCE_URL: "https://login.salesforce.com/",
    NOTION_CLIENT_ID: "2fc56c4a-68b1-4097-b197-d58d3e5786d0",
    GDRIVE_CLIENT_ID: "475338433669-ngid5skfhs3ancd4n80trcfvce9j20e4.apps.googleusercontent.com",
    GITHUB_CLIENT_ID: "Ov23liFt2jS2Aqu2R7oA",
    HUBSPOT_CLIENT_ID: "9cacc4ce-93bc-4737-a4f2-547af6688b84",
  }
};

const config = isProdEnvironment()
  ? prod
  : isStagingEnvironment()
    ? staging
    : isDevEnvironment()
      ? dev
      : local;

export default config;
