import { Selector } from "@/components/Selector/Selector";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "@/components/ui/dialog";
import { toast } from "@/components/ui/use-toast";
import { MemberRole } from "@/lib/types";
import { getOrgId, inviteOrgMember } from "@/lib/utils";
import { FC, useEffect, useState } from "react";
import { FaLink } from "react-icons/fa6";
import { InvitationStatus, OrgUser } from "./types";

type AddOrganizationUserProps = {
  addUser: (user: OrgUser) => void;
};
const AddOrganizationUser: FC<AddOrganizationUserProps> = ({ addUser }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<MemberRole>(MemberRole.MEMBER);
  const [submissionEnabled, setSubmissionEnabled] = useState<boolean>(false);
  const orgId = getOrgId();

  useEffect(() => {
    const isEmailValid =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
    setSubmissionEnabled(isEmailValid);
  }, [email]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button
          variant="negative"
          className="text-[11px] font-semibold w-[109px] rounded-[8px] text-xs h-[25px] p-0"
          aria-expanded={open}
        >
          Invite User
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[200px] max-w-[550px] min-h-[] p-3">
        <DialogHeader>
          <DialogTitle>Add Member</DialogTitle>
          <DialogDescription>
            Invite through work emails and choose the appropriate role.
          </DialogDescription>
        </DialogHeader>
        <div className="flex justify-between bg-[#F7F7FA] font-medium rounded-[13px] w-full p-2 items-center">
          <input
            type="text"
            placeholder="name@brev.io"
            className="bg-[#F7F7FA] h-auto transition-colors placeholder-[#00000036] min-h-[1.25rem] text-xs w-[230px] outline-none p-2 label-text-xs text-[#000]"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <Selector
            onChange={(value: string) => setRole(value as MemberRole)}
            title="Role"
            optionsList={Object.entries(MemberRole).map(([name, value]) => {
              return { name, value };
            })}
            className="w-[225.5px]"
            value={role}
          />

          <Button
            variant="negative"
            className="w-14 h-8 bg-zinc-900 rounded text-xs"
            onClick={async () => {
              try {
                setSubmissionEnabled(false);
                const response = await inviteOrgMember(orgId!, email, role);
                console.log(JSON.stringify(response));
                setOpen(false);
                toast({
                  title: "Success",
                  description: `An invitation has been created for ${email} with role ${role}`
                });
                setEmail("");
                setRole(MemberRole.MEMBER);
                setSubmissionEnabled(true);
                addUser({
                  memberId: response?.data?.memberId,
                  email: email,
                  role: role,
                  memberStatus: InvitationStatus.PENDING
                });
              } catch (e) {
                toast({
                  title: "Error inviting new member",
                  description: `${e}`
                });
              }
            }}
            disabled={!submissionEnabled}
          >
            Invite
          </Button>
        </div>
        <div className="flex gap-2 items-center text-[#0676C7]">
          <div className="flex bg-[#0676C7] h-[27.5px] w-[27.5px] rounded-full items-center justify-center">
            <FaLink className="text-white" size={12} />
          </div>
          <span className="font-medium text-xs hover:cursor-pointer">
            Copy Invite Link
          </span>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrganizationUser;
