import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot
} from "@/components/ui/input-otp";

type OTPInputProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function OTPInput({ value, onChange }: OTPInputProps) {
  return (
    <div className="space-y-2 p-4 text-dark-text dark:text-light-text transition-colors duration-500 ease-in-out">
      <InputOTP maxLength={6} value={value} onChange={onChange} name="verificationCode">
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
    </div>
  );
}
