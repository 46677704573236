import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { FiUser } from "react-icons/fi";
import { Button } from "@/components/ui/button";
import StripePaymentElement from "../Settings/Payments/Payments";

export default function Upgrade() {
  const [selectedPlan, setSelectedPlan] = useState<"Single" | "Multi" | null>(
    null
  );
  return (
    <div className="my-auto pt-10 relative flex flex-col gap-10 items-center justify-center px-[5rem] h-screen w-full">
      <div className="">
        <h1>Upgrade Account</h1>
      </div>
      {selectedPlan ? (
        <div
          className={`grid grid-cols-1 gap-4 w-full h-[80rem] lg:h-full max-w-[1200px] xl:max-h-[1000px] max-h-[2000px]`}
        >
          <div>
            <h1>{selectedPlan} plan</h1>
            <Button variant="outline" onClick={() => setSelectedPlan(null)}>
              Change Plan
            </Button>
            <StripePaymentElement />
          </div>
        </div>
      ) : (
        <div
          className={`grid grid-cols-1 lg:grid-cols-2 gap-4 w-full h-[80rem] lg:h-full max-w-[1200px] xl:max-h-[1000px] max-h-[2000px]`}
        >
          <UpgradeCard
            type="Single"
            onSelect={() => setSelectedPlan("Single")}
          />
          <UpgradeCard type="Multi" onSelect={() => setSelectedPlan("Multi")} />
        </div>
      )}
    </div>
  );
}

const UpgradeCard = ({
  type,
  onSelect
}: {
  type: "Multi" | "Single";
  onSelect: () => void;
}) => {
  const [hover, setHover] = useState(false);
  return (
    <motion.div
      className="max-h-[650px] rounded-2xl border-brev-border border-2 bg-brev-light/40 cursor-pointer hover:scale-[101%] hover:bg-brev/40 hover:border-brev transition-all duration-300 overflow-hidden"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onSelect}
    >
      <div className="py-10 w-full flex items-center justify-center bg-brev border-2 border-brev">
        <h1 className="font-bold text-brev-light">Tier Level</h1>
      </div>
      <div className="relative py-10 w-full flex gap-2 bg-brev/40 text-white font-bold items-center justify-center text-3xl">
        $XX <span className="font-normal text-xl">/ month</span>
        <div className="absolute flex left-4 top-10 w-[100px]">
          {type === "Multi" ? (
            <MultiUser isVisible={hover} />
          ) : (
            <SingleUser isVisible={hover} />
          )}
        </div>
      </div>
      <div className="flex flex-col h-full">
        <ul className="flex flex-col p-10 text-xl gap-4 bg-white py-5">
          <li className="flex gap-2 items-center">
            <FaCheckCircle /> Feature 1
          </li>
          <li className="flex gap-2 items-center">
            <FaCheckCircle /> Feature 2
          </li>
          <li className="flex gap-2 items-center">
            <FaCheckCircle /> Feature 3
          </li>
          <li className="flex gap-2 items-center">
            <FaCheckCircle /> Feature 4
          </li>
        </ul>
        <div className="flex w-full h-20 items-center justify-center">
          <Button variant="gradient" className="px-16 rounded-[8px] text-xl">
            Upgrade
          </Button>
        </div>
      </div>
    </motion.div>
  );
};

const SingleUser = ({ isVisible }: { isVisible: boolean }) => {
  return (
    isVisible && (
      <motion.div
        initial={{ opacity: 0, x: -25 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.3 }}
        className="flex items-center justify-center bg-brev-light h-10 w-10 rounded-full border-2 border-brev"
      >
        <FiUser color="black" />
      </motion.div>
    )
  );
};

const MultiUser = ({
  size,
  isVisible
}: {
  size?: number;
  isVisible: boolean;
}) => {
  const teamSize = size || 5;

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          {Array.from({ length: teamSize }).map((_, index) => (
            <motion.div
              key={index}
              initial={{ opacity: index < 5 ? 0 : 1, x: -25 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -25 }}
              transition={{ duration: 0.2, delay: index * 0.1 }}
              className={`flex items-center justify-center bg-brev-light h-10 w-10 rounded-full border-2 border-brev ${
                index !== teamSize - 1 ? "mr-[-28px]" : ""
              } `}
              style={{ zIndex: index }}
            >
              {index === teamSize - 1 && <FiUser color="black" />}
            </motion.div>
          ))}
        </>
      )}
    </AnimatePresence>
  );
};
