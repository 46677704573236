import { ReactNode } from "react";
import BrevLoader from "../Loaders/BrevLoader";

type QuickViewTextBlockProps = {
  title: string;
  content?: string;
  isGenerating?: boolean;
  children?: React.ReactNode;
  loader?: ReactNode;
};

export default function QuickViewTextBlock({
  title,
  content,
  isGenerating = false,
  children,
  loader
}: QuickViewTextBlockProps): JSX.Element {
  const loadingComponent = loader || (
    <div className="flex w-full justify-center items-center h-10">
      <BrevLoader type="propagate" size={20} loadingId="quickview-text" />
    </div>
  );
  return (
    <div className="flex flex-col gap-2 my-2">
      <div className="text-[1rem] font-semibold capitalize">{title}</div>
      <div className="rounded-[8px] bg-light-main dark:bg-dark-main p-3 text-[0.875rem] dark:text-light-text text-pretty">
        {content && <div>{content}</div>}
        {!isGenerating && children}
        {isGenerating && loadingComponent}
      </div>
    </div>
  );
}
