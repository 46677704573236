import { cn } from "@/lib/utils";

export default function RhythmsSvg({
  size,
  className,
  isActive = false
}: {
  size?: number;
  className?: string;
  isActive?: boolean;
}) {
  return (
    <svg
      width={size || 14}
      height={size || 14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("", className)}
    >
      <defs>
        <linearGradient id="activeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#3FCFF1" />
          <stop offset="50%" stopColor="#3FCFF1" />
          <stop offset="100%" stopColor="#00FFB2" />
        </linearGradient>
      </defs>
      <path
        d="M11.7027 1.27539H2.2973C1.58082 1.27539 1 1.85796 1 2.5766V12.0104C1 12.729 1.58082 13.3116 2.2973 13.3116H11.7027C12.4192 13.3116 13 12.729 13 12.0104V2.5766C13 1.85796 12.4192 1.27539 11.7027 1.27539Z"
        stroke={isActive ? "url(#activeGradient)" : "#656A6B"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.06113 4.05273V10.5338M4.97457 4.43136V8.12421M9.14769 5.65536V10.1696M11.2467 7.25799V9.31656M2.84668 5.29838V7.45832"
        stroke={isActive ? "url(#activeGradient)" : "#656A6B"}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}