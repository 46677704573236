import PageContainer from "@/components/Layout/PageContainer";
import OrganizationMembers from "./OrganizationMembers";
import IntegrationContainer from "../Integrations/Integrations";
import CompanySettings from "./CompanySettings";
import TimePeriods from "./TimePeriods";
import Modules from "./Modules";
import StripePaymentElement from "./Payments/Payments";
import Teams from "./Teams";
import type { Tab } from "../Tabs";
import Tabs from "../Tabs";

const SettingsContainer = () => {
  const tabs: Tab[] = [
    { name: "Users", component: OrganizationMembers },
    { name: "Teams", component: Teams },
    { name: "Integrations", component: IntegrationContainer },
    { name: "Time Periods", component: TimePeriods },
    { name: "Company Memory", component: CompanySettings },
    { name: "Modules", component: Modules },
    { name: "Payments", component: StripePaymentElement }
  ];

  return (
    <PageContainer className="w-[1169px] mt-10 px-16">
      <h3 className="text-sm font-semibold">Settings</h3>
      <p className="text-xs font-normal">
        Manage the settings of your organization, teams, and users.
      </p>
      <Tabs tabs={tabs} />
    </PageContainer>
  );
};

export default SettingsContainer;
