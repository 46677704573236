import useSpacingClassnames from "@/hooks/useSpacingClassnames";
import { cn } from "@/lib/utils";

type SpacingScale = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type TextElement = {
  as?: "p" | "h1" | "h2" | "h3" | "h4" | "h5";
};

export type BaseTextProps = {
  children: React.ReactNode;
  spacing?: SpacingScale;
  spacingTop?: SpacingScale;
  spacingBottom?: SpacingScale;
  spacingStart?: SpacingScale;
  spacingEnd?: SpacingScale;
  className?: string;
} & TextElement;

type TextLabelProps = BaseTextProps & TextElement;

export default function TextLabel({
  as = "p",
  className,
  children,
  spacingTop,
  spacingEnd,
  spacingBottom,
  spacingStart,
  spacing
}: TextLabelProps) {
  const Tag = as;
  const spacingClassnames = useSpacingClassnames({
    spacingTop,
    spacingEnd,
    spacingBottom,
    spacingStart,
    spacing
  });

  return (
    <Tag className={cn("font-light text-xs", className, spacingClassnames)}>
      {children}
    </Tag>
  );
}
