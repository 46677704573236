export default function GreenGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5 47L39.0571 39.5574H51.9922L65.4922 30.5741L79.9922 34.457L91.834 30.5741L103 24.5"
        stroke="#67CD00"
        strokeOpacity="0.55"
        strokeWidth="2.24582"
      />
      <path
        d="M18.3203 16.5029H45.7939"
        stroke="#5CB800"
        strokeOpacity="0.75"
        strokeWidth="5.24026"
      />
      <path
        d="M17.5781 63.6309H106.662"
        stroke="#5CB800"
        strokeOpacity="0.75"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5781 68.123H106.662"
        stroke="#5CB800"
        strokeOpacity="0.55"
        strokeWidth="0.748608"
      />
      <path
        d="M17.5781 73.7373H58.3773"
        stroke="#5CB800"
        strokeOpacity="0.35"
        strokeWidth="0.748608"
      />
      <rect
        x="7.83915"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect y="48" width="121" height="84" fill="url(#paint0_linear_9027_14)" />
    </svg>
  );
}
