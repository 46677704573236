import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";

interface EmbedInputProps {
  visible: boolean;
  input: string;
  onInputChange: (value: string) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const EmbedInput = ({
  visible,
  input,
  onInputChange,
  onSave,
  onCancel
}: EmbedInputProps) => {
  if (!visible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-light-main dark:bg-dark-main p-6 rounded-lg w-[600px]">
        <Textarea
          value={input}
          onChange={(e) => onInputChange(e.target.value)}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 min-h-[100px]"
          placeholder="Enter URL or embed code..."
          autoFocus
        />
        <div className="flex space-x-2 mt-2">
          <Button variant="outline" onClick={onSave} className="h-8 w-16">
            Save
          </Button>
          <Button variant="negative" onClick={onCancel} className="h-8 w-16">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
