import { RxCross2 } from "react-icons/rx";
import { Table } from "@tanstack/react-table";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { DataTableViewOptions } from "./table-view-options";

import { booleanOptions } from "./table-data";

import {
  // priorities,
  services
} from "./table-data";
import { DataTableFacetedFilter } from "./data-filter";

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function DataTableToolbar<TData>({
  table
}: DataTableToolbarProps<TData>) {
  const isFiltered = table.getState().columnFilters.length > 0;

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <Input
          placeholder="Filter users..."
          value={(table.getColumn("email")?.getFilterValue() as string) ?? ""}
          onChange={(event) =>
            table.getColumn("email")?.setFilterValue(event.target.value)
          }
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("aiService") && (
          <DataTableFacetedFilter
            column={table.getColumn("aiService")}
            title="Service"
            options={services}
          />
        )}
        {/* {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )} */}
        {table.getColumn("nextBestActionsEnabled") && (
          <DataTableFacetedFilter
            column={table.getColumn("nextBestActionsEnabled")}
            title="Next Best Actions"
            options={booleanOptions}
          />
        )}
        {table.getColumn("useCompanyMemory") && (
          <DataTableFacetedFilter
            column={table.getColumn("useCompanyMemory")}
            title="Company Memory"
            options={booleanOptions}
          />
        )}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Reset
            <RxCross2 className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
      <DataTableViewOptions table={table} />
    </div>
  );
}
