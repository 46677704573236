import Tile from "../../Tile/Tile";
import config from "@/config";
import { useParams, useSearchParams } from "react-router-dom";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { useContext, useEffect } from "react";
import { IntegrationApp } from "@/containers/Integrations/types";
import NotionSvg from "@/components/Icons/NotionSvg";

type NotionConnectorProps = {
  onSuccess?: (stepName: string) => void;
  activeIntegration?: string;
};

export enum NotionObjectType {
  DATABASE = "database",
  PAGE = "page"
}


export default function NotionConnector({
  onSuccess,
  activeIntegration
}: NotionConnectorProps) {
  const integrationApp = IntegrationApp.NOTION;
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(IntegrationConnectionsContext);

  useEffect(() => {
    if (isAppActive(IntegrationApp.NOTION)) {
      onSuccess?.("notion-picker");
      setSearchParams({ });
    }
  }, [isAppActive, onSuccess]);
  
  const authenticateClient = async () => {
    if (isAppConnected(integrationApp)) return onSuccess?.("notion-picker");
    
    const state = `NOTION/${artifactId}`;
    const notionAuthUrl = new URL('https://api.notion.com/v1/oauth/authorize');
    
    const searchParams = new URLSearchParams({
      client_id: config.env.NOTION_CLIENT_ID,
      response_type: 'code',
      owner: 'user',
      state,
      redirect_uri: `${config.env.REDIRECT_URL_ROOT}/oauth2/callback`,
    });
    notionAuthUrl.search = searchParams.toString();
    window.location.href = notionAuthUrl.toString();
  };

  return (
    <Tile
      onPress={authenticateClient}
      label="Notion"
      svg={<NotionSvg size={30} />}
      connected={isAppConnected(integrationApp)}
      selected={activeIntegration === "notion-picker"}
      isLoading={isLoading}
    />
  );
}