import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Selector } from "@/components/Selector/Selector";
import React, { useState, useEffect, useCallback, useContext } from "react";
import LogoutButton from "../Onboarding/LogoutButton";
import { UserSettingsProvider } from "./UserSettingsProvider";
import UserSettingsInput from "./UserSettingsInput";
import type { SettingsTabProps } from "../ArtifactBuilder/ArtifactSettings/ArtifactSettingsDialog";
import { motion } from "framer-motion";
import { Textarea } from "@/components/ui/textarea";
import UserCard from "@/components/Card/UserCard";
import { AuthContext } from "../Auth/AuthProvider";

type Theme = "light" | "dark" | "system";

export default function UserSettingsDialog({ openTab }: { openTab?: number }) {
  const { user } = useContext(AuthContext);
  const [theme, setTheme] = useState<Theme>("dark");
  const [selectedTab, setSelectedTab] = useState(openTab || 0);
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const localTheme = window.localStorage.getItem("theme") as Theme | null;

    if (localTheme) {
      setTheme(localTheme);
      applyTheme(localTheme);
    } else if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("dark");
      document.documentElement.classList.add("dark");
    }
  }, []);

  const applyTheme = useCallback((theme: Theme) => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, []);

  const handleChange = useCallback(
    (value: Theme) => {
      setTheme(value);
      window.localStorage.setItem("theme", value);

      if (value === "system") {
        const systemPrefersDark = window.matchMedia(
          "(prefers-color-scheme: dark)"
        ).matches;
        applyTheme(systemPrefersDark ? "dark" : "light");
      } else {
        applyTheme(value);
      }
    },
    [applyTheme]
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "o") {
        event.preventDefault();
        if (theme === "light") {
          handleChange("dark");
        } else if (theme === "dark") {
          handleChange("light");
        } else {
          handleChange("system");
        }
      }
    },
    [theme, handleChange]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    setSelectedTab(openTab || 0);
  }, [openTab]);

  const tabs: SettingsTabProps[] = [
    {
      name: "General",
      component: <GeneralSettings theme={theme} handleChange={handleChange} />
    },
    {
      name: "Memory",
      component: <MemorySettings />
    },
    {
      name: "Data",
      component: <DataSettings />
    }
  ];

  return (
    <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <DialogTrigger asChild>
        <div className="hover:bg-dark-alt flex gap-3 items-center rounded-full pr-2 cursor-pointer transition-all" data-cy="settings-trigger">
          <UserCard
            firstName={user?.firstName}
            lastName={user?.lastName}
            profilePictureUrl={user?.profilePictureUrl}
            showMinimal={true} />

        </div>
      </DialogTrigger>
      <DialogContent className="flex gap-0 min-w-[41rem] min-h-[26rem] p-0 rounded-2xl sm:rounded-3xl overflow-hidden font-[Poppins] border border-dark-alt">
        <div className="flex flex-col h-[26rem] w-[158px] bg-brev items-center gap-4 px-2">
          <div className="text-center text-white w-full py-[1.625rem] text-[1rem] font-semibold">
            Settings
          </div>
          {tabs.map((tab, index) => (
            <div
              key={index}
              onClick={() => setSelectedTab(index)}
              className={`${tab.name === tabs[selectedTab].name ? "text-light-main" : "text-brev-gray"} w-[141px] text-sm font-semibold relative flex items-center justify-start px-3 py-2 rounded-[8px] cursor-pointer select-none transition-all duration-300 hover:bg-dark`}
            >
              <span className="flex items-center gap-3 z-10 px-2">
                {tab.icon &&
                  React.cloneElement(tab.icon, {
                    color:
                      tab.name === tabs[selectedTab].name
                        ? "#3FCFF1"
                        : "#5D5C65"
                  })}

                {tab.name}
              </span>
              {tab.name === tabs[selectedTab].name && (
                <motion.span
                  layoutId="connector-tab"
                  className="absolute inset-0 rounded-[8px] bg-dark-alt"
                ></motion.span>
              )}
            </div>
          ))}
        </div>
        <div className="flex flex-col flex-grow-1 h-[26rem] bg-light-main dark:bg-dark-alt w-[750px]">
          {tabs[selectedTab].component}
        </div>
      </DialogContent>
    </Dialog>
  );
}

const GeneralSettings = ({
  theme,
  handleChange
}: {
  theme: Theme;
  handleChange: (theme: Theme) => void;
}) => {
  return (
    <div className="flex flex-col h-full w-full gap-4 text-[0.75rem]">
      <div className="flex text-dark-text dark:text-light-text items-center py-[1.625rem] w-full text-[1rem] font-bold border border-b-brev-gray px-10">
        Manage your settings
      </div>
      <UserSettingsProvider>
        <div className="flex w-full justify-between px-10">
          <span className="text-dark-text dark:text-brev-light transition-colors duration-500 ease-in-out">
            Theme
          </span>
          <Selector
            className="w-[14rem]"
            title="Theme"
            itemList={["system", "light", "dark"]}
            value={theme}
            onChange={(value) => handleChange(value as Theme)}
          />
        </div>
        <UserSettingsInput />
      </UserSettingsProvider>
      <div className="absolute bottom-4 right-4 flex justify-center">
        <LogoutButton />
      </div>
    </div>
  );
};

const MemorySettings = () => {
  return (
    <div className="flex flex-col h-full w-full gap-4 text-[0.75rem]">
      <div className="flex text-dark-text dark:text-light-text items-center py-[1.625rem] w-full text-[1rem] font-bold border border-b-brev-gray px-10">
        Manage your memory
      </div>
      <div className="flex flex-col px-10 text-[0.75rem] gap-1.5">
        <span className="text-dark-text dark:text-light-text">
          What would you like Brev to know about your role?
        </span>
        <Textarea className="text-[0.75rem] px-2" />
      </div>
      <div className="flex flex-col px-10 text-[0.75rem] gap-1.5">
        <span className="text-dark-text dark:text-light-text">
          What would you like Brev to know about your team or department?
        </span>
        <Textarea className="text-[0.75rem] px-2" />
      </div>
      <div className="flex flex-col px-10 text-[0.75rem] gap-1.5">
        <span className="text-dark-text dark:text-light-text">
          What would you like Brev to know about your writing style?
        </span>
        <Textarea className="text-[0.75rem] px-2" />
      </div>
    </div>
  );
};

const DataSettings = () => {
  return (
    <div className="flex flex-col h-full w-full gap-4 text-[0.75rem]">
      <div className="flex text-dark-text dark:text-light-text items-center py-[1.625rem] w-full text-[1rem] font-bold border border-b-brev-gray px-10">
        Connected data
      </div>
    </div>
  );
};
