import { cn } from "@/lib/utils";

type TextThroughLineProps = { content: string; className?: string };

function TextThroughLine({ content, className }: TextThroughLineProps) {
  return (
    <div className={cn("flex items-center font-[Poppins]", className)}>
      <div className="flex-grow bg bg-light-alt h-0.5 rounded-full"></div>
      <div className="flex-grow-0 mx-3 text-sm font-semibold">{content}</div>
      <div className="flex-grow bg bg-light-alt h-0.5 rounded-full"></div>
    </div>
  );
}

export default TextThroughLine;
