import ArtifactSvg from "@/components/Icons/ArtifactSvg";
import { useLocation } from "react-router-dom";
import RhythmsSvg from "@/components/Icons/RhythmsSvg";
import ObserveSvg from "@/components/Icons/ObserveSvg";
import GoalsSvg from "@/components/Icons/GoalsSvg";
import SettingsSvg from "@/components/Icons/SettingsSvg";
import NavButton from "@/components/SidePane/NavButton";
import Hint from "@/components/Tooltips/Hint";

export default function SharedNavMenu({
  children
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();

  return (
    <div className="flex flex-1 min-h-0 w-full overflow-hidden pr-2 pb-2 bg-brev font-[Poppins]">
      <div className="flex flex-shrink-0 transition-all duration-300 overflow-hidden">
        <div className="flex flex-col gap-3 h-full w-2 sm:w-24 flex-shrink-0 bg-brev py-3">
          <div className="hidden sm:flex flex-col ">
            <NavButton
              icon={
                <ArtifactSvg
                  size={20}
                  isActive={location.pathname.includes("/artifacts")}
                />
              }
              label="Artifacts"
              active={location.pathname.includes("/artifacts")}
            />
            <Hint message="Contact sales">
              <NavButton
                disabled
                icon={
                  <RhythmsSvg
                    size={20}
                    isActive={location.pathname.includes("/rhythms")}
                  />
                }
                label="Rhythms"
              />
            </Hint>
            <Hint message="Contact sales">
              <NavButton
                disabled
                icon={
                  <ObserveSvg
                    size={20}
                    isActive={location.pathname.includes("/observe")}
                  />
                }
                label="Observe"
              />
            </Hint>
            <Hint message="Contact sales">
              <NavButton
                disabled
                icon={
                  <GoalsSvg
                    size={20}
                    isActive={location.pathname.includes("/goals")}
                  />
                }
                label="Goals"
              />
            </Hint>
            <Hint message="Contact sales">
              <NavButton
                disabled
                icon={
                  <SettingsSvg
                    size={20}
                    isActive={location.pathname.includes("/settings")}
                  />
                }
                label="Settings"
              />
            </Hint>
          </div>
        </div>
      </div>
      <div className="py-2 px-2 sm:px-4 md:px-16 flex flex-grow rounded-[8px] overflow-hidden bg-light-main">
        {children}
      </div>
    </div>
  );
}
