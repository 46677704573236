type corner = "top-left" | "top-right" | "bottom-left" | "bottom-right";

type ResizeHandleProps = {
  corner: corner;
  borderRadius?: number;
  borderWidth?: number;
  visible?: boolean;
};

const ResizeHandle = ({
  corner,
  borderRadius = 10,
  visible = false
}: ResizeHandleProps) => {
  const getStyles = () => {
    const baseStyles = {
      width: `${borderRadius + 1}px`,
      height: `${borderRadius + 1}px`,
      padding: "0",
      margin: "0",
      backgroundColor: "transparent",
      overflow: "hidden",
      zIndex: 10
    };

    const cornerStyles = {
      "top-left": { top: "0", left: "0" },
      "top-right": { top: "0", right: "0" },
      "bottom-left": { bottom: "0", left: "0" },
      "bottom-right": { bottom: "0", right: "0" }
    };

    const pseudoStyles = {
      content: '""',
      width: `${borderRadius * 2}px`,
      height: `${borderRadius * 2}px`,
      borderRadius: "50%"
    };

    const pseudoPositions = {
      "top-left": { top: "0", left: "0" },
      "top-right": { top: "0", right: "0" },
      "bottom-left": { bottom: "0", left: "0" },
      "bottom-right": { bottom: "0", right: "0" }
    };

    return {
      handle: { ...baseStyles, ...cornerStyles[corner] },
      pseudo: { ...pseudoStyles, ...pseudoPositions[corner] }
    };
  };

  const styles = getStyles();
  const className = `${visible ? "opacity-100" : "opacity-0"} absolute pointer-events-none react-resizable-handle-${corner} transition-opacity duration-500 ease-out`;

  return (
    <span className={className} style={styles.handle}>
      <div
        style={styles.pseudo}
        className="absolute pointer-events-none border-[3px] border-brev-border dark:border-brev-gray/30"
      />
    </span>
  );
};

export default ResizeHandle;
