import { useMemo, useState } from "react";
import { HiOutlineTrash } from "react-icons/hi2";
import { ConnectorDataEntity } from "../types";
import EntityPickerOption from "./EntityPickerOption";
import debounce from "lodash.debounce";
import { cn } from "@/lib/utils";
import {
  Select,
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import BrevLoader from "@/components/Loaders/BrevLoader";
import SearchInput from "@/components/Inputs/SearchInput";

type EntityPickerProps = {
  title?: string;
  placeholder?: string;
  applyDataName?: string;
  chosenEntityId?: string;
  showSearch?: boolean;
  connectedEntities: ConnectorDataEntity[];
  className?: string;
  queryTypes?: string[];
  isLoadingProp?: boolean;
  onEntityPressed?: (entity: ConnectorDataEntity) => Promise<void>;
  removeConnectorCallback?: () => void;
  onApplyDataCallback?: (dataEntities: ConnectorDataEntity[]) => Promise<void>;
  onSearchCallback?: (query: string, queryType?: string) => void;
};

const EntityPicker = ({
  title,
  placeholder,
  connectedEntities,
  chosenEntityId,
  showSearch,
  className,
  queryTypes,
  isLoadingProp,
  onEntityPressed,
  removeConnectorCallback,
  onSearchCallback,
}: EntityPickerProps) => {
  const [filter, setFilter] = useState<string>("");
  const [searchQueryType, setSearchQueryType] = useState<string>(
    (queryTypes || [""])[0]
  );
  const debouncedSearchCallback = useMemo(() => {
    if (onSearchCallback) {
      console.log(
        `debounced searching with filter and query type ${searchQueryType}`
      );
      return debounce(onSearchCallback, 1500);
    } else {
      return debounce(() => {}, 1500);
    }
  }, [searchQueryType, onSearchCallback]);

  return (
    <div className={cn("my-2 flex flex-col", className)}>
      <div className="px-2">
        <div className="flex justify-between w-full text-dark-text dark:text-light-text">
          {title && (
            <div className="text-[1.125rem] font-bold pb-2">{title}</div>
          )}
          {removeConnectorCallback && (
            <div
              className="cursor-pointer hover:scale-[110%] hover:text-red-500 transition-all ease-in-out"
              onClick={() => removeConnectorCallback()}
            >
              <HiOutlineTrash size={20} />
            </div>
          )}
        </div>
        {showSearch && (
          <div className="flex justify-between gap-2 items-bottom w-full">
            {queryTypes && (
              <Select
                value={searchQueryType}
                onValueChange={(value) => setSearchQueryType(value)}
              >
                <SelectTrigger className="text-xs border border-brev-border dark:border-dark-border dark:text-light-text dark:bg-dark-alt hover:shadow-sm w-[200px] h-[36px] p-3 rounded-lg data-[state=open]:rounded-lg text-dark-text transition-all duration-200">
                  <SelectValue placeholder="Select Query Type" />
                  <SelectIcon />
                </SelectTrigger>
                <SelectContent className="rounded-lg border border-brev-border dark:border-dark-border data-[state=open]:rounded-lg mt-1 p-1 text-dark-text dark:text-light-text bg-light-alt dark:bg-dark-alt">
                  {queryTypes.map((queryType, index) => {
                    return (
                      <SelectItem
                        key={index}
                        value={queryType}
                        className="cursor-pointer focus:bg-light-main dark:focus:bg-dark-main rounded-[8px] p-2"
                      >
                        {queryType}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
            )}
            <SearchInput
              placeholder={placeholder}
              onChange={(text: string) => {
                setFilter(text);
                debouncedSearchCallback(text, searchQueryType);
              }}
              disabled={isLoadingProp}
              value={filter}
            />
          </div>
        )}
        {isLoadingProp && (
          <div className="pt-4 flex flex-col gap-4 w-full h-[12.5rem] items-center justify-center">
            <BrevLoader type="propagate" />
          </div>
        )}
        {!isLoadingProp && (
          <div className="overflow-y-scroll overflow-x-hidden scrollbar-webkit-blur max-h-[14.5rem] py-2 mt-2 space-y-1">
            {connectedEntities
              .filter((de) => {
                if (filter.length > 0) {
                  return de.name
                    .toLocaleLowerCase()
                    .includes(filter.toLocaleLowerCase() ?? "");
                } else {
                  return de.name.toLocaleLowerCase();
                }
              })
              .map((de) => {
                return (
                  <EntityPickerOption
                    chosenEntityId={chosenEntityId}
                    dataEntity={de}
                    onEntityPressed={onEntityPressed!}
                    key={`${de.id}-${de.connected}`} 
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default EntityPicker;
