import { IntegrationEntityModel } from "@/containers/Integrations/types";
import EntityPickerOption from "@/components/Connector/components/EntityPickerOption";
import { SourceType } from "@/components/Connector/types";

type ArtifactContextCitationCardProps = {
  integrationEntity: IntegrationEntityModel;
};


function buildDeepLink(integrationEntity: IntegrationEntityModel) {
  switch (integrationEntity.entityType) {
    case SourceType.GOOGLE_DOC:
      return `https://docs.google.com/document/d/${integrationEntity.entityId}`;
    case SourceType.GOOGLE_SHEET:
      return `https://docs.google.com/spreadsheets/d/${integrationEntity.entityId}`;
    case SourceType.NOTION_PAGE:
      return `https://www.notion.so/${integrationEntity.entityId}`;
    case SourceType.SLACK_CHANNEL:
      return `https://slack.com/${integrationEntity.entityId}`;
    case SourceType.SALESFORCE:
      return `https://salesforce.com/${integrationEntity.entityId}`;
    case SourceType.GITHUB_REPO:
      return `https://github.com/${integrationEntity.entityId}`;
    default:
      return undefined;
  }
}

export default function ArtifactContextCitationCard({
  integrationEntity
}: ArtifactContextCitationCardProps) {
  return (
    <EntityPickerOption
      dataEntity={{
        id: integrationEntity.entityId,
        name: integrationEntity.entityName,
        sourceType: integrationEntity.entityType,
        isPending: false,
        connected: integrationEntity.isConnected,
        isNew: false,
      }}
      onEntityPressed={async () => {
        window.open(buildDeepLink(integrationEntity), "_blank");
      }}
    />
  );
}
