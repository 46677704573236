import LoginVisual from "@/containers/WebsiteVisuals/LoginVisual";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { motion } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";
import EmailLogin from "./EmailLogin";
import GoogleLogin from "./GoogleLogin";
import TextThroughLine from "@/components/ui/textThroughLine";
import { toast } from "@/components/ui/use-toast";

const LoginHeader = () => {
  return (
    <div className="px-16">
      <div className="text-dark-text text-3xl font-bold mb-2">Login</div>
      <div className="text-dark-text text-sm font-normal pt-3">
        Thanks for being here. Let's get after it!
      </div>
    </div>
  );
};

const Login = () => {
  const [showLogin, setShowLogin] = useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  const error = searchParams.get("error");
  if (error) {
    let errorMessage = "Something went wrong";
    if (error === "invalid-email") {
      errorMessage = "Invalid domain. Please use a work email.";
    }
    toast({
      title: "Error",
      description: errorMessage,
      variant: "destructive"
    });
    searchParams.delete("error");
    if (searchParams.size > 0) {
      window.history.replaceState({}, "", `${window.location.pathname}?${searchParams.toString()}`);
    } else {
      window.history.replaceState({}, "", window.location.pathname);
    }
  }

  return (
    <div className="flex p-0 overflow-hidden no-scrollbar">
      <LoginVisual />
      {showLogin ? (
        <motion.div
          className="fixed top-0 right-0 bg-white w-full h-screen sm:w-[572px] pt-10 flex flex-col drop-shadow-[0_15px_15px_rgba(0,0,0,0.15)]"
          initial={{ x: 150, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            type: "tween",
            duration: "0.3"
          }}
        >
          <div className="overflow-y-auto no-scrollbar">
            <Button
              variant="ghost"
              className="fixed top-5 right-5 rounded-full"
              onClick={() => {
                setShowLogin(false);
              }}
            >
              <IoCloseOutline />
            </Button>
            <LoginHeader />
            <div className="flex flex-col items-center py-5 w-full px-10 gap-5">
              <GoogleLogin />
              <TextThroughLine content="Or" className="w-[25rem]" />
              <EmailLogin />
            </div>
          </div>
          <div className="fixed flex items-center justify-center text-brev-gray bottom-0 left-0 h-20 w-full bg-light-main/20">
            Brev is hand crafted in the 415, 416, and 206.
          </div>
        </motion.div>
      ) : (
        <Button
          variant="negative"
          className="fixed top-5 right-5 w-[148px] hover:ring-0 hover:shadow-xl transition-all"
          onClick={() => {
            setShowLogin(true);
          }}
        >
          Log in
        </Button>
      )}
    </div>
  );
};

export default Login;
