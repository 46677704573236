import { useEffect, useState } from "react";

export const useGenerateList = (items: string[], speed: number) => {
  const [list, setList] = useState<string[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    if (items.length > 0) {
      setList([]);
      setIsGenerating(true);
      let itemIndex = 0;
      let charIndex = 0;
      let currentItem = "";

      const interval = setInterval(() => {
        if (itemIndex < items.length) {
          if (charIndex < items[itemIndex].length) {
            currentItem += items[itemIndex][charIndex];
            charIndex++;
          } else {
            itemIndex++;
            charIndex = 0;
            currentItem = "";
          }

          setList((prev) => {
            const newList = [...prev];
            newList[itemIndex] = currentItem;
            return newList;
          });
        } else {
          clearInterval(interval);
          setList(items);
          setIsGenerating(false);
        }
      }, speed);

      return () => {
        clearInterval(interval);
      };
    } else {
      setIsGenerating(false);
    }
  }, [items, speed]);

  return { list, isGenerating };
};
