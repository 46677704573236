import {
  Select,
  SelectContent,
  SelectGroup,
  SelectIcon,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import { cn } from "@/lib/utils";

export type OptionNameValue = {
  name: string;
  value: string;
};

type SelectorProps = {
  onChange?: (value: string) => void;
  title: string;
  itemList?: string[];
  optionsList?: OptionNameValue[];
  warning?: boolean;
  className?: string;
  defaultValue?: string;
  value?: string;
  hideIcon?: boolean;
};
export function Selector({
  title,
  itemList,
  optionsList,
  onChange,
  warning,
  className,
  defaultValue,
  value,
  hideIcon
}: SelectorProps) {
  const handleValueChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  if (!optionsList && itemList) {
    optionsList = itemList.map((e) => {
      return {
        name: e.trim().toLocaleLowerCase(),
        value: e
      };
    });
  }
  return (
    <Select
      onValueChange={handleValueChange}
      defaultValue={defaultValue}
      value={value}
    >
      <SelectTrigger
        className={cn(
          "capitalize dark:border dark:border-dark-border font-normal text-dark-text h-[1.75rem] w-[9rem] rounded-[8px] dark:bg-dark-alt dark:text-brev-light bg-light-alt data-[state=open]:bg-light-alt px-[0.75rem] pr-2 transition-colors duration-500 ease-in-out overflow-hidden truncate",
          warning
            ? "data-[state=closed]:ring-2 data-[state=closed]:ring-red-600"
            : "",
          className
        )}
      >
        <SelectValue placeholder={title} className="truncate" />
        {!hideIcon && <SelectIcon />}
      </SelectTrigger>
      <SelectContent className="font-[Poppins] bg-light-alt dark:bg-dark-alt text-dark-text dark:text-brev-light">
        <SelectGroup className="divide-y-[1px] divide-brev-border dark:divide-dark-border border border-t-0 dark:border-dark-border">
          {optionsList?.map((option) => (
            <SelectItem
              key={option.value + "selector"}
              value={option.value}
              className="pl-3 font-normal text-wrap capitalize"
            >
              {option.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
