import {
  createContext,
  ReactNode,
  useReducer,
  useEffect,
  useMemo,
  useState
} from "react";
import type { CalendarEvent } from "./calendar-types";

export interface RhythmState {
  eventArray?: Array<CalendarEvent>;
  currentEventLinks?: Array<string>;
  openEventLinks?: Array<string>;
  isGeneratingBrief?: boolean;
  isGeneratingSummary?: boolean;
  isGeneratingDescription?: boolean;
  isGeneratingAgenda?: boolean;
}

type Actions =
  | "rhythms/GENERATEDESCRIPTION"
  | "rhythms/GENERATEBRIEF"
  | "rhythms/GENERATESUMMARY"
  | "rhythms/GENERATEAGENDA";


type Payload = {
  eventArray?: Array<CalendarEvent>;
  currentEvent?: string;
  openEvent?: string;
  currentEventLinks?: Array<string>;
  openEventLinks?: Array<string>;
  eventToSummarize?: string;
  isGeneratingBrief?: boolean;
  isGeneratingSummary?: boolean;
  isGeneratingDescription?: boolean;
  isGeneratingAgenda?: boolean;
};

interface RhythmAction {
  type: Actions;
  payload: Payload;
}

interface RhythmContextProps {
  state: RhythmState;
  dispatch: React.Dispatch<RhythmAction>;
  selectedEvents: Array<CalendarEvent>;
  setSelectedEvents: React.Dispatch<React.SetStateAction<Array<CalendarEvent>>>;
  eventArray: Array<CalendarEvent>;
  setEventArray: React.Dispatch<React.SetStateAction<Array<CalendarEvent>>>;
  openEventId: string;
  setOpenEventId: React.Dispatch<React.SetStateAction<string>>;
  currentEventId: string;
  setCurrentEventId: React.Dispatch<React.SetStateAction<string>>;
}

export const RhythmContext = createContext<RhythmContextProps>({
  state: {},
  dispatch: () => ({}) as React.Dispatch<RhythmAction>,
  selectedEvents: [],
  setSelectedEvents: () => {
    throw new Error("No RhythmContext available");
  },
  eventArray: [],
  setEventArray: () => {
    throw new Error("No RhythmContext available");
  },
  openEventId: "",
  setOpenEventId: () => {
    throw new Error("No RhythmContext available");
  },
  currentEventId: "",
  setCurrentEventId: () => {
    throw new Error("No RhythmContext available");
  }
});

// Define the reducer
const reducer = (state: RhythmState, action: RhythmAction): RhythmState => {
  const { currentEventLinks, openEventLinks, isGeneratingBrief, isGeneratingSummary, isGeneratingDescription, isGeneratingAgenda } =
    action.payload;

  switch (action.type) {
    case "rhythms/GENERATEDESCRIPTION":
      return {
        ...state,
        openEventLinks,
        isGeneratingDescription
      };
    case "rhythms/GENERATEBRIEF":
      return {
        ...state,
        isGeneratingBrief
      };
    case "rhythms/GENERATESUMMARY":
      return {
        ...state,
        currentEventLinks,
        isGeneratingSummary
      };
    case "rhythms/GENERATEAGENDA":
      return {
        ...state,
        isGeneratingAgenda
      };
    default:
      return state;
  }
};

type RhythmProviderProps = {
  children: ReactNode;
};

const RhythmsProvider = ({ children }: RhythmProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {});
  const [selectedEvents, setSelectedEvents] = useState<Array<CalendarEvent>>(
    []
  );
  const [eventArray, setEventArray] = useState<Array<CalendarEvent>>([]);
  const [openEventId, setOpenEventId] = useState<string>("");
  const [currentEventId, setCurrentEventId] = useState<string>("");

  const extractUrls = (description: string): string[] => {
    const urlRegex = /https:\/\/docs\.google\.com\/[^\s<"]+/g;
    const urls = description.match(urlRegex);
    return urls ? Array.from(new Set(urls)) : [];
  };

  useEffect(() => {
    const curr = eventArray.find((e) => e.id === currentEventId);
    const urls = curr?.description
      ? extractUrls(curr.description)
      : [];
    const attachmentUrls = curr?.attachments
      ? curr.attachments.map((a) => a.fileUrl)
      : [];
    dispatch({
      type: "rhythms/GENERATESUMMARY",
      payload: { currentEventLinks: [...urls, ...attachmentUrls] }
    });
  }, [currentEventId]);

  useEffect(() => {
    const openEvent = eventArray.find((e) => e.id === openEventId);
    const urls = openEvent?.description
      ? extractUrls(openEvent.description)
      : [];
    const attachmentUrls = openEvent?.attachments
      ? openEvent.attachments.map((a) => a.fileUrl)
      : [];
    dispatch({
      type: "rhythms/GENERATEDESCRIPTION",
      payload: { openEventLinks: [...urls, ...attachmentUrls] }
    });
  }, [openEventId]);

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      selectedEvents,
      setSelectedEvents, 
      eventArray,
      setEventArray,
      openEventId,
      setOpenEventId,
      currentEventId,
      setCurrentEventId
    }),
    [
      state,
      dispatch,
      selectedEvents,
      setSelectedEvents,
      eventArray,
      setEventArray,
      openEventId,
      setOpenEventId,
      currentEventId,
      setCurrentEventId
    ]
  );

  return (
    <RhythmContext.Provider value={contextValue}>
      {children}
    </RhythmContext.Provider>
  );
};

export { RhythmsProvider };
