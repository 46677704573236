import React, { useState, useEffect } from "react";
import type { CalendarEvent } from "./calendar-types";
import { Separator } from "@/components/ui/separator";
import { BounceLoader } from "react-spinners";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { SmallGDocSvg } from "@/components/Icons/SmallGDocSvg";
import { parseRrule } from "./utils";
import { mockData } from "./mock-data";
import { useGenerateText } from "./useGenerateText";
import { useGenerateList } from "./useGenerateList";
import { IoIosCheckmarkCircle } from "react-icons/io";

type EventQuickViewProps = {
  event?: CalendarEvent;
};

type EventLink = {
  linkUrl: string;
  iconComponent: React.ReactNode;
};

const checkList = [
  "Sara is completing pipeline creation strategy for 2024 planning given launch of new regions.",
  "Marketing is preparing bottoms up lead to pipeline model based on 2023 Q1 historicals.",
  "Product team is completing their FY 24 Roadmap including marketing variation for customers.",
  "Engineering is working on their Enterprise Readiness migration from Azure to AWS in 2025 and reviewing additional consultants to support."
];

export default function EventQuickView({ event }: EventQuickViewProps) {
  const [checkedItems, setCheckedItems] = useState<number[]>([]);
  const { list, isGenerating: listGenerating } = useGenerateList(
    mockData["Monthly Sales Review"].agendaArray,
    10
  );
  const { text, isGenerating } = useGenerateText(
    mockData["Monthly Sales Review"].summary,
    5
  );
  // const { text: a1 } = useGenerateText(
  //   mockData["Monthly Sales Review"].agendaArray[0],
  //   5
  // );
  const [mode, setMode] = useState<"summary" | "actions">("summary");
  // const [isGenerating, _setIsGenerating] = useState(false);
  const [eventLinks, setEventLinks] = useState<EventLink[]>([]);
  const parsedRrule = event?.recurrence
    ? parseRrule(event.recurrence[event.recurrence.length - 1])
    : undefined;

  function extractUrls(description: string): string[] {
    // only grabbing google docs urls for demo rhythms
    const urlRegex = /https:\/\/docs\.google\.com\/[^\s<"]+/g;
    const urls = description.match(urlRegex);
    const uniqueUrls = urls ? Array.from(new Set(urls)) : [];
    return uniqueUrls;
  }

  useEffect(() => {
    const urls = event?.description ? extractUrls(event.description) : [];
    // const attachments = event.attachments ? event.attachments.map((a) => a.fileUrl) : [];
    // setCurrentEventLinks([...urls, ...attachments]);
    const eventUrls = urls.map((url) => ({
      linkUrl: url,
      iconComponent: <SmallGDocSvg />
    }));
    const attachmentUrls = event?.attachments
      ? event.attachments.map((a) => ({
          linkUrl: a.fileUrl,
          iconComponent: a.iconLink ? (
            <ImagePadding url={a.iconLink} />
          ) : (
            <SmallGDocSvg />
          )
        }))
      : [];
    setEventLinks([...eventUrls, ...attachmentUrls]);
  }, [event?.description, event?.attachments]);
  // const contributors = event.attendees?.map(a => a.displayName || a.email).join(", ");

  return (
    <div
      className={`relative pt-[28px] overflow-hidden transition-all w-full duration-300`}
      style={{ backgroundColor: event?.colour || "#110F1D" }}
    >
      <>
        <div
          className={`text-white text-xs font-normal font-[Poppins] w-full text-left justify-start pl-14`}
        >
          <span className="text-[23px] capitalize">{event?.summary}</span>
          <div className="flex gap-2 h-[15.5px] mb-2">
            <span className="">Team</span>
            <Separator orientation="vertical" className="bg-white" />
            <span className="lowercase first-letter:uppercase">
              {parsedRrule?.interval && parsedRrule?.interval === 13
                ? "Quarterly"
                : parsedRrule?.frequency}
            </span>
          </div>
          <span className="capitalize">
            {event?.organizer
              ? event.organizer.displayName || event.organizer.email
              : event?.creator.displayName || event?.creator.email}
          </span>
          <hr></hr>
          <div className="flex flex-col gap-2 justify-between pr-10">
            <span>Contributors: </span>
            <div className="">Vic Hu, Benn Graham</div>
            {/* <span>Observers: </span> */}
          </div>
        </div>
        <div className="relative h-[calc(100vh-216.71px)] flex flex-col bg-white rounded-tl-3xl mt-[2rem]">
          <div className="sticky top-0 px-3 py-3 z-1 bg-white rounded-2xl">
            <div className="h-[50px] w-full flex justify-around">
              <div
                onMouseDown={() => setMode("summary")}
                className={`w-[111px] h-[27px] text-xs font-medium cursor-pointer ${mode === "summary" ? "bg-brev text-white" : "bg-[#FFF] text-dark-text"} hover:bg-brev hover:text-[#FFF] text-[#000] flex items-center justify-center rounded-[8px]`}
              >
                Summary
              </div>
              <div
                onMouseDown={() => setMode("actions")}
                className={`w-[111px] h-[27px] text-xs font-medium cursor-pointer ${mode === "actions" ? "bg-brev text-white" : "bg-[#FFF] text-dark-text"} hover:bg-brev hover:text-[#FFF] text-[#000] flex items-center justify-center rounded-[8px]`}
              >
                Actions
              </div>
              <div className="w-[111px] text-xs font-medium cursor-pointer  hover:text-[#FFF] text-[#000] flex items-center justify-center rounded-[8px]">
                {/* Reviews+ */}
              </div>
            </div>
          </div>
          {mode === "summary" && (
            <div className="px-12 overflow-y-scroll break-words py-3 no-scrollbar">
              <span className="text-xs font-bold">Rhythm summary</span>
              <div className="flex flex-col my-3 bg-brev-light rounded-[8px] w-[341px] h-[268px] overflow-hidden">
                <div className="w-[22px] min-h-[22px] flex items-center justify-center rounded-[8px] bg-white m-3 shadow-[0_0_15.5px_0_rgba(54,71,114,0.15)]">
                  {isGenerating ? (
                    <BounceLoader color="#3FCFF1" size={12} />
                  ) : (
                    <div className="flex bg-brev-accent ring-2 ring-[#017B4F05] shadow-xl shadow-black min-w-[7.34px] min-h-[7.34px] rounded-full"></div>
                  )}
                </div>
                <p className="text-[11px] font-normal px-4 h-full overflow-y-scroll no-scrollbar">
                  {text}
                </p>
              </div>
              {eventLinks.length > 0 && (
                <>
                  <span className="text-xs font-bold">Links & attachments</span>
                  <div className="flex flex-col my-3 gap-2">
                    {eventLinks.map((link, index) => {
                      return (
                        <a
                          href={link.linkUrl}
                          key={index}
                          target="_blank"
                          className="flex gap-2 items-center w-[340px] h-[36px] pl-2 pr-4 rounded-[8px] bg-brev-light text-[10px] font-medium no-underline text-dark-text"
                        >
                          <div className="flex items-center justify-center h-[19px] w-[19px]">
                            {link.iconComponent}
                          </div>
                          <span className="truncate">{link.linkUrl}</span>
                        </a>
                      );
                    })}
                  </div>
                </>
              )}
              <span className="text-xs font-bold">Rhythm agenda</span>
              <div className="flex flex-col justify-center bg-brev-light rounded-[8px] w-[341px] h-[268px] my-3">
                <div className="h-full text-[11px] text-left font-normal pr-4 py-3 overflow-y-scroll no-scrollbar">
                  {/* {mockData["Monthly Sales Review"].agenda} */}
                  <div>
                    {listGenerating && (
                      <div className="flex w-full justify-center mb-3">
                        <BrevLoader type="pulse" size={8} />
                      </div>
                    )}

                    <ul className="list-disc">
                      {list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
          {mode === "actions" && (
            <div className="flex flex-col my-3 gap-2 items-center">
              <div className="flex items-center w-full px-8">
                <div className="w-[22px] min-h-[22px] flex items-center justify-center rounded-[8px] bg-white m-3 shadow-[0_0_15.5px_0_rgba(54,71,114,0.15)]">
                  {isGenerating ? (
                    <BounceLoader color="#3FCFF1" size={12} />
                  ) : (
                    <div className="flex bg-brev-accent ring-2 ring-[#017B4F05] shadow-xl shadow-black min-w-[7.34px] min-h-[7.34px] rounded-full"></div>
                  )}
                </div>
                <span className="font-bold text-[0.75em]">Action Items</span>
              </div>
              <div className="flex flex-col gap-2 bg-brev-light rounded-[8px] w-[341px] h-[268px] overflow-hidden p-3">
                {checkList.map((c, index) => (
                  <div key={index} className="flex flex-shrink-0 gap-2">
                    <div
                      onMouseDown={() => {
                        setCheckedItems((prev) => {
                          if (prev.includes(index)) {
                            return prev.filter((item) => item !== index);
                          } else {
                            return [...prev, index];
                          }
                        });
                      }}
                      className="h-[17px] w-[17px] cursor-pointer"
                    >
                      <IoIosCheckmarkCircle
                        color={
                          checkedItems.includes(index) ? "#3FCFF1" : "#CECECE"
                        }
                        size={16}
                      />
                    </div>
                    <span
                      className={`text-[0.75em] font-medium ${checkedItems.includes(index) ? "line-through text-brev-gray" : "text-dark-text"}`}
                    >
                      {c}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </>
    </div>
  );
}

export const ImagePadding = ({ url }: { url: string }) => {
  return (
    <div className="p-[5px] bg-white rounded-full h-[19px] w-[19px]">
      <img src={url} alt="File Icon" />
    </div>
  );
};
