import { FaUserCircle } from "react-icons/fa";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

type UserAvatarProps = {
  image: string;
  dark?: boolean;
  small?: boolean;
};

export default function UserAvatar({ image, dark, small }: UserAvatarProps) {
  return (
    <Avatar
      className={`flex flex-row items-center ${small && "h-[28px] w-[28px]"}`}
    >
      <AvatarImage src={image} referrerPolicy="no-referrer" />
      <AvatarFallback>
        <FaUserCircle
          size={40}
          className={`${dark ? "text-dark-text bg-brev-light" : "text-brev-light bg-brev"}`}
        />
      </AvatarFallback>
    </Avatar>
  );
}
