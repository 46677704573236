import { memo } from "react";
import UserAvatar from "./UserAvatar";
import { cn } from "@/lib/utils";

type UserCardProps = {
  firstName?: string;
  lastName?: string;
  profilePictureUrl?: string;
  email?: string;
  showMinimal?: boolean;
  dark?: boolean;
  title?: string;
  image?: string;
  className?: string;
  avatarSize?: number;
  small?: boolean;
};

const UserCard = ({
  firstName,
  lastName,
  profilePictureUrl,
  email,
  showMinimal,
  small,
  className,
  dark
}: UserCardProps) => {
  return (
    <div className="flex flex-row items-center font-[Poppins]">
      <UserAvatar
        image={profilePictureUrl || ""}
        dark={dark || false}
        small={small || false}
      />
      {!showMinimal && (
        <div
          className={`flex flex-col mb-1 ml-2 transition-all duration-300 ease-in-out ${
            showMinimal ? "max-w-0 opacity-0" : "opacity-100"
          }`}
        >
          <div
            className={cn(
              "text-xs font-medium tracking-tight leading-snug whitespace-nowrap overflow-ellipsis",
              small && "leading-none",
              className
            )}
          >
            <span>
              {firstName} {lastName}
            </span>
            <div className="text-xs font-light">{small ? "Owner" : email}</div>
          </div>
        </div>
      )}
    </div>
  );
};

const MemoizedUserCard = memo(UserCard);
export default MemoizedUserCard;
