type UploadedFileProps = {
  fileName: string;
  actionElement?: JSX.Element;
  index: number;
  uploaded?: boolean;
};

const UploadedFileCard = ({
  fileName,
  actionElement,
  index,
  uploaded
}: UploadedFileProps) => {
  return (
    <div
      key={index}
      title={fileName}
      className={`${uploaded ? "bg-lime-500/20" : "bg-white/20"} rounded-[8px] p-2 flex items-baseline justify-between min-h-10 transition-all`}
    >
      <span className="text-xs truncate w-[20rem]">
        {fileName}
      </span>
      {actionElement}
    </div>
  );
};

export default UploadedFileCard;
