import React, { useMemo, useContext } from "react";
import {
  DataSet,
  Tile,
  KPIData,
  GoalData,
  TextData,
  TabularData,
  EmbedData
} from "@/containers/ReviewBuilder/review-types";
import SlideChart from "../TemplateComponents/SlideChart";
import SlideGoal from "../TemplateComponents/SlideGoal";
import SlideKPI from "../TemplateComponents/SlideKPI";
import SlideText from "../TemplateComponents/SlideText";
import SlideHeading from "../TemplateComponents/SlideHeading";
import { TileType } from "@/containers/ReviewBuilder/review-types";
import { ArtifactContext } from "../../ArtifactProvider";
import EmbedTile from "../TemplateComponents/EmbedTile/EmbedTile";

const GridTile = React.memo(
  ({
    tile,
    isEditMode,
    published = false
  }: {
    tile: Tile;
    isEditMode?: boolean;
    published?: boolean;
  }) => {
    const { updateTileContent } = useContext(ArtifactContext);
    const content = useMemo(() => {
      switch (tile.tile_type) {
        case TileType.KPI:
          return (
            <SlideKPI
              editable={!published}
              kpi={tile.content as KPIData}
              updateTileContent={(newContent: Partial<KPIData>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        case TileType.TEXT:
          return (
            <SlideText
              editable={!published}
              content={tile.content as TextData}
              updateTileContent={(newContent: Partial<TextData>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        case TileType.CHART:
          return (
            <SlideChart
              editable={!published}
              data={tile.content as TabularData}
              updateTileContent={(newContent: Partial<DataSet>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        case TileType.GOAL:
          return (
            <SlideGoal
              editable={!published}
              data={tile.content as GoalData}
              updateTileContent={(newContent: Partial<GoalData>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        case TileType.HEADING:
          return (
            <SlideHeading
              editable={!published}
              content={tile.content as TextData}
              updateTileContent={(newContent: Partial<TextData>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        case TileType.EMBED:
          return (
            <EmbedTile
              published={published}
              content={tile.content as EmbedData}
              updateTileContent={(newContent: Partial<EmbedData>) =>
                updateTileContent && updateTileContent(tile.id, newContent)
              }
            />
          );
        default:
          return <div>Unknown tile type</div>;
      }
    }, [tile, updateTileContent]);

    return (
      <div
        style={{ pointerEvents: isEditMode ? "none" : "auto" }}
        className={`${isEditMode ? "group-hover:opacity-50" : ""} flex flex-col flex-grow h-full w-full items-center justify-start sm:justify-center`}
      >
        {content}
      </div>
    );
  }
);

export default GridTile;
