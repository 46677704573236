import { createContext, useCallback, useContext, useState } from "react";
import { AuthContext } from "../Auth/AuthProvider";

interface UserSettingsProviderProps {
  firstName: string;
  setFirstName: React.Dispatch<React.SetStateAction<string>>;
  lastName: string;
  setLastName: React.Dispatch<React.SetStateAction<string>>;
  jobTitle: string;
  setJobTitle: React.Dispatch<React.SetStateAction<string>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  saveUserSettings: () => Promise<void>;
}

export const UserSettingsContext = createContext<UserSettingsProviderProps>({
  firstName: "",
  setFirstName: () => {
    throw new Error("No UserSettingsContext available");
  },
  lastName: "",
  setLastName: () => {
    throw new Error("No UserSettingsContext available");
  },
  jobTitle: "",
  setJobTitle: () => {
    throw new Error("No UserSettingsContext available");
  },
  companyName: "",
  setCompanyName: () => {
    throw new Error("No UserSettingsContext available");
  },
  saveUserSettings: async () => {
    throw new Error("No UserSettingsContext available");
  }
});

export const UserSettingsProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { user, sendApiRequest, fetchUser } = useContext(AuthContext);
  const [firstName, setFirstName] = useState(user?.firstName || "");
  const [lastName, setLastName] = useState(user?.lastName || "");
  const [jobTitle, setJobTitle] = useState(user?.jobTitle || "");
  const [companyName, setCompanyName] = useState(user?.companyName || "");
  const [isSaving, setIsSaving] = useState(false);

  const saveUserSettings = useCallback(async () => {
    console.log("Saving user settings");
    if (isSaving || (!firstName && !lastName && !jobTitle && !companyName)) {
      console.log("No changes to save");
      return;
    }
    setIsSaving(true);
    await sendApiRequest("/users", "PATCH", {
      firstName,
      lastName,
      jobTitle,
      companyName
    });
    fetchUser();
    setIsSaving(false);
  }, [isSaving, firstName, lastName, jobTitle, companyName, sendApiRequest, fetchUser]);

  return (
    <UserSettingsContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        jobTitle,
        setJobTitle,
        companyName,
        setCompanyName,
        saveUserSettings
      }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};
