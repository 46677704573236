import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { LiaSalesforce } from "react-icons/lia";
import { FaRegFileLines } from "react-icons/fa6";
import { ResourceModel, SourceType } from "@/components/Connector/types";
import NotionSvg from "@/components/Icons/NotionSvg";
import GSheetsSvg from "@/components/Icons/GSheetsSvg";
import GDocsSvg from "@/components/Icons/GDocsSvg";
import SlackSvg from "@/components/Icons/SlackSvg";

type ResourceIndicatorProps = {
  resources: ResourceModel[];
};

const sourceIcon = (sourceType: SourceType) => {
  switch (sourceType) {
    case SourceType.CSV:
    case SourceType.TEXT:
      return <FaRegFileLines size={18} />;
    case SourceType.SLACK_CHANNEL:
      return <SlackSvg size={18} />;
    case SourceType.SALESFORCE:
      return <LiaSalesforce size={18} />;
    case SourceType.GOOGLE_DOC:
      return <GDocsSvg size={18} />;
    case SourceType.GOOGLE_SHEET:
      return <GSheetsSvg size={18} />;
    case SourceType.NOTION_DB:
    case SourceType.NOTION_PAGE:
      return <NotionSvg size={18} />;
    default:
      return null;
  }
};

const groupResourcesBySourceType = (resources: ResourceModel[]) => {
  return resources.reduce(
    (acc, resource) => {
      const { sourceType } = resource.resourceSource;
      if (!acc[sourceType]) {
        acc[sourceType] = [];
      }
      acc[sourceType].push(resource);
      return acc;
    },
    {} as Record<SourceType, ResourceModel[]>
  );
};

export default function ResourceIndicator({
  resources
}: ResourceIndicatorProps) {
  const groupedResources = groupResourcesBySourceType(resources);

  return (
    <div className="flex gap-2">
      {resources && resources.length > 0 && (
        <div className="cursor-pointer group-hover:text-brev-active hover:text-brev-active flex items-center">
          <TooltipProvider>
            {Object.entries(groupedResources).map(([sourceType, resources]) => (
              <Tooltip key={sourceType}>
                <TooltipTrigger className="px-1">
                  {sourceIcon(sourceType as SourceType)}
                </TooltipTrigger>
                <TooltipContent
                  side="top"
                  className="flex flex-col py-2 m-0 min-h-2 overflow-auto max-w-[250px] transition-all"
                >
                  <div className="group/resources flex flex-col gap-2 m-0 pt-2 overflow-hidden">
                    {resources.map((resource, index) => (
                      <span
                        className="line-clamp-1 group-hover/resources:line-clamp-none group-hover/resources:p-2 transition-all"
                        key={index}
                      >
                        {resource.resourceName}
                      </span>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            ))}
          </TooltipProvider>
        </div>
      )}
    </div>
  );
}
