export type DateRange = {
  start: Date;
  end: Date;
};

export type RRuleInfo = {
  frequency:
    | "DAILY"
    | "WEEKLY"
    | "MONTHLY"
    | "QUARTERLY"
    | "BIANNUALLY"
    | "ANNUALLY";
  days?: string[];
  week?: number;
  month?: number;
  half?: 1 | 2;
  interval?: number;
  weekNums?: number[];
  dayOfWeek?: string;
  weekOfMonth?: number;
};

export const parseRrule = (rrule: string): RRuleInfo => {
  const rruleParts = rrule.replace("RRULE:", "").split(";");
  const ruleObj: { [key: string]: string } = {};
  rruleParts.forEach((part) => {
    const [key, value] = part.split("=");
    ruleObj[key] = value;
  });

  const freq = ruleObj["FREQ"];
  let frequency: RRuleInfo["frequency"] = "DAILY";

  switch (freq) {
    case "DAILY":
      frequency = "DAILY";
      break;
    case "WEEKLY":
      frequency = "WEEKLY";
      break;
    case "MONTHLY":
      frequency = "MONTHLY";
      break;
  }

  const result: RRuleInfo = { frequency };
  result.interval = ruleObj["INTERVAL"]
    ? parseInt(ruleObj["INTERVAL"] || "1", 10)
    : undefined;

  if (frequency === "WEEKLY" && ruleObj["BYDAY"]) {
    result.days = ruleObj["BYDAY"].split(",");
  } else if (frequency === "MONTHLY") {
    if (ruleObj["BYSETPOS"]) {
      result.week = parseInt(ruleObj["BYSETPOS"], 10);
    }

    if (ruleObj["BYDAY"]) {
      const byday = ruleObj["BYDAY"];
      const weekOfMonthMatch = byday.match(/(-?\d)([A-Z]{2})/);
      if (weekOfMonthMatch) {
        result.weekOfMonth = parseInt(weekOfMonthMatch[1], 10);
        result.dayOfWeek = weekOfMonthMatch[2];
      }
    }
  }

  return result;
};

type MonthInfo = {
  startDate: Date;
  endDate: Date;
  numWeeks: number;
};

export const getMonthInfo = (monthIndex: number): MonthInfo => {
  const currentDate = new Date();
  const year = currentDate.getFullYear();

  const startDate = new Date(year, monthIndex, 1);
  const endDate = new Date(year, monthIndex + 1, 0);

  const firstDayOfMonth = startDate.getDay();
  const lastDayOfMonth = endDate.getDate();
  const numWeeks = Math.ceil((lastDayOfMonth + firstDayOfMonth) / 7);

  return {
    startDate,
    endDate,
    numWeeks
  };
};

// export const getQuarterInfo = (quarterIndex: number): QuarterInfo => {
//   const currentDate = new Date();
//   const year = currentDate.getFullYear();

//   const startDate = new Date(year, quarterIndex * 3, 1);
//   const endDate = new Date(year, (quarterIndex + 1) * 3, 0);

//   const firstDayOfMonth = startDate.getDay();
//   const lastDayOfMonth = endDate.getDate();
//   const numWeeks = Math.ceil((lastDayOfMonth + firstDayOfMonth) / 7);

//   return {
//     startDate,
//     endDate,
//     numWeeks
//   };
// };

export const getWeekOfMonth = (date: Date): number => {
  const dayOfMonth = date.getDate();

  const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  const firstDayOfWeek = firstDayOfMonth.getDay();

  const currentDayOfWeek = date.getDay();

  const weekOfMonth = Math.ceil((dayOfMonth + firstDayOfWeek) / 7);
  if (currentDayOfWeek < firstDayOfWeek) {
    return weekOfMonth - 1;
  }

  return weekOfMonth;
};

// export const getWeekNumber = (date: Date) => {
//   const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
//   const dayOfWeek = firstDay.getDay();
//   const adjustedDay = firstDay.getDate() + dayOfWeek;
//   return Math.ceil(adjustedDay / 7);
// };
export function getWeekNumber(date: Date): number {
  const dateCopy = new Date(date.getTime());
  dateCopy.setDate(dateCopy.getDate() + 4 - (dateCopy.getDay() || 7));
  const yearStart = new Date(dateCopy.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((dateCopy.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
  );
  return weekNumber;
}

// export const getEventWeeks = (
//   rrule: RRuleInfo,
//   startDate: Date,
//   year?: number
// ): number[] => {
//   const interval = rrule.interval || 1;

//   const startWeek = getWeekNumber(startDate);

//   if (!year) {
//     year = new Date().getFullYear();
//   }

//   const eventWeeks: number[] = [startWeek];
//   for (let i = 1; i < 52; i++) {
//     const weekNumber = startWeek + i * interval;
//     if (weekNumber <= 52) {
//       eventWeeks.push(weekNumber);
//     } else {
//       break;
//     }
//   }

//   return eventWeeks;
// };

const getLastDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month + 1, 0);
};

export const getEventWeeks = (
  rrule: RRuleInfo,
  startDate: Date,
  year?: number
): number[] => {
  const interval = rrule.interval || 1;
  const startWeek = getWeekNumber(startDate);

  if (!year) {
    year = new Date().getFullYear();
  }

  const eventWeeks: number[] = [];

  switch (rrule.frequency) {
    case "WEEKLY":
      eventWeeks.push(startWeek);
      for (let i = 1; i < 52; i++) {
        const weekNumber = startWeek + i * interval;
        if (weekNumber <= 52) {
          eventWeeks.push(weekNumber);
        } else {
          break;
        }
      }
      break;

    case "MONTHLY":
      if (rrule.weekOfMonth !== undefined) {
        for (let month = 0; month < 12; month++) {
          const firstDayOfMonth = new Date(year, month, 1);
          const lastDayOfMonth = getLastDayOfMonth(year, month);
          let occurrenceDate: Date;

          if (rrule.weekOfMonth > 0) {
            occurrenceDate = new Date(firstDayOfMonth);
            occurrenceDate.setDate((rrule.weekOfMonth - 1) * 7 + 1);
          } else {
            occurrenceDate = new Date(lastDayOfMonth);
            occurrenceDate.setDate(
              lastDayOfMonth.getDate() + rrule.weekOfMonth * 7 + 1
            );
          }

          if (occurrenceDate >= startDate) {
            const occurrenceWeek = getWeekNumber(occurrenceDate);
            if (!eventWeeks.includes(occurrenceWeek)) {
              eventWeeks.push(occurrenceWeek);
            }
          }
        }
      }
      break;

    default:
      console.log(`invalid ${rrule.frequency}`);
  }

  return eventWeeks;
};

export function getCurrentWeek(): DateRange {
  const today = new Date();

  today.setHours(0, 0, 0, 0);

  const day = today.getDay();

  const start = new Date(today.setDate(today.getDate() - day));
  const end = new Date(start.getTime());
  end.setDate(end.getDate() + 6);

  return { start: start, end: end };
}

export const dateRanges = {
  Week: () => {
    const now = new Date();
    const start = new Date(now.setDate(now.getDate() - now.getDay()));
    start.setHours(0, 0, 0, 0);
    const end = new Date(now.setDate(now.getDate() + 6));
    end.setHours(23, 59, 59, 999);
    return { start, end };
  },
  Month: () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 999);
    return { start, end };
  },
  Quarter: () => {
    const now = new Date();
    const quarter = Math.floor(now.getMonth() / 3);
    const start = new Date(now.getFullYear(), quarter * 3, 1);
    const end = new Date(now.getFullYear(), (quarter + 1) * 3, 0);
    end.setHours(23, 59, 59, 999);
    return { start, end };
  },
  "Bi-Annual": () => {
    const now = new Date();
    const half = Math.floor(now.getMonth() / 6);
    const start = new Date(now.getFullYear(), half * 6, 1);
    const end = new Date(now.getFullYear(), (half + 1) * 6, 0);
    end.setHours(23, 59, 59, 999);
    return { start, end };
  },
  Annual: () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const end = new Date(now.getFullYear(), 11, 31);
    end.setHours(23, 59, 59, 999);
    return { start, end };
  },
  Custom: () => {
    // custom date range?
    return { start: new Date(), end: new Date() };
  }
};
