import EditableText from "../EditableText";
import { TextData } from "@/containers/ReviewBuilder/review-types";

export default function SlideHeading({
  content,
  updateTileContent,
  editable = true
}: {
  content: TextData;
  updateTileContent: (title: Partial<TextData>) => void;
  editable?: boolean;
}) {
  const handleTitleChange = (value: string) => {
    updateTileContent({ title: value });
  };
  return (
    <div className="flex items-baseline justify-start w-full text-[1.5rem] z-50">
      <EditableText
        value={content.title}
        onSave={handleTitleChange}
        className="font-bold w-full"
        editable={editable}
        scaleFontSize={true}
        maxLength={100}
        minLength={35}
        minFontSize={16}
        maxFontSize={30}
      />
    </div>
  );
}
