import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import {
  ArtifactCitationModel,
  ArtifactMetadata,
  ArtifactModel,
  ArtifactPublishModel
} from "./types";
import { useParams } from "react-router-dom";
// import debounce from "lodash.debounce";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { Switch } from "@/components/ui/switch";
import { isBeta } from "@/lib/stageUtils";
import { ArtifactCopilotProvider } from "./ArtifactCopilot/ArtifactCopilotProvider";
import {
  DataSet,
  KPIData,
  GoalData,
  TextData,
  TabularData,
  Tile,
  SectionOutline
} from "../ReviewBuilder/review-types";
import { AuthContext } from "../Auth/AuthProvider";
import { WebSocketContext } from "../WebSocket/WebSocketProvider";
import { MessageAction } from "../WebSocket/WebSocketManager";
import { GlobalContext } from "@/GlobalProvider";
import LayoutContainer from "../Layout/LayoutContainer";
import { Layout } from "react-grid-layout";
import { dataSetToTabularData } from "./ArtifactCanvas/gridUtils";
import debounce from "lodash.debounce";
import { IntegrationEntityModel } from "../Integrations/types";

interface ArtifactContextProps {
  citationMap: Map<string, ArtifactCitationModel>;
  setCitationMap: React.Dispatch<
    React.SetStateAction<Map<string, ArtifactCitationModel>>
  >;
  artifactMetadata: ArtifactMetadata;
  setArtifactMetadata: React.Dispatch<React.SetStateAction<ArtifactMetadata>>;
  artifactContent?: string;
  setArtifactContent: React.Dispatch<React.SetStateAction<string | undefined>>;
  isArtifactSaving: boolean;
  isArtifactLoading: boolean;
  isArtifactGenerating: boolean;
  setIsArtifactGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  isDevMode: boolean;
  generateArtifactV3: () => void;
  currentTag: string;
  artifactTemplateId?: string;
  setArtifactTemplateId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  llmModelValue?: string;
  setLlmModelValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  chartDatasets: TabularData[];
  setChartDatasets: React.Dispatch<React.SetStateAction<TabularData[]>>;
  kpiDatasets: KPIData[];
  setKpiDatasets: React.Dispatch<React.SetStateAction<KPIData[]>>;
  artifactTiles: Tile[];
  setArtifactTiles: React.Dispatch<React.SetStateAction<Tile[]>>;
  artifactPublishes: ArtifactPublishModel[];
  setArtifactPublishes: React.Dispatch<
    React.SetStateAction<ArtifactPublishModel[]>
  >;
  artifactLayout: Layout[];
  setArtifactLayout: React.Dispatch<React.SetStateAction<Layout[]>>;
  saveArtifact: (artifact: Partial<ArtifactModel>, immediate: boolean) => void;
  updateTileContent: <
    T extends
      | Partial<KPIData>
      | Partial<TabularData>
      | Partial<GoalData>
      | Partial<TextData>
  >(
    tileId: string,
    newContent: T
  ) => void;
  integrationEntities: IntegrationEntityModel[];
  setIntegrationEntities: React.Dispatch<
    React.SetStateAction<IntegrationEntityModel[]>
  >;
  sectionOutlines: SectionOutline[];
  setSectionOutlines: React.Dispatch<React.SetStateAction<SectionOutline[]>>;
}

const ArtifactContext = createContext<ArtifactContextProps>({
  citationMap: new Map<string, ArtifactCitationModel>(),
  setCitationMap: () => {
    throw new Error("setCitationMap() not implemented");
  },
  artifactMetadata: {},
  setArtifactMetadata: () => {
    throw new Error("setArtifactMetadata() not implemented");
  },
  artifactContent: undefined,
  setArtifactContent: () => {
    throw new Error("setArtifactContent() not implemented");
  },
  isArtifactSaving: false,
  isArtifactLoading: false,
  isArtifactGenerating: false,
  setIsArtifactGenerating: () => {
    throw new Error("setIsArtifactGenerating() not implemented");
  },
  isDevMode: false,
  generateArtifactV3: () => {
    throw new Error("generateArtifactV3() not implemented");
  },
  currentTag: "",
  artifactTemplateId: "",
  setArtifactTemplateId: () => {
    throw new Error("setArtifactTemplateId() not implemented");
  },
  llmModelValue: undefined,
  setLlmModelValue: () => {
    throw new Error("setLlmModelValue() not implemented");
  },
  chartDatasets: [],
  setChartDatasets: () => {
    throw new Error("setChartDatasets() not implemented");
  },
  kpiDatasets: [],
  setKpiDatasets: () => {
    throw new Error("setKpiDatasets() not implemented");
  },
  artifactTiles: [],
  setArtifactTiles: () => {
    throw new Error("setArtifactTiles() not implemented");
  },
  artifactPublishes: [],
  setArtifactPublishes: () => {
    throw new Error("setArtifactPublishes() not implemented");
  },
  artifactLayout: [],
  setArtifactLayout: () => {
    throw new Error("setArtifactLayout() not implemented");
  },
  saveArtifact: async () => {
    throw new Error("saveArtifact() not implemented");
  },
  updateTileContent: () => {
    throw new Error("updateTileContent() not implemented");
  },
  integrationEntities: [],
  setIntegrationEntities: () => {
    throw new Error("setIntegrationEntities() not implemented");
  },
  sectionOutlines: [],
  setSectionOutlines: () => {
    throw new Error("setSectionOutlines() not implemented");
  }
});

const ArtifactProvider = ({ children }: { children: ReactNode }) => {
  const { artifactId } = useParams<{ artifactId: string }>();
  const { artifactTitle, setArtifactTitle } = useContext(GlobalContext);
  const { webSocketManager, isConnectionOpen } = useContext(WebSocketContext);
  const { userId } = useContext(AuthContext);
  const [citationMap, setCitationMap] = useState<
    Map<string, ArtifactCitationModel>
  >(new Map<string, ArtifactCitationModel>());
  const [artifactMetadata, setArtifactMetadata] = useState<ArtifactMetadata>({
    format: "documentv4"
  });
  const [artifactContent, setArtifactContent] = useState<string | undefined>(
    ""
  );
  const [artifactLayout, setArtifactLayout] = useState<Layout[]>([]);
  const [artifactPublishes, setArtifactPublishes] = useState<
    ArtifactPublishModel[]
  >([]);
  const [artifactTemplateId, setArtifactTemplateId] = useState<
    string | undefined
  >(undefined);
  const [isArtifactSaving, setIsArtifactSaving] = useState<boolean>(false);
  const [isArtifactLoading, setIsArtifactLoading] = useState<boolean>(false);
  const [isArtifactGenerating, setIsArtifactGenerating] =
    useState<boolean>(false);
  const [isArtifactGenerationPending, setIsArtifactGenerationPending] =
    useState<boolean>(false);
  const [isDevMode, setIsDevMode] = useState<boolean>(false);
  const [currentTag, _setCurrentTag] = useState<string>("");
  const [llmModelValue, setLlmModelValue] = useState<string | undefined>(
    "langgraph-v2"
  );
  const [chartDatasets, setChartDatasets] = useState<TabularData[]>([]);
  const [kpiDatasets, setKpiDatasets] = useState<KPIData[]>([]);
  const [artifactTiles, setArtifactTiles] = useState<Tile[]>([]);
  const [integrationEntities, setIntegrationEntities] = useState<
    IntegrationEntityModel[]
  >([]);
  const [sectionOutlines, setSectionOutlines] = useState<SectionOutline[]>([]);
  const chunkTimeoutRef = useRef<NodeJS.Timeout>();
  const fetchArtifactAttempts = useRef<number>(0);

  const { sendApiRequest } = useContext(AuthContext);

  const resetArtifactState = useCallback(() => {
    setCitationMap(new Map<string, ArtifactCitationModel>());
    setArtifactMetadata({ format: "documentv4" });
    setArtifactContent(undefined);
    setArtifactPublishes([]);
    setArtifactTemplateId(undefined);
    setIsArtifactSaving(false);
    setIsArtifactLoading(true);
    setIsArtifactGenerating(false);
    setIsArtifactGenerationPending(false);
    setChartDatasets([]);
    setKpiDatasets([]);
    setArtifactTiles([]);
  }, []);

  const fetchArtifact = useCallback(async () => {
    try {
      const response = (await sendApiRequest(
        `/artifacts/${artifactId}`,
        "GET"
      )) as {
        data: {
          artifact: ArtifactModel;
          artifactPublishes: ArtifactPublishModel[];
          layout?: Layout[];
        };
      };
      if (!response.data || !response.data.artifact) {
        throw new Error("Artifact not found");
      }

      const artifact = response.data.artifact;
      setArtifactTitle(artifact.artifactTitle || "");
      setArtifactMetadata({
        ...artifact.artifactMetadata,
        format: artifact.artifactMetadata?.format || "documentv4",
        startAt: artifact.artifactMetadata?.startAt
          ? new Date(artifact.artifactMetadata.startAt)
          : undefined,
        endAt: artifact.artifactMetadata?.endAt
          ? new Date(artifact.artifactMetadata.endAt)
          : undefined
      });
      setArtifactTemplateId(artifact.artifactTemplateId);
      setArtifactPublishes(response.data.artifactPublishes);
      if (artifact.artifactLayout) {
        setArtifactLayout(artifact.artifactLayout);
      }
      if (artifact.artifactContent) {
        setArtifactContent(artifact.artifactContent);
      }
      if (artifact.artifactTiles) {
        setArtifactTiles(JSON.parse(artifact.artifactTiles) as Tile[]);
      }
      if (artifact.chartDatasets) {
        setChartDatasets(JSON.parse(artifact.chartDatasets) as TabularData[]);
      }
      if (artifact.kpiDatasets) {
        setKpiDatasets(JSON.parse(artifact.kpiDatasets) as KPIData[]);
      }
    } catch (err) {
      console.error("Error fetching artifact:", err);
      throw new Error("Error fetching artifact");
    } finally {
      setIsArtifactLoading(false);
    }
  }, [artifactId, sendApiRequest, setArtifactTitle, setIsArtifactGenerating]);

  const attemptFetch = useCallback(() => {
    if (fetchArtifactAttempts.current < 20) {
      fetchArtifactAttempts.current += 1;
      console.log("attempting fetch", fetchArtifactAttempts.current);
      fetchArtifact();
      if (chunkTimeoutRef.current) {
        clearTimeout(chunkTimeoutRef.current);
      }
      chunkTimeoutRef.current = setTimeout(attemptFetch, 5000);
    }
  }, [fetchArtifact]);

  useEffect(() => {
    if (!isArtifactGenerating && chunkTimeoutRef.current) {
      clearTimeout(chunkTimeoutRef.current);
      fetchArtifactAttempts.current = 0; // Reset the counter
    }
  }, [isArtifactGenerating]);

  const processArtifactChunk = useCallback(
    (data: object) => {
      setIsArtifactGenerationPending(false);
      const { chunk } = data as { chunk: string };
      setArtifactContent(chunk);

      // Reset the fetch attempt counter since we got a chunk
      fetchArtifactAttempts.current = 0;

      if (chunkTimeoutRef.current) {
        clearTimeout(chunkTimeoutRef.current);
      }
      // Initial fetch attempt after 20 seconds
      if (isArtifactGenerating) {
        chunkTimeoutRef.current = setTimeout(attemptFetch, 20000);
      }
    },
    [attemptFetch, isArtifactGenerating]
  );

  useEffect(() => {
    return () => {
      if (chunkTimeoutRef.current) {
        clearTimeout(chunkTimeoutRef.current);
      }
    };
  }, []);

  const rawSaveArtifact = useCallback(
    async (artifact?: Partial<ArtifactModel>) => {
      if (isArtifactGenerating || isArtifactLoading || isArtifactSaving) return;
      console.log("saving artifact", artifact);
      setIsArtifactSaving(true);
      await sendApiRequest(
        `/artifacts/${artifactId}`,
        "PATCH",
        {
          artifactTitle: artifact?.artifactTitle || artifactTitle,
          artifactMetadata: artifact?.artifactMetadata || artifactMetadata,
          artifactContent: artifact?.artifactContent || artifactContent,
          artifactTemplateId:
            artifact?.artifactTemplateId || artifactTemplateId,
          artifactLayout: artifact?.artifactLayout || artifactLayout,
          artifactTiles:
            artifact?.artifactTiles || JSON.stringify(artifactTiles),
          kpiDatasets: artifact?.kpiDatasets || JSON.stringify(kpiDatasets),
          chartDatasets:
            artifact?.chartDatasets || JSON.stringify(chartDatasets)
        },
        "Error saving artifact"
      );
      setIsArtifactSaving(false);
    },
    [
      isArtifactGenerating,
      isArtifactLoading,
      isArtifactSaving,
      sendApiRequest,
      artifactId,
      artifactTitle,
      artifactMetadata,
      artifactContent,
      artifactTemplateId,
      artifactLayout,
      artifactTiles,
      kpiDatasets,
      chartDatasets
    ]
  );

  const debouncedSaveRef = useRef<ReturnType<typeof debounce>>();

  useEffect(() => {
    return () => {
      if (debouncedSaveRef.current) {
        debouncedSaveRef.current.cancel();
      }
    };
  }, []);

  const saveArtifact = useCallback(
    (artifact: Partial<ArtifactModel>, immediate: boolean = true) => {
      if (immediate) {
        rawSaveArtifact(artifact);
        if (debouncedSaveRef.current) {
          debouncedSaveRef.current.cancel();
        }
      } else {
        if (!debouncedSaveRef.current) {
          debouncedSaveRef.current = debounce(
            (artifact?: Partial<ArtifactModel>) => {
              rawSaveArtifact(artifact);
            },
            2000
          );
        }
        debouncedSaveRef.current(artifact);
      }
    },
    [rawSaveArtifact]
  );

  useEffect(() => {
    if (webSocketManager) {
      webSocketManager.addListener(
        MessageAction.generateArtifactChunk,
        processArtifactChunk
      );
      webSocketManager.addListener(
        MessageAction.generateDatasetCandidatesComplete,
        (data) => {
          console.log("generateDatasetCandidatesComplete", data);
          const { datasetCandidates } = data as {
            datasetCandidates: DataSet[];
          };
          const transformedDatasets = datasetCandidates.map((dataset) =>
            dataSetToTabularData(dataset)
          );
          setChartDatasets((prevDataSets) => [
            ...(prevDataSets || []),
            ...transformedDatasets
          ]);
        }
      );
    }
    return () => {
      if (webSocketManager) {
        webSocketManager.removeListener(MessageAction.generateArtifactChunk);
        webSocketManager.removeListener(MessageAction.generateArtifactComplete);
        webSocketManager.removeListener(
          MessageAction.generateDatasetCandidatesComplete
        );
      }
    };
  }, [processArtifactChunk, saveArtifact, webSocketManager]);

  useEffect(() => {
    resetArtifactState();
    fetchArtifact();
  }, [artifactId, fetchArtifact, resetArtifactState, sendApiRequest]);

  const streamArtifactV3Generation = useCallback(
    async (userId: string) => {
      if (isConnectionOpen) {
        setIsArtifactGenerationPending(true);
        webSocketManager?.sendMessage({
          action: "generateArtifact",
          data: {
            userId,
            artifactId,
            artifactTemplateId,
            llmModel: llmModelValue
          }
        });
      }
    },
    [isConnectionOpen, webSocketManager, artifactId, artifactTemplateId, llmModelValue]
  );

  const updateTileContent = useCallback(
    <T extends Partial<KPIData | TabularData | GoalData | TextData>>(
      tileId: string,
      newContent: T
    ) => {
      if (!artifactTiles) return;

      const updatedTiles = artifactTiles.map((tile: Tile) =>
        tile.id === tileId
          ? {
              ...tile,
              content:
                typeof newContent === "string"
                  ? newContent
                  : { ...tile.content, ...newContent }
            }
          : tile
      ) as Tile[];

      setArtifactTiles(updatedTiles);
      saveArtifact({ artifactTiles: JSON.stringify(updatedTiles) }, false);
    },
    [artifactTiles, saveArtifact]
  );

  const resetStreamingState = useCallback(() => {
    setSectionOutlines([]);
    setArtifactContent("");
    setArtifactTiles([]);
    setArtifactLayout([]);
    setKpiDatasets([]);
    setChartDatasets([]);
  }, []);

  const generateArtifactV3 = useCallback(async () => {
    resetStreamingState();
    setIsArtifactGenerating(true);
    await streamArtifactV3Generation(userId);
  }, [resetStreamingState, streamArtifactV3Generation, userId]);

  // useEffect(() => {
  //   if (!isArtifactGenerating && !isArtifactSaving && artifactContent) {
  //     debouncedSaveArtifact();
  //   }
  // }, [
  //   artifactContent,
  //   debouncedSaveArtifact,
  //   isArtifactGenerating,
  //   isArtifactSaving
  // ]);

  const contextValue = useMemo(
    () => ({
      citationMap,
      setCitationMap,
      artifactMetadata,
      setArtifactMetadata,
      artifactContent,
      setArtifactContent,
      isArtifactSaving,
      isArtifactLoading,
      isArtifactGenerating,
      isArtifactGenerationPending,
      setIsArtifactGenerating,
      isDevMode,
      generateArtifactV3,
      currentTag,
      setArtifactTemplateId,
      llmModelValue,
      setLlmModelValue,
      chartDatasets,
      setChartDatasets,
      kpiDatasets,
      setKpiDatasets,
      artifactTiles,
      setArtifactTiles,
      artifactPublishes,
      setArtifactPublishes,
      artifactTemplateId,
      artifactLayout,
      setArtifactLayout,
      saveArtifact,
      updateTileContent,
      integrationEntities,
      setIntegrationEntities,
      sectionOutlines,
      setSectionOutlines
    }),
    [
      citationMap,
      setCitationMap,
      artifactMetadata,
      setArtifactMetadata,
      artifactContent,
      setArtifactContent,
      isArtifactSaving,
      isArtifactLoading,
      isArtifactGenerating,
      isArtifactGenerationPending,
      isDevMode,
      generateArtifactV3,
      currentTag,
      setArtifactTemplateId,
      llmModelValue,
      setLlmModelValue,
      chartDatasets,
      setChartDatasets,
      kpiDatasets,
      setKpiDatasets,
      artifactTiles,
      setArtifactTiles,
      artifactPublishes,
      setArtifactPublishes,
      artifactTemplateId,
      artifactLayout,
      setArtifactLayout,
      saveArtifact,
      updateTileContent,
      integrationEntities,
      setIntegrationEntities,
      sectionOutlines,
      setSectionOutlines
    ]
  );

  return (
    <ArtifactContext.Provider value={contextValue}>
      {isArtifactSaving && (
        <div className="fixed bottom-2 right-2 flex justify-end items-center gap-2 z-10">
          <div className="text-[0.75rem]">Saving...</div>
          <BrevLoader type="ring" size={20} />
        </div>
      )}
      {!isArtifactLoading && (
        <ArtifactCopilotProvider>{children}</ArtifactCopilotProvider>
      )}
      {isArtifactLoading && (
        <LayoutContainer>
          <div className="flex justify-center items-center h-screen w-full">
            <BrevLoader type="ring" size={40} />
          </div>
        </LayoutContainer>
      )}
      {isBeta() && (
        <div className="fixed top-6 right-[30rem] flex justify-end items-center gap-2">
          <span className="text-[0.75rem] font-mono text-brev-light">
            Dev Mode
          </span>
          <Switch
            aria-label="Toggle Dev Mode"
            checked={isDevMode}
            onCheckedChange={() => {
              setIsDevMode(!isDevMode);
            }}
          />
        </div>
      )}
    </ArtifactContext.Provider>
  );
};

export { ArtifactProvider, ArtifactContext };
