import { useState, useEffect } from "react";
import {
  VisualType,
  TabularData
} from "@/containers/ReviewBuilder/review-types";
import GenericDataWidget from "@/containers/ReviewBuilder/DataVisualWidgets/GenericDataWidget";
import { cn } from "@/lib/utils";
import EditableText from "../EditableText";

interface ChartProps {
  data: TabularData;
  className?: string;
  updateTileContent: (chart: Partial<TabularData>) => void;
  editable?: boolean;
}

export default function SlideChart({
  data,
  className,
  updateTileContent,
  editable = true
}: ChartProps) {
  const [isDataComplete, setIsDataComplete] = useState(false);
  const [visualType, setVisualType] = useState<VisualType | undefined>(
    data.visual_type
  );

  // const hasNumericValues = (data: TabularData) => {
  //   return data.rows?.some((row) =>
  //     row.slice(1).some((val) => {
  //       const numeric = typeof val === "number" ? val : parseFloat(val);
  //       return !isNaN(numeric);
  //     })
  //   );
  // };

  const handleTitleChange = (value: string) => {
    updateTileContent({ title: value });
  };

  const handleDescriptionChange = (value: string) => {
    updateTileContent({ data_description: value });
  };

  useEffect(() => {
    const isComplete =
      data &&
      data.rows &&
      data.rows.length > 0 &&
      data.col_headers &&
      data.col_headers.length > 0;
    setIsDataComplete(isComplete);
  }, [data]);

  useEffect(() => {
    const type = data.visual_type || VisualType.TABLE;

    setVisualType(type);

    if (!data.visual_type) {
      updateTileContent({ visual_type: type });
    }
  }, [data, updateTileContent]);

  return (
    <div className={cn("w-full rounded-lg overflow-hidden", className)}>
      <div className="flex flex-col">
        {data.title && (
          <EditableText
            value={data.title}
            onSave={handleTitleChange}
            className="text-2xl font-bold mb-2 text-wrap max-w-[640px]"
            editable={editable}
          />
        )}
        {data.data_description && (
          <EditableText
            value={data.data_description}
            onSave={handleDescriptionChange}
            isTextarea
            className="text-sm"
            editable={editable}
          />
        )}
      </div>
      {isDataComplete && visualType ? (
        <GenericDataWidget
          visualWidget={{
            visualType: visualType,
            dataset: data
          }}
          isEditable={editable}
          isPreview={false}
        />
      ) : (
        <div>Loading chart data...</div>
      )}
    </div>
  );
}
