export default function YellowGreenGraphic() {
  return (
    <svg
      width="115"
      height="87"
      viewBox="0 0 115 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3516 16.7559H71.7858"
        stroke="url(#paint0_linear_9047_20)"
        strokeOpacity="0.8"
        strokeWidth="5.24026"
      />
      <rect
        x="2.86649"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect
        x="66.7656"
        y="38"
        width="3.27516"
        height="15.5833"
        fill="url(#paint1_linear_9047_20)"
        fillOpacity="0.35"
      />
      <rect
        x="71.3809"
        y="46"
        width="3.27516"
        height="7.72294"
        fill="url(#paint2_linear_9047_20)"
        fillOpacity="0.35"
      />
      <rect
        x="76"
        y="42"
        width="3.27516"
        height="11.8403"
        fill="url(#paint3_linear_9047_20)"
        fillOpacity="0.8"
      />
      <rect
        x="82.2363"
        y="41"
        width="3.27516"
        height="13.0568"
        fill="url(#paint4_linear_9047_20)"
        fillOpacity="0.35"
      />
      <rect
        x="89"
        y="41"
        width="3.27516"
        height="13.0568"
        fill="url(#paint5_linear_9047_20)"
        fillOpacity="0.8"
      />
      <rect
        x="94.0898"
        y="39"
        width="3.27516"
        height="14.8347"
        fill="url(#paint6_linear_9047_20)"
        fillOpacity="0.8"
      />
      <rect
        x="99"
        y="42"
        width="3.27516"
        height="12.3082"
        fill="url(#paint7_linear_9047_20)"
        fillOpacity="0.35"
      />
      <circle
        cx="34.1784"
        cy="41.9225"
        r="9.63833"
        stroke="url(#paint8_linear_9047_20)"
        strokeOpacity="0.8"
        strokeWidth="5.24026"
      />
      <path
        d="M43.8876 41.9227C43.8876 43.8539 43.2903 45.7396 42.1747 47.3309C41.059 48.9221 39.4774 50.144 37.6388 50.8352C35.8001 51.5264 33.791 51.6543 31.8767 51.202C29.9624 50.7498 28.233 49.7387 26.917 48.3022C25.6009 46.8658 24.7601 45.0716 24.5055 43.1566C24.2509 41.2416 24.5947 39.296 25.4912 37.5766C26.3878 35.8572 27.7951 34.4451 29.5272 33.5266C31.2592 32.6081 33.2347 32.2265 35.1925 32.4323"
        stroke="url(#paint9_linear_9047_20)"
        strokeOpacity="0.8"
        strokeWidth="5.24026"
      />
      <path
        d="M12 67H101.084"
        stroke="#ADDFD4"
        strokeOpacity="0.3"
        strokeWidth="0.748608"
      />
      <path
        d="M12 62H101.084"
        stroke="#ADDFD4"
        strokeOpacity="0.55"
        strokeWidth="0.748608"
      />
      <path
        d="M12 71.4922H101.084"
        stroke="#50BAA2"
        strokeOpacity="0.2"
        strokeWidth="0.748608"
      />
      <rect
        y="69"
        width="115"
        height="84"
        fill="url(#paint10_linear_9047_20)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9047_20"
          x1="12.3516"
          y1="17.2559"
          x2="71.7858"
          y2="17.2559"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9047_20"
          x1="68.4032"
          y1="38"
          x2="68.4032"
          y2="53.5833"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFFA7D" />
          <stop offset="1" stopColor="#84DD64" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9047_20"
          x1="73.0184"
          y1="46"
          x2="73.0184"
          y2="53.7229"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFFA7D" />
          <stop offset="1" stopColor="#84DD64" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9047_20"
          x1="76"
          y1="47.9201"
          x2="79.2752"
          y2="47.9201"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9047_20"
          x1="83.8739"
          y1="41"
          x2="83.8739"
          y2="54.0568"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFFA7D" />
          <stop offset="1" stopColor="#84DD64" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9047_20"
          x1="89"
          y1="47.5284"
          x2="92.2752"
          y2="47.5284"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9047_20"
          x1="94.0898"
          y1="46.4174"
          x2="97.365"
          y2="46.4174"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_9047_20"
          x1="100.638"
          y1="42"
          x2="100.638"
          y2="54.3082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CFFA7D" />
          <stop offset="1" stopColor="#84DD64" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_9047_20"
          x1="21.9199"
          y1="41.9225"
          x2="46.4368"
          y2="41.9225"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_9047_20"
          x1="24.4238"
          y1="41.9227"
          x2="43.8876"
          y2="41.9227"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.28" stopColor="#FCB626" stopOpacity="0.5" />
          <stop offset="0.59" stopColor="#FECA46" stopOpacity="0.75" />
          <stop offset="0.9" stopColor="#FFDF65" />
        </linearGradient>
      </defs>
    </svg>
  );
}
