import {
  useCallback,
  useMemo,
  useState,
  useContext,
  useEffect,
  useRef
} from "react";
import { Responsive, WidthProvider, Layout } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  UpdateTileContent,
  TileType,
  Tile,
  Slide
} from "@/containers/ReviewBuilder/review-types";
import "./ArtifactGridStyles.css";
import { ArtifactContext } from "../ArtifactProvider";
import {
  GRID_COLS,
  ROW_HEIGHT,
  SLIDE_HEIGHT,
  SLIDE_DIVIDER_ID,
  FINAL_DIVIDER_ID,
  parseSlideId,
  generateLayout,
  isTileComplete
} from "./gridUtils";
import SlideDivider from "./ArtifactSlides/SlideDivider";
import GridCard from "./GridTiles/GridCard";
import SlideViewer from "./ArtifactSlides/SlideViewer";
import { GridContext } from "./GridProvider";
import { ArtifactSettingsProvider } from "../ArtifactSettings/ArtifactSettingsProvider";
import { useParams } from "react-router-dom";
import ArtifactBadges from "../ArtifactBadges";
// import { JsonContent } from "@/containers/ReviewBuilder/review-types";
import { Button } from "@/components/ui/button";
import { LuX } from "react-icons/lu";
import TileToolbar from "./TileToolbar";
import TileControlPanel from "./GridTiles/TileControlPanel";

const ResponsiveGridLayout = WidthProvider(Responsive);

type ArtifactGridProps = {
  updateTileContent?: UpdateTileContent;
  onAddNewTile: (type: TileType, layout: Layout) => void;
};

const PLACEHOLDER_LAYOUT = [
  {
    i: "placeholder",
    x: 0,
    y: 0,
    w: GRID_COLS,
    h: 2,
    static: true
  }
];

export default function ArtifactGrid({ onAddNewTile }: ArtifactGridProps) {
  const {
    isEditMode,
    isPresentation,
    setIsPresentation,
    isSlideView,
    droppingItem,
    activeTile,
    setActiveTile,
    setHoveredTile
  } = useContext(GridContext);
  useContext(GridContext);
  const [isDragging, setIsDragging] = useState(false);
  const isInitialRender = useRef(true);

  const {
    artifactLayout,
    setArtifactLayout,
    saveArtifact,
    isArtifactGenerating,
    setArtifactTiles,
    artifactTiles,
    isArtifactSaving,
    isArtifactLoading
  } = useContext(ArtifactContext);

  const handleActiveTileChange = (tile: Tile | null) => {
    setActiveTile(tile);
  };

  const handleTileHover = (tileId: string | null) => {
    setHoveredTile(tileId);
  };

  const currentLayout = useMemo(() => {
    if (artifactTiles.length === 0) return PLACEHOLDER_LAYOUT;
    return artifactLayout.length > 0 && !isArtifactGenerating
      ? artifactLayout.filter(
          (item) =>
            typeof item.i === "string" &&
            (item.i.startsWith(SLIDE_DIVIDER_ID) ||
              artifactTiles.find(
                (tile) => tile.id === item.i && isTileComplete(tile)
              ))
        )
      : generateLayout(artifactTiles);
  }, [artifactLayout, artifactTiles, isArtifactGenerating]);

  useEffect(() => {
    if (isSlideView && !isPresentation) {
      setIsPresentation(true);
    }
  }, [isSlideView, isPresentation, setIsPresentation]);

  const slideDividers = useMemo(() => {
    if (!isPresentation) return [];

    const maxY = Math.max(...currentLayout.map((item) => item.y + item.h));
    const slideCount = Math.ceil(maxY / SLIDE_HEIGHT);

    return Array.from({ length: slideCount - 1 }, (_, index) => ({
      i: `${SLIDE_DIVIDER_ID}${index}`,
      x: 0,
      y: (index + 1) * SLIDE_HEIGHT - 1,
      w: GRID_COLS,
      h: 2,
      static: true
    }));
  }, [currentLayout, isPresentation]);

  const combinedLayout = useMemo(() => {
    if (isPresentation) {
      const lastSlideIndicator = {
        i: `final-divider-${slideDividers.length}`,
        x: 0,
        y: (slideDividers.length + 1) * SLIDE_HEIGHT - 1,
        w: GRID_COLS,
        h: 1,
        static: true
      };
      return [...slideDividers, ...currentLayout, lastSlideIndicator];
    } else {
      return currentLayout;
    }
  }, [slideDividers, currentLayout, isPresentation]);

  const slides = useMemo(() => {
    if (!isPresentation) return [];

    const itemsWithContent = currentLayout.filter((item) => {
      const tile = artifactTiles.find((t) => t.id === item.i);
      return tile && isTileComplete(tile);
    });

    itemsWithContent.sort((a, b) => a.y - b.y);

    const slides: Slide[] = [];
    let currentSlide: Layout[] = [];
    let currentSlideIndex = 0;

    itemsWithContent.forEach((item) => {
      const slideIndex = Math.floor(item.y / SLIDE_HEIGHT);

      if (slideIndex > currentSlideIndex) {
        if (currentSlide.length > 0) {
          slides.push({
            id: `slide-${currentSlideIndex}`,
            items: currentSlide
          });
        }
        currentSlide = [];
        currentSlideIndex = slideIndex;
      }

      currentSlide.push({
        ...item,
        y: item.y % SLIDE_HEIGHT
      });
    });

    if (currentSlide.length > 0) {
      slides.push({ id: `slide-${currentSlideIndex}`, items: currentSlide });
    }

    return slides;
  }, [currentLayout, isPresentation, artifactTiles]);

  const onLayoutChange = useCallback(
    (newLayout: Layout[]) => {
      const updatedLayout = newLayout.filter(
        (item) => item.i !== "placeholder"
      );
      const filteredLayout = updatedLayout.filter(
        (item) => !item.i.startsWith(SLIDE_DIVIDER_ID)
      );
      setArtifactLayout(filteredLayout);
      if (
        artifactTiles.length > 0 &&
        !isArtifactGenerating &&
        !isArtifactSaving &&
        !isArtifactLoading &&
        !isInitialRender.current
      ) {
        // only save if there are tiles to save; otherwise, this may overwrite the artifact from the backend
        console.log("saving artifact layout on layout change");
        saveArtifact({ artifactLayout: filteredLayout }, true);
      }
      isInitialRender.current = false;
    },
    [
      setArtifactLayout,
      artifactTiles.length,
      isArtifactGenerating,
      isArtifactSaving,
      isArtifactLoading,
      saveArtifact
    ]
  );

  const removeTile = useCallback(
    (tileId: string) => {
      const updatedTiles = artifactTiles.filter((tile) => tile.id !== tileId);
      setArtifactTiles(updatedTiles);
      const updatedLayout = artifactLayout.filter((item) => item.i !== tileId);
      setArtifactLayout(updatedLayout);

      saveArtifact(
        {
          artifactTiles: JSON.stringify(updatedTiles),
          artifactLayout: updatedLayout
        },
        true
      );
    },
    [
      artifactTiles,
      setArtifactTiles,
      artifactLayout,
      setArtifactLayout,
      saveArtifact
    ]
  );

  const onDragStop = useCallback(
    (newLayout: Layout[]) => {
      onLayoutChange(newLayout);
      setIsDragging(false);
    },
    [onLayoutChange]
  );

  const onDrop = (_layout: Layout[], layoutItem: Layout, event: DragEvent) => {
    const type = event.dataTransfer?.getData("text/plain") as TileType;
    if (type) {
      onAddNewTile(type, layoutItem);
    }
  };

  return (
    <div className="flex gap-6 mx-auto max-w-[1080px] 2xl:max-w-[1200px] -translate-x-[34px]">
      <div className="w-12 flex-shrink-0 relative overflow-visible z-[100]">
        <div className="sticky top-[5rem]">
          <FormattingContainer />
        </div>
      </div>
      {!isArtifactGenerating && !isArtifactLoading && (
        <div
          className={`w-full rounded-lg relative ${isPresentation ? "bg-light-alt dark:bg-dark-alt" : ""} p-0`}
        >
          {isSlideView ? (
            <SlideViewer slides={slides} />
          ) : (
            <ResponsiveGridLayout
              className=""
              layouts={{ lg: combinedLayout }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{
                lg: GRID_COLS,
                md: GRID_COLS,
                sm: GRID_COLS,
                xs: GRID_COLS,
                xxs: GRID_COLS
              }}
              rowHeight={ROW_HEIGHT}
              width={1200}
              isDraggable={true}
              isResizable={true}
              compactType={isPresentation ? null : "vertical"}
              preventCollision={isPresentation ? true : false}
              margin={[10, 10]}
              onDragStart={() => {
                setIsDragging(true);
              }}
              onDragStop={onDragStop}
              onLayoutChange={onLayoutChange}
              onResizeStop={onLayoutChange}
              resizeHandles={["s", "w", "e", "n", "sw", "nw", "se", "ne"]}
              draggableHandle=".drag-handle"
              onDrop={onDrop}
              isDroppable={true}
              droppingItem={droppingItem}
            >
              {combinedLayout.map((item) => {
                if (item.i === "placeholder") {
                  return (
                    <div
                      key={item.i}
                      className="h-full w-full min-h-[40rem] flex justify-center items-center"
                    >
                      {/* <div>Drag and drop tiles below</div> */}
                    </div>
                  );
                } else if (
                  isPresentation &&
                  (item.i.startsWith(SLIDE_DIVIDER_ID) ||
                    item.i.startsWith(FINAL_DIVIDER_ID))
                ) {
                  const { slideNumber, isLastSlide } = parseSlideId(item.i);

                  return (
                    <div key={item.i} className="relative z-10 my-2">
                      {!isLastSlide && (
                        <div>
                          <SlideDivider />
                        </div>
                      )}
                      <div
                        className={`fixed ${isLastSlide ? "bottom-2" : "bottom-10"} tracking-wider right-0 bg-light-main dark:bg-dark-main dark:bg-opacity-50 text-dark-text dark:text-light-text px-3 py-1 rounded-full text-sm pointer-events-none z-10`}
                      >
                        {slideNumber} / {slideDividers.length + 1}
                      </div>
                    </div>
                  );
                }
                const tile = artifactTiles.find((t) => t.id === item.i);
                const isAtTop = item.y === 0;
                if (!tile || !isTileComplete(tile)) return null;
                return (
                  <div
                    key={tile.id}
                    className={`relative ${isDragging ? "" : "select-none"} ${activeTile?.id === tile.id ? "z-50" : ""}`}
                    onMouseEnter={() => handleTileHover(tile.id)}
                    onMouseLeave={() => handleTileHover(null)}
                    onClick={() => handleActiveTileChange(tile)}
                    // onBlur={() => handleActiveTileChange(null)}
                    // onDragStart={() => handleDragStart(tile.id)}
                  >
                    {activeTile?.id === tile.id && (
                      <div
                        className={`absolute ${!isAtTop ? "-top-[3.5rem]" : "-bottom-[3.5rem]"} left-1/2 -translate-x-1/2 z-[1000]`}
                      >
                        <TileControlPanel />
                      </div>
                    )}
                    {activeTile?.id === tile.id && (
                      <Button
                        variant="ghost"
                        onClick={() => removeTile(tile.id)}
                        className="absolute -top-1.5 -left-1.5 text-md border bg-light-main dark:bg-dark-main rounded-full p-0.5 z-20 shadow"
                      >
                        <LuX
                          size={14}
                          className="text-brev dark:text-light-text"
                        />
                      </Button>
                    )}
                    <GridCard
                      tile={tile}
                      isEditMode={isEditMode}
                      isDragging={isDragging}
                      isActive={activeTile?.id === tile.id}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          )}
        </div>
      )}
    </div>
  );
}

const FormattingContainer = () => {
  const { artifactId } = useParams<{ artifactId: string }>();
  return (
    <div className="flex flex-col items-center justify-center rounded-full bg-light-alt dark:bg-dark-alt border shadow gap-1 py-2 px-1.5">
      <TileToolbar />
      <ArtifactSettingsProvider artifactId={artifactId!}>
        <ArtifactBadges />
      </ArtifactSettingsProvider>
    </div>
  );
};
