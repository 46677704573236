export default function RedGraphic() {
  return (
    <svg
      width="121"
      height="87"
      viewBox="0 0 121 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="8.83915"
        y="0.374304"
        width="104.057"
        height="128.012"
        rx="2.62013"
        stroke="#9DA2C1"
        strokeWidth="0.748608"
        strokeOpacity="0.35"
      />
      <rect
        x="65"
        y="14"
        width="40"
        height="34"
        rx="3"
        fill="#D5725B"
        fillOpacity="0.43"
      />
      <rect x="65" y="51" width="40" height="40" rx="3" fill="#F69E8B" />
      <path
        d="M17.9609 16.9844H45.4345"
        stroke="#FF6C4F"
        strokeWidth="5.24026"
      />
      <path
        d="M17.2188 56.1123H58.0176"
        stroke="url(#paint0_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 60.6045H58.0176"
        stroke="url(#paint1_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 47.1289H58.0176"
        stroke="url(#paint2_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 51.6211H58.0176"
        stroke="url(#paint3_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 38.1455H58.0176"
        stroke="url(#paint4_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 42.6377H58.0176"
        stroke="url(#paint5_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 29.1621H58.0176"
        stroke="url(#paint4_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17 34H57.7988"
        stroke="url(#paint4_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <path
        d="M17.2188 66.2188H58.0179"
        stroke="url(#paint4_linear_9047_15)"
        strokeOpacity="0.65"
        strokeWidth="0.748608"
      />
      <rect y="30" width="121" height="94" fill="url(#paint9_linear_9047_15)" />
      <defs>
        <linearGradient
          id="paint0_linear_9047_15"
          x1="17.2188"
          y1="56.6123"
          x2="58.0176"
          y2="56.6123"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9047_15"
          x1="17.2188"
          y1="61.1045"
          x2="58.0176"
          y2="61.1045"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_9047_15"
          x1="17.2188"
          y1="47.6289"
          x2="58.0176"
          y2="47.6289"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_9047_15"
          x1="17.2188"
          y1="52.1211"
          x2="58.0176"
          y2="52.1211"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_9047_15"
          x1="17.2188"
          y1="38.6455"
          x2="58.0176"
          y2="38.6455"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_9047_15"
          x1="17.2188"
          y1="43.1377"
          x2="58.0176"
          y2="43.1377"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_9047_15"
          x1="17.2188"
          y1="29.6621"
          x2="58.0176"
          y2="29.6621"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_9047_15"
          x1="17"
          y1="34.5"
          x2="57.7988"
          y2="34.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_9047_15"
          x1="17.2188"
          y1="66.7188"
          x2="58.0179"
          y2="66.7188"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#FC6D26" />
          <stop offset="0.455" stopColor="#E24329" />
        </linearGradient>
      </defs>
    </svg>
  );
}
