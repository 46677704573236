import { Tile } from "@/containers/ReviewBuilder/review-types";
import { Card, CardContent } from "@/components/ui/card";
import GridTile from "./GridTile";
// import TileMenu from "./TileMenu";
import ResizeHandle from "./ResizeHandle";
import TileSources from "./TileSources";
import { TileType } from "@/containers/ReviewBuilder/review-types";
import { useContext } from "react";
import { GridContext } from "../GridProvider";

export default function GridCard({
  tile,
  isEditMode,
  isActive,
  readonly = false
}: {
  tile: Tile;
  isEditMode?: boolean;
  isDragging?: boolean;
  isActive?: boolean;
  readonly?: boolean;
}) {
  const { hoveredTile } = useContext(GridContext);

  if (!tile || !tile.id) {
    return null;
  }

  const isHovered = hoveredTile === tile.id;
  return (
    <Card
      className={`group relative h-full ${tile.tile_type !== TileType.HEADING ? "bg-light-main dark:bg-dark-main border pt-6" : "pt-2"} ${isActive ? "border-brev-active" : ""} overflow-hidden`}
    >
      <div className="absolute top-2 right-4 z-50 opacity-[0%] group-hover:opacity-100">
        <TileSources sources={tile.source_integration_entities} />
      </div>
      {!readonly && <DragHandle />}
      <CardContent
        className={`h-full flex flex-col ${tile.tile_type === TileType.HEADING ? "pt-6" : ""}`}
      >
        <GridTile tile={tile} isEditMode={isEditMode} published={readonly} />
      </CardContent>
      {tile.tile_type !== TileType.HEADING && (
        <>
          <ResizeHandle corner="bottom-right" visible={isHovered} />
          <ResizeHandle corner="bottom-left" visible={isHovered} />
          <ResizeHandle corner="top-right" visible={isHovered} />
          <ResizeHandle corner="top-left" visible={isHovered} />
        </>
      )}
    </Card>
  );
}

const DragHandle = () => (
  <div
    className={`z-[99] absolute top-0 left-1/2 -translate-x-1/2 flex items-center justify-center drag-handle cursor-grab active:cursor-grabbing opacity-[0%] group-hover:opacity-100 transition-opacity rounded-b-lg h-6 w-[5rem]`}
  >
    <div className="min-h-1 min-w-12 bg-[#E0E2E9] dark:bg-light-alt rounded-full"></div>
  </div>
);
