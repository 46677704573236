import Tile from "@/components/Tile/Tile";
import { StepNames } from "@/containers/Integrations/ConnectionSteps";
import { IntegrationConnectionsContext } from "@/containers/Integrations/IntegrationConnectionsProvider";
import { IntegrationApp } from "@/containers/Integrations/types";
import { useCallback, useContext, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import GithubSvg from "@/components/Icons/GithubSvg";
import { authenticateGitHub } from "@/lib/utils";

type GitHubConnectorProps = {
  onSuccess?: (stepName: StepNames) => void;
  activeIntegration?: string;
};

export default function GitHubConnector({
  onSuccess,
  activeIntegration
}: GitHubConnectorProps) {
  const { artifactId } = useParams<{ artifactId: string }>();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { isAppConnected, isAppActive, isLoading } = useContext(
    IntegrationConnectionsContext
  );

  useEffect(() => {
    if (isAppActive(IntegrationApp.GITHUB)) {
      onSuccess?.("github-picker");
      setSearchParams({});
    }
  }, [isAppActive, onSuccess]);

  const authenticateClient = useCallback(async () => {
    if (isAppConnected(IntegrationApp.GITHUB)) {
      return onSuccess?.("github-picker");
    }
    authenticateGitHub(artifactId);
  }, [artifactId, isAppConnected, onSuccess]);
  return (
    <Tile
      onPress={authenticateClient}
      label="GitHub"
      svg={<GithubSvg size={30} />}
      connected={isAppConnected(IntegrationApp.GITHUB)}
      selected={activeIntegration === "github-picker"}
      isLoading={isLoading}
    />
  );
}
