import Tile from "../../Tile/Tile";
import Tableau from "@/images/tableau.svg";

export default function TableauConnector() {
  // useEffect(() => {
  //   API.get("reviews", "/auth", {
  //     appName: "SNOWFLAKE"
  //   }).then(() => {});
  // }, [setIsRedirected]);

  const authenticateClient = () => {};

  return (
    <Tile
      disabled={true}
      onPress={authenticateClient}
      label="Tableau"
      src={Tableau}
    />
  );
}
