import { cn } from "@/lib/utils";

export default function SettingsSvg({
  className,
  size,
  isActive = false
}: {
  className?: string;
  size?: number;
  isActive?: boolean;
}) {
  return (
    <svg
      width={size || 15}
      height={size || 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("", className)}
    >
      <defs>
        <linearGradient
          id="formatActiveGradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#3FCFF1" />
          <stop offset="50%" stopColor="#3FCFF1" />
          <stop offset="100%" stopColor="#00FFB2" />
        </linearGradient>
      </defs>
      <path
        d="M2.85714 0.892578H12.1429C13.1717 0.892578 14 1.72336 14 2.75532V12.069C14 13.101 13.1717 13.9318 12.1429 13.9318H2.85714C1.82829 13.9318 1 13.101 1 12.069V2.75532C1 1.72336 1.82829 0.892578 2.85714 0.892578Z"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.78613 3.6875H4.7147"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64272 4.6186C5.39644 4.6186 5.16026 4.52048 4.98612 4.34581C4.81198 4.17114 4.71415 3.93425 4.71415 3.68723C4.71415 3.44022 4.81198 3.20332 4.98612 3.02865C5.16026 2.85399 5.39644 2.75586 5.64272 2.75586C5.76466 2.75586 5.88541 2.77995 5.99807 2.82676C6.11073 2.87356 6.21309 2.94217 6.29932 3.02865C6.38554 3.11514 6.45394 3.21781 6.50061 3.33081C6.54727 3.44381 6.57129 3.56492 6.57129 3.68723C6.57129 3.80954 6.54727 3.93065 6.50061 4.04365C6.45394 4.15665 6.38554 4.25932 6.29932 4.34581C6.21309 4.4323 6.11073 4.5009 5.99807 4.54771C5.88541 4.59451 5.76466 4.6186 5.64272 4.6186Z"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.57129 3.68752L11.2141 3.6875"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2147 7.41211H10.2861"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.35728 8.34321C9.60355 8.34321 9.83974 8.24509 10.0139 8.07042C10.188 7.89575 10.2859 7.65886 10.2859 7.41184C10.2859 7.16483 10.188 6.92793 10.0139 6.75326C9.83974 6.5786 9.60355 6.48047 9.35728 6.48047C9.11101 6.48047 8.87482 6.5786 8.70068 6.75326C8.52654 6.92793 8.42871 7.16483 8.42871 7.41184C8.42871 7.65886 8.52654 7.89575 8.70068 8.07042C8.87482 8.24509 9.11101 8.34321 9.35728 8.34321Z"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42899 7.41213L3.78613 7.41211"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.78613 11.1387H4.7147"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.64272 12.0698C5.39644 12.0698 5.16026 11.9716 4.98612 11.797C4.81198 11.6223 4.71415 11.3854 4.71415 11.1384C4.71415 10.8914 4.81198 10.6545 4.98612 10.4798C5.16026 10.3052 5.39644 10.207 5.64272 10.207C5.76466 10.207 5.88541 10.2311 5.99807 10.2779C6.11073 10.3247 6.21309 10.3933 6.29932 10.4798C6.38554 10.5663 6.45394 10.669 6.50061 10.782C6.54727 10.895 6.57129 11.0161 6.57129 11.1384C6.57129 11.2607 6.54727 11.3818 6.50061 11.4948C6.45394 11.6078 6.38554 11.7105 6.29932 11.797C6.21309 11.8835 6.11073 11.9521 5.99807 11.9989C5.88541 12.0457 5.76466 12.0698 5.64272 12.0698Z"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.57129 11.1387L11.2141 11.1387"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="0.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
