import { motion } from "framer-motion";
import OnboardingInput from "./OnboardingInput";
import { UserSettingsProvider } from "../UserSettings/UserSettingsProvider";
import BrevLogo from "@/components/ui/brevlogo";

const OnboardingContainer = () => {
  return (
    <div className="fixed w-screen h-screen inset-0 bg-dark-main flex z-50">
      <div className="flex flex-col items-center justify-center w-screen text-light-alt">
        <div className="py-10">
          <BrevLogo size={120} dark />
        </div>
        <div className="text-[1.875rem] font-bold mb-10">
          Welcome, let's get you set up
        </div>
        <div className=" text-[0.875rem] font-semibold">
          The following information enables our AI to generate high-quality
          results.
        </div>
        <div className="flex flex-col justify-center mt-12 px-10 w-[50rem]">
          <motion.div className="flex flex-col gap-5 w-full items-center justify-center transition-all duration-500 ">
            <UserSettingsProvider>
              <OnboardingInput />
            </UserSettingsProvider>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default OnboardingContainer;
