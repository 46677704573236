import { cva } from "class-variance-authority";

const commonButtonStyles =
  "inline-flex items-center justify-center rounded-[8px] text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed transition-colors duration-500 ease-in-out rounded-[8px] hover:opacity-80 transition-opacity duration-500";

const commonInputStyles =
  "flex font-[Poppins] font-normal rounded-[8px] outline-none bg-transparent transition-colors duration-500 ease-in-out file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-brev-text-gray disabled:cursor-not-allowed disabled:opacity-50";

export const buttonVariants = cva(commonButtonStyles, {
  variants: {
    variant: {
      default:
        "bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text hover:text-light-text dark:hover:text-dark-text hover:bg-dark-alt dark:hover:bg-light-alt h-[1.75rem] w-[9rem]",
      negative:
        "bg-dark-main dark:bg-light-alt text-light-text dark:text-dark-text hover:text-light-alt dark:hover:text-dark-text hover:bg-dark-hover dark:hover:bg-light-main h-[1.75rem] w-[9rem]",
      destructive:
        "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline:
        "border border-input bg-light-alt border border-brev-border dark:border-dark-border dark:bg-dark-alt hover:bg-light-main dark:hover:bg-dark-hover",
      gradient:
        "bg-gradient-to-r from-[#3FCFF1] from-50% to-[#00FFB2] hover:shadow-md dark:shadow-brev-light/15 text-dark-text min-w-[6rem] h-[1.75rem] text-[0.75rem] active:scale-[98%]",
      ghost:
        "hover:bg-light-main hover:text-dark-text dark:text-light-text dark:hover:text-light-text dark:hover:bg-dark-main focus-visible:ring-0 focus-visible:ring-offset-0",
      link: "text-brev-active underline-offset-4 hover:underline",
      integrate:
        "bg-[#E5E5ED] hover:ring-2 hover:ring-brev-accent hover:bg-brev-accent",
      disabled: "bg-[#C4C5C7] text-white cursor-not-allowed",
      dropdown:
        "bg-light-main dark:bg-dark-alt dark:text-brev-light dark:hover:bg-dark-hover hover:bg-light-alt hover:text-dark-text text-left font-normal rounded-[8px] h-7 text-xs font-[Poppins] focus-visible:ring-0 focus-visible:ring-offset-0 border border-light-alt dark:border-dark-hover",
      nav: "group flex-col h-16 hover:bg-brev rounded-none",
      badge:
        "h-[2rem] font-normal text-[0.75rem] px-2.5 py-1 bg-light-alt border border-light-alt dark:border-dark-alt dark:bg-dark-alt text-dark-text dark:text-light-text dark:hover:bg-dark-hover hover:bg-light-main hover:text-dark-text gap-2",
      bubbleMenu:
        "bg-dark-main hover:bg-light-main hover:text-dark-text text-light-text min-w-8"
    },
    size: {
      default: "p-2",
      sm: "px-3",
      lg: "px-8",
      icon: "h-10 w-10",
      noPadding: ""
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});

export const inputVariants = cva(commonInputStyles, {
  variants: {
    variant: {
      default:
        "bg-light-main focus-within:bg-light-alt dark:bg-dark-alt dark:focus-within:bg-dark-main text-dark-text dark:text-light-text rounded-[8px]",
      title: "px-0 font-bold placeholder:text-brev-text-gray w-full",
      destructive:
        "bg-destructive text-destructive-foreground hover:bg-destructive/90",
      profile:
        "bg-light-main dark:bg-dark-alt text-dark-text dark:text-light-text text-xs px-2 py-1.5 border border-light-alt dark:border-dark-border w-[12rem] focus-within:bg-light-alt dark:focus-within:bg-dark-main",
      login:
        "bg-brev-light dark:bg-dark-alt h-10 focus-within:ring-2 focus-within:ring-brev-accent",
      ghost: "bg-transparent py-1"
    },
    fontSize: {
      default: "text-[1rem]", // 16px
      xs: "text-[0.75rem]", // 12px
      sm: "text-[0.875rem]", // 14px
      lg: "text-[1.125rem]", // 18px
      xl: "text-[1.25rem]", // 20px
      title: "text-[2.5rem]" // 40px
    }
  },
  defaultVariants: {
    variant: "default",
    fontSize: "default"
  }
});
