import { useState, useMemo } from "react";
import BrevLoader from "@/components/Loaders/BrevLoader";
import type { CalendarEvent } from "./calendar-types";
import {
  parseRrule,
  getCurrentWeek,
  getEventWeeks,
  getWeekNumber,
  getMonthInfo
  // getWeekOfMonth
} from "./utils";
import type { DateRange } from "./utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { Selector } from "@/components/Selector/Selector";
import { BounceLoader, PulseLoader } from "react-spinners";
import { RhythmAccordion } from "./RhythmAccordion";
import useRhythmContext from "./useRhythmContext";
import { mockData } from "./mock-data";
import QuarterView from "./QuarterView";
import { useGenerateList } from "./useGenerateList";

function WeekView({
  isLoading,
  setCurrentEvent
}: {
  isLoading: boolean;
  setCurrentEvent: (event: CalendarEvent) => void;
}) {
  const { selectedEvents } = useRhythmContext();
  const days = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"];
  const [selectedWeek, _setSelectedWeek] =
    useState<DateRange>(getCurrentWeek());
  const weekNumber = useMemo(
    () => getWeekNumber(selectedWeek.start),
    [selectedWeek]
  );

  const filteredEventsByDay = (dayIndex: number) => {
    return selectedEvents.filter((event) => {
      const parsedRrule = event.recurrence
        ? parseRrule(event.recurrence[event.recurrence.length - 1])
        : undefined;
      if (
        parsedRrule &&
        new Date(event.start?.dateTime) <= selectedWeek.start
      ) {
        return parsedRrule.days?.includes(days[dayIndex]);
      }
    });
  };

  return !isLoading ? (
    <div className="flex flex-col h-full w-full mt-4">
      <div className="grid grid-cols-7 w-full rounded-xl border-2 border-brev overflow-hidden">
        {days.map((day, index) => {
          const filteredEvents = filteredEventsByDay(index);
          return (
            <div key={index} className="flex flex-col items-center">
              <div className="flex w-full justify-center h-[33px] bg-brev text-white pt-1">
                {day}
              </div>
              <div>
                {filteredEvents.map((event) => {
                  const parsedRrule = event.recurrence
                    ? parseRrule(event.recurrence[event.recurrence.length - 1])
                    : undefined;

                  const weeks =
                    parsedRrule &&
                    parsedRrule.frequency === "WEEKLY" &&
                    parsedRrule.interval! > 1
                      ? getEventWeeks(
                          parsedRrule,
                          new Date(event.start.dateTime)
                        )
                      : undefined;

                  const displayEvent = weeks
                    ? weeks.includes(weekNumber)
                    : true;

                  return (
                    parsedRrule &&
                    displayEvent && (
                      <div
                        key={event.id}
                        className="max-w-[120px] break-words m-1 border border-brev rounded-[8px] px-1"
                        onMouseDown={() => setCurrentEvent(event)}
                      >
                        <span>{event.summary}</span>
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-96 w-full">
      <BrevLoader type="propagate" size={20} />
    </div>
  );
}

function MonthView({
  isLoading,
  setCurrentEvent
}: {
  isLoading: boolean;
  setCurrentEvent: (event: CalendarEvent) => void;
}) {
  const { openEventId, selectedEvents } = useRhythmContext();
  const [selectedMonth, _setSelectedMonth] = useState(new Date().getMonth());
  const [highlight, setHighlight] = useState<number | null>(null);
  const [month, setMonth] = useState<string>(
    new Date().toLocaleString("en-US", { month: "long" })
  );

  const monthInfo = getMonthInfo(selectedMonth);
  const firstWeek = getWeekNumber(monthInfo.startDate);
  const weekNumbers: number[] = [];
  const numWeeks = monthInfo.numWeeks;
  for (let i = 0; i < numWeeks; i++) {
    weekNumbers.push(firstWeek + i);
  }

  return !isLoading ? (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div
        className="flex items-center justify-between w-full bg-brev text-[#FFF] h-[39px] text-xs font-bold rounded-[8px]"
        style={{ gridTemplateColumns: `repeat(${numWeeks + 1}, 1fr)` }}
      >
        <div className="min-w-[230px]">
          <Selector
            title="Select"
            itemList={[
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December"
            ]}
            value={month}
            defaultValue="Period"
            onChange={(value) => setMonth(value)}
            className="bg-brev selection:font-normal text-xs text-[#FFF] w-[140px]"
          />
        </div>
        <div className="flex justify-between w-full">
          {weekNumbers.map((_, index) => {
            return (
              <div
                key={index}
                className="max-w-[120px] flex text-center transition-all px-8"
              >
                <div className={`rounded-full w-[39px]`}>W{index + 1}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="space-y-5 py-4 overflow-y-scroll no-scrollbar max-h-[calc(100vh-320px)]">
        {selectedEvents.map((event, index) => {
          const parsedRrule = event.recurrence
            ? parseRrule(event.recurrence[event.recurrence.length - 1])
            : undefined;

          const weeks = getEventWeeks(
            parsedRrule!,
            new Date(event.start.dateTime)
          );
          const extraAttendees =
            event.attendees && event.attendees?.length > 3
              ? event.attendees?.length - 3
              : 0;
          const monthWeek =
            parsedRrule && !weeks && parsedRrule.weekOfMonth !== -1
              ? parsedRrule.weekOfMonth
              : monthInfo.numWeeks;

          return (
            <div
              className="group flex w-full my-5"
              key={event.id}
              onMouseEnter={() => setHighlight(index)}
              onMouseLeave={() => setHighlight(null)}
            >
              <RhythmAccordion
                event={event}
                isHighlighted={highlight === index}
              />
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full px-6">
                  {weekNumbers.map((week) => {
                    return !weeks ||
                      weeks?.includes(week) ||
                      week === monthWeek ? (
                      <TooltipProvider key={week}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div
                              className="max-w-[120px] h-[33px] flex items-center justify-center group-hover:scale-[1.1] hover:scale-[1.2] transition-all"
                              onMouseDown={() => setCurrentEvent(event)}
                            >
                              <div className="w-full flex items-center justify-center h-full hover:scale-[1.1] transition-all duration-200">
                                <div
                                  className={`rounded-full flex w-[50px] h-[13px] cursor-pointer group-hover:shadow-md divide-x-2 divide-black/20 overflow-hidden`}
                                  style={{
                                    backgroundColor:
                                      index === highlight
                                        ? event.colour
                                        : "#C1C6D6"
                                  }}
                                >
                                  {parsedRrule?.days &&
                                    parsedRrule.days.map((_, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="w-full"
                                        ></div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent
                            className="p-0"
                            side="bottom"
                            // sideOffset={0}
                            align="end"
                            alignOffset={-150}
                          >
                            <div className="flex flex-col gap-2 rounded-[8px]">
                              <span
                                style={{ backgroundColor: event.colour }}
                                className="text-dark-text w-full p-3 backdrop-brightness-50"
                              >
                                {event.summary}
                              </span>
                              <div className="flex justify-around items-center pb-2">
                                <div className="flex pl-2">
                                  {event.attendees?.slice(0, 3).map((a) => {
                                    return (
                                      <div
                                        key={a.email}
                                        className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border border-black bg-white text-black"
                                      >
                                        {a.email.charAt(0)}
                                      </div>
                                    );
                                  })}
                                  {extraAttendees > 0 && (
                                    <div className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border-2 border-black bg-black text-white font-medium">
                                      {extraAttendees}
                                    </div>
                                  )}
                                </div>
                                <span className="lowercase first-letter:uppercase px-3">
                                  {parsedRrule?.frequency}
                                </span>
                              </div>
                              {/* <div className="flex justify-center pb-2">
                                {parsedRrule?.days && (
                                  <div className="flex w-full items-center justify-center px-3 pb-3 divide-x-2 divide-brev">
                                    {parsedRrule.days.map((d) => {
                                      return (
                                        <span key={d} className="p-1">
                                          {d}
                                        </span>
                                      );
                                    })}
                                  </div>
                                )}
                              </div> */}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <div
                        key={week}
                        className="max-w-[120px] h-[33px] flex items-center justify-center group-hover:scale-[1.1] hover:scale-[1.2] transition-all"
                      >
                        <div className="w-full flex items-center justify-center h-full hover:scale-[1.1] transition-all duration-200">
                          <div
                            className={`rounded-full w-[39px] h-[13px] cursor-pointer`}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {event.id === openEventId && <NextBestActions />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-96 w-full">
      <BrevLoader type="propagate" size={20} />
    </div>
  );
}

export default function RhythmViews({
  isLoading,
  range,
  setCurrentEvent
}: {
  isLoading: boolean;
  range: number;
  setCurrentEvent: (event: CalendarEvent) => void;
}) {
  return (
    <div>
      {range === 0 && (
        <WeekView isLoading={isLoading} setCurrentEvent={setCurrentEvent} />
      )}
      {range === 1 && (
        <MonthView isLoading={isLoading} setCurrentEvent={setCurrentEvent} />
      )}
      {range === 2 && (
        <QuarterView isLoading={isLoading} setCurrentEvent={setCurrentEvent} />
      )}
    </div>
  );
}

export const NextBestActions = () => {
  const { list, isGenerating: listGenerating } = useGenerateList(
    mockData["Monthly Sales Review"].nextBestActionsArray,
    10
  );
  return (
    <div className="transition-all duration-300 h-full mx-6 rounded-[8px] shadow-[0_0_15.5px_0_rgba(54,71,114,0.05)] border border-brev-border mt-2 max-h-[177px] overflow-y-scroll no-scrollbar">
      <div className="pl-4 h-[40px] flex gap-2 items-center">
        {listGenerating ? (
          <BounceLoader color="#3FCFF1" size={16} />
        ) : (
          <div className="flex bg-brev-accent ring-2 ring-[#017B4F05] shadow-xl shadow-black min-w-[7.34px] min-h-[7.34px] rounded-full"></div>
        )}
        <span className="text-xs font-bold">Next Best Actions</span>
      </div>
      <div>
        {listGenerating && (
          <div className="flex w-full justify-center mb-3">
            <PulseLoader color="#3FCFF1" size={8} />
          </div>
        )}

        <ul className="list-decimal">
          {list.map((item, index) => (
            <li key={index} className="text-[11px] font-normal">
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
