import { useContext, useEffect, useState } from "react";
import { ArtifactModel } from "../types";
import { motion } from "framer-motion";
import BrevLoader from "@/components/Loaders/BrevLoader";
import RecentArtifact from "./RecentArtifact";
import { ArtifactsListContext } from "@/components/SidePane/ArtifactsListProvider";

export const RecentArtifacts = () => {
  const { artifacts, isArtifactsListLoading } = useContext(ArtifactsListContext);
  const [artifactsToday, setArtifactsToday] = useState<ArtifactModel[]>([]);
  const [artifactsWeek, setArtifactsWeek] = useState<ArtifactModel[]>([]);
  const [artifactsMonth, setArtifactsMonth] = useState<ArtifactModel[]>([]);
  const [hiddenArtifacts, setHiddenArtifacts] = useState<Set<string>>(
    new Set()
  );
  
  useEffect(() => {
    const now = Date.now();
    const oneDay = 24 * 60 * 60 * 1000;
    const sevenDays = 7 * oneDay;
    const thirtyDays = 30 * oneDay;

    const artifactsToday: ArtifactModel[] = [];
    const artifactsWeek: ArtifactModel[] = [];
    const artifactsMonth: ArtifactModel[] = [];

    artifacts.forEach((artifact) => {
      const createdAtDate = new Date(Number(artifact.createdAt));
      const ageInMillis = now - createdAtDate.getTime();

      if (ageInMillis <= oneDay) {
        artifactsToday.push(artifact);
      } else if (ageInMillis <= sevenDays) {
        artifactsWeek.push(artifact);
      } else if (ageInMillis <= thirtyDays) {
        artifactsMonth.push(artifact);
      }
    });

    const sortByDate = (a: ArtifactModel, b: ArtifactModel) => {
      const dateA = a.updatedAt
        ? new Date(Number(a.updatedAt))
        : new Date(Number(a.createdAt));
      const dateB = b.updatedAt
        ? new Date(Number(b.updatedAt))
        : new Date(Number(b.createdAt));
      return dateB.getTime() - dateA.getTime();
    };

    setArtifactsToday(artifactsToday.sort(sortByDate));
    setArtifactsWeek(artifactsWeek.sort(sortByDate));
    setArtifactsMonth(artifactsMonth.sort(sortByDate));
  }, [artifacts]);

  const deleteArtifact = async (artifactId: string) => {
    setHiddenArtifacts((prevHidden) => new Set(prevHidden).add(artifactId));
  };

  const renderArtifacts = (artifactsInput: ArtifactModel[], title: string) => {
    const visibleArtifacts = artifactsInput.filter(
      (artifact) => !hiddenArtifacts.has(artifact.artifactId)
    );

    if (visibleArtifacts.length === 0) return null;

    return (
      <div className="flex flex-col gap-0.5 mb-2">
        <span className="text-dark-text dark:text-light-text text-[8px] mb-1 pl-2">
          {title}
        </span>
        {visibleArtifacts.map((artifact) => (
          <RecentArtifact
            key={artifact.artifactId}
            artifact={artifact}
            deleteArtifact={deleteArtifact}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="relative w-full flex min-h-0 overflow-hidden justify-center">
      <div className="overflow-y-scroll w-full h-full no-scrollbar">
        {isArtifactsListLoading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="flex flex-grow flex-shrink-0 h-[200px] justify-center items-center"
          >
            <BrevLoader type="grid" />
          </motion.div>
        )}
        {!isArtifactsListLoading && (
          <>
            {renderArtifacts(artifactsToday, "Today")}
            {renderArtifacts(artifactsWeek, "Last 7 days")}
            {renderArtifacts(artifactsMonth, "Last 30 days")}

            {artifactsToday.length === 0 &&
              artifactsWeek.length === 0 &&
              artifactsMonth.length === 0 && (
                <div className="flex flex-col items-center justify-center gap-4 text-center py-10">
                  <div className="text-xl font-semibold text-dark-text/20 dark:text-light-text/20">
                    No recent artifacts
                  </div>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};
