import Tile from "@/components/Tile/Tile";
import { StepNames } from "@/containers/Integrations/ConnectionSteps";
import Brev from "@/images/brev.svg";

export default function InternalConnector({ onSuccess }: { onSuccess: (stepName: StepNames) => void }) {
  return (
    <Tile
      onPress={async () => {
        onSuccess("internal-picker");
      }}
      label="Internal"
      src={Brev}
      connected={true}
    />
  );
}