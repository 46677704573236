import { cn } from "@/lib/utils";

export default function GoalsSvg({
  className,
  size,
  isActive = false
}: {
  className?: string;
  size?: number;
  isActive?: boolean;
}) {
  return (
    <svg
      width={size || 13}
      height={size || 15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("", className)}
    >
      <defs>
        <linearGradient
          id="formatActiveGradient"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor="#3FCFF1" />
          <stop offset="50%" stopColor="#3FCFF1" />
          <stop offset="100%" stopColor="#00FFB2" />
        </linearGradient>
      </defs>
      <path
        d="M6.96289 8.2723V1L12.2567 3.64447L6.96289 6.28894"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="1.32345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6203 6.49731C12.9724 7.58164 13.0054 8.74421 12.7152 9.8467C12.4251 10.9492 11.824 11.9452 10.9836 12.7162C10.1431 13.4873 9.09869 14.0009 7.9745 14.1959C6.85032 14.391 5.69371 14.2593 4.64228 13.8165C3.59085 13.3737 2.68887 12.6385 2.04365 11.6983C1.39842 10.7581 1.03711 9.65247 1.00271 8.51301C0.968304 7.37356 1.26225 6.24822 1.84958 5.27087C2.4369 4.29352 3.29288 3.5053 4.31568 3"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="1.32345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.31564 6C3.98406 6.44098 3.76819 6.95785 3.6877 7.50351C3.60721 8.04917 3.66466 8.60628 3.85482 9.12408C4.04498 9.64188 4.3618 10.1039 4.77642 10.4681C5.19104 10.8322 5.69027 11.087 6.22864 11.2091C6.76701 11.3311 7.32739 11.3167 7.85874 11.167C8.39009 11.0174 8.8755 10.7372 9.27077 10.3522C9.66603 9.96714 9.95858 9.4894 10.1217 8.96249C10.2849 8.43557 10.3135 7.87624 10.205 7.33546"
        stroke={isActive ? "url(#formatActiveGradient)" : "#656A6B"}
        strokeWidth="1.32345"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
