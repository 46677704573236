import { cn } from "@/lib/utils";
import { ring } from "ldrs";
ring.register();

export default function RingLoader({
  size,
  className,
  color = "#3FCFF1"
}: {
  size?: number;
  className?: string;
  color?: string
}) {
  return (
    <div className={cn("grid place-items-center", className)}>
      <l-ring size={size || 24} stroke={1.5} color={color}></l-ring>
    </div>
  );
}
