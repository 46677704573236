import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { TabProps } from "./types";
import { Button } from "@/components/ui/button";
import { FaChevronLeft } from "react-icons/fa6";
import { ArtifactContext } from "../ArtifactProvider";
import { useParams } from "react-router-dom";
import TemplateCard from "./TemplateCard";
import { ArtifactTemplateModel, ArtifactTemplateType } from "../types";
import { AuthContext } from "@/containers/Auth/AuthProvider";
import { WebSocketContext } from "@/containers/WebSocket/WebSocketProvider";
import IconButton from "@/components/Buttons/IconButton";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { MessageAction } from "@/containers/WebSocket/WebSocketManager";
import { ArtifactSettingsContext } from "./ArtifactSettingsProvider";
import { GlobalContext } from "@/GlobalProvider";

const colors: string[] = [
  "purple",
  "blue",
  "green",
  "yellow",
  "orange",
  "red",
  "teal",
  "darkgreen",
  "darkblue",
  "lightgreen",
  "lightblue",
  "yellowgreen"
];

const shuffleColors = (colors: string[]): string[] => {
  const shuffled = [...colors];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

export default function TemplateTab({
  handleTabSelection,
  setDialogOpen
}: TabProps): JSX.Element {
  const { artifactId } = useParams<{ artifactId: string }>();
  const { webSocketManager, isConnectionOpen } = useContext(WebSocketContext);
  const { artifactTemplates, isTemplatesLoading, setArtifactTemplates } =
    useContext(ArtifactSettingsContext);
  const { userId } = useContext(AuthContext);
  const { setArtifactTitle } = useContext(GlobalContext);
  const {
    generateArtifactV3,
    llmModelValue,
    artifactTemplateId,
    setArtifactTemplateId,
    saveArtifact
  } = useContext(ArtifactContext);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const templateCandidatesRequested = useRef<boolean>(false);
  const [chosenArtifactTemplate, setChosenArtifactTemplate] =
    useState<ArtifactTemplateModel | null>(
      artifactTemplateId
        ? artifactTemplates.find(
            (template) => template.templateId === artifactTemplateId
          ) || null
        : null
    );

  useEffect(() => {
    if (
      artifactTemplates.length > 0 &&
      !chosenArtifactTemplate
    ) {
      setChosenArtifactTemplate(artifactTemplates[0]);
      setArtifactTemplateId(artifactTemplates[0].templateId);
      setArtifactTitle(artifactTemplates[0].templateTitle);
      saveArtifact({ artifactTitle: artifactTemplates[0].templateTitle }, true);
    }
  }, [
    artifactTemplates,
    chosenArtifactTemplate,
    setArtifactTemplateId,
    setArtifactTitle,
    artifactTemplateId,
    saveArtifact
  ]);

  useEffect(() => {
    if (chosenArtifactTemplate) {
      setArtifactTemplateId(chosenArtifactTemplate.templateId);
    }
  }, [chosenArtifactTemplate, setArtifactTemplateId]);

  useEffect(() => {
    if (
      isConnectionOpen &&
      artifactTemplates.length < 5 &&
      !templateCandidatesRequested.current
    ) {
      templateCandidatesRequested.current = true;
      console.log("Generating artifact template candidates");
      setIsGenerating(true);
      webSocketManager?.sendMessage({
        action: "generateArtifactTemplateCandidates",
        data: {
          userId,
          artifactId,
          llmModel: llmModelValue
        }
      });
    }
  }, [
    artifactId,
    artifactTemplates.length,
    isConnectionOpen,
    llmModelValue,
    userId,
    webSocketManager
  ]);

  useEffect(() => {
    if (!webSocketManager || !isConnectionOpen) {
      return;
    }
    webSocketManager.addListener(
      MessageAction.generateArtifactTemplateCandidatesComplete,
      (data) => {
        const { artifactTemplateModels } = data as {
          artifactTemplateModels: ArtifactTemplateModel[];
        };
        setArtifactTemplates((prev) => [...artifactTemplateModels, ...prev]);
        setChosenArtifactTemplate(artifactTemplateModels[0]);
        setArtifactTitle(artifactTemplateModels[0].templateTitle);
        saveArtifact({ artifactTitle: artifactTemplateModels[0].templateTitle }, true);
        setIsGenerating(false);
      }
    );
    return () => {
      // webSocketManager.removeListener(
      //   MessageAction.generateArtifactTemplateCandidatesInProgress
      // );
      webSocketManager.removeListener(
        MessageAction.generateArtifactTemplateCandidatesComplete
      );
    };
  }, [
    artifactTemplates,
    isConnectionOpen,
    setArtifactTemplates,
    setArtifactTitle,
    webSocketManager,
    saveArtifact
  ]);

  const shuffledColors = useMemo(() => shuffleColors(colors), []);

  return (
    <div className="p-4 h-[517px] bg-brev-light dark:bg-dark-alt dark:text-brev-light transition-colors duration-500 ease-in-out">
      <div className="text-lg font-bold text-dark-text dark:text-brev-light py-2">
        <span className="mr-3">Choose your template</span>
        {!isGenerating && isTemplatesLoading && (
          <BrevLoader type="ring" size={20} />
        )}
      </div>
      <div className="font-normal text-[0.625rem] pb-2">Based on your data</div>
      <div className="grid place-items-center grid-cols-[repeat(2,_19rem)] h-[21rem] my-2 gap-3 overflow-y-auto scrollbar-webkit-standard dark:scrollbar-webkit-gray transition-colors duration-500 ease-in-out">
        {artifactTemplates.map(
          (template: ArtifactTemplateModel, index: number) => {
            if (template.templateType === ArtifactTemplateType.PLACEHOLDER) {
              return null;
            }
            return (
              <TemplateCard
                key={template.templateId}
                templateModel={template}
                isSelected={
                  chosenArtifactTemplate?.templateId === template.templateId
                }
                onClickHandler={() => {
                  setChosenArtifactTemplate(template);
                  setArtifactTitle(template.templateTitle);
                  saveArtifact({ artifactTitle: template.templateTitle }, true);
                }}
                color={shuffledColors[index % shuffledColors.length]}
              />
            );
          }
        )}
        {isGenerating && (
          <div className="w-full h-[15rem] col-span-2 flex justify-center items-center">
            <BrevLoader type="propagate" size={20} />
          </div>
        )}
      </div>
      <div className="flex gap-2 absolute bottom-5 right-5 items-center ">
        <Button
          variant="link"
          type="submit"
          className="h-[28px] w-[37px] rounded-[8px] p-0"
          onClick={() => handleTabSelection(1)}
        >
          <FaChevronLeft />
        </Button>
        {/* <ModelSelector /> */}
        <IconButton
          onClick={() => {
            if (setDialogOpen) {
              setDialogOpen(false);
            }
            if (chosenArtifactTemplate?.templateId) {
              generateArtifactV3();
            }
          }}
          disabled={!chosenArtifactTemplate || !isConnectionOpen}
          className="w-[8rem]"
        >
          Generate
        </IconButton>
      </div>
    </div>
  );
}
