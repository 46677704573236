import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import config from "@/config";
import { useState } from "react";

const blacklistedDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "mail.com",
  "zoho.com",
  "yandex.com",
  "protonmail.com",
  "icloud.com",
  "gmx.com",
  "mail.ru",
  "live.com",
  "inbox.com",
  "rediffmail.com",
  "gmx.de",
  "web.de",
  "mailinator.com",
  "trashmail.com",
  "tempmail.com",
  "10minutemail.com",
  "tutanota.com",
  "fastmail.com",
  "hushmail.com",
  "laposte.net",
  "bluewin.ch",
  "freenet.de",
  "wanadoo.fr",
  "orange.fr",
  "lycos.com"
];

const allowedEmails = [
  "hodorbolton.364162@gmail.com", // google oauth verifier
  "chinwei.hu@gmail.com" // Vic's personal email
];

export default function EmailLogin() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const validateEmail = (email: string) => {
    const domain = email.split("@")[1]?.toLowerCase();
    return (domain && !blacklistedDomains.includes(domain)) || allowedEmails.includes(email);
  };

  const sendEmail = async () => {
    if (!validateEmail(email)) {
      setError("Invalid domain. Please use a work email.");
      return;
    }

    setLoading(true);
    setError(null);
    window.location.href = `${config.apiGateway.V3_API_URL}/auth/link/authorize?email=${encodeURIComponent(email)}`;
  };

  return (
    <div className="w-[25rem] flex flex-col gap-5">
      <Input
        variant="profile"
        type="email"
        label="Work email"
        onChange={(event) => {
          setEmail(event.target.value);
          setError(null);
        }}
        placeholder="Enter your work email"
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            sendEmail();
          }
        }}
        disabled={loading}
        value={email}
        className="w-[25rem] h-[3.25rem] rounded-full"
      />
      {error && <p className="text-red-500 text-sm">{error}</p>}
      <Button
        variant="gradient"
        className="w-[25rem] h-[3.25rem] rounded-full"
        disabled={loading || !email}
        onClick={sendEmail}
        isLoading={loading}
      >
        <span className="text-[0.875rem] font-bold">Let's go!</span>
      </Button>
    </div>
  );
}
