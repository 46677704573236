export default function SlideDivider() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center rounded-lg">
      <div className="flex flex-col justify-between min-h-8 bg-light-main dark:bg-dark-main w-[calc(100%+20px)] absolute bottom-1 z-10">
        <div className="min-h-2 bg-light-alt dark:bg-dark-alt w-full rounded-b-[16px]" />
        <div className="min-h-2 bg-light-alt dark:bg-dark-alt w-full rounded-t-[16px]" />
      </div>
    </div>
  );
}
