import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogTitle,
  DialogFooter,
  DialogClose,
  DialogHeader,
  DialogDescription
} from "@/components/ui/dialog";
import { HiOutlineTrash } from "react-icons/hi";
import BrevLoader from "@/components/Loaders/BrevLoader";
import { Button } from "@/components/ui/button";

type ConfirmDialogProps = {
  isLoading: boolean;
  onDelete: () => void;
  itemToDelete: string;
};

export default function ConfirmDialog({
  isLoading,
  onDelete,
  itemToDelete
}: ConfirmDialogProps) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="my-1 flex items-center justify-end">
          <button
            disabled={isLoading}
            className="absolute top-0 right-0 bg-gradient-to-l from-65% from-light-main dark:from-dark-hover text-dark-text dark:text-light-text p-1 opacity-[0%] group-hover:opacity-100 transition-all duration-500 ease-in-out hover:text-red-500 h-full w-[2rem] rounded-r-[8px]"
            title="Delete artifact"
          >
            {isLoading ? (
              <BrevLoader size={16} type="ring" />
            ) : (
              <HiOutlineTrash size={16} className="ml-auto" />
            )}
          </button>
        </div>
      </DialogTrigger>
      <DialogContent className="p-0 gap-0 min-w-[20rem] min-h-[10rem] rounded-2xl sm:rounded-3xl overflow-hidden border border-dark-alt focus:outline-black focus:border-dark-alt">
         <DialogHeader>
          <DialogTitle className="w-full p-6 border-b border-b-black dark:border-dark-alt">Delete Artifact?</DialogTitle>
          <DialogDescription className="p-6">
            Removing <b>{itemToDelete || "this artifact"}</b>
            <span> will permanently delete the artifact and it's contents.</span>
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className="sm:justify-end p-6">
          <DialogClose asChild>
            <Button type="button" variant="outline" className="w-[6rem]">Close</Button>
          </DialogClose>
          <Button type="submit" variant="destructive" className="w-[6rem]" isLoading={isLoading} onClick={onDelete}>
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
