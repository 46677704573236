export default function GDocsSvg({ size }: { size?: number }) {
  return (
    <svg
      width={size || 25}
      height={size || 34}
      viewBox="0 0 25 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_877_2203)">
        <mask
          id="mask0_877_2203"
          maskUnits="userSpaceOnUse"
          x="0"
          y="2"
          width="23"
          height="32"
        >
          <path
            d="M22.4986 2.53149H0.208984V33.3576H22.4986V2.53149Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_877_2203)">
          <mask
            id="mask1_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_877_2203)">
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L17.6228 7.43565L14.14 2.53149Z"
              fill="#4285F4"
            />
          </g>
          <mask
            id="mask2_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask2_877_2203)">
            <path
              d="M14.75 10.3234L22.4974 18.114V10.9381L14.75 10.3234Z"
              fill="url(#paint0_linear_877_2203)"
            />
          </g>
          <mask
            id="mask3_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask3_877_2203)">
            <path
              d="M5.78125 24.9504H16.9261V23.5492H5.78125V24.9504ZM5.78125 27.7528H14.1399V26.3516H5.78125V27.7528ZM5.78125 17.9445V19.3457H16.9261V17.9445H5.78125ZM5.78125 22.148H16.9261V20.7468H5.78125V22.148Z"
              fill="#F1F1F1"
            />
          </g>
          <mask
            id="mask4_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask4_877_2203)">
            <path
              d="M14.1406 2.53137V8.8367C14.1406 9.99795 15.0757 10.9385 16.2303 10.9385H22.4993L14.1406 2.53137Z"
              fill="#A1C2FA"
            />
          </g>
          <mask
            id="mask5_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask5_877_2203)">
            <path
              d="M2.29864 2.53149C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V4.80842C0.208984 3.65244 1.14933 2.70664 2.29864 2.70664H14.14V2.53149H2.29864Z"
              fill="white"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask6_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask6_877_2203)">
            <path
              d="M20.409 33.1825H2.29864C1.14933 33.1825 0.208984 32.2367 0.208984 31.0807V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V31.0807C22.4986 32.2367 21.5583 33.1825 20.409 33.1825Z"
              fill="#1A237E"
              fillOpacity="0.2"
            />
          </g>
          <mask
            id="mask7_877_2203"
            maskUnits="userSpaceOnUse"
            x="0"
            y="2"
            width="23"
            height="32"
          >
            <path
              d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask7_877_2203)">
            <path
              d="M16.2303 10.9387C15.0757 10.9387 14.1406 9.99817 14.1406 8.83691V9.01207C14.1406 10.1733 15.0757 11.1139 16.2303 11.1139H22.4993V10.9387H16.2303Z"
              fill="#1A237E"
              fillOpacity="0.1"
            />
          </g>
          <path
            d="M14.14 2.53149H2.29864C1.14933 2.53149 0.208984 3.4773 0.208984 4.63328V31.2558C0.208984 32.4118 1.14933 33.3576 2.29864 33.3576H20.409C21.5583 33.3576 22.4986 32.4118 22.4986 31.2558V10.9386L14.14 2.53149Z"
            fill="url(#paint1_radial_877_2203)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_877_2203"
          x1="18.6241"
          y1="10.9923"
          x2="18.6241"
          y2="18.1151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1A237E" stopOpacity="0.2" />
          <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_877_2203"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(0.915132 3.14077) scale(35.9417 35.9417)"
        >
          <stop stopColor="white" stopOpacity="0.1" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <clipPath id="clip0_877_2203">
          <rect
            width="23.8533"
            height="32.6757"
            fill="white"
            transform="translate(0.208984 0.682007)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
