type SettingsOptionProps = {
  option: string;
  setOption: (option: string) => void;
  active: string;
  disabled?: boolean;
};

export default function SettingsOption({
  option,
  setOption,
  active,
  disabled
}: SettingsOptionProps) {
  return (
    <div
      className={`border ${active === option ? "hover:bg-dark-alt hover:text-brev-light bg-dark-alt text-light-text dark:bg-brev-light dark:text-dark-text border-dark-main dark:border-light-main" : "dark:hover:bg-dark-hover hover:bg-light-alt text-dark-text bg-brev-light dark:bg-dark-alt dark:text-brev-light border-light-alt dark:border-dark-hover"} flex justify-center items-center font-normal truncate rounded-[8px] h-7 px-2 ${disabled ? "cursor-not-allowed opacity-20" : "cursor-pointer"} transition-colors duration-300 ease-in-out`}
      onClick={disabled ? undefined : () => setOption(option)}
      data-cy="dialog-option"
      data-option={option}
    >
      <span className="normal-case font-[Poppins] text-xs">{option}</span>
    </div>
  );
}
