import { Button } from "@/components/ui/button";
import { LuPresentation } from "react-icons/lu";
import { GridContext } from "./GridProvider";
import { useContext, useEffect, useState } from "react";
import Hint from "@/components/Tooltips/Hint";

export default function PresentButton() {
  const { isSlideView, setIsSlideView, isPresentation, setIsPresentation } = useContext(GridContext);
  const [isPendingSlideView, setIsPendingSlideView] = useState(false);

  const handleSlideViewChange = () => {
    if (isSlideView) {
      setIsPresentation(false);
      setIsSlideView(false);
    } else {
      setIsPresentation(true);
      setIsPendingSlideView(true);
    }
  }

  useEffect(() => {
    if (isPendingSlideView && isPresentation) {
      setIsSlideView(true);
      setIsPendingSlideView(false);
    }
  }, [isPendingSlideView, isPresentation, setIsSlideView]);

  return (
    <Button variant="outline" onClick={handleSlideViewChange} className="h-[1.75rem]">
      <Hint message="Presentation mode" side="bottom" offset={20}>
        <LuPresentation size={14} />
      </Hint>
    </Button>
  );
}