import { Button } from "@/components/ui/button";

type NavButtonProps = {
  onClick?: () => void;
  className?: string;
  icon?: React.ReactNode;
  label: string;
  active?: boolean;
  disabled?: boolean;
};

export default function NavButton(props: NavButtonProps) {
  return (
    <Button
      variant="nav"
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled}
    >
      <div
        className={`grid place-items-center p-2 rounded-[8px] group-hover:bg-brev-light/20 transition-colors duration-300 text-brev-light ${props.active ? "bg-brev-light/10" : ""}`}
      >
        {props.icon}
      </div>
      <span
        className={`text-[10px] text-brev-light font-[Poppins] ${props.active ? "font-semibold" : "font-normal"} capitalize`}
      >
        {props.label}
      </span>
    </Button>
  );
}
