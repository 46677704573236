import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { ArtifactContext } from "./ArtifactProvider";
// import ArtifactContainer from "./ArtifactCanvas/ArtifactContainer";
import ArtifactTitle from "./ArtifactTitle";
// import { ArtifactSettingsProvider } from "./ArtifactSettings/ArtifactSettingsProvider";
import ShareButton from "./Share/ShareButton";
import LayoutContainer from "../Layout/LayoutContainer";
// import ArtifactBadges from "./ArtifactBadges";
import PresentationContainer from "./ArtifactCanvas/PresentationContainer";
import ArtifactContainerV4 from "./ArtifactCanvas/ArtifactContainerV4";
import { GridContext } from "./ArtifactCanvas/GridProvider";
import PresentButton from "./ArtifactCanvas/PresentButton";
import DivideButton from "./ArtifactCanvas/DivideButton";
import ArtifactDrawer from "./ArtifactCanvas/ArtifactDrawer";
import { isProdEnvironment } from "@/lib/stageUtils";

export default function ArtifactBuilderPage() {
  // const { artifactId } = useParams<{ artifactId: string }>();
  const { artifactMetadata, isArtifactGenerating } =
    useContext(ArtifactContext);
  // const [dialogOpen, setDialogOpen] = useState(false);
  // const [openTab, setOpenTab] = useState(0);
  const [autoScroll, setAutoScroll] = useState(true);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const { setActiveTile } = useContext(GridContext);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        const isAtBottom = scrollHeight - scrollTop <= clientHeight + 1; // account for small differences to prevent annoying scroll behavior
        setAutoScroll(isAtBottom);
      }
    };

    const container = scrollRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isArtifactGenerating && autoScroll && scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isArtifactGenerating, autoScroll]);

  useEffect(() => {
    const container = scrollRef.current;
    if (!container) return;

    const observer = new MutationObserver(() => {
      if (isArtifactGenerating && autoScroll) {
        container.scrollTop = container.scrollHeight;
      }
    });

    observer.observe(container, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [isArtifactGenerating, autoScroll]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, []);

  // const handleBadgeClick = (tab: number) => {
  //   setOpenTab(tab);
  //   setDialogOpen(true);
  // };

  const handleContainerClick = useCallback(
    (e: React.MouseEvent) => {
      if (
        e.target === e.currentTarget ||
        (e.target as HTMLElement).classList.contains("scroll-container")
      ) {
        setActiveTile(null);
      }
    },
    [setActiveTile]
  );

  return (
    <LayoutContainer className="p-0 relative">
      <div
        ref={scrollRef}
        className="relative w-full h-full px-16 pb-[6.5rem] bg-light-main dark:bg-dark-main text-dark-text dark:text-light-text rounded-[8px] transition-colors duration-500 ease-in-out overflow-y-scroll no-scrollbar overflow-x-visible"
        onClick={handleContainerClick}
      >
        <div className="sticky flex justify-end items-center top-0 pt-3 pb-1 gap-2 bg-light-main dark:bg-dark-main transition-colors duration-500 ease-in-out z-10 max-w-[1008px] 2xl:max-w-[1112px] mx-auto">
          <ArtifactTitle />
          <DivideButton />
          <PresentButton />
          <ShareButton />
        </div>
        {artifactMetadata?.format === "Presentation" && (
          <PresentationContainer key="presentation" />
        )}
        {artifactMetadata?.format
          ?.toLocaleLowerCase()
          .startsWith("document") && <ArtifactContainerV4 key="document-v4" />}
        {/* {artifactMetadata?.format?.toLocaleLowerCase() === "document" && (
            <ArtifactContainer key="document-v3" />
          )} */}
      </div>
      {!isProdEnvironment() && <ArtifactDrawer />}
    </LayoutContainer>
  );
}
