import { useContext, useState } from "react";
import { ArtifactContext } from "../ArtifactProvider";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import ArtifactPdfDoc from "./ArtifactPdfDoc";
import { useParams } from "react-router-dom";
import { Copy } from "lucide-react";
import { TbCloudDownload } from "react-icons/tb";
// import BrevLoader from "@/components/Loaders/BrevLoader";
import PublishButton from "./PublishButton";
import IconButton from "@/components/Buttons/IconButton";
import { LuShare } from "react-icons/lu";
// import { GlobalContext } from "@/GlobalProvider";

export default function ShareButton() {
  const [contentCopied, setContentCopied] = useState(false);
  // const { artifactTitle } = useContext(GlobalContext);
  const { artifactTiles, isArtifactGenerating } = useContext(ArtifactContext);
  const { artifactId } = useParams<{ artifactId: string }>();

  const copyContentToClipboard = () => {
    const url = `${window.location.origin}/shared/artifacts/${artifactId}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log("URL copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy URL: ", err);
      });
    setContentCopied(true);
    // setLinkCopied(false);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        asChild
        className="font-[Poppins]"
        disabled={!artifactTiles || isArtifactGenerating}
      >
        <div>
          <IconButton
            start={<LuShare size={14} />}
            disabled={!artifactTiles || isArtifactGenerating}
            data-cy="shareButton"
          >
            Share
          </IconButton>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-light-alt dark:bg-dark-alt transition-colors duration-500 ease-in-out border border-brev-border dark:border-dark-border">
        <Card className="w-[180px] bg-transparent shadow-none border-0">
          <CardHeader className="p-3">
            <CardTitle className="text-[1rem] font-bold text-dark-text dark:text-light-text transition-colors duration-500 ease-in-out">
              Share your artifact
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-4 px-3 pb-1.5">
            <div className="flex flex-col">
              <PublishButton />
              <Button
                variant="ghost"
                disabled
                onClick={() => copyContentToClipboard()}
                className={`justify-start ${contentCopied ? "text-brev-accent dark:text-brev-accent hover:text-brev-accent" : "text-dark-text"} transition-color`}
              >
                <Copy className="mr-2 bg-[#0676C7] rounded-full p-1.5 text-white h-6 w-6" />
                {contentCopied ? "Copied!" : "Copy content"}
              </Button>
              <Button variant="ghost" disabled className="justify-start">
                <TbCloudDownload className="mr-2 bg-[#0676C7] rounded-full p-1.5 text-white h-6 w-6" />
                Download PDF
                {/* <PDFDownloadLink
                  className="no-underline text-dark-text hover:text-dark-text dark:text-light-text dark:hover:text-light-text transition-colors duration-500 ease-in-out"
                  document={
                    <ArtifactPdfDoc
                      artifactTitle={artifactTitle}
                      artifactContent={artifactContent}
                    />
                  }
                  fileName={`${artifactTitle}.pdf`}
                >
                  {({ loading }) =>
                    loading ? (
                      <BrevLoader type="ring" />
                    ) : (
                      <span className="text-nowrap">Download PDF</span>
                    )
                  }
                </PDFDownloadLink> */}
              </Button>
            </div>
          </CardContent>
        </Card>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
