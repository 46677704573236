import brev from "@/images/brev.svg";
import brevDark from "@/images/brev-dark.svg";
import brevDarkMinimal from "@/images/brev-dark-minimal.svg";
import brevMinimal from "@/images/brev-minimal.svg";

interface BrevLogoProps {
  dark?: boolean;
  isMinimal?: boolean;
  size?: number;
}

const BrevLogo: React.FC<BrevLogoProps> = ({ dark, isMinimal, size}) => {
  const defaultSize = 84;
  const minimalSize = 20;

  const height = size ? size : isMinimal ? minimalSize : defaultSize;
  const width = size ? size : isMinimal ? minimalSize : defaultSize;

  const imageSource = isMinimal
    ? (dark ? brevMinimal : brevDarkMinimal)
    : (dark ? brevDark : brev);

  return <img src={imageSource} height={height} width={width} />;
};

export default BrevLogo;
