export const mockData = {
  "Monthly Sales Review": {
    description: `  The meeting covered the previous month's sales performance, key metrics, pipeline updates, and major deals, along with identifying high-priority leads, challenges, and strategy adjustments.`,
    summary: `  In the recent sales meeting, the previous month's performance was reviewed, highlighting a total sales figure of $18 million, a 5% increase from the previous month, with significant deals closed with ABC Corp and XYZ Inc. Key metrics showed an improvement in conversion rates and a reduction in customer acquisition costs. The sales pipeline, valued at $50 million, was discussed with a strong forecast for the next month. Challenges such as delays in product delivery and high competition were identified, along with potential solutions including enhanced customer relationship management tools and targeted marketing strategies.`,
    nextBestActions: (
<ul className="list-decimal">
  <li>Implement CRM System: Prioritize the implementation of the new CRM system by Q3 2024 to streamline sales processes and improve lead follow-up efficiency.</li>
  <li>Enhance Training Programs: Schedule regular training sessions focused on advanced sales techniques and product knowledge to boost sales team performance.</li>
  <li>Develop Personalized Service Plans: Create personalized service plans and explore flexible payment options to meet customer demands and improve customer satisfaction.</li>
</ul>
    ),
    nextBestActionsArray: [
      "Implement CRM System: Prioritize the implementation of the new CRM system by Q3 2024 to streamline sales processes and improve lead follow-up efficiency.",
      "Enhance Training Programs: Schedule regular training sessions focused on advanced sales techniques and product knowledge to boost sales team performance.",
      "Develop Personalized Service Plans: Create personalized service plans and explore flexible payment options to meet customer demands and improve customer satisfaction."
    ],  
    agenda: (
<ol className="list-disc">
    <li>Review of Previous Month's Sales Performance</li>
    <li>Analysis of Key Sales Metrics</li>
    <li>Update on Sales Pipeline and Forecast</li>
    <li>Discussion of Major Deals Closed and Lost</li>
    <li>Identification of High-Priority Leads and Opportunities</li>
    <li>Review of Sales Strategies and Campaigns</li>
    <li>Customer Feedback and Market Insights</li>
    <li>Identification of Challenges and Bottlenecks</li>
    <li>Strategy Adjustments and Action Plans</li>
    <li>Sales Team Performance and Development</li>
    <li>Open Forum for Questions and Suggestions</li>
    <li>Summary of Key Takeaways and Next Steps</li>
    <li>Closing Remarks and Scheduling of Next Meeting</li>
</ol>
    ),
    agendaArray: [
      "Review of Previous Month's Sales Performance",
      "Analysis of Key Sales Metrics",
      "Update on Sales Pipeline and Forecast",
      "Discussion of Major Deals Closed and Lost",
      "Identification of High-Priority Leads and Opportunities",
      "Review of Sales Strategies and Campaigns",
      "Customer Feedback and Market Insights",
      "Identification of Challenges and Bottlenecks",
      "Strategy Adjustments and Action Plans",
      "Sales Team Performance and Development",
      "Open Forum for Questions and Suggestions",
      "Summary of Key Takeaways and Next Steps",
      "Closing Remarks and Scheduling of Next Meeting"
    ]
  }
};