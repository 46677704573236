import { NodeViewWrapper } from "@tiptap/react";
import { Button } from "@/components/ui/button";
import InfoSvg from "@/components/Icons/InfoSvg";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function CitationWithoutContext(props: any) {
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!props.editor) return;

    const pos = props.editor.view.posAtCoords({
      left: event.clientX,
      top: event.clientY
    });
    if (!pos) return;
  };

  return (
    <NodeViewWrapper as="span" onClick={handleClick} className="citation">
      <CitationButton
        title={props.node.attrs.contextId}
        id={props.node.attrs.contextId}
      />
    </NodeViewWrapper>
  );
}

export const CitationButton = ({
  title,
  small = true
}: {
  title: string;
  id: string;
  small?: boolean;
}) => {
  return (
    <Button
      variant="ghost"
      className="rounded-full p-0 gap-2 w-full truncate justify-start"
      title={title}
      onClick={() => {
        console.log("clicked");
      }}
    >
      <InfoSvg size={11} className="min-w-3" />
      {!small && <span className="text-xs truncate text-nowrap">{title}</span>}
    </Button>
  );
};
