type Listener = (data: object) => void;

export enum MessageAction {
  generateArtifactChunk = 'generateArtifactChunk',
  generateArtifactComplete = 'generateArtifactComplete',
  generateDatasetComplete = 'generateDatasetComplete',
  generateDatasetCandidatesComplete = 'generateDatasetCandidatesComplete',
  generateArtifactTemplateCandidatesInProgress = 'generateArtifactTemplateCandidatesInProgress',
  generateArtifactTemplateCandidatesComplete = 'generateArtifactTemplateCandidatesComplete',
  explainArtifactSnippetComplete = 'explainArtifactSnippetComplete',
  explainArtifactSnippetChunk = 'explainArtifactSnippetChunk',
  explainArtifactSnippetSourcesComplete = 'explainArtifactSnippetSourcesComplete',
  regenHighlightedTextChunk = "regenHighlightedTextChunk",
  regenHighlightedTextComplete = "regenHighlightedTextComplete",
}

export default class WebSocketManager {
  private socket: WebSocket | null = null;
  private authToken: string;
  private listeners: Map<string, Set<Listener>> = new Map();
  private url: string;
  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectInterval: number = 5000; // 5 seconds
  private heartbeatInterval: NodeJS.Timeout | null = null;

  constructor(url: string, authToken: string) {
    this.url = url;
    this.authToken = authToken;
  }

  private startHeartbeat(intervalMs = 1000 * 60 * 2) {  // 2 minutes
    const heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify({ action: '@/heartbeat' }));
      } else {
        clearInterval(heartbeatInterval);
      }
    }, intervalMs);

    return heartbeatInterval;
  }

  private createSocket(): void {
    this.socket = new WebSocket(this.url, [this.authToken]);

    this.socket.onopen = () => {
      console.log('WebSocket connected');
      this.reconnectAttempts = 0;
      this.heartbeatInterval = this.startHeartbeat();
    };

    this.socket.onmessage = (event: MessageEvent) => {
      const data = JSON.parse(event.data);
      if (this.listeners.has(data.action)) {
        this.listeners.get(data.action)!.forEach(callback => callback(data.data));
      }
    };

    this.socket.onclose = (event: CloseEvent) => {
      console.log('WebSocket disconnected:', event.reason);
      if (this.heartbeatInterval) {
        clearInterval(this.heartbeatInterval);
      }
      this.reconnect();
    };

    this.socket.onerror = (error: Event) => {
      console.error('WebSocket error:', error);
    };
  }

  private reconnect(): void {
    if (this.reconnectAttempts >= this.maxReconnectAttempts) {
      console.log('Max reconnection attempts reached');
      return;
    }

    this.reconnectAttempts++;
    console.log(`Attempting to reconnect (${this.reconnectAttempts}/${this.maxReconnectAttempts})...`);

    setTimeout(() => {
      this.connect();
    }, this.reconnectInterval);
  }

  addListener(event: MessageAction, callback: Listener, entityId?: string): void {
    const key = entityId ? `${event}-${entityId}` : event;
    if (!this.listeners.has(key)) {
      this.listeners.set(key, new Set());
    }
    this.listeners.get(key)!.add(callback);
  }

  removeListener(event: MessageAction): void {
    if (this.listeners.has(event)) {
      this.listeners.delete(event);
    }
  }

  sendMessage(message: object): void {
    if (this.socket && this.isReady()) {
      this.socket.send(JSON.stringify(message));
    } else {
      console.warn('WebSocket is not open. Message not sent.');
    }
  }

  isReady(): boolean {
    return this.socket !== null && this.socket.readyState === WebSocket.OPEN;
  }

  connect(): void {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      this.createSocket();
    }
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close();
    }
  }
}
