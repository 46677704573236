import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

type BadgeButtonProps = {
  data?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  className?: string;
  title?: string;
};

export default function BadgeButton({
  data,
  icon,
  onClick,
  className,
  title
}: BadgeButtonProps) {
  if (!icon && !data) return null;

  return (
    <Button
      title={title}
      variant="badge"
      onClick={onClick}
      className={cn(
        "transition-all duration-500 ease-in-out shadow h-[1.2rem] sm:h-[2rem]",
        className
      )}
    >
      <span className="text-[10px] sm:text-[0.75rem] flex gap-2 truncate items-center">
        {!!icon && icon}
        {data}
      </span>
    </Button>
  );
}
