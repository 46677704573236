import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { useState } from "react";
import { DateRange } from "react-day-picker";

export default function DateSelect() {
  const [date, setDate] = useState<DateRange | undefined>();
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="dropdown" id="date">
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, "LLL dd, y")} -{" "}
                {format(date.to, "LLL dd, y")}
              </>
            ) : (
              format(date.from, "LLL dd, y")
            )
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-auto flex-col space-y-2 p-0 mt-2 border-0 border-none overflow-hidden rounded-[8px]">
        <div className="rounded-[8px]">
          <Calendar
            initialFocus
            mode="range"
            // defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
}
