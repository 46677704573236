import { createContext, useState, ReactNode } from "react";
import { Editor } from "@tiptap/react";
import { TileType, Tile } from "@/containers/ReviewBuilder/review-types";
// import { Layout } from "react-grid-layout";
import { getDefaultTileDimensions } from "./gridUtils";

type ActiveEditorInfo = {
  editor: Editor | null;
  isToolbarActive: boolean;
};

type GridContextType = {
  activeEditorInfo: ActiveEditorInfo;
  setActiveEditorInfo: React.Dispatch<React.SetStateAction<ActiveEditorInfo>>;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  isPresentation: boolean;
  setIsPresentation: (value: boolean) => void;
  isSlideView: boolean;
  setIsSlideView: (value: boolean) => void;
  onDragStart: (type: TileType) => void;
  //   onAddNewTile: (type: TileType, layout: Layout) => void;
  droppingItem: { i: string; w: number; h: number } | undefined;
  activeTile: Tile | null;
  setActiveTile: (tile: Tile | null) => void;
  hoveredTile: string | null
  setHoveredTile: (tileId: string | null) => void;
};

export const GridContext = createContext<GridContextType>({
  activeEditorInfo: { editor: null, isToolbarActive: false },
  setActiveEditorInfo: () => {},
  isEditMode: false,
  setIsEditMode: () => {},
  isPresentation: false,
  setIsPresentation: () => {},
  isSlideView: false,
  setIsSlideView: () => {},
  onDragStart: () => {},
  //   onAddNewTile: () => {},
  droppingItem: undefined,
  activeTile: null,
  setActiveTile: () => {},
  hoveredTile: null,
  setHoveredTile: () => {}
});

export default function GridProvider({ children }: { children: ReactNode }) {
  const [activeEditorInfo, setActiveEditorInfo] = useState<ActiveEditorInfo>({
    editor: null,
    isToolbarActive: false
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPresentation, setIsPresentation] = useState(false);
  const [isSlideView, setIsSlideView] = useState(false);
  const [droppingItem, setDroppingItem] = useState<
    { i: string; w: number; h: number } | undefined
  >();
  const [activeTile, setActiveTile] = useState<Tile | null>(null);
  const [hoveredTile, setHoveredTile] = useState<string | null>(null);

  const onDragStart = (type: TileType) => {
    setDroppingItem(undefined);
    if (type) {
      const defaultDimensions = getDefaultTileDimensions(type);
      setDroppingItem({
        i: "__dropping-elem__",
        w: defaultDimensions.width,
        h: defaultDimensions.height
      });
    }
  };

  return (
    <GridContext.Provider
      value={{
        activeEditorInfo,
        setActiveEditorInfo,
        isEditMode,
        setIsEditMode,
        isPresentation,
        setIsPresentation,
        isSlideView,
        setIsSlideView,
        onDragStart,
        // onAddNewTile,
        droppingItem,
        activeTile,
        setActiveTile,
        hoveredTile,
        setHoveredTile
      }}
    >
      {children}
    </GridContext.Provider>
  );
}
