import { useState } from "react";
import type { CalendarEvent } from "./calendar-types";
import {
  parseRrule,
  getEventWeeks,
  getWeekNumber
  //   getQuarterInfo
  // getWeekOfMonth
} from "./utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger
} from "@/components/ui/tooltip";
import { Selector } from "@/components/Selector/Selector";
import { RhythmAccordion } from "./RhythmAccordion";
import useRhythmContext from "./useRhythmContext";
import { NextBestActions } from "./RhythmViews";
import BrevLoader from "@/components/Loaders/BrevLoader";

function getCurrentQuarter(): { start: Date; end: Date } {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  let start: Date, end: Date;

  if (currentMonth < 3) {
    start = new Date(currentDate.getFullYear(), 0, 1);
    end = new Date(currentDate.getFullYear(), 2, 31);
  } else if (currentMonth < 6) {
    start = new Date(currentDate.getFullYear(), 3, 1);
    end = new Date(currentDate.getFullYear(), 5, 30);
  } else if (currentMonth < 9) {
    start = new Date(currentDate.getFullYear(), 6, 1);
    end = new Date(currentDate.getFullYear(), 8, 30);
  } else {
    start = new Date(currentDate.getFullYear(), 9, 1);
    end = new Date(currentDate.getFullYear(), 11, 31);
  }

  return { start, end };
}

export default function QuarterView({
  isLoading,
  setCurrentEvent
}: {
  isLoading: boolean;
  setCurrentEvent: (event: CalendarEvent) => void;
}) {
  const currQuarter = getCurrentQuarter();
  const quarterStart = getWeekNumber(currQuarter.start);
  const quarterEnd = getWeekNumber(currQuarter.end);
  const { openEventId, selectedEvents } = useRhythmContext();
  // const [selectedQuarter, _setSelectedQuarter] = useState(2);
  const [highlight, setHighlight] = useState<number | null>(null);
  const [quarter, setQuarter] = useState("Q2");

  // const quarterInfo = getQuarterInfo(selectedMonth);
  // const firstWeek = getWeekNumber(quarterInfo.startDate);
  const weekNumbers: number[] = [];
  // const numWeeks = quarterInfo.numWeeks;
  for (let i = quarterStart; i <= quarterEnd; i++) {
    weekNumbers.push(i);
  }

  return !isLoading ? (
    <div className="flex flex-col h-full w-full overflow-hidden">
      <div className="flex items-center justify-between w-full bg-brev text-[#FFF] h-[39px] text-xs font-bold rounded-[8px]">
        <div className="min-w-[230px]">
          <Selector
            title="Select"
            itemList={["Q1", "Q2", "Q3", "Q4"]}
            value={quarter}
            defaultValue="Period"
            onChange={(value) => setQuarter(value)}
            className="bg-brev selection:font-normal text-xs text-[#FFF] w-[140px]"
          />
        </div>
        <div className="flex justify-between w-full px-4">
          {weekNumbers.map((_, index) => {
            return (
              <div
                key={index}
                className="max-w-[120px] flex text-center transition-all"
              >
                <div className={`rounded-full w-[24px]`}>W{index + 1}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="space-y-5 py-4 overflow-y-scroll no-scrollbar max-h-[calc(100vh-320px)]">
        {selectedEvents.map((event, index) => {
          const parsedRrule = event.recurrence
            ? parseRrule(event.recurrence[event.recurrence.length - 1])
            : undefined;

          const weeks = getEventWeeks(
            parsedRrule!,
            new Date(event.start.dateTime)
          );
          const extraAttendees =
            event.attendees && event.attendees?.length > 3
              ? event.attendees?.length - 3
              : 0;

          return (
            <div
              className="group flex w-full my-5"
              key={event.id}
              onMouseEnter={() => setHighlight(index)}
              onMouseLeave={() => setHighlight(null)}
            >
              <RhythmAccordion
                event={event}
                isHighlighted={highlight === index}
              />
              <div className="flex flex-col w-full">
                <div className="flex justify-between w-full px-4">
                  {weekNumbers.map((week) => {
                    return !weeks || weeks?.includes(week) ? (
                      <TooltipProvider key={week}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div
                              className="max-w-[120px] h-[33px] flex items-center justify-center group-hover:scale-[1.1] hover:scale-[1.2] transition-all"
                              onMouseDown={() => setCurrentEvent(event)}
                            >
                              <div className="w-full flex items-center justify-center h-full hover:scale-[1.1] transition-all duration-200">
                                <div
                                  className={`rounded-full flex w-[24px] h-[13px] cursor-pointer group-hover:shadow-md divide-x-2 divide-black/20 overflow-hidden`}
                                  style={{
                                    backgroundColor:
                                      index === highlight
                                        ? event.colour
                                        : "#C1C6D6"
                                  }}
                                >
                                  {parsedRrule?.days &&
                                    parsedRrule.days.map((_, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="w-full"
                                        ></div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent
                            className="p-0"
                            side="bottom"
                            // sideOffset={0}
                            align="end"
                            alignOffset={-150}
                          >
                            <div className="flex flex-col gap-2 rounded-[8px]">
                              <span
                                style={{ backgroundColor: event.colour }}
                                className="text-dark-text w-full p-3 backdrop-brightness-50"
                              >
                                {event.summary}
                              </span>
                              <div className="flex justify-around items-center pb-2">
                                <div className="flex pl-2">
                                  {event.attendees?.slice(0, 3).map((a) => {
                                    return (
                                      <div
                                        key={a.email}
                                        className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border border-black bg-white text-black"
                                      >
                                        {a.email.charAt(0)}
                                      </div>
                                    );
                                  })}
                                  {extraAttendees > 0 && (
                                    <div className="-ml-1 flex items-center justify-center rounded-full h-[19px] w-[19px] border-2 border-black bg-black text-white font-medium">
                                      {extraAttendees}
                                    </div>
                                  )}
                                </div>
                                <span className="lowercase first-letter:uppercase px-3">
                                  {parsedRrule?.frequency}
                                </span>
                              </div>
                              {/* <div className="flex justify-center pb-2">
                                  {parsedRrule?.days && (
                                    <div className="flex w-full items-center justify-center px-3 pb-3 divide-x-2 divide-brev">
                                      {parsedRrule.days.map((d) => {
                                        return (
                                          <span key={d} className="p-1">
                                            {d}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  )}
                                </div> */}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : (
                      <div
                        key={week}
                        className="max-w-[120px] h-[33px] flex items-center justify-center group-hover:scale-[1.1] hover:scale-[1.2] transition-all"
                      >
                        <div className="w-full flex items-center justify-center h-full hover:scale-[1.1] transition-all duration-200">
                          <div
                            className={`rounded-full w-[24px] h-[13px] cursor-pointer`}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {event.id === openEventId && <NextBestActions />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  ) : (
    <div className="flex justify-center items-center h-96 w-full">
      <BrevLoader type="propagate" size={20} />
    </div>
  );
}
