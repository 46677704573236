import { TabularData } from "../review-types";
import { formatNumber } from "@/lib/utils";

type TableWidgetProps = {
  content: TabularData;
  isPreview?: boolean;
};

export default function TableWidget({ content }: TableWidgetProps) {
  return (
    <div className="h-full overflow-y-scroll no-scrollbar">
      {content.col_headers.length > 0 && content.rows.length > 0 ? (
        <div className="overflow-x-auto my-4 rounded-lg">
          <table className="w-full">
            <thead>
              <tr className="bg-light-alt dark:bg-dark-alt transition-colors duration-500 ease-in-out">
                {content.col_headers.map((header, index) => (
                  <th
                    key={index}
                    className="px-6 py-3 text-left text-sm font-medium text-brev-gray uppercase tracking-wider"
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-light-main dark:bg-dark-main divide-y transition-colors duration-500 ease-in-out">
              {content.rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className="even:bg-light-alt dark:even:bg-dark-alt text-dark-text dark:text-light-text transition-colors duration-500 ease-in-out"
                >
                  {content.row_headers.length > 0 &&
                    content.col_headers.length > content.rows[0].length && (
                      <td
                        key={`rowHeader-${rowIndex}`}
                        className="px-6 py-4 whitespace-nowrap text-sm"
                      >
                        {content.row_headers[rowIndex]}
                      </td>
                    )}
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className="px-6 py-4 whitespace-nowrap text-sm"
                    >
                      {typeof cell === "number" ? formatNumber(cell, 2) : cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="px-6 py-4">
          <p className="text-gray-500">No table data available.</p>
        </div>
      )}
    </div>
  );
}
