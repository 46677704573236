import { useEffect, useRef, useContext } from "react";
import { TextData } from "@/containers/ReviewBuilder/review-types";
import EditableText from "../EditableText";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import { Markdown } from "tiptap-markdown";
import { GridContext } from "../GridProvider";
import "../tiptap.scss";
import { Link } from "@tiptap/extension-link";

type SlideTextProps = {
  content: TextData;
  updateTileContent: (content: Partial<TextData>) => void;
  editable?: boolean;
};

export default function SlideText({
  content,
  updateTileContent,
  editable = false
}: SlideTextProps) {
  const isExternalUpdate = useRef(false);
  const lastKnownContent = useRef(content.markdown_content);
  const containerRef = useRef<HTMLDivElement>(null);
  const { activeEditorInfo, setActiveEditorInfo } = useContext(GridContext);

  const handleTitleChange = (value: string) => {
    updateTileContent({ title: value });
  };

  const editor = useEditor({
    extensions: [
      StarterKit,
      Highlight.configure({
        multicolor: true
      }),
      Typography,
      Markdown.configure({
        transformPastedText: true,
        transformCopiedText: true,
        breaks: true
      }),
      Image,
      TextAlign.configure({
        types: ["heading", "paragraph"]
      }),
      Link.configure({
        openOnClick: true,
        linkOnPaste: true,
        autolink: true,
        defaultProtocol: "https",
        HTMLAttributes: {
          class: "cursor-pointer",
          rel: "noopener noreferrer",
          target: "_blank"
        }
      })
    ],
    content: content.markdown_content,
    editorProps: {
      attributes: {
        class:
          "p-1 prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl focus:outline-none"
      }
    },
    onUpdate: ({ editor }) => {
      const newContent = editor.storage.markdown.getMarkdown();
      if (
        !isExternalUpdate.current &&
        newContent !== lastKnownContent.current
      ) {
        lastKnownContent.current = newContent;
        updateTileContent({
          markdown_content: newContent
        });
      }
    },
    editable: editable
  });

  useEffect(() => {
    if (editor && content.markdown_content !== lastKnownContent.current) {
      isExternalUpdate.current = true;
      editor.commands.setContent(content.markdown_content);
      lastKnownContent.current = content.markdown_content;
      isExternalUpdate.current = false;
    }
  }, [editor, content.markdown_content]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && editor) {
      const handleFocus = () => {
        console.log("handleFocus", editor);
        setActiveEditorInfo((prev) => ({
          editor,
          isToolbarActive: prev.isToolbarActive
        }));
      };

      const handleBlur = (e: FocusEvent) => {
        const isInDropdown =
          e.relatedTarget &&
          (e.relatedTarget as Element).closest('[role="menu"]');
        if (
          !container.contains(e.relatedTarget as Node) &&
          !isInDropdown &&
          !activeEditorInfo.isToolbarActive
        ) {
          setActiveEditorInfo({ editor: null, isToolbarActive: false });
        }
      };

      container.addEventListener("focusin", handleFocus);
      container.addEventListener("focusout", handleBlur);

      return () => {
        container.removeEventListener("focusin", handleFocus);
        container.removeEventListener("focusout", handleBlur);
      };
    }
  }, [editor, setActiveEditorInfo, activeEditorInfo.isToolbarActive]);

  return (
    <div
      className="relative flex flex-col w-full h-full pt-0 gap-1"
      ref={containerRef}
    >
      <EditableText
        value={content.title || "No title"}
        onSave={handleTitleChange}
        className="sticky top-0 text-2xl font-bold text-wrap"
        editable={editable}
      />
      <div className="h-full text-sm mt-1 overflow-y-auto scrollbar-webkit-standard">
        <EditorContent editor={editor} className="tiptap-editor" />
      </div>
    </div>
  );
}
