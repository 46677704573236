export default function ContextSvg({
  color,
  size
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size || 14}
      height={size || 14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2029 10.0815L7.77734 12.1376V6.91502L12.444 4.74777V9.7518C12.444 9.88711 12.3522 10.0131 12.2029 10.0815ZM1.79037 10.0823C1.64337 10.0139 1.55315 9.88789 1.55471 9.74636V4.74777L6.22135 6.91502V12.1391L1.79037 10.0823ZM6.77366 1.60654C6.84444 1.5731 6.923 1.55677 7.00155 1.55677C7.07933 1.55677 7.15789 1.5731 7.22866 1.60654L11.3734 3.53039L7.00155 5.56155L2.62891 3.53039L6.77366 1.60654ZM13.7324 3.31308C13.7301 3.30686 13.7309 3.29986 13.7278 3.29364C13.7254 3.28742 13.7192 3.28353 13.7153 3.27731C13.6796 3.21821 13.636 3.16611 13.5948 3.11167C13.5707 3.08523 13.5512 3.05568 13.5248 3.03313C13.342 2.81851 13.1266 2.62954 12.859 2.5059L7.88125 0.194018C7.88047 0.194018 7.87969 0.194018 7.87969 0.193241C7.32048 -0.0649314 6.67881 -0.0641538 6.11804 0.194018L1.14261 2.50512C0.87506 2.62877 0.658839 2.81695 0.476062 3.03158C0.447285 3.05568 0.426285 3.08834 0.400618 3.11712C0.360174 3.16922 0.318952 3.21976 0.28473 3.27575C0.280841 3.28275 0.274619 3.28742 0.271508 3.29364C0.268397 3.30064 0.269174 3.30764 0.266063 3.31463C0.101953 3.59613 6.44335e-05 3.91263 6.44335e-05 4.24545V9.74094C-0.00615776 10.4797 0.438729 11.1671 1.13406 11.4922L6.11104 13.8033C6.39259 13.9347 6.69437 14 6.99692 14C7.2987 14 7.60047 13.9347 7.88125 13.804L12.8567 11.4929C13.5504 11.1726 13.9992 10.4898 14 9.7526V4.24468C13.9992 3.91185 13.8973 3.59536 13.7324 3.31308Z"
        fill={color || "#3FCFF1"}
        className="transition-colors duration-300 ease-in-out"
      />
    </svg>
  );
}
