import { cn } from "@/lib/utils";
import {
  PulseLoader,
  GridLoader,
  PuffLoader,
  PropagateLoader,
  ClipLoader,
  BounceLoader
} from "react-spinners";
import RingLoader from "./RingLoader";

const loaderComponents = {
  pulse: PulseLoader,
  grid: GridLoader,
  puff: PuffLoader,
  propagate: PropagateLoader,
  clip: ClipLoader,
  bounce: BounceLoader,
  ring: RingLoader
};

export default function BrevLoader({
  type = "propagate",
  className,
  size,
  color = "#3FCFF1",
  speed = 0.6,
  loadingId = "loader"
}: {
  type?: "pulse" | "grid" | "puff" | "propagate" | "clip" | "bounce" | "ring";
  className?: string;
  size?: number;
  color?: string;
  speed?: number;
  loadingId?: string
}) {
  const LoaderComponent = loaderComponents[type];

  return (
    <LoaderComponent
      className={cn("", className)}
      size={size || 18}
      color={color}
      speedMultiplier={speed}
      loading={true}
      aria-label="Loading Spinner"
      data-loading-id={loadingId}
    />
  );
}
