import { DEFAULT_ASPECT_RATIO, TABLEAU_PLACEHOLDER } from "./embed_types";
import { EmbedType } from "./embed_types";

export const isEmbedCode = (str: string): boolean => {
  return (
    str.trim().startsWith("<iframe") ||
    str.trim().startsWith("<div") ||
    str.trim().includes(TABLEAU_PLACEHOLDER)
  );
};

export const getEmbedAspectRatio = (embedHtml: string) => {
  const widthMatch = embedHtml.match(/width="(\d+)"/);
  const heightMatch = embedHtml.match(/height="(\d+)"/);
  const originalWidth = widthMatch ? parseInt(widthMatch[1]) : null;
  const originalHeight = heightMatch ? parseInt(heightMatch[1]) : null;

  return originalWidth && originalHeight
    ? (originalHeight / originalWidth) * 100
    : DEFAULT_ASPECT_RATIO;
};

export const parseTableauEmbed = (embedCode: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(embedCode, "text/html");

  const placeholderDiv = doc.querySelector(`.${TABLEAU_PLACEHOLDER}`);
  const vizId = placeholderDiv?.getAttribute("id");

  const scriptContent = embedCode.match(
    /vizElement\.style\.width='(\d+)px';vizElement\.style\.height='(\d+)px'/
  );
  const width = scriptContent?.[1] || "1050";
  const height = scriptContent?.[2] || "500";

  return { vizId, width, height };
};

const isImageUrl = (url: string) => /\.(jpg|jpeg|png|gif|webp)$/i.test(url);

export const getEmbedType = (url: string) => {
  if (!url) return EmbedType.NONE;
  if (isImageUrl(url)) return EmbedType.IMAGE;
  if (isEmbedCode(url)) {
    if (url.includes(TABLEAU_PLACEHOLDER)) return EmbedType.TABLEAU;
    return EmbedType.EMBED;
  }
  return EmbedType.IFRAME;
};
