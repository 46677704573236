import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { HiSparkles } from "react-icons/hi2";

type IconButtonProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  start?: React.ReactNode;
  end?: React.ReactNode;
  isLoading?: boolean;
};

export default function IconButton({
  children,
  className,
  onClick,
  disabled,
  start,
  end,
  isLoading
}: IconButtonProps) {
  const defaultStartIcon = !start && !end ? <HiSparkles size={14} /> : null;

  return (
    <Button
      variant="gradient"
      className={cn("justify-center gap-1.5 font-normal", className)}
      size="sm"
      disabled={disabled}
      onClick={onClick}
      isLoading={isLoading}
    >
      {start || defaultStartIcon}
      {children}
      {end}
    </Button>
  );
}
