import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import EditableText from "../EditableText";
import { GoalData, Status } from "@/containers/ReviewBuilder/review-types";
import { Badge } from "@/components/ui/badge";
import { LuTarget } from "react-icons/lu";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import Hint from "@/components/Tooltips/Hint";

type StatusConfig = {
  bgColor: string;
  strokeColor: string;
  icon?: React.ComponentType<{ size: number }>;
};

const StatusColorMap: Record<Status, StatusConfig> = {
  [Status.AtRisk]: { bgColor: "bg-[#ED6E60]", strokeColor: "#ED6E60" },
  [Status.Behind]: { bgColor: "bg-[#F4CF50]", strokeColor: "#F4CF50" },
  [Status.OnTrack]: { bgColor: "bg-[#3DDE7D]", strokeColor: "#3DDE7D" },
  [Status.NoStatus]: {
    bgColor: "",
    strokeColor: "url(#gradient)",
    icon: LuTarget
  }
};

type SlideGoalProps = {
  data: GoalData;
  updateTileContent: (goal: Partial<GoalData>) => void;
  editable?: boolean;
};
export default function SlideGoal({
  data,
  updateTileContent,
  editable = true
}: SlideGoalProps) {
  const [status, setStatus] = useState<Status>(data.status || Status.NoStatus);
  const [percentage, setPercentage] = useState(0);
  const [title, setTitle] = useState(data.title);

  useEffect(() => {
    const newPercentage = calcPercentage(
      data?.current_value || 0,
      data?.goal_value || 0
    );
    setPercentage(newPercentage);
  }, [data]);

  const handlePercentageChange = (value: string) => {
    const newPercentage = Math.min(100, Math.max(0, parseInt(value) || 0));
    setPercentage(newPercentage);
    updateTileContent({ current_value: newPercentage, goal_value: 100 });
  };

  const handleTitleChange = (value: string) => {
    setTitle(value);
    updateTileContent({ title: value });
  };
  const handleStatusChange = (newStatus: Status) => {
    setStatus(newStatus);
    updateTileContent({ status: newStatus });
  };

  return (
    <motion.div className="group relative flex flex-col w-full h-full gap-3">
      <EditableText
        value={title || "No title"}
        onSave={handleTitleChange}
        className="sticky top-0 font-bold text-wrap h-[36px] min-h-[36px] max-h-[36px] overflow-y-scroll no-scrollbar break-words"
        editable={editable}
        scaleFontSize={true}
        minFontSize={12}
        maxFontSize={26}
        minLength={8}
        maxLength={60}
      />
      <ProgressSvg
        percentage={percentage}
        onPercentageChange={handlePercentageChange}
        status={status}
        editable={editable}
      />
      <StatusSelector
        status={status}
        onStatusChange={handleStatusChange}
        disabled={!editable}
      />
    </motion.div>
  );
}

const calcPercentage = (current_value: number, goal_value: number) =>
  goal_value === 0 ? 0 : (current_value / goal_value) * 100;

const ProgressSvg = ({
  percentage,
  onPercentageChange,
  status,
  editable = true
}: {
  percentage: number;
  onPercentageChange: (value: string) => void;
  status: Status;
  editable?: boolean;
}) => {
  const radius = 90;
  const circumference = radius * Math.PI;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="relative flex justify-center h-full items-center">
      <div className="absolute h-full w-full place-items-center inset-0 top-0 left-0 right-0 bottom-0">
        <div className="flex items-center justify-center h-full w-full">
          <EditableText
            value={`${Math.round(percentage)}%`}
            onSave={onPercentageChange}
            className="text-center text-[36px] font-bold mt-12"
            editable={editable}
            maxLength={3}
          />
        </div>
      </div>
      <svg width="200" height="150" viewBox="0 0 200 150">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#ED6E60" />
            <stop offset="25%" stopColor="#f1ca07" />
            <stop offset="50%" stopColor="#F4CF50" />
            <stop offset="75%" stopColor="#99D767" />
            <stop offset="100%" stopColor="#3DDE7D" />
          </linearGradient>
        </defs>
        <path
          d="M10 110 A90 90 0 0 1 190 110"
          fill="none"
          stroke="#e5e7eb"
          strokeWidth="20"
          strokeLinecap="round"
        />
        <path
          d="M10 110 A90 90 0 0 1 190 110"
          fill="none"
          stroke={StatusColorMap[status].strokeColor}
          strokeWidth="20"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{
            transition: "stroke-dashoffset 0.3s ease, stroke 0.3s ease"
          }}
        />
      </svg>
    </div>
  );
};

const StatusBadge = ({ status }: { status: Status }) => {
  const config = StatusColorMap[status];

  if (config.icon) {
    return (
      <Badge variant="outline" className="p-1">
        <config.icon size={16} />
      </Badge>
    );
  }

  return (
    <Badge variant="outline" className={config.bgColor}>
      {status}
    </Badge>
  );
};

const StatusSelector = ({
  status,
  onStatusChange,
  disabled = false
}: {
  status: Status;
  onStatusChange: (status: Status) => void;
  disabled?: boolean;
}) => (
  <Select
    onValueChange={(value) => onStatusChange(value as Status)}
    disabled={disabled}
  >
    <SelectTrigger asChild>
      <Button variant="ghost" className="p-0">
        <Hint message="Goal status" side="bottom" offset={10}>
          <StatusBadge status={status} />
        </Hint>
      </Button>
    </SelectTrigger>
    <SelectContent className="data-[state=open]:rounded-t-lg bg-light-alt dark:bg-dark-alt text-dark-text dark:text-light-text max-w-[4rem]">
      <SelectGroup>
        <SelectLabel className="text-dark-text dark:text-light-text">
          Goal Status
        </SelectLabel>
        {Object.values(Status).map((statusOption) => (
          <SelectItem key={statusOption} value={statusOption}>
            {statusOption}
          </SelectItem>
        ))}
      </SelectGroup>
    </SelectContent>
  </Select>
);
