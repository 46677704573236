import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { createContext, ReactNode } from "react";
import { AuthContext } from "../Auth/AuthProvider";
import { IntegrationApp, IntegrationConnection, IntegrationStatus } from "./types";
import { useSearchParams } from "react-router-dom";

interface IntegrationConnectionsContext {
  connections: IntegrationConnection[];
  setConnections: React.Dispatch<React.SetStateAction<IntegrationConnection[]>>;
  isAppConnected: (app: IntegrationApp) => boolean;
  isAppActive: (app: IntegrationApp) => boolean;
  getAppConnection: (app: IntegrationApp) => IntegrationConnection | undefined;
  fetchConnections: () => Promise<void>;
  isLoading: boolean;
}

/**
 * Initial State
 */
export const IntegrationConnectionsContext = createContext<IntegrationConnectionsContext>({
  connections: [],
  setConnections: () => {
    throw new Error("No SlackContext available");
  },
  isAppConnected: () => {
    throw new Error("Not implemented");
  },
  isAppActive: () => {
    throw new Error("Not implemented");
  },
  getAppConnection: () => {
    throw new Error("Not implemented");
  },
  fetchConnections: () => {
    throw new Error("Not implemented");
  },
  isLoading: false,
});

type IntegrationConnectionsProviderProps = {
  children: ReactNode;
};
export const IntegrationConnectionsProvider = ({
  children
}: IntegrationConnectionsProviderProps) => {
  const { sendApiRequest } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [connections, setConnections] = useState<IntegrationConnection[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isFetchRequestSent = useRef(false);

  const isAppConnected = useCallback((app: IntegrationApp) => {
    // console.log(`connections: ${JSON.stringify(connections)}`);
    return connections.some((connection) => 
      connection.integrationApp === app && 
      connection.integrationStatus === IntegrationStatus.CONNECTED
    )
  }, [
    connections,
  ]);

  const isAppActive = useCallback((app: IntegrationApp) => {
    if (searchParams.get("iid")) {
      const integrationId = searchParams.get("iid");
      return connections.some((connection) => 
        connection.integrationId === integrationId &&
        connection.integrationApp === app && 
        connection.integrationStatus === IntegrationStatus.CONNECTED
      )
    }
    return false;
  }, [searchParams, connections]);

  const getAppConnection = useCallback((app: IntegrationApp) => {
    return connections.find((connection) => connection.integrationApp === app);
  }, [connections]);

  const fetchConnections = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await sendApiRequest(
        "/integrations",
        'GET'
      ) as { data: { integrations: IntegrationConnection[] } };
      if (response.data.integrations) {
        setConnections(response.data.integrations);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [sendApiRequest]);

  useEffect(() => {
    if (isLoading) return;
    if (isFetchRequestSent.current) return;
    fetchConnections();
    isFetchRequestSent.current = true;
  }, []);

  const values = useMemo(
    () => ({
      connections,
      setConnections,
      isAppConnected,
      isAppActive,
      getAppConnection,
      fetchConnections,
      isLoading,
    }),
    [
      connections,
      setConnections,
      isAppConnected,
      isAppActive,
      getAppConnection,
      fetchConnections,
      isLoading,
    ]
  );

  return (
    <IntegrationConnectionsContext.Provider value={values as IntegrationConnectionsContext}>
      {children}
    </IntegrationConnectionsContext.Provider>
  );
};
